import React, { useState } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

import states from './states';
import countries from './countries';

import {
  Button,
  Checkbox,
  TextField,
  NTextField,
  DropdownSelect,
  DatePickerPlus,
} from '@/components/ui';

export interface ProfileEditFormProps {
  data?: Array<{ label: string; value: any; type: string }>;
  toggleModal?: any;
  handleFormSubmit: any;
  formType: string;
  displayFormType?: () => void;
}

export const ProfileEditForm: React.FC<ProfileEditFormProps> = ({
  data,
  toggleModal,
  handleFormSubmit,
  formType,
  ...props
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootStateOrAny) => {
    return state.user.user;
  });

  const [userInfo, setUserInfo] = useState(user);

  const handleInputChange = (key, value) => {
    setUserInfo((userInfo) => ({
      ...userInfo,
      [key]: value,
    }));
  };

  const handleOnSubmit = (evt) => {
    evt.preventDefault();
    dispatch(handleFormSubmit(userInfo));
    toggleModal();
  };

  const textFieldType = (type) => {
    switch (type) {
      case 'Email Address':
        return 'email';
      default:
        return 'text';
    }
  };

  const ssnValidation = () => {
    const re = /^\d{9}/;
    if (
      re.test(userInfo['socialSecurityNumber']) &&
      userInfo['socialSecurityNumber'].length === 9
    ) {
      return true;
    }
    return false;
  };

  const minTwoCharValidation = (text) => {
    const re = /^[a-zA-Z]{2}/;
    if (re.test(text)) {
      return true;
    }
    return false;
  };

  const displayFields = (data) => {
    return data.map((elem, idx) => {
      switch (elem.type) {
        case 'checkbox':
          let backgroundColor = userInfo[elem.name] ? "black" : "white";
          return (
            <label style={{fontSize: '0.875rem', marginLeft: 6}}>
              {elem.label}
              <input
                type="checkbox"
                name={elem.name}
                checked={userInfo[elem.name] || false}
                style={{marginLeft: 5, width: 13, height: 13, border: '1px solid', background: backgroundColor}}
                onChange={(value) => {handleInputChange(elem.name, !userInfo[elem.name]) } }
                key={idx}
              />
            </label>
          );
        case 'string':
          return (
            <NTextField
              name={elem.name}
              key={idx}
              label={elem.label}
              value={userInfo[elem.name] || ''}
              type={textFieldType(elem.label)}
              required={true}
              error={
                userInfo[elem.name] &&
                userInfo[elem.name].replace(/\s/g, '').length
                  ? false
                  : true
              }
              errorMessage={
                userInfo[elem.name] &&
                userInfo[elem.name].replace(/\s/g, '').length > 0
                  ? null
                  : 'This field is required'
              }
              validation={false}
              onChange={(value) => handleInputChange(elem.name, value)}
            />
          );
        case 'address':
          if (elem.label === 'State') {
            return (
              <DropdownSelect
                key={idx}
                label={elem.label}
                name={elem.name}
                options={states}
                required={true}
                error={
                  userInfo[elem.name] && userInfo[elem.name].length > 0
                    ? false
                    : true
                }
                errorMessage={
                  userInfo[elem.name] && userInfo[elem.name].length > 0
                    ? null
                    : 'This field is required'
                }
                selectedValue={userInfo[elem.name]}
                onClick={(value) => handleInputChange(elem.name, value)}
              />
            );
          } else if (elem.label === 'Country') {
            return (
              <DropdownSelect
                key={idx}
                label={elem.label}
                name={elem.name}
                options={countries}
                required={true}
                error={
                  userInfo[elem.name] && userInfo[elem.name].length > 0
                    ? false
                    : true
                }
                errorMessage={
                  userInfo[elem.name] && userInfo[elem.name].length > 0
                    ? null
                    : 'This field is required'
                }
                selectedValue={userInfo[elem.name]}
                onClick={(value) => handleInputChange(elem.name, value)}
              />
            );
          } else if (elem.label === 'Address Line 2') {
            return (
              <NTextField
                key={idx}
                label={elem.label}
                name={elem.name}
                value={userInfo[elem.name] || ''}
                validation={false}
                required={false}
                type="text"
                onChange={(value) => handleInputChange(elem.name, value)}
              />
            );
          } else {
            return (
              <NTextField
                key={idx}
                label={elem.label}
                name={elem.name}
                value={userInfo[elem.name] || ''}
                error={
                  userInfo[elem.name] &&
                  userInfo[elem.name].replace(/\s/g, '').length
                    ? false
                    : true
                }
                errorMessage={
                  userInfo[elem.name] &&
                  userInfo[elem.name].replace(/\s/g, '').length > 0
                    ? null
                    : 'This field is required'
                }
                validation={false}
                required={true}
                type={textFieldType(elem.label)}
                onChange={(value) => handleInputChange(elem.name, value)}
              />
            );
          }
        case 'date':
          return (
            <DatePickerPlus
              key={idx}
              label={elem.label}
              initialDate={new Date(userInfo['dateOfBirth'])}
              errorMessage={
                userInfo[elem.name] === null ? 'This field is required' : null
              }
              noFutureDates={false}
              required={true}
              onChange={(value) => handleInputChange(elem.name, value)}
            />
          );
        default:
          break;
      }
    });
  };

  const validateForm = () => {
    if (
      minTwoCharValidation(userInfo['firstName']) &&
      minTwoCharValidation(userInfo['lastName']) &&
      userInfo['dateOfBirth'] !== null &&
      ssnValidation() &&
      userInfo['email'].length > 0 &&
      userInfo['address1'] !== null &&
      userInfo['address1'].length > 0 &&
      userInfo['city'] !== null &&
      userInfo['city'].length > 0 &&
      userInfo['state'] !== null &&
      userInfo['state'].length > 0 &&
      userInfo['zip'] !== null &&
      userInfo['zip'].length >= 5 &&
      userInfo['country'] !== null &&
      userInfo['country'].length >= 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  const displayFormType = (form: string) => {
    switch (form) {
      case 'reset-password':
        return form;
      case 'edit-user-profile':
        return displayFields(data);
      default:
        return form;
    }
  };
  console.log("reredering == ");

  return (
    <form onSubmit={handleOnSubmit}>
      <div>{data && displayFormType(formType)}</div>
      <div className="button-group">
        <Button
          primary={true}
          label="cancel"
          size="large"
          border={true}
          onClick={toggleModal}
        />
        <Button
          primary={false}
          label="save"
          size="large"
          border={true}
          backgroundColor="var(--secondary1)"
          disabled={validateForm()}
          type="submit"
          labelColor="#fff"
        />
      </div>
    </form>
  );
};
