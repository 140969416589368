import React, { useState } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

import { updateDiscoveredData } from '@/actions/actions/discovered_data';

import {
  Button,
  TextField,
  NTextField,
} from '@/components/ui';

export interface AdditionalDataFormProps {
  data?: Array<{ label: string; value: any; type: string }>;
  toggleModal?: any;
  handleFormSubmit: any;
  formType: string;
  displayFormType?: () => void;
}

export const AdditionalDataForm: React.FC<AdditionalDataFormProps> = ({
  data,
  toggleModal,
  handleFormSubmit,
  formType,
  ...props
}) => {
  const dispatch = useDispatch();

  const discoveredUserData = useSelector((state: RootStateOrAny) => {
    return state.discoveredData.discoveredData;
  });

  const [discoveredData, setDiscoveredData] = useState(discoveredUserData);

  const handleInputChange = (id, key, value) => {
    let newData = [...discoveredData];
    newData[id] = {
      key: key,
      value: value.trimStart(),
      status: newData[id].status
    };
    setDiscoveredData(newData);
  };

  const handleOnSubmit = (evt) => {
    evt.preventDefault();
    dispatch(updateDiscoveredData(discoveredData));
    toggleModal();
  };

  const validateForm = () => {
    let isDisabled = false;
    discoveredData.forEach(elem => {
      if (elem.value.trim().length === 0) {
        isDisabled = true;
      }
    })
    return isDisabled;
  }

  const displayFields = (data) => {
    return data.map((elem, idx) => {
      return (
        <NTextField
          name={elem.label}
          key={idx}
          label={elem.label}
          value={discoveredData[elem.id].value || ''}
          type={'text'}
          error={
            discoveredData[elem.id].value &&
              discoveredData[elem.id].value.replace(/\s/g, '').length
              ? false
              : true
          }
          errorMessage={
            discoveredData[elem.id].value &&
              discoveredData[elem.id].value.replace(/\s/g, '').length > 0
              ? null
              : 'This field is required'
          }
          validation={false}
          onChange={(value) => handleInputChange(elem.id, elem.name, value)}
        />
      );
    });
  };

  const displayFormType = (form: string) => {
    switch (form) {
      case 'edit-additional-data':
        return displayFields(data);
      default:
        return form;
    }
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <div>{data && displayFormType(formType)}</div>
      <div className="button-group">
        <Button
          primary={true}
          label="cancel"
          size="large"
          border={true}
          onClick={toggleModal}
        />
        <Button
          primary={false}
          label="save"
          size="large"
          border={true}
          backgroundColor="var(--secondary1)"
          disabled={validateForm()}
          type="submit"
          labelColor="#fff"
        />
      </div>
    </form>
  );
};
