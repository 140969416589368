import { DiscoveredData } from "@/types/discovered_data";

// Action Types
export enum DiscoveredDataActions {
  GET_USER_DISCOVERED_DATA = 'USER/GET_USER_DISCOVERED_DATA',
  GET_USER_DISCOVERED_DATA_SUCCESS = 'USER/GET_USER_DISCOVERED_DATA_SUCCESS',
  GET_USER_DISCOVERED_DATA_FAILURE = 'USER/GET_USER_DISCOVERED_DATA_FAILURE',
  UPDATE_USER_DISCOVERED_DATA = 'USER/UPDATE_USER_DISCOVERED_DATA',
  UPDATE_USER_DISCOVERED_DATA_SUCCESS = 'USER/UPDATE_USER_DISCOVERED_DATA_SUCCESS',
  UPDATE_USER_DISCOVERED_DATA_FAILURE = 'USER/UPDATE_USER_DISCOVERED_DATA_FAILURE',
}

// Action Interfaces
export interface GetDiscoveredData {
  type: typeof DiscoveredDataActions.GET_USER_DISCOVERED_DATA;
  payload: null;
}

export interface GetDiscoveredDataSuccess {
  type: typeof DiscoveredDataActions.GET_USER_DISCOVERED_DATA_SUCCESS;
  payload: {
    discoveredData: DiscoveredData[];
  }
}

export interface GetDiscoveredDataFailure {
  type: typeof DiscoveredDataActions.GET_USER_DISCOVERED_DATA_FAILURE;
  payload: {
    error: string;
  }
}

export interface UpdateDiscoveredData {
  type: typeof DiscoveredDataActions.UPDATE_USER_DISCOVERED_DATA;
  payload: {
    discoveredData: DiscoveredData[];
  }
}

export interface UpdateDiscoveredDataSuccess {
  type: typeof DiscoveredDataActions.UPDATE_USER_DISCOVERED_DATA_SUCCESS;
  payload: {
    discoveredData: DiscoveredData[];
  }
}

export interface UpdateDiscoveredDataFailure {
  type: typeof DiscoveredDataActions.UPDATE_USER_DISCOVERED_DATA_FAILURE;
  payload: {
    error: string;
  }
}



// Action Type List
export type DiscoveredDataAction = GetDiscoveredData | GetDiscoveredDataSuccess | GetDiscoveredDataFailure | UpdateDiscoveredData | UpdateDiscoveredDataSuccess | UpdateDiscoveredDataFailure


// Action Creators
export const getDiscoveredData = (): DiscoveredDataAction => ({
  type: DiscoveredDataActions.GET_USER_DISCOVERED_DATA,
  payload: null,
});

export const getDiscoveredDataSuccess = (discoveredData: DiscoveredData[]): DiscoveredDataAction => ({
  type: DiscoveredDataActions.GET_USER_DISCOVERED_DATA_SUCCESS,
  payload: {discoveredData,}
});

export const getDiscoveredDataFailure = (error: string): DiscoveredDataAction => ({
  type: DiscoveredDataActions.GET_USER_DISCOVERED_DATA_FAILURE,
  payload: {
    error,
  }
});

export const updateDiscoveredData = (discoveredData: DiscoveredData[]): DiscoveredDataAction => ({
  type: DiscoveredDataActions.UPDATE_USER_DISCOVERED_DATA,
  payload: {discoveredData,}
})

export const updateDiscoveredDataSuccess = (discoveredData: DiscoveredData[]): DiscoveredDataAction => ({
  type: DiscoveredDataActions.UPDATE_USER_DISCOVERED_DATA_SUCCESS,
  payload: {discoveredData,}
})

export const updateDiscoveredDataFailure = (error: string): DiscoveredDataAction => ({
  type: DiscoveredDataActions.UPDATE_USER_DISCOVERED_DATA_FAILURE,
  payload: {error,}
})
