import React, { useEffect, useState, useRef } from 'react';

import { Icon, Tooltip } from '@/components/ui';

export interface DropdownSelectProps {
  /**
   * Label for select element
   */
  label?: string;
  /**
   * Name property for select element
   */
  name?: string;
  /**
   * Array of option objects for dropdown menu
   */
  options: Option[];
  /**
   * Optional boolean value for disabling the element
   */
  disabled?: boolean;
  /**
   * Optional boolean value for rendering a null dropdown option
   */
  nullOption?: boolean;
  /**
   * Optional label value for null dropdown option
   */
  nullOptionLabel?: string;
  /**
   * Optional className for select element
   */
  className?: string;
  /**
   * Optional message
   */
  message?: string;
  /**
   * Optional error message
   */
  errorMessage?: string;
  /**
   * Is this field required?
   */
  required?: boolean;
  /**
   * Placeholder text for type ahead input
   */
  placeholder?: string;
  /**
   * Inital/current selected value of dropdown option
   */
  selectedValue?: any;
  /**
   * Render selected option in list? (Optional)
   */
  renderSelectedOption?: boolean;
  /**
   * Render selected option in list? (Optional)
   */
  typeAhead?: boolean;
  /**
   * Optional tooltip text
   */
  tooltipText?: string;
  /**
   * onClick handler
   */
  onClick: (e) => void;
}

export type Option = { value: any; label: string; header?: boolean };

export const DropdownSelect: React.FC<DropdownSelectProps> = ({
  label,
  onClick,
  options,
  disabled = !options && true,
  selectedValue,
  nullOption = false,
  nullOptionLabel,
  className,
  errorMessage,
  required,
  renderSelectedOption,
  message,
  typeAhead = false,
  placeholder,
  tooltipText,
}) => {
  const [value, setValue] = useState<string>(null);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(null);
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(null);
  const [optionSelected, setOptionSelected] = useState<boolean>(false);
  const _dropdownContainer = useRef(null);
  const _inputField = useRef(null);
  const [dropdownContainerWidth, setDropdownContainerWidth] = useState<number>(
    null,
  );

  useEffect(() => {
    if (selectedValue && options) {
      options.map((o) => {
        if (o.value === selectedValue) {
          setValue(o.label);
          typeAhead && setInputValue(o.label);
        }
      });
    } else if (!selectedValue && nullOptionLabel) {
      setValue(nullOptionLabel);
      typeAhead && setInputValue(nullOptionLabel);
    }
  }, [selectedValue, options]);

  const selectedValueHelper = (o) => {
    setDropdownOpen(!dropdownOpen);
    onClick(o.value);
    if (typeAhead) {
      setInputValue(o.label);
      filterOptions(o.label);
      setOptionSelected(true);
    }
  };

  useEffect(() => {
    _dropdownContainer?.current &&
      setDropdownContainerWidth(_dropdownContainer.current.clientWidth);
  }, [_dropdownContainer, selectedValue]);

  const renderOptions = (optionArr) => {
    const _options = optionArr;
    let optionsArr = _options.map((o) => {
      if (renderSelectedOption || o.value !== selectedValue) {
        return (
          <div
            tabIndex={o.header ? null : 0}
            key={o.value}
            className={`dropdown-select-option ${o.header ? 'header' : ''}`}
            onClick={
              !o.header
                ? () => {
                    selectedValueHelper(o);
                  }
                : () => {
                    return null;
                  }
            }
            onKeyDown={(e) => {
              if (
                (!o.header && e.code === 'Enter') ||
                e.code === 'NumpadEnter'
              ) {
                selectedValueHelper(o);
              }
            }}>
            {o.label}
          </div>
        );
      }
    });
    nullOption &&
      optionsArr.unshift(
        <div
          key="null-option"
          className="dropdown-select-option"
          onClick={(e) => onClick(e)}>
          {nullOptionLabel || nullOptionLabel}
        </div>,
      );
    return optionsArr;
  };

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  const handleClickOutside = (e) => {
    if (
      _dropdownContainer.current &&
      !_dropdownContainer.current.contains(e.target)
    ) {
      setDropdownOpen(false);
      !typeAhead && setFilteredOptions(null);
    }
  };

  const filterOptions = (value) => {
    const _filteredOptions = options.filter((option) => {
      return option.label.toLowerCase().includes(value.toLowerCase());
    });

    setFilteredOptions(_filteredOptions);
  };

  const validationCheck = () => {
    if (!optionSelected && !selectedValue) {
      setInputValue(null);
      setValue(null);
    }
  };

  const inputValueHelper = (e) => {
    if (e.type !== 'click') {
      setOptionSelected(false);
      onClick(null);
    }
    optionSelected && setInputValue(e.currentTarget.value);
    setInputValue(e.currentTarget.value);
    filterOptions(e.currentTarget.value);
  };

  const dropdownOpenToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const calculateWidth = () => {
    if (dropdownContainerWidth <= 226) return '100.8%';
    else if (dropdownContainerWidth <= 260) return '100.7%';
    else if (dropdownContainerWidth <= 314) return '100.6%';
    else if (dropdownContainerWidth <= 390) return '100.5%';
    else if (dropdownContainerWidth <= 520) return '100.455%';
    else if (dropdownContainerWidth > 520) return '100.2%';
  };

  return (
    <div
      className={`input-container dropdown-select__container
        ${className ? className : ''}
        ${errorMessage ? 'error' : ''}
        ${selectedValue ? '' : 'empty'}
      `}
      ref={_dropdownContainer}>
      {label && (
        <label
          className={`dropdown-select__container--label ${
            required ? 'field-required' : ''
          }`}>
          {label}
        </label>
      )}
      {tooltipText && <Tooltip text={tooltipText} />}
      <div
        className={`input-inner ${disabled ? 'disabled' : ''}`}
        onClick={!disabled ? dropdownOpenToggle : undefined}>
        <div className="dropdown-select-options">
          {typeAhead ? (
            <input
              ref={_inputField}
              type="text"
              className={`
                  input-container__input--text-field
                  dropdown-select-option value 
                  ${dropdownOpen ? 'open' : ''}
                  ${!selectedValue ? 'empty' : ''}
                  `}
              autoComplete="off"
              placeholder={placeholder}
              value={inputValue || ''}
              onChange={(e) => inputValueHelper(e)}
              onKeyUp={(e) => inputValueHelper(e)}
              onFocus={() => setDropdownOpen(!dropdownOpen)}
              onClick={(e) => e.currentTarget.value && inputValueHelper(e)}
              onBlur={() => validationCheck()}
            />
          ) : (
            <div
              className={`dropdown-select-option value ${
                dropdownOpen ? 'open' : ''
              }
              ${value === nullOptionLabel ? 'null-value' : ''}
              `}
              tabIndex={0}>
              {value || ''}
            </div>
          )}
          <div
            style={{ width: calculateWidth() }}
            className={`dropdown-select-options-list${
              dropdownOpen && !optionSelected ? '-visible' : ''
            }
              ${typeAhead ? 'type-ahead' : ''}
              ${
                typeAhead && filteredOptions && filteredOptions.length === 0
                  ? 'no-results'
                  : ''
              }
            `}>
            {dropdownOpen && renderOptions(filteredOptions || options)}
          </div>
          <Icon
            className={`${typeAhead ? 'type-ahead' : ''} ${
              selectedValue ? 'value-selected' : ''
            }`}
            iconClass={`chevron-down position-right ${
              dropdownOpen ? 'open' : ''
            }`}
          />
        </div>
      </div>
      {message && <p className="dropdown-select__message">{message}</p>}
      {errorMessage && (
        <p className="dropdown-select__error-message">{errorMessage}</p>
      )}
    </div>
  );
};
