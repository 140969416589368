import React from 'react';

import { CustomToastProps } from '@/components/ui/toast';

export const ErrorToast: React.FC<CustomToastProps> = ({
  message
}) => {
  return(
    <div className="toast-card">
      <div className="toast-sidebar__error" />
      <div className="toast-icon toast-icon__error" />
      <div className="toast-content">
        <div className="toast-content__header toast-content__header__error">Uh oh</div>
        <div className="toast-content__message">{message}</div>
      </div>
    </div>
  );
};
