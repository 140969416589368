import { FormBundle } from "@/types/form_bundle";

// Action Types
export enum FormBundlesActions {
  GET_FORM_BUNDLES = 'PACKET/GET_FORM_BUNDLES',
  GET_FORM_BUNDLES_SUCCESS = 'PACKET/GET_FORM_BUNDLES_SUCCESS',
  GET_FORM_BUNDLES_FAILURE = 'PACKET/GET_FORM_BUNDLES_FAILURE',
}

// Action Interfaces
export interface GetFormBundles {
  type: typeof FormBundlesActions.GET_FORM_BUNDLES;
}

export interface GetFormBundlesSuccess {
  type: typeof FormBundlesActions.GET_FORM_BUNDLES_SUCCESS;
  payload: {
    packet: FormBundle[];
  }
}

export interface GetFormBundlesFailure {
  type: typeof FormBundlesActions.GET_FORM_BUNDLES_FAILURE;
  payload: {
    error: string;
  }
}


// Action Type List
export type PacketAction = GetFormBundles | GetFormBundlesFailure | GetFormBundlesSuccess

// Action Creators
export const getFormBundles = (): PacketAction => ({
  type: FormBundlesActions.GET_FORM_BUNDLES,
});

export const getFormBundlesSuccess = (packet: FormBundle[]): PacketAction => ({
  type: FormBundlesActions.GET_FORM_BUNDLES_SUCCESS,
  payload: {
    packet,
  }
});

export const getFormBundlesFailure = (error: string): PacketAction => ({
  type: FormBundlesActions.GET_FORM_BUNDLES_FAILURE,
  payload: {
    error,
  }
});
