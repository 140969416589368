import { call, put, takeLatest } from 'redux-saga/effects';

import { PacketActions, UpdatePacket, updatePacketFailure, updatePacketSuccess } from '@/actions/actions/packet';
import Api from '@/api/Api';
import { buildPath, Routes } from '@/utils/routeUtils';
import { flashError, flashSuccess } from '../messageSaga';

export function* updatePacketSaga(
  action: UpdatePacket
) {
  try {
    const { packet, packetNumber } = action.payload;

    const url = buildPath(Routes.api.packets, packetNumber.toString())
    
    const response = yield call(Api.utility.put, url, packet);

    yield put(flashSuccess('Successfully saved packet'));
    yield put(updatePacketSuccess(response.data));
  } catch (err) {
    yield put(flashError('Could not save packet'));
    yield put(updatePacketFailure(err));
  }
}

export function* watchUpdatePacket() {
  yield takeLatest(PacketActions.UPDATE_PACKET, updatePacketSaga);
}
