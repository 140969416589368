import React from 'react';
import { useHistory } from 'react-router';

import { Button } from '@/components/ui/button';

interface Props {
  header: string;
  backText: string;
}

const Header = (props: Props): JSX.Element => {
  const { header, backText } = props;

  const history = useHistory();

  return (
    <div className="send-packet__header">
      <Button
        backgroundColor="transparent"
        iconClass="back"
        label={backText}
        primary={true}
        size="small"
        transformText={false}
        onClick={() => history.goBack()}
        labelColor="#fff"
        iconColor="#fff"
      />

      <h1>{header}</h1>
      <div style={{ width: '100px' }} />
    </div>
  );
};

export default Header;
