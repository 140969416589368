import React, { useState } from 'react';

import { Loading } from '@/components/ui/loading';
import api from '@/api/Api';

export interface AttachmentListProps {
  data: any;
}

export const AttachmentList: React.FC<AttachmentListProps> = ({ data }) => {
  let keyCount = 0;
  const getKey = (prefix) => {
    keyCount = keyCount += 1;
    return `${prefix}-${keyCount}`;
  };

  const [isDownloading, setIsDownloading] = useState<boolean>();

  const handleFileClicked = async (file: any) => {
    const { fileId, fileName } = file;

    setIsDownloading(true);

    const FILE_HEADERS = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('bearer_token')}`,
        'Access-Control-Allow-Origin': '*',
      },
      responseType: 'blob',
    };

    const url = `${api.baseUrl}/api/v1/packets/files/${fileId}`;

    // @ts-ignore
    const response = await api.utility.get(url, FILE_HEADERS);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(response.data);
    link.download = fileName;
    link.click();
    link.remove();

    setIsDownloading(false);
  };
  return (
    <div className="title-value-list__section">
      {isDownloading && (
        <Loading ringColor="#201f35" message="Downloading File..." />
      )}

      <ul className="title-value-list__section--ul">
        {data &&
          data.map((file) => {
            if (file === 'No files attached') {
              return <div key={getKey('attachment')}>No files attached</div>;
            } else {
              return (
                <div
                  className="file-upload__remove-button"
                  key={getKey('attachment')}
                  onClick={() => handleFileClicked(file)}>
                  {file.fileName}
                </div>
              );
            }
          })}
      </ul>
    </div>
  );
};
