import React, { useEffect } from 'react';
import Uppy from '@uppy/core';
import StatusBar from '@uppy/status-bar';
import { DragDrop } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import { useDispatch, useSelector } from 'react-redux';

import { selectPacket } from '@/selectors/packet';
import { Modal } from '@/components/ui';

import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import { getPacketForm } from '@/actions/actions/packet_form';

interface FileUploadModalProps {
  onClickClose: () => void;
  packetFormBundleId: string;
  packetFormId: string;
}

export const FileUploadModal: React.FC<FileUploadModalProps> = ({
  onClickClose,
  packetFormBundleId,
  packetFormId,
}) => {
  const dispatch = useDispatch();

  const packet = useSelector(selectPacket);

  const uppy = Uppy({
    autoProceed: true,
  })
    .use(XHRUpload, {
      endpoint: `${process.env.APPLICATION_URL}/api/v1/packets/${packet.packetId}/packetformbundles/${packetFormBundleId}/packetform/${packetFormId}/files`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('bearer_token')}`,
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json',
      },
    })
    .on('complete', async () => {
      dispatch(
        getPacketForm(
          packet.packetId,
          parseInt(packetFormBundleId),
          parseInt(packetFormId),
        ),
      );
    });

  useEffect(() => {
    uppy.use(StatusBar, {
      id: 'StatusBar',
      target: '#uppyStatusBar',
      hideAfterFinish: true,
      showProgressDetails: true,
      hideUploadButton: false,
      hideRetryButton: false,
      hidePauseResumeButton: false,
      hideCancelButton: false,
      doneButtonHandler: null,
      locale: {},
    });
  });

  return (
    <Modal handleClickX={() => onClickClose()}>
      <div className="file-upload__modal">
        <DragDrop
          width="100%"
          height="100%"
          uppy={uppy}
          locale={{
            strings: {
              dropHereOr: 'Drag and Drop Files or Folders Here or %{browse}',
              browse: 'BROWSE YOUR COMPUTER',
            },
          }}
        />
      </div>
      <div id="uppyStatusBar" />
    </Modal>
  );
};
