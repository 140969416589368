import React from 'react';

import { ErrorToast } from './error-toast';
import { InfoToast } from './info-toast';
import { SuccessToast } from './success-toast';

export interface CustomToastProps {
  message: string,
};

export const renderToast = (message: string, type: string) => {
  if (type === 'success') {
    return <SuccessToast message={message} />;
  } else if (type === 'notice') {
    return <InfoToast message={message} />;
  } else {
    return <ErrorToast message={message} />;
  };
};
