import { call, put, takeLatest } from 'redux-saga/effects';

import { PacketActions, GetPacket, getPacketFailure, getPacketSuccess } from '@/actions/actions/packet';
import Api from '@/api/Api';
import { buildPath, Routes } from '@/utils/routeUtils';

export function* getPacketSaga(
  action: GetPacket
) {
  try {
    const { packetNumber } = action.payload;

    const url = buildPath(Routes.api.packets, packetNumber.toString())

    const response = yield call(Api.utility.get, url);

    yield put(getPacketSuccess(response.data));
  } catch (err) {
    yield put(getPacketFailure(err));
  }
}

export function* watchGetPacket() {
  yield takeLatest(PacketActions.GET_PACKET, getPacketSaga);
}
