import { DiscoveredDataActions } from '@/actions/actions/discovered_data';
import { DiscoveredDataState } from '@/types/discovered_data';


const initialState: DiscoveredDataState = {
  discoveredData: null,
}

export default function reducer(
  state: DiscoveredDataState = initialState,
  action: any,
): DiscoveredDataState {
  const { type, payload } = action;

  switch (type) {
    case DiscoveredDataActions.GET_USER_DISCOVERED_DATA_SUCCESS:
      return {
        discoveredData: payload.discoveredData
      }
    case DiscoveredDataActions.UPDATE_USER_DISCOVERED_DATA_SUCCESS:
      return {
        discoveredData: payload.discoveredData
      }
    default:
      return state;
  }
}
