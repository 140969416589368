import React from 'react';

import { Button, Modal } from '@/components/ui';
import { TextField } from '../text-field';

export interface ConfirmationModalProps {
  noButtonLabel?: string;
  yesButtonLabel: string;
  header: string;
  prompt: string;
  handleResponse: (response) => void;
  closeModal: () => void;
  className?: string;
  hasCommentSection?: boolean;
  handleTextInput?: (value: string) => void;
  textValue?: string;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  yesButtonLabel,
  noButtonLabel = 'cancel',
  header,
  prompt,
  handleResponse,
  className,
  closeModal,
  handleTextInput,
  hasCommentSection,
  textValue,
}) => {
  return (
    <Modal header={header} handleClickX={() => closeModal()}>
      <div className={`confirmation-modal ${className ? className : ''}`}>
        <p className="confirmation-modal__prompt">{prompt}</p>
        {hasCommentSection && (
          <div>
            <TextField
              defaultValue={textValue}
              textArea
              onChange={(value) => handleTextInput(value)}
              label="Comments"
            />
          </div>
        )}
        <div className="confirmation-modal__buttons flex flex-space-between">
          <Button
            label={noButtonLabel}
            primary={false}
            size="large"
            onClick={() => closeModal()}
            borderColor="var(--primary)"
          />
          <Button
            backgroundColor="var(--secondary1)"
            borderColor="var(--secondary1)"
            label={yesButtonLabel}
            labelColor="#fff"
            primary={true}
            size="large"
            onClick={() => handleResponse(true)}
          />
        </div>
      </div>
    </Modal>
  );
};
