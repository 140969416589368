import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ApprovalPath } from '@/types/approval_path';
import { Button } from '@/components/ui';
import { Users } from '@/contexts/create-packet-page/packet-approvers/users';
import { selectUsers } from '@/selectors/users';
import { getUsers } from '@/actions/actions/users';

type Mode = 'edit' | 'view';

export interface PacketApproversProps {
  selectedApprovalPath: ApprovalPath;
  localDispatch: React.Dispatch<any>;
  mode: Mode;
}

export const PacketApprovers: React.FC<PacketApproversProps> = ({
  selectedApprovalPath,
  localDispatch,
  mode,
}) => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [userIndex, setUserIndex] = useState(null);
  let keyCount = 0;

  const userData = useSelector(selectUsers);

  const getKey = (prefix) => {
    keyCount = keyCount += 1;
    return `${prefix}-${keyCount}`;
  };

  useEffect(() => {
    dispatch(getUsers('3'));
  }, []);

  useEffect(() => {
    userData && setUsers(userData);
  }, [userData]);

  useEffect(() => {
    users && buildUserOptions(users);
  }, [users]);

  useEffect(() => {
    console.log('users', users);
  })

  const buildUserOptions = (_users) => {
    const _options = _users.map((user) => {
      return {
        value: user.userId,
        label: `${user.firstName} ${user.lastName}`,
      };
    });
    setUserOptions(
      _options.sort((a, b) => {
        return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
      }),
    );
    setUserIndex(
      _users.reduce((obj, user) => {
        obj[user.userId] = user;
        return obj;
      }, {}),
    );
  };

  const updateApprover = (value, path) => {
    const _selectedApprovalPath = selectedApprovalPath;
    const newUser = {
      userId: null,
      approvalStatusId: null,
    };

    eval(path);
    localDispatch({
      type: 'SELECTED_APPROVAL_PATH',
      key: 'selectedApprovalPath',
      value: _selectedApprovalPath,
    });
  };

  return (
    <>
      {userIndex &&
        selectedApprovalPath &&
        selectedApprovalPath?.stops?.map((stop, index) => {
          const stopIndex = index;
          const addUser = `_selectedApprovalPath.stops[${stopIndex}].approvalStopDetail.users.push(newUser)`;
          return (
            <div
              className="create-packet__section--approver flex align-items-center"
              key={getKey('stop')}>
              {
                <>
                  <span
                    className="create-packet__section--approver--squence-number"
                    key={getKey('approver--squence-number')}>
                    {`${stop.sequenceNumber}. `}
                    <span className="create-packet__section--approver--squence-group-name">
                      {stop.displayName}
                    </span>
                  </span>
                  <div className="create-packet__section--approver--squence-group">
                    <Users
                      selectedApprovalPath={selectedApprovalPath}
                      mode={mode}
                      stop={stop}
                      stopIndex={stopIndex}
                      userOptions={userOptions}
                      userIndex={userIndex}
                      localDispatch={localDispatch}
                    />
                    {mode === 'edit' && (
                      <Button
                        className="solid"
                        label="add approver"
                        primary={false}
                        size="large"
                        onClick={(e) => {
                          updateApprover(null, addUser);
                          e.target.blur();
                        }}
                        labelColor="#fff"
                      />
                    )}
                  </div>
                </>
              }
            </div>
          );
        })}
    </>
  );
};
