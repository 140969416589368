import { DropdownSelect } from '@/components/ui/dropdown-select';
import { Icon } from '@/components/ui';
import { ApprovalPath } from '@/types/approval_path';
import React from 'react';

interface Props {
  userOptions: any[];
  approvalPath: ApprovalPath;
  stopIndex: number;
  setApprovalPath: (approvalPath: ApprovalPath) => void;
  userIndex: number;
}

const StopUser = (props: Props) => {
  const {
    userOptions,
    approvalPath,
    stopIndex,
    setApprovalPath,
    userIndex,
  } = props;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <DropdownSelect
        label="Approver"
        options={userOptions}
        onClick={(value) => {
          let _approvalPath = { ...approvalPath };
          _approvalPath.stops[stopIndex].approvalStopDetail.users[
            userIndex
          ].userId = value;
          setApprovalPath(_approvalPath);
        }}
        selectedValue={
          approvalPath.stops[stopIndex].approvalStopDetail.users[userIndex]
            .userId
        }
        renderSelectedOption={true}
        typeAhead={false}
        placeholder="Select approver"
      />

      {userIndex !== 0 && (
        <Icon
          iconClass="x-enclosed"
          onClick={() => {
            let _approvalPath = { ...approvalPath };

            _approvalPath.stops[stopIndex].approvalStopDetail.users.splice(
              userIndex,
              1,
            );

            setApprovalPath(_approvalPath);
          }}
          size="1.1rem"
        />
      )}
    </div>
  );
};

export default StopUser;
