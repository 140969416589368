// Action Types
export enum DeviceTypeActions {
  SET_DEVICE_TYPE = 'DEVICE_TYPE/GET_DEVICE_TYPE',
}

// Action Interfaces
export interface SetDeviceType {
  type: typeof DeviceTypeActions.SET_DEVICE_TYPE;
  payload: {
    deviceType: string;
  }
}

// Action Type List
export type DeviceTypeAction = SetDeviceType

export const setDeviceType = (deviceType: string): DeviceTypeAction => ({
  type: DeviceTypeActions.SET_DEVICE_TYPE,
  payload: {
    deviceType
  },
});
