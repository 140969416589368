import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { Button } from '@/components/ui';
import { Icon } from '@/components/ui';
import { Packet } from '@/types/packet';

export interface PacketCardProps {
  packet: Packet;
  type: string;
}

const toDateString = (date: Date) => {
  return moment(date).format('MM/DD/YYYY');
};

const toDateTimeString = (date: Date) => {
  return moment(date).format('MM/DD/YYYY LT');
};

export const PacketCard: React.FC<PacketCardProps> = (
  props: PacketCardProps,
) => {
  const { packet, type } = props;

  const history = useHistory();

  const requestDate = new Date(packet.submittedForApprovalDateTimeUtc);
  const createdDate = new Date(packet.createdDateTimeUtc);

  const dueDate = moment(requestDate)
    //@ts-ignore
    .add(packet?.approvalPath?.stops?.length, 'd')
    .toDate();

  const statusUpdated = packet?.submittedForApprovalDateTimeUtc
    ? moment(requestDate).subtract(1, 'd').toDate()
    : moment(createdDate).subtract(1, 'd').toDate();

  const now = moment().toDate();

  const pastDue = now > dueDate;

  const renderStatusUpdated = () => {
    return (
      <>
        <div className="packet-card-label">Status Updated</div>
        <div className="packet-card-value">
          {toDateTimeString(statusUpdated)}
        </div>
      </>
    );
  };

  const renderCompleted = () => {
    return (
      <>
        <div className="packet-card-label">Completed</div>
        <div className="packet-card-value">
          {toDateTimeString(statusUpdated)}
        </div>
      </>
    );
  };

  const renderStatus = () => {
    return (
      <>
        <div className="packet-card-label">Status</div>
        <div className="packet-card-value">{packet.status}</div>
      </>
    );
  };

  return (
    <div className="packet-card container">
      <div className="packet-card-label">
        <span>Name</span>
        {packet.statusId === 4 && (
          <span>
            <Icon iconClass="attention" />
          </span>
        )}
      </div>
      <div className="packet-card-value">{packet?.name}</div>
      <div className="columns pocket-card-columns">
        <div className="column pocket-card-column">
          <div className="packet-card-label">Request Date</div>
          <div className="packet-card-value">
            {packet?.submittedForApprovalDateTimeUtc
              ? toDateString(requestDate)
              : 'Pending'}
          </div>
          {type === 'active' && renderStatusUpdated()}
          {type === 'past' && renderCompleted()}
        </div>

        <div className="column pocket-card-column">
          {type !== 'unsent' && renderStatus()}
          <div className="packet-card-label">Due Date</div>
          {packet?.submittedForApprovalDateTimeUtc ? (
            <>
              {type === 'active' && pastDue && (
                <div className="packet-card-overdue">Overdue</div>
              )}
              <div className="packet-card-value">{toDateString(dueDate)}</div>
            </>
          ) : (
            <div className="packet-card-value">Pending</div>
          )}
        </div>
      </div>

      <Button
        className="packet-card-button"
        primary={true}
        size="medium"
        label="view"
        onClick={() => history.push(`/packets/packet/${packet.packetId}`)}
      />
    </div>
  );
};
