import React, { useState, useEffect, useRef } from 'react';

export interface LoadingProps {
  message?: string;
  backgroundColor?: string;
  ringColor?: string;
  positionRelative?: boolean;
  height?: string;
  width?: string;
}

export const Loading: React.FC<LoadingProps> = ({
  message,
  backgroundColor,
  positionRelative,
  height,
  width,
  ringColor = 'var(--primary)',
}) => {
  let styles = { backgroundColor: backgroundColor };

  useEffect(() => {
    document
      .querySelector<HTMLElement>(':root')
      .style.setProperty('--loadingRingColor', `${ringColor}`);
  }, []);

  if (positionRelative) {
    let _styles = {
      position: 'relative',
      width: width,
      height: height,
      ...styles,
    };
    styles = _styles;
  } else if (!positionRelative) {
    let _styles = {
      height: '100vh',
      width: '100vw',
      zIndex: ' 100000',
      ...styles,
    };
    styles = _styles;
  }

  return (
    <div
      className={`loading flex flex-col ${
        positionRelative ? '' : 'position-fixed'
      }`}
      style={styles}>
      <div
        className={`loading__box flex flex-col ${
          positionRelative ? '' : 'loading-modal'
        }`}>
        <div className="loading__ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        {message && <p className="loading__message fw-700">{message}</p>}
      </div>
    </div>
  );
};
