import React from 'react';

const PageNotFound: React.FC<null> = ({}) => {
  return (
    <div className="page-not-found flex flex-col">
      <div className="page__header">
        <h1>404</h1>
      </div>
      <p className="page-not-found__header-sub-text">Page not found.</p>
      <p className="page-not-found__blurb">
        You may have mistyped the address or the page may have been moved.
      </p>
    </div>
  );
};

export default PageNotFound;
