import { Packet } from '@/types/packet';

// Action Types

export enum PacketsActions {
  CLEAR_PACKETS = 'PACKET/CLEAR_PACKETS',
  GET_PACKETS = 'PACKET/GET_PACKETS',
  GET_TEAM_PACKETS = 'PACKET/GET_TEAM_PACKETS',
  GET_PACKETS_SUCCESS = 'PACKET/GET_PACKETS_SUCCESS',
  GET_PACKETS_FAILURE = 'PACKET/GET_PACKETS_FAILURE',
  GET_TEAM_PACKETS_SUCCESS = "PACKET/GET_TEAM_PACKETS_SUCCESS",
  GET_TEAM_PACKETS_FAILURE = 'PACKET/GET_TEAM_PACKETS_FAILURE',
}

// Action Interfaces
export interface ClearPackets {
  type: typeof PacketsActions.CLEAR_PACKETS;
}

export interface GetPackets {
  type: typeof PacketsActions.GET_PACKETS;
  payload: {
    userId: string;
  };
}

export interface GetTeamPackets {
  type: typeof PacketsActions.GET_TEAM_PACKETS;
  payload: {
    userId: string;
  };
}

export interface GetPacketsSuccess {
  type: typeof PacketsActions.GET_PACKETS_SUCCESS;
  payload: {
    packets: Packet[];
  };
}

export interface GetTeamPacketsSuccess {
  type: typeof PacketsActions.GET_TEAM_PACKETS_SUCCESS;
  payload: {
    packets: Packet[];
  };
}

export interface GetPacketsFailure {
  type: typeof PacketsActions.GET_PACKETS_FAILURE;
  payload: {
    error: string;
  };
}

// Action Type List
export type PacketsAction =
  | ClearPackets
  | GetPackets
  | GetTeamPackets
  | GetPacketsFailure
  | GetPacketsSuccess
  | GetTeamPacketsSuccess;

// Action Creators
export const clearPackets = (): PacketsAction => ({
  type: PacketsActions.CLEAR_PACKETS,
});

export const getPackets = (userId: string): PacketsAction => ({
  type: PacketsActions.GET_PACKETS,
  payload: {
    userId,
  },
});

export const getTeamPackets = (userId: string): PacketsAction => ({
  type: PacketsActions.GET_TEAM_PACKETS,
  payload: {
    userId,
  },
});

export const getPacketsSuccess = (packets: Packet[]): PacketsAction => ({
  type: PacketsActions.GET_PACKETS_SUCCESS,
  payload: {
    packets,
  },
});

export const getTeamPacketsSuccess = (packets: Packet[]): PacketsAction => ({
  type: PacketsActions.GET_TEAM_PACKETS_SUCCESS,
  payload: {
    packets,
  },
});

export const getPacketsFailure = (error: string): PacketsAction => ({
  type: PacketsActions.GET_PACKETS_FAILURE,
  payload: {
    error,
  },
});
