import React, { useEffect, useState, useRef } from 'react';

import { SearchBar, TextField, Loading } from '@/components/ui/';
import { PacketPreviews } from '@/contexts/create-packet-page/section-one/packet-previews';
import { PacketContents } from '@/contexts/create-packet-page/packet-contents';
import { User } from '@/types/user';
import { RequesterSection } from '@/contexts/create-packet-page/section-one/requester-section';
import { Option } from '@/types/option';

type PacketPreview = {
  packet: any;
  title: string;
  details: string[];
};

export interface SectionOneProps {
  handleInput: (e) => void;
  packetPreviews: PacketPreview[];
  state: any;
  filteredPacketPreviews: PacketPreview[];
  setFilteredPacketPreviews: (e) => void;
  localDispatch: React.Dispatch<any>;
  user: User;
  userIndex: any;
  userOptions: Option[];
  isPowerUser: boolean;
  onBehalfOf: number;
  setOnBehalfOf: React.Dispatch<number>;
}

export const SectionOne: React.FC<SectionOneProps> = ({
  handleInput,
  packetPreviews,
  state,
  filteredPacketPreviews,
  setFilteredPacketPreviews,
  localDispatch,
  user,
  userOptions,
  isPowerUser,
  onBehalfOf,
  setOnBehalfOf,
}) => {
  const [filterInitiated, setFilterInitiated] = useState<boolean>(null);
  const [searchbarValue, setSearchBarValue] = useState<string>('');

  useEffect(() => {
    searchbarValue?.length
      ? setFilterInitiated(true)
      : setFilterInitiated(false);
  }, [searchbarValue]);

  const addPreview = (preview) => {
    let _selectedPackets = state?.selectedPackets || [];
    const selectedPacketNames = _selectedPackets.reduce((acc, currVal) => {
      acc.push(currVal.title);
      return acc;
    }, []);

    if (selectedPacketNames.includes(preview.title)) {
      _selectedPackets.splice(selectedPacketNames.indexOf(preview.title), 1);
    } else if (!selectedPacketNames.includes(preview.title)) {
      _selectedPackets.push(preview);
    }
    localDispatch({
      type: 'SELECTED_PACKETS',
      value: _selectedPackets,
    });
  };

  return (
    <>
      <div className="columns">
        {isPowerUser && (
          <div className="column is-6">
            <RequesterSection
              state={state}
              localDispatch={localDispatch}
              user={user}
              setOnBehalfOf={setOnBehalfOf}
              onBehalfOf={onBehalfOf}
              userOptions={userOptions}
            />
          </div>
        )}
        <div className="column is-6">
          <div className="create-packet__section" style={{ marginTop: '0' }}>
            <div className="create-packet__step">{isPowerUser ? '2' : '1'}</div>
            <div className="create-packet__section--main">
              <h3 className="create-packet__section--header">Name Packet</h3>
              <p>
                Approvers and viewers with access to this packet will see the
                packet name.
              </p>
              <TextField
                label="Packet Name"
                placeholder="Enter Packet Name"
                className="packet-name--input flex flex-col"
                onChange={(value) => handleInput(value)}
                maxLength={500}
                defaultValue={state?.packet?.name || null}
                disabled={isPowerUser && !state?.packet?.onBehalfOfUserId}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column is-12">
          <div className="create-packet__section">
            <div className="create-packet__step">{isPowerUser ? '3' : '2'}</div>
            <div className="create-packet__section--main">
              <h3 className="create-packet__section--header">
                Select forms or form groups to include in this packet
              </h3>
              <div className="create-packet__section--inner flex">
                <div className="create-packet__section--inner--left">
                  <SearchBar
                    label="Search for form or form group names "
                    placeholder="Start typing to search"
                    searchKeys={['title']}
                    data={packetPreviews}
                    onChange={(res, value) => {
                      setFilteredPacketPreviews(res);
                      setSearchBarValue(value);
                    }}
                  />
                  <div className="create-packet__packet-selection-section">
                    {packetPreviews && packetPreviews.length > 0 ? (
                      <PacketPreviews
                        packetInfo={
                          (filteredPacketPreviews?.length &&
                            filteredPacketPreviews) ||
                          (filterInitiated && []) ||
                          (!filterInitiated && packetPreviews)
                        }
                        onClick={(preview) => addPreview(preview)}
                        selectedPackets={
                          state?.selectedPackets?.reduce((acc, currVal) => {
                            acc.push(currVal.title);
                            return acc;
                          }, []) || []
                        }
                      />
                    ) : (
                      <Loading
                        width="100%"
                        height="100%"
                        positionRelative={true}
                        message="Loading packets..."
                        ringColor="var(--neutral6)"
                      />
                    )}
                  </div>
                </div>
                <div className="create-packet__section--inner--right">
                  {(!state?.selectedPackets ||
                    state?.selectedPackets?.length === 0) && (
                    <div className="create-packet__packet-selected-section">
                      <p className="create-packet__packet-selected-section--header">
                        Packet Contents
                      </p>
                      <p className="create-packet__packet-selected-section--blurb">
                        Select forms from the list to include in this packet
                      </p>
                    </div>
                  )}
                  {state?.selectedPackets &&
                    state.selectedPackets.length !== 0 && (
                      <PacketContents
                        selectedPackets={state.selectedPackets}
                        localDispatch={localDispatch}
                        drawerOpen={false}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
