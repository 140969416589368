import { call, put, takeLatest } from 'redux-saga/effects';

import { UsersActions, GetUsers, getUsersFailure, getUsersSuccess, getUsers } from '@/actions/actions/users';
import Api from '@/api/Api';
import { buildPath, Routes } from '@/utils/routeUtils';

export function* getUsersSaga(
  action: GetUsers,
) {
  try {

    const { rolePermissionId } = action.payload;

    const params = rolePermissionId ? { rolePermissionId: rolePermissionId } : {};

    const url = buildPath(Routes.api.users, null, null, params)
    const response = yield call(Api.utility.get, url);

    yield put(getUsersSuccess(response.data));
  } catch (err) {
    yield put(getUsersFailure(err));
  }
}

export function* watchGetUsers() {
  yield takeLatest(UsersActions.GET_USERS, getUsersSaga);
}
