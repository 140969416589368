import { FieldValuesActions } from '@/actions/actions/field_values';
import { FieldValuesState } from '@/types/field_values';

const initialState: FieldValuesState = {
  fieldValues: null,
};

export default function reducer(
  state: FieldValuesState = initialState,
  action: any,
): FieldValuesState {
  const { type, payload } = action;

  switch (type) {
    case FieldValuesActions.GET_FIELD_VALUES_SUCCESS:
      return {
        ...state,
        fieldValues: payload.fieldValues
      }
    default:
      return state;
  }
}
