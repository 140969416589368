import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

import { contains } from '@/utils/contains';
import { Icon, Button, Drawer } from '@/components/ui';
import { logoutUser } from '@/utils/oauth';
import { getDiscoveredData } from '@/actions/actions/discovered_data';
import { showDiscoveredDataNotification } from '@/utils/notificationCount';
import { selectTeamApprovalNeededPackets } from '@/selectors/packets';
import { getTeamPackets } from '@/actions/actions/packets';

type userRoles = 'User' | 'PowerUser' | 'Approver';

const Navbar = (): JSX.Element => {
  const [root, setRoot] = useState<string[]>(
    window.location.pathname.split('/'),
  );
  const [userRoles, setUserRoles] = useState<userRoles[]>(null);
  const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
  const [renderMobileNavMenu, setRenderMobileNavMenu] = useState<boolean>(
    false,
  );
  const [myPacketsOpen, setMyPacketsOpen] = useState<boolean>(false);
  const [teamPacketsOpen, setTeamPacketsOpen] = useState<boolean>(false);
  const [discoveredData, setDiscoveredData] = useState([]);

  const user = useSelector((state: RootStateOrAny) => {
    return state.user.user;
  });
  const discoveredUserData = useSelector((state: RootStateOrAny) => {
    return state.discoveredData.discoveredData;
  });

  const teamPackets = useSelector(selectTeamApprovalNeededPackets);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((location) => {
      if (location) {
        setRoot(location.pathname.split('/'));
      } else {
        setRoot(window.location.pathname.split('/'));
      }
      toggleMobileNavMenu(false);
    });

    user?.userRoles &&
      setUserRoles(
        user.userRoles?.map((role) => {
          return Object.values(role)[0];
        }),
      );
    if (discoveredUserData === null) {
      dispatch(getDiscoveredData());
    }

    setDiscoveredData(discoveredUserData);
  }, [user, discoveredUserData]);

  useEffect(() => {
    if(user != null) {
      dispatch(getTeamPackets(localStorage.getItem('user_id')));
    }
    
  }, [user]);

  const links = () => {
    return (
      <div className="navbar__links column is-9 md-display-none">
        <div className="navbar__links--inner columns is-vcentered">
          {user && userRoles && (
            <>
              {contains(userRoles, ['PowerUser', 'User']) && (
                <div
                  className={`navbar__link column ${
                    root[1] == ''
                      ? 'active'
                      : root[1] === 'packets' && root[2] !== 'team-packets'
                      ? 'active'
                      : ''
                  }`}>
                  <Link to={'/packets'}>my packets</Link>
                </div>
              )}
              {contains(userRoles, ['Approver', 'PowerUser']) && (
                <div
                  className={`navbar__link column ${
                    root[2] === 'team-packets' ? 'active' : ''
                  }`}>
                  <Link to={'/packets/team-packets/approval-needed'}>
                    team packets
                  </Link>
                  {teamPackets && teamPackets.length > 0 && (
                    <span className="navbar__link--highlight">
                      {teamPackets.length}
                    </span>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (mobileNavOpen) {
      document.querySelector('html').style.overflow = 'hidden';
    } else if (!mobileNavOpen) {
      document.querySelector('html').style.overflow = 'auto';
      setMyPacketsOpen(false);
      setTeamPacketsOpen(false);
    }

    return () => {
      document.querySelector('html').style.overflow = 'auto';
    };
  }, [mobileNavOpen]);

  const toggleMobileNavMenu = (val: boolean) => {
    if (val) {
      setMobileNavOpen(val);
      setTimeout(() => {
        setRenderMobileNavMenu(true);
      }, 50);
    } else {
      setRenderMobileNavMenu(false);
      setTimeout(() => {
        setMobileNavOpen(val);
      }, 200);
    }
  };

  return (
    <>
      <div className="navbar">
        {user && (
          <div className="navbar__menu-icon">
            <Icon
              iconClass="menu"
              color="#000"
              size="1.5rem"
              onClick={() => toggleMobileNavMenu(true)}
            />
          </div>
        )}
        <div
          className={
            'navbar__inner container is-widescreen columns is-vcentered'
          }>
          <div className="navbar__logo column is-1"></div>
          {links()}
          {user && (
            <div
              className={`column is-1 navbar__link profile-link md-display-none ${
                root[1] === 'profile' ? 'active' : ''
              }`}>
              <Link to={'/profile'}>profile</Link>
              {discoveredData &&
                showDiscoveredDataNotification(discoveredData) > 0 && (
                  <span className="navbar__notification">
                    <Link to="/profile/discovered-data">
                      {showDiscoveredDataNotification(discoveredData)}
                    </Link>
                  </span>
                )}
            </div>
          )}
        </div>
      </div>
      {user && mobileNavOpen && (
        <div className={`navbar-mobile ${renderMobileNavMenu ? 'open' : ''}`}>
          <div className="navbar-mobile__header">
            <Icon
              iconClass="x"
              color="#000"
              size="1.1rem"
              onClick={() => toggleMobileNavMenu(false)}
            />
            <div className="navbar__logo column is-1"></div>
          </div>
          {contains(userRoles, ['PowerUser', 'User']) && (
            <Drawer
              label="MY PACKETS"
              className="navbar-mobile__nav-drawer"
              open={myPacketsOpen}
              onClick={() => setMyPacketsOpen(!myPacketsOpen)}>
              <div className="navbar-mobile__navlinks flex flex-col">
                <Link className="navbar-mobile__navlinks--link" to={'/'}>
                  unsent packets
                </Link>
                <Link
                  className="navbar-mobile__navlinks--link"
                  to={'/packets/submitted-packets'}>
                  submitted packets
                </Link>
                <Link
                  className="navbar-mobile__navlinks--link"
                  to={'/packets/past-packets'}>
                  past packets
                </Link>
              </div>
            </Drawer>
          )}
          {contains(userRoles, ['Approver', 'PowerUser']) && (
            <Drawer
              label="TEAM PACKETS"
              className="navbar-mobile__nav-drawer"
              open={teamPacketsOpen}
              onClick={() => setTeamPacketsOpen(!teamPacketsOpen)}>
              <div className="navbar-mobile__navlinks flex flex-col">
                <Link
                  className="navbar-mobile__navlinks--link"
                  to={'/packets/team-packets/approval-needed'}>
                  approval needed
                </Link>
                <Link
                  className="navbar-mobile__navlinks--link"
                  to={'/packets/team-packets/past'}>
                  past packets
                </Link>
              </div>
            </Drawer>
          )}
          {user && (
            <>
              <div
                className="navbar-mobile__nav-drawer"
                onClick={() => history.push('/profile')}>
                <div className="drawer__toggle profile-mobile-nav-link">
                  <div
                    className="navbar-mobile__navlinks--link"
                    style={{ textTransform: 'uppercase', fontSize: '1.25rem' }}>
                    profile
                    {discoveredData &&
                      showDiscoveredDataNotification(discoveredData) > 0 && (
                        <span className="navbar__notification-mobile">
                          <Link to="/profile/discovered-data">
                            {showDiscoveredDataNotification(discoveredData)}
                          </Link>
                        </span>
                      )}
                  </div>
                </div>
              </div>
              <Button
                className="log-out-button"
                primary={false}
                size="large"
                label="log out"
                labelColor="var(--primary)"
                onClick={() => logoutUser()}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Navbar;
