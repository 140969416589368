import React from 'react';
import { Icon } from '@/components/ui';

export interface SubNavButtonProps {
  /**
   * Icon class/category
   */
  iconClass: string;
  /**
   * Side navbar label
   */
  label: string;
  /**
  * Active status
  */
  status: boolean;
  /**
   * Optional click handler
   */
  onClick?: () => void;
}

export const SubNavButton: React.FC<SubNavButtonProps> = ({
  iconClass,
  label,
  status = false,
  onClick
}) => {
  return (
    <div
      className={`sub-nav-button ${iconClass} ${status ? "active" : ""}`}
      onClick={onClick ? onClick : () => { return null }}
    >
      <Icon
        iconClass={iconClass}
        size="1.5rem"
      />
      <label className="sub-nav-button--label">{label}</label>
    </div>
  );
};

export default SubNavButton;
