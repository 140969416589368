import React, { useState } from 'react';
import { Modal, ConfirmationCard, Loading, Button } from '@/components/ui';

export interface LabelValueInputProps {
  id?: number;
  data?: { key: string; value: any };
  /**
   * Optional className value
   */
  className?: string;
  handleChange: any;
  onChange: any;
  handleConfirm: any;
  modal?: boolean;
  showApproveDeny?: boolean;
}

export const LabelValueInput: React.FC<LabelValueInputProps> = ({
  id,
  data,
  className,
  handleChange,
  handleConfirm,
  showApproveDeny
}) => {

  const [keyValueData, setKeyValueData] = useState(data.value)
  const [toggleModal, setToggleModal] = useState(false)

  const handleValueChange = (evt) => {
    setKeyValueData(evt.target.value)
  }


  const handleToggleModal = () => {
    setToggleModal(!toggleModal)
  }

  if (keyValueData === null) {
    return (
      <Loading
        ringColor="#201f35"
        message="Loading Content..."
      />
    )
  }
  return (
    <div className="label-value-row is-full " key={data.key + id}>
      <div className="label-value-row__label ">{data.key}</div>
      <div className="label-value-row__data fw-700 ">
        <input
          value={keyValueData}
          onChange={(evt) => {
            handleValueChange(evt)
            handleChange(id, data.key, evt.target.value)
          }}
          className='input-container__input--text-field discovered'
        />

        <div className="button-group button-group__small">
          {
            showApproveDeny &&
            <Button
              primary={true}
              label="reject"
              size="small"
              border={true}
              onClick={handleToggleModal}
            />
          }
          <Button
            primary={false}
            label="approve"
            size="small"
            border={true}
            backgroundColor="var(--secondary1)"
            type="submit"
            labelColor="#fff"
            onClick={() => handleConfirm(id, 'A')}
          />
        </div>
      </div>
      {toggleModal &&
        <Modal>
          <ConfirmationCard
            data={keyValueData}
            arrayIdx={id}
            onSubmit={handleConfirm}
            text='Are you sure you want to remove the following data?'
            toggleModal={handleToggleModal}
            status='R'
          />
        </Modal>
      }
    </div>
  );
};
