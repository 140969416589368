import React from 'react';

import { Icon, Tooltip } from '@/components/ui';
import { generateKey } from '@/utils/generate_unique_key';

type value = string | number | boolean;

export interface RadioButtonsProps {
  /**
   * Optional className
   */
  className?: string;
  /**
   * Component label
   */
  label?: string;
  /**
   * Options to be rendered
   */
  options: Array<{ value: value; label: string; helpText?: string }>;
  /**
   * Value
   */
  value: string | number;
  /**
   * Optional message
   */
  message?: string;
  /**
   * Optional error message
   */
  errorMessage?: string;
  /**
   * Optional tooltip text
   */
  tooltipText?: string;
  /**
   * Optional onClick handler
   */
  onClick?: (data) => void;
  required?: boolean;
  disabled?: boolean;
}

export const RadioButtons: React.FC<RadioButtonsProps> = ({
  className,
  disabled,
  errorMessage,
  label,
  message,
  onClick,
  options,
  required,
  tooltipText,
  value,
}) => {
  return (
    <div className={`radio-buttons ${className ? className : ''}`}>
      {label && (
        <h4
          className={`radio-buttons__label ${
            required ? 'field-required' : ''
          }`}>
          {label}
        </h4>
      )}
      {tooltipText && <Tooltip text={tooltipText} />}
      {options.map((option) => {
        return (
          <div key={generateKey('help-text')}>
            <div
              className={`radio-buttons__option ${
                value === option.value ? 'selected' : ''
              }`}
              onClick={() => !disabled && onClick(option.value)}>
              <div className="radio-buttons__option--icon">
                <Icon
                  iconClass={`radio ${
                    value === option.value ? 'selected' : ''
                  }`}
                  color="var(--primary)"
                />
              </div>
              <div className="radio-buttons__option--label">{option.label}</div>
            </div>
            {option.helpText && (
              <p className="radio-buttons__option--help-text">
                {option.helpText}
              </p>
            )}
          </div>
        );
      })}
      {message && <p className="radio-buttons__message">{message}</p>}
      {errorMessage && (
        <p className={`radio-buttons__message--error`}>{errorMessage}</p>
      )}
    </div>
  );
};
