import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { DateTime } from 'luxon';

import { Icon, Tooltip } from '@/components/ui';
export interface DatePickerProps {
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  handleOnBlur?: () => void;
  initialDate?;
  label?: string;
  message?: string;
  noFutureDates?: boolean;
  onChange: (date) => void;
  presetRanges?;
  required?: boolean;
  selectedDate?;
  setDatePickerToggle?;
  tooltipText?: string;
  updateDate?;
  useRange?;
  value?: string;
}

export const DatePickerPlus: React.FC<DatePickerProps> = ({
  className,
  disabled,
  errorMessage,
  handleOnBlur,
  initialDate,
  label,
  message,
  noFutureDates,
  onChange,
  required,
  selectedDate,
  setDatePickerToggle,
  tooltipText,
  updateDate,
  value,
}) => {
  const [startDate, setStartDate] = useState<Date>(initialDate);
  const _dateChooser = useRef();

  useEffect(() => {
    value && value !== null && setStartDate(new Date(value));
  }, [value]);

  return (
    <div
      className={`date-picker-plus ${className ? className : ''}`}
      ref={_dateChooser}>
      <label
        className={`date-picker-plus__label ${
          required ? 'field-required' : ''
        }`}>
        {label}
      </label>
      {tooltipText && <Tooltip text={tooltipText} />}
      <div className={`date-picker-plus__input`}>
        <DatePicker
          selected={startDate}
          onChange={(date: Date) => {
            setStartDate(date);
            onChange(date);
          }}
          maxDate={noFutureDates ? new Date() : null}
          isClearable={!disabled}
          className={errorMessage ? 'error' : null}
          showYearDropdown
          disabled={disabled}
          onBlur={handleOnBlur}
        />
        {!startDate && (
          <Icon
            iconClass="calendar"
            color="var(--secondary1)"
            size="0.875rem"
          />
        )}
        {message && <p className="date-picker-plus__message">{message}</p>}
        {errorMessage && (
          <p className={`date-picker-plus__message--error`}>{errorMessage}</p>
        )}
      </div>
    </div>
  );
};
