import React, { useState, useRef, useEffect } from 'react';

import { Button } from '@/components/ui';

interface SearchBarProps {
  label?: string;
  data?: any;
  placeholder: string;
  handleSearch?: () => void;
  searchKeys: string[];
  onChange: (e, v) => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  data,
  placeholder,
  label,
  searchKeys,
  onChange,
}) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const _searchField = useRef(null);
  const debounce = useRef(null);

  const handleChange = (e) => {
    let results = data.reduce((acc, cval) => {
      for (let i = 0; i < searchKeys.length; i++) {
        if (cval[searchKeys[i]]) {
          if (
            cval[searchKeys[i]]
              .toString()
              .toLowerCase()
              .includes(_searchField?.current?.value.toLowerCase())
          ) {
            acc.push(cval);
          }
        }
      }
      return acc;
    }, []);
    let resultsArr = [...new Set(results)];

    debounce.current && clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      onChange(
        resultsArr.sort((a, b) => {
          return a[searchKeys[0]].toLowerCase() > b[searchKeys[0]].toLowerCase()
            ? 1
            : -1;
        }),
        _searchField?.current?.value,
      );
    }, 200);
  };

  const clearSearchField = () => {
    _searchField.current.value = '';
    onChange(data, '');
  };

  useEffect(() => {
    if (!data || (data && data.length <= 1)) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [data]);

  return (
    <div className={`search-bar ${disabled ? 'disabled' : ''}`}>
      {label && <label className="search-bar__label">{label}</label>}
      <div
        className={`search-bar__search-field ${
          _searchField?.current?.value ? _searchField?.current?.value : 'empty'
        }`}>
        <input
          className="search-bar__search-field--input"
          ref={_searchField}
          type="text"
          placeholder={`${placeholder}...`}
          onChange={(e) => {
            handleChange(e);
          }}
          onKeyDown={(e) => {
            handleChange(e);
          }}
          disabled={disabled}
        />
        <Button
          backgroundColor="transparent"
          iconClass={_searchField?.current?.value ? 'x' : 'search'}
          primary={false}
          onClick={() => {
            _searchField?.current?.value
              ? clearSearchField()
              : _searchField.current.focus();
          }}
        />
      </div>
    </div>
  );
};
