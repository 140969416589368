import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Navbar from '@/components/navbar';
import ErrorBoundary from '@/components/errors/ErrorBoundary';
import { setDeviceType } from '@/actions/actions/device_type';

function Layout({ children }): JSX.Element {
  const dispatch = useDispatch();

  const detectScreenWidth = () => {
    window.screen.width <= 768 && dispatch(setDeviceType('mobile'));
    window.screen.width > 768 && dispatch(setDeviceType(null));
  };

  const registerListeners = () => {
    window.addEventListener('resize', detectScreenWidth);
  };

  const deRegisterListeners = () => {
    window.removeEventListener('resize', detectScreenWidth);
  };

  useEffect(() => {
    registerListeners();
    detectScreenWidth();

    return () => {
      deRegisterListeners();
    };
  }, []);

  return (
    <>
      <Navbar />
      <ErrorBoundary>{children}</ErrorBoundary>
    </>
  );
}

export default Layout;
