import { UserActions } from '@/actions/actions/user';
import { UserState } from '@/types/user';

const initialState: UserState = {
  user: null,
};

export default function reducer(
  state: UserState = initialState,
  action: any,
): UserState {
  const { type, payload } = action;
  switch (type) {
    case UserActions.GET_USER_SUCCESS:
      return {
        ...state,
        user: payload.user
      }
    default:
      return state;
  }
}
