import { call, put, takeLatest } from 'redux-saga/effects';
import jwt_decode from 'jwt-decode';

import { UserActions, GetUser, getUserFailure, getUserSuccess, GetUserProfile, GetOnBehalfOfUserProfile, getOnBehalfOfUserProfileSuccess, getOnBehalfOfUserProfileFailure } from '@/actions/actions/user';
import Api from '@/api/Api';

export function* getUserSaga(
  _action: GetUser,
) {
  try {
    const url = '/api/v1/users';
    const response = yield call(Api.utility.get, url);

    yield put(getUserSuccess(response.data));
  } catch (err) {
    yield put(getUserFailure(err));
  }
}

export function* getUserProfileSaga(
  _action: GetUserProfile,
) {
  try {
    let decoded = jwt_decode(localStorage.getItem('bearer_token'));
    let userId = decoded['sub'];
    const url = `/api/v1/users/${userId}`;

    const response = yield call(Api.utility.get, url);

    yield put(getUserSuccess(response.data));
  } catch (err) {
    yield put(getUserFailure(err));
  }
}

export function* getOnBehalfOfUserProfileSaga(
  action: GetOnBehalfOfUserProfile,
) {
  try {
    const { userId } = action.payload;

    const url = `/api/v1/users/${userId}`;

    const response = yield call(Api.utility.get, url);
    
   yield put(getOnBehalfOfUserProfileSuccess(response.data));
  } catch (err) {
    yield put(getOnBehalfOfUserProfileFailure(err));
  }
}

export function* watchGetUser() {
  yield takeLatest(UserActions.GET_USER, getUserSaga);
}

export function* watchGetUserProfile() {
  yield takeLatest(UserActions.GET_USER_PROFILE, getUserProfileSaga);
}

export function* watchGetOnBehalfOfUserProfileSaga() {
  yield takeLatest(UserActions.GET_ON_BEHALF_OF_USER_PROFILE, getOnBehalfOfUserProfileSaga);
}
