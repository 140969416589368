import classNames from 'classnames';
import React, { useEffect } from 'react';

import { Icon, Tooltip } from '@/components/ui';
import { generateKey } from '@/utils/generate_unique_key';
export interface CheckboxProps {
  /**
   * Optional className
   */
  className?: string;
  /**
   * Component label
   */
  label?: string;
  /**
   * Value
   */
  value: StringBoolean;
  /**
   * Default value
   */
  defaultValue?: StringBoolean;
  /**
   * Optional message
   */
  message?: string;
  errorMessage?: string;
  /**
   * Optional tooltip text
   */
  tooltipText?: string;
  /**
   * Optional onClick handler
   */
  onClick?: (data) => void;
  required?: boolean;
  disabled?: boolean;
  isPdf?: boolean;
  size?: string;
}

export type StringBoolean = 'true' | 'false';

/**
 * Primary UI component for Checkbox input
 */
export const Checkbox: React.FC<CheckboxProps> = ({
  className,
  disabled,
  label,
  message,
  onClick,
  required,
  tooltipText,
  value,
  errorMessage,
  defaultValue,
  isPdf,
  size,
}) => {
  useEffect(() => {
    !value && defaultValue && onClick('false');
  }, [defaultValue]);

  const toggleCheckbox = () => {
    if (value === 'true') onClick('false');
    else if (value === 'false') onClick('true');
    else {
      onClick('false');
    }
  };

  return (
    <div
      className={classNames('checkbox', {
        [className]: className,
        'is-pdf': isPdf,
      })}
      onClick={() => !disabled && toggleCheckbox()}>
      {tooltipText && <Tooltip text={tooltipText} />}
      <div
        className={classNames('checkbox__option', 'flex', 'flex-start', {
          selected: value === 'true',
          'is-pdf': isPdf,
        })}>
        <div
          className={classNames('checkbox__option--icon', { 'is-pdf': isPdf })}>
          <Icon
            iconClass={classNames('checkbox', 'flex', {
              selected: value === 'true',
              'is-pdf': isPdf,
            })}
            size={isPdf ? size : '0.8rem'}
          />
        </div>
        <h4
          className={classNames('checkbox__label', {
            'field-required': required,
          })}>
          {label}
        </h4>
      </div>
      {message && <p className="checkbox__message">{message}</p>}
      {errorMessage && (
        <p className="checkbox__message--error">{errorMessage}</p>
      )}
    </div>
  );
};
