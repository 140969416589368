import { ApprovalPathsActions } from '@/actions/actions/approval_paths';
import { ApprovalPathState } from '@/types/approval_path';

const initialState: ApprovalPathState = {
  approvalPaths: null,
  userApprovalPath: null,
};

export default function reducer(
  state: ApprovalPathState = initialState,
  action: any,
): ApprovalPathState {
  const { type, payload } = action;

  switch (type) {
    case ApprovalPathsActions.GET_APPROVAL_PATHS_SUCCESS:
      return {
        ...state,
        approvalPaths: payload.approvalPaths
      }
    case ApprovalPathsActions.SET_USER_APPROVAL_PATH:
      return {
        ...state,
        userApprovalPath: payload.approvalPath
      }
    default:
      return state;
  }
}
