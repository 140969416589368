import React from 'react';

export interface PDFPreviewProps {
  section: any;
}

export const PDFPreview: React.FC<PDFPreviewProps> = ({ section }) => {
  const thumbnailStyle = {
    maxHeight: '1000px',
    maxWidth: '100%',
    width: 'auto',
    height: 'auto',
  };

  return <img src={section.image} style={thumbnailStyle} />;
};
