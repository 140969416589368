import React from 'react';

import { CustomToastProps } from '@/components/ui/toast';

export const InfoToast: React.FC<CustomToastProps> = ({
  message
}) => {
  return(
    <div className="toast-card">
      <div className="toast-sidebar__info" />
      <div className="toast-icon toast-icon__info" />
      <div className="toast-content">
        <div className="toast-content__header toast-content__header__info">Information</div>
        <div className="toast-content__message">{message}</div>
      </div>
    </div>
  );
};
