import { all, call } from 'redux-saga/effects';

import user from './user';
import packet from './packet';
import packets from './packets';
import packetForm from './packet-form';
import formBundles from './form-bundles';
import approvalPaths from './approval-path';
import approvalTimelines from './approval-timeline';
import users from './users';
import forms from './forms';
import fieldValues from './field-values';
import discoveredData from './discovered-data';
import file from './file';
import messageSaga from './messageSaga';

const sagas = [
  ...user,
  ...packet,
  ...packets,
  ...packetForm,
  ...formBundles,
  ...approvalPaths,
  ...approvalTimelines,
  ...users,
  ...forms,
  ...fieldValues,
  ...discoveredData,
  ...file,
  messageSaga,
];

export default function* rootSaga() {
  yield all(sagas.map(saga => call(saga)));
}
