import React, { useState, useEffect } from 'react';

import { Icon } from '@/components/ui';

export interface DrawerProps {
  /**
   * Optional flag to keep the drawer always open
   */
  alwaysOpen?: boolean;
  /**
   * Optional label
   */
  label?: string;
  /**
   * Child components
   */
  children: JSX.Element;
  /**
   * Optional onClick handler
   */
  onClick?: () => void;
  open?: boolean;
  className: string;
}
/**
 * Drawer UI component
 */
export const Drawer: React.FC<DrawerProps> = ({
  alwaysOpen,
  children,
  label,
  onClick,
  open,
  className,
}) => {
  const [showContents, setShowContents] = useState<boolean>(false);

  useEffect(() => {
    setShowContents(open || alwaysOpen);
  }, [open]);

  return (
    <div
      onClick={onClick || onClick}
      className={`drawer ${showContents ? 'open' : ''} ${
        className ? className : ''
      }`}>
      <div
        className="drawer__toggle"
        onClick={() => !alwaysOpen && setShowContents(!showContents)}>
        {label}
        {!alwaysOpen && (
          <Icon
            iconClass={`chevron-down ${showContents ? 'open' : ''}`}
            size="0.9rem"
            color="var(--primary)"
          />
        )}
      </div>
      <div
        className={`${
          showContents ? 'drawer__contents--show-contents' : 'drawer__contents'
        }`}>
        {children}
      </div>
    </div>
  );
};
