import React, { useRef, useState, useEffect } from 'react';

import { Tooltip } from '@/components/ui';
import { Loading } from '../loading';
export interface TextFieldProps {
  /**
   * Label for text field element
   */
  label?: string;
  /**
   * Name property for text field element
   */
  name?: string;
  /**
   * Optional boolean value for disabling the element
   */
  disabled?: boolean;
  /**
   * Type of Input (text, password, email, tel, social)
   */
  type?: string;
  /**
   * Optional className
   */
  className?: string;
  /**
   * Optional error message
   */
  errorMessage?: string;
  /**
   * Error state
   */
  error?: boolean;
  /**
   * Optional tooltip text
   */
  tooltipText?: string;
  /**
   * Optional standard message
   */
  message?: string;
  /**
   * Does this field require validation?
   */
  validation?: boolean;
  /**
   * Is field data validated?
   */
  validated?: boolean;
  /**
   * Current value
   */
  value?: string | null;
  /**
   * Default value
   */
  defaultValue?: string | null;
  /**
   * Is this field readonly?
   */
  readonly?: boolean;
  /**
   * Is this field required?
   */
  required?: boolean;
  /**
   * Optional placeholder text?
   */
  placeholder?: string;
  /**
   * returns text field
   */
  textArea?: boolean;
  rows?: number;
  /**
   * Field id
   */
  id?: string;
  /**
   * Field character limit
   */
  maxLength?: number;
  /**
   * onClick handler
   */
  onChange?: (e, isKeystroke?) => void;
  suggestedValues?: string[];
}

/**
 * Primary UI component for text input
 */
export const TextField: React.FC<TextFieldProps> = ({
  label,
  onChange,
  disabled,
  type = 'text',
  className,
  error,
  errorMessage,
  required,
  value,
  validation,
  validated,
  placeholder,
  readonly,
  name,
  defaultValue,
  message,
  id,
  textArea,
  rows,
  maxLength,
  tooltipText,
  suggestedValues,
}) => {
  const [suggestionSelected, setSuggestionSelected] = useState<boolean>(false);
  const _inputField = useRef(null);
  const _suggestedValuesInner = useRef(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [renderSuggestions, setRenderSuggestions] = useState<boolean>(true);
  let keyCount = 0;
  const canRenderSuggestedValues =
    renderSuggestions && suggestedValues && !suggestionSelected && !disabled;

  const getKey = (prefix) => {
    keyCount = keyCount += 1;
    return `${prefix}-${keyCount}`;
  };

  const resetSuggestions = () => {
    setRenderSuggestions(false);
    setLoading(true);
  };

  const handleChange = (value: string, isKeystroke?: boolean) => {
    onChange(value, isKeystroke);
    setSuggestionSelected(false);
    if (suggestedValues && isKeystroke) {
      !_inputField?.current?.value?.length
        ? resetSuggestions()
        : setRenderSuggestions(true);
    }
  };

  const handleClickOutside = (e) => {
    if (
      _inputField.current &&
      !_inputField?.current?.contains(e.target) &&
      !_suggestedValuesInner?.current?.contains(e.target)
    ) {
      setRenderSuggestions(false);
    }
  };

  useEffect(() => {
    if (suggestedValues) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [suggestedValues]);

  const boldenMatch = (result, querystr) => {
    var reg = new RegExp(querystr, 'gi');
    var finalStr = result.replace(reg, function (str) {
      return '<b>' + str + '</b>';
    });
    return (
      <div
        ref={(ref) => {
          ref && (ref.innerHTML = finalStr);
        }}></div>
    );
  };

  useEffect(() => {
    defaultValue && onChange(defaultValue);
  }, []);

  const suggestionSelectedHelper = (suggestion) => {
    _inputField.current.value = suggestion;
    handleChange(suggestion);
    setSuggestionSelected(true);
  };

  return (
    <div
      className={`input-container
      ${validation ? (validated ? 'valid-field' : 'invalid-field') : ''}
      ${className ? className : ''}
      ${errorMessage || error ? 'input-error' : ''}
    `}>
      {label && (
        <label
          htmlFor="none"
          className={`input-container__label ${
            required ? 'field-required' : ''
          }`}>
          {label}
        </label>
      )}
      <label style={{ display: 'none' }}>fieldlabel</label>
      {tooltipText && <Tooltip text={tooltipText} />}
      {textArea ? (
        <textarea
          ref={_inputField}
          id={id}
          name={name || name}
          value={value}
          rows={rows}
          disabled={disabled || disabled}
          required={required}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onKeyUp={(e) => handleChange(e.currentTarget.value)}
          maxLength={maxLength}
          className={`input-container__input--text-field ${
            disabled || readonly ? 'disabled-readonly' : ''
          }`}
        />
      ) : (
        <input
          ref={_inputField}
          id={id}
          type={type}
          maxLength={maxLength}
          name={name || name}
          value={value}
          disabled={disabled}
          required={required}
          placeholder={placeholder}
          autoComplete="off"
          defaultValue={defaultValue}
          onClick={() => {
            suggestedValues && setRenderSuggestions(true);
          }}
          onKeyUp={(e) => handleChange(e.currentTarget.value, true)}
          className={`input-container__input--text-field
            ${disabled || readonly ? 'disabled-readonly' : ''}
          `}
        />
      )}
      {canRenderSuggestedValues && (
        <div className="input-container__suggested-values">
          <div
            className="input-container__suggested-values--inner"
            ref={_suggestedValuesInner}>
            {_inputField?.current?.value?.length &&
              loading &&
              setTimeout(() => {
                setLoading(false);
              }, 900) && (
                <div className="input-container__suggested-values--suggestion suggestion-loading">
                  <Loading positionRelative={true} height="100%" width="100%" />
                </div>
              )}
            {!loading &&
              suggestedValues.map((suggestion) => {
                return (
                  <div
                    id={suggestion}
                    className="input-container__suggested-values--suggestion"
                    key={getKey('suggestion')}
                    onClick={(e) => {
                      suggestionSelectedHelper(suggestion);
                    }}
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                        suggestionSelectedHelper(suggestion);
                      }
                    }}>
                    {boldenMatch(suggestion, _inputField?.current?.value)}
                  </div>
                );
              })}
          </div>
        </div>
      )}
      {message && <p className={`input-container__message`}>{message}</p>}
      {errorMessage && (
        <p className={`input-container__message--error`}>{errorMessage}</p>
      )}
    </div>
  );
};
