import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, SubNavButton } from '@/components/ui';

export interface SubNavbarProps {
  isActive?: string;
  onClick?: (category: string) => void;
  className?: string;
  shouldShowApprovers?: boolean;
}

export const SubNavbar: React.FC<SubNavbarProps> = ({
  isActive,
  onClick,
  className,
  shouldShowApprovers,
}) => {
  const history = useHistory();

  const setActiveSection = (section: string) => {
    history.replace(`#${section}`);
    onClick(section);
  };

  return (
    <div className={`sub-nav ${className}`}>
      <SubNavButton
        iconClass={`${isActive === 'details' ? 'details active' : 'details'}`}
        status={isActive === 'packet-details' ? true : false}
        label="Packet Details"
        onClick={() => setActiveSection('packet-details')}
      />
      <SubNavButton
        iconClass={`${isActive === 'forms' ? 'forms active' : 'forms'}`}
        status={isActive === 'forms' ? true : false}
        label="Forms"
        onClick={() => setActiveSection('forms')}
      />
      {shouldShowApprovers && (
        <SubNavButton
          iconClass={`${
            isActive === 'approvers' ? 'approvers active' : 'approvers'
          }`}
          status={isActive === 'approvers' ? true : false}
          label="Approvers"
          onClick={() => setActiveSection('approvers')}
        />
      )}
    </div>
  );
};

export default SubNavbar;
