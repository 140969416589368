import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table';

import { Paginator } from '@/components/ui/';
import { Button } from '@/components/ui/button/index';
import { Icon } from '@/components/ui/icon';
import { formatDate } from '@/utils/dateUtils';
import { ColumnFilter } from '@/contexts/packets-page/utils/column-filter';

export const UnsentPacketsTable = ({ packets, users }) => {
  const history = useHistory();

  const displayUserName = (user: any) => {
    return user?.firstName + ' ' + user?.lastName;
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Requested By',
        accessor: (row) =>
          displayUserName(
            users.find((user) => user.userId === row.creatorUserId),
          ),
        width: 'fifteen',
        Filter: ColumnFilter,
      },
      {
        Header: 'Created Date',
        accessor: (row) =>
        formatDate(new Date(formatDate(row.createdDateTimeUtc, 'MM/DD/YYYY h:mm a') + " UTC").toString(), 'MM/DD/YYYY h:mm a'),
        width: 'fifteen',
        Filter: false,
      },
      {
        Header: 'Name',
        accessor: 'name',
        width: 'forty',
        Filter: ColumnFilter,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 'tenth',
        Filter: false,
      },
      {
        Header: 'Due Date',
        accessor: (row) => 'Pending',
        width: 'tenth',
        Filter: false,
      },
      {
        Header: () => null,
        id: 'view',
        width: 'tenth',
        Cell: ({ row }) => {
          const { ...props } = row;
          return (
            <Button
              backgroundColor="white"
              label="View"
              className="table-button"
              border={true}
              onClick={() =>
                history.push(`/packets/packet/${props.original.packetId}`)
              }
            />
          );
        },
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    pageCount,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: packets,
      initialState: { pageIndex: 0, pageSize: 24 },
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  const handleChangePage = () => {
    gotoPage();
  };

  return (
    <div className="packet-table-wrapper">
      <table {...getTableProps()} className="packet-table">
        <thead>
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup?.getHeaderGroupProps()}>
              {headerGroup.headers?.map((column) => (
                <th
                  className={`packet-table-header packet-table-header__${column.width}`}
                  {...column?.getHeaderProps(column.getSortByToggleProps())}>
                  {column?.render('Header')}
                  {column?.id !== 'view'
                    ? column?.isSorted &&
                      (column?.isSortedDesc ? (
                        <Icon
                          iconClass="chevron-up table-header-icon"
                          color={'var(--primary'}
                        />
                      ) : (
                        <Icon
                          iconClass="chevron-down table-header-icon"
                          color={'var(--primary'}
                        />
                      ))
                    : null}
                  <div onClick={(e) => e.stopPropagation()}>
                    {column?.canFilter ? column?.render('Filter') : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page?.length ? (
            page?.map((row) => {
              prepareRow(row);
              return (
                <tr {...row?.getRowProps()} className="packet-table-row">
                  {row.cells?.map((cell) => {
                    return (
                      <td
                        className="packet-table-cell"
                        {...cell?.getCellProps()}>
                        {cell?.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr className="no-table-data">
              <td>No Packets</td>
            </tr>
          )}
        </tbody>
      </table>
      {page?.length ? (
        <Paginator
          currentPage={pageIndex}
          onChangePage={handleChangePage}
          totalPages={pageCount}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          gotoPage={gotoPage}
        />
      ) : (
        ''
      )}
    </div>
  );
};
