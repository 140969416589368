import { Users, RolePermissionId } from "@/types/users";

// Action Types
export enum UsersActions {
  GET_USERS = 'USERS/GET_USERS',
  GET_USERS_SUCCESS = 'USERS/GET_USERS_SUCCESS',
  GET_USERS_FAILURE = 'USERS/GET_USERS_FAILURE',
  GET_USERS_PROFILE = 'USERS/GET_USERS_PROFILE',
  UPDATE_USERS_PROFILE = 'USERS/UPDATE_USERS_PROFILE',
  UPDATE_USERS_SUCCESS = 'USERS/UPDATE_USERS_SUCCESS',
}

// Action Interfaces
export interface GetUsers {
  type: typeof UsersActions.GET_USERS;
  payload: {
    rolePermissionId?: RolePermissionId;
  };
}

export interface GetUsersSuccess {
  type: typeof UsersActions.GET_USERS_SUCCESS;
  payload: {
    users: Users;
  }
}

export interface GetUsersFailure {
  type: typeof UsersActions.GET_USERS_FAILURE;
  payload: {
    error: string;
  }
}


// Action Type List
export type UsersAction = GetUsers | GetUsersFailure | GetUsersSuccess ;

// Action Creators
export const getUsers = (rolePermissionId?: RolePermissionId): UsersAction => ({
  type: UsersActions.GET_USERS,
  payload: {
    rolePermissionId,
  }
});

export const getUsersSuccess = (users: Users): UsersAction => ({
  type: UsersActions.GET_USERS_SUCCESS,
  payload: {
    users,
  }
});

export const getUsersFailure = (error: string): UsersAction => ({
  type: UsersActions.GET_USERS_FAILURE,
  payload: {
    error,
  }
});