import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { clearPackets, getPackets } from '@/actions/actions/packets';
import { selectActivePackets } from '@/selectors/packets';
import PacketsHeader from '@/contexts/packets-page/my-packets/packets-header';
import { Packet } from '@/types/packet';
import { getUsers } from '@/actions/actions/users';
import { selectUsers } from '@/selectors/users';
import { UnsentPacketsTable } from '@/contexts/packets-page/my-packets/unsent-packets/unsent-packets-table';
import { contains } from '@/utils/contains';
import { selectDeviceType } from '@/selectors/device_type';
import { PacketCard, PacketCardPaginator, Loading } from '@/components/ui';

type userRoles = 'User' | 'PowerUser' | 'Approver';

const ActivePackets: React.FC = () => {
  const [filteredPackets, setFilteredPackets] = useState<Packet[]>(null);
  const dispatch = useDispatch();
  const [userRoles, setUserRoles] = useState<userRoles[]>(null);
  const history = useHistory();
  const [cards, setCards] = useState(null);
  const [cardsPerPage, setCardsPerPage] = useState<number>(6);
  const [startIndex, setStartIndex] = useState<number>(0);
  const [endIndex, setEndIndex] = useState<number>(startIndex + cardsPerPage);
  let keyCount = 0;

  let packets = useSelector(selectActivePackets);
  const deviceType = useSelector(selectDeviceType);
  const users = useSelector(selectUsers);
  const user = useSelector((state: RootStateOrAny) => {
    return state.user.user;
  });

  const getKey = (prefix) => {
    keyCount = keyCount += 1;
    return `${prefix}-${keyCount}`;
  };

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getPackets(localStorage.getItem('user_id')));
    return () => {
      dispatch(clearPackets());
    };
  }, []);

  useEffect(() => {
    user?.userRoles &&
      setUserRoles(
        user.userRoles?.map((role) => {
          return Object.values(role)[0];
        }),
      );
  }, [user]);

  useEffect(() => {
    userRoles &&
      !contains(userRoles, ['User', 'PowerUser']) &&
      history.push('/packets/team-packets/approval-needed');
  }, [userRoles]);

  useEffect(() => {
    if (packets) {
      setFilteredPackets(
        packets.filter((packet) => {
          if ([1, 2].includes(packet.statusId)) {
            return packet;
          }
        }),
      );
    }
  }, [packets]);

  useEffect(() => {
    setEndIndex(startIndex + cardsPerPage);
  }, [startIndex]);

  useEffect(() => {
    if (filteredPackets) {
      setCards(filteredPackets.slice(startIndex, endIndex));
    }
  }, [filteredPackets, endIndex]);

  const memoizedPackets = useMemo(() => filteredPackets, [filteredPackets]);

  const navigate = (directionOrPageNumber) => {
    if (typeof directionOrPageNumber === 'number') {
      setStartIndex(cardsPerPage * directionOrPageNumber);
    }
  };

  return (
    <div className="create-packet page__container bg-blue-gradient ">
      <div className="create-packet__container container">
        <div className="create-packet__actions section is-12 is-widescreen">
          <PacketsHeader currentPage="unsent" />
        </div>
        {deviceType === 'mobile' ? (
          <>
            <div className="packet-card-table">
              {cards &&
                cards?.map((packet) => {
                  return (
                    <PacketCard
                      key={getKey('packet-card')}
                      packet={packet}
                      type="active"
                    />
                  );
                })}
            </div>
            <PacketCardPaginator
              navigate={navigate}
              filteredItems={filteredPackets}
              itemsPerPage={cardsPerPage}
            />
          </>
        ) : (
          filteredPackets &&
          users && (
            <UnsentPacketsTable packets={memoizedPackets} users={users} />
          )
        )}
        {!memoizedPackets && (
          <Loading
            ringColor="#fff"
            height="70vh"
            width="100%"
            positionRelative={true}
            message="Loading Packets..."
          />
        )}
      </div>
    </div>
  );
};

export default ActivePackets;
