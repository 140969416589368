import { buildRequestPath, cleanPath } from './apiUtils';

interface Params {
  [key: string]: string;
}

export const buildPath = (
  route: string,
  arg: string,
  params: Params = {},
  query: Params = {},
  include?: string[],
) => {
  let formattedPath = route;

  if (arg) { formattedPath = `${route}/${arg} `}

  if (params) {
    for (const param of Object.keys(params)) {
      formattedPath = formattedPath.replace(`:${param}`, params[param]);
    }
  }

  const combinedQuery = (() => {
    if (include) {
      return {
        ...query,
        include: include.join(','),
      };
    }

    return query;
  })();

  return `/${buildRequestPath(formattedPath, combinedQuery)}`;
};

const api1 = (path: string): string => {
  return `/api/v1/${cleanPath(path)}`;
};

export const Routes = {
  // API routes
  api: {
    approvalPaths: api1('approvals/approvalpaths/templates?includeUserSaved=true'),
    approvalTimelines: api1('approvals/approvaltimelines'),
    file: api1('packets/files'),
    formbundles: api1('forms/formbundles'),
    forms: api1('forms'),
    packets: api1('packets'),
    packetForm: api1('forms'),
    users: api1('users'),
    fieldValues: api1('forms/fields/values/search')
  },
}
