import React, { useState } from 'react';

export interface PacketCardPaginatorProps {
  filteredItems: any;
  navigate: (pageNumber) => void;
  itemsPerPage: number;
}

export const PacketCardPaginator: React.FC<PacketCardPaginatorProps> = ({
  filteredItems,
  navigate,
  itemsPerPage,
}) => {
  let keyCount = 0;
  let pageNumber = 0;
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);

  const getKey = (prefix) => {
    keyCount = keyCount += 1;
    return `${prefix}-${keyCount}`;
  };

  return (
    <div className="pagination-buttons flex flex-center">
      {filteredItems &&
        filteredItems?.map((packet, index) => {
          if (index % itemsPerPage === 0) {
            pageNumber += 1;
            const page = pageNumber;
            return (
              <div
                className={`pagination-buttons__button ${
                  currentPageNumber === page ? 'current-page' : ''
                }`}
                key={getKey('page-number')}
                onClick={() => {
                  navigate(page - 1);
                  setCurrentPageNumber(page);
                  window.scrollTo({ top: 0, behavior: 'auto' });
                }}>
                {page}
              </div>
            );
          }
        })}
    </div>
  );
};
