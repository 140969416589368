import { ApprovalTimelinesActions } from '@/actions/actions/approval_timelines';
import { ApprovalTimelineState } from '@/types/approval_timeline';

const initialState: ApprovalTimelineState = {
  approvalTimelines: null,
};

export default function reducer(
  state: ApprovalTimelineState = initialState,
  action: any,
): ApprovalTimelineState {
  const { type, payload } = action;

  switch (type) {
    case ApprovalTimelinesActions.GET_APPROVAL_TIMELINES_SUCCESS:
      return {
        ...state,
        approvalTimelines: payload.approvalTimelines
      }
    default:
      return state;
  }
}
