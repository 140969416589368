import { call, put, takeLatest } from 'redux-saga/effects';

import { PacketsActions, getPacketsFailure, getPacketsSuccess, GetPackets } from '@/actions/actions/packets';
import Api from '@/api/Api';
import { buildPath, Routes } from '@/utils/routeUtils';

export function* getPacketsSaga(
  action: GetPackets
) {
  try {
    const { userId } = action.payload;

    const url = buildPath(Routes.api.packets, 'user')

    const response = yield call(Api.utility.get, url);

    yield put(getPacketsSuccess(response));
  } catch (err) {
    yield put(getPacketsFailure(err));
  }
}

export function* watchGetPackets() {
  yield takeLatest(PacketsActions.GET_PACKETS, getPacketsSaga);
}
