import { PacketsActions } from '@/actions/actions/packets';
import { PacketsState } from '@/types/packet';

const initialState: PacketsState = {
  packets: null,
  teamPackets: null
};

export default function reducer(
  state: PacketsState = initialState,
  action: any,
): PacketsState {
  const { type, payload } = action;
  switch (type) {
    case PacketsActions.GET_PACKETS_SUCCESS:
      return {
        ...state,
        packets: payload.packets.data.filter( packet => {return !packet.name.toLowerCase().includes('do not use')}),
      }
    case PacketsActions.CLEAR_PACKETS:
      return {
        ...state,
        packets: undefined,
      }
    case PacketsActions.GET_TEAM_PACKETS_SUCCESS:
      return {
        ...state,
        teamPackets: payload.packets.data.filter(packet => {return !packet.name.toLowerCase().includes('do not use')}),
      }
    default:
      return state;
  }
}
