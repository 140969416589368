// Action Types
export enum FileActions {
  DELETE_FILE = 'FILE/DELETE_FILE',
  DELETE_FILE_SUCCESS = 'FILE/DELETE_FILE_SUCCESS',
  DELETE_FILE_FAILURE = 'FILE/DELETE_FILE_FAILURE',
};

// Action Interfaces
export interface DeleteFile {
  type: typeof FileActions.DELETE_FILE;
  payload: {
    fileId: string;
  }
};

export interface DeleteFileSuccess {
  type: typeof FileActions.DELETE_FILE_SUCCESS;
  payload: {
    data: any;
  }
};

export interface DeleteFileFailure {
  type: typeof FileActions.DELETE_FILE_FAILURE;
  payload: {
    error: string;
  }
};

// Action Type List
export type FileAction = DeleteFile | DeleteFileFailure | DeleteFileSuccess;

// Action Creators
export const deleteFile = (fileId: string): FileAction => ({
  type: FileActions.DELETE_FILE,
  payload: {
    fileId,
  }
});

export const deleteFileSuccess = (data: any): FileAction => ({
  type: FileActions.DELETE_FILE_SUCCESS,
  payload: {
    data,
  }
});

export const deleteFileFailure = (error: string): FileAction => ({
  type: FileActions.DELETE_FILE_FAILURE,
  payload: {
    error,
  }
});
