import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectUser } from '@/selectors/user';
import { contains } from '@/utils/contains';

type userRoles = 'User' | 'PowerUser' | 'Approver';

export interface PrivateRouteProps {
  path: string;
  component?: React.ComponentType<any>;
  roles: userRoles[];
  render?: JSX.Element;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  path,
  component,
  roles,
  render,
}) => {
  const user = useSelector(selectUser);

  return (
    <>
      {user &&
        (contains(
          user?.userRoles?.map((role) => {
            return Object.values(role)[0];
          }),
          roles,
        ) ? (
          render ? (
            <Route path={path} exact render={() => render} />
          ) : (
            <Route path={path} exact component={component} />
          )
        ) : (
          <Redirect path={path} to="/page-not-found" />
        ))}
    </>
  );
};
