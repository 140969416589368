import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { Button, InfoCard, Modal, EditForm } from '@/components/ui';
import { getUserProfile, updateUserProfile } from '@/actions/actions/user';
import { getAuth, checkParse } from '@/utils/oauth';
import { useHistory } from 'react-router-dom';

const Login = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: RootStateOrAny) => {
    return state.user.user;
  });

  useEffect(() => {
    checkParse();
  }, []);

  return (
    <div className="login-page bg-blue-gradient page__container">
      <div className="page__header container">
        <h1>Log in</h1>
        <p>Log in to your account.</p>
      </div>
      <div className="login card">
        <Button
          label="Click to log In"
          primary={false}
          onClick={() => getAuth()}
          size="large"
          labelColor="#fff"
        />
      </div>
    </div>
  );
};

export default Login;
