import { FormsActions } from '@/actions/actions/forms';
import { FormsState } from '@/types/forms';

const initialState: FormsState = {
  forms: null,
};

export default function reducer(
  state: FormsState = initialState,
  action: any,
): FormsState {
  const { type, payload } = action;
  switch (type) {
    case FormsActions.GET_FORMS_SUCCESS:
      return {
        ...state,
        forms: payload.forms.data
      }
    default:
      return state;
  }
}
