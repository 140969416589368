import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import classNames from 'classnames';

import { Button } from '@/components/ui';
import { ProfileAction } from '@/contexts/profile-page/profile-actions';
import { logoutUser } from '@/utils/oauth';
import { getDiscoveredData } from '@/actions/actions/discovered_data';
import { showDiscoveredDataNotification } from '@/utils/notificationCount';

export interface ProfileHeaderProps {
  currentPage: string;
}

export const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  currentPage,
}) => {
  const dispatch = useDispatch();
  const [discoveredData, setDiscoveredData] = useState([]);

  const discoveredUserData = useSelector((state: RootStateOrAny) => {
    return state.discoveredData.discoveredData;
  });

  useEffect(() => {

    if (discoveredUserData === null) {
      dispatch(getDiscoveredData());
    }

    setDiscoveredData(discoveredUserData)
  }, [discoveredUserData]);

  const profileLinkStyles = classNames('profile-header-link', {
    'is-current-page': currentPage === 'profile',
  });

  const descoveredLinkStyles = classNames('profile-header-link', {
    'is-current-page': currentPage === 'discovered',
  });

  return (
    <ProfileAction className="profile-index">
      <div className="profile-index__sections">
        <Link className={profileLinkStyles} to="/profile">
          MY PROFILE
        </Link>
        <Link className={descoveredLinkStyles} to="/profile/discovered-data">
          DISCOVERED DATA
        </Link>
        {
          showDiscoveredDataNotification(discoveredData) > 0 &&
          <span className="navbar__notification">
            <Link to='/profile/discovered-data' >{ showDiscoveredDataNotification(discoveredData) }</Link>
          </span>
        }
      </div>
      <Button
        className="profile-header-button"
        primary={false}
        size="large"
        label="log out"
        labelColor="#fff"
        onClick={() => logoutUser()}
      />
    </ProfileAction>
  );
};

export default ProfileHeader;
