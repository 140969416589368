import React from 'react';

export interface ColumnFilterProps {
  column: any;
}

export const ColumnFilter: React.FC<ColumnFilterProps> = ({ column }) => {
  const { filterValue, setFilter } = column;

  return (
    <span>
      <label style={{ display: 'none' }}>filter</label>
      <input
        className="packet-table-header__column-filter--input-field"
        value={filterValue || ''}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Search..."
      />
    </span>
  );
};
