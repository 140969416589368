import { DeviceTypeActions } from '@/actions/actions/device_type';
import { DeviceTypeState } from '@/types/device_type';

const initialState: DeviceTypeState = {
  deviceType: null,
};

export default function reducer(
  state: DeviceTypeState = initialState,
  action: any,
): DeviceTypeState {
  const { type, payload } = action;
  switch (type) {
    case DeviceTypeActions.SET_DEVICE_TYPE:
      return {
        ...state,
        deviceType: payload.deviceType
      }
    default:
      return state;
  }
}
