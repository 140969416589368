import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { TextField, Button, Loading, ConfirmationModal } from '@/components/ui';

export interface PacketSignatureProps {
  packetId: number;
  header: string;
  handleSubmit: any;
  decisionType: string;
  action: string;
}

export type DecisionType = 'Reject';

export const PacketSignature: React.FC<PacketSignatureProps> = ({
  packetId,
  header,
  handleSubmit,
  action,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [comment, setComment] = useState(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [confirmationResponse, setConfirmationResponse] = useState<boolean>(
    null,
  );
  const [decisionCategory, setDecisionCategory] = useState<DecisionType>(null);

  const updateComments = (value) => {
    setComment(value);
  };

  useEffect(() => {
    confirmationResponse && handleFormSubmit();
  }, [confirmationResponse]);

  const handleFormSubmit = () => {
    const approvalAction = {
      action: decisionCategory,
      comment: comment,
    };
    dispatch(handleSubmit(approvalAction, packetId));
    setSubmitted(true);
    setTimeout(() => {
      history.push('/packets/team-packets/approval-needed');
    }, 500);
  };

  return (
    <div className="packet-signature info-card container">
      <div className="info-card-header-parent container">
        {header && <p className="packet-signature-header">{header}</p>}
      </div>
      <div className="packet-signature__comment">
        <TextField
          label={`Comments ${
            action === 'Approving' ? '(Optional)' : '(Required)'
          }`}
          placeholder="Enter comments..."
          textArea={true}
          rows={5}
          onChange={updateComments}
        />
      </div>
      <div className="packet-signature__submit">
        <div className="button-group">
          <Button
            primary={true}
            label="cancel"
            size="large"
            border={true}
            onClick={() => history.push(`/packets/packet/${packetId}`)}
          />
          <div className="packet-signature__button-group--right">
            {/* <Button
              primary={false}
              label="deny packet"
              size="large"
              border={true}
              labelColor="#fff"
              backgroundColor="var(--secondary1)"
              // disabled={validateForm()}
              disabled={!comment?.length}
              onClick={() => {
                setDecisionCategory('Deny');
                setConfirmationModal(true);
              }}
            /> */}
            <Button
              primary={false}
              label="reject packet"
              size="large"
              border={true}
              labelColor="#fff"
              backgroundColor="var(--secondary1)"
              // disabled={validateForm()}
              disabled={!comment?.length}
              onClick={() => {
                setDecisionCategory('Reject');
                setConfirmationModal(true);
              }}
            />
          </div>
        </div>
      </div>
      {submitted && <Loading message={`${action} Packet`} />}
      {confirmationModal && (
        <ConfirmationModal
          yesButtonLabel={`${decisionCategory} Packet`}
          header={`${decisionCategory} Packet`}
          prompt="Are you sure?"
          noButtonLabel="no"
          handleResponse={(res) => {
            setConfirmationResponse(res);
            setConfirmationModal(false);
          }}
          closeModal={() => {
            setConfirmationModal(false);
          }}
        />
      )}
    </div>
  );
};
