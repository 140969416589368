import React, { useEffect, useMemo, useState, useRef } from 'react';

import { Field, Form, Page } from '@/types/packet_form';
import PacketFormViewCanvas from './packet-form-view-canvas';
import { Paginator } from '@/components/ui/';
import { PacketForm as PacketFormType } from '@/types/packet_form';

interface Props {
  currentPage: number;
  form: Form;
  handleCanvasClick: (location: Location) => void;
  setCurrentPage: (page: number) => void;
  setImageOffsetPercentage: (percentage: number) => void;
  handleChange: any;
  state: any;
  savePacketForm: (packetForm?: PacketFormType, autosave?: boolean) => void;
  packetFormSections: any;
  requiredFieldsError: boolean;
}

export interface ImageDimensions {
  width: number;
  height: number;
}

export interface Location {
  x: number;
  y: number;
}

const PacketFormView = (props: Props): JSX.Element => {
  const {
    currentPage,
    form,
    handleCanvasClick,
    setCurrentPage,
    setImageOffsetPercentage,
    handleChange,
    state,
    savePacketForm,
    packetFormSections,
    requiredFieldsError,
  } = props;
  const [imageDimensions, setImageDimensions] = useState<ImageDimensions>();
  const [imageForDimensions, setImageForDimensions] = useState<
    ImageDimensions
  >();
  const [originalImageDimensions, setOriginalImageDimensions] = useState<
    ImageDimensions
  >();
  const [allFields, setAllFields] = useState<Field[]>([]);
  const imgEl = useRef<HTMLImageElement>(null);
  const [pdfUrl, setPdfUrl] = useState<string>(null);
  const [_currentPage, _setCurrentPage] = useState(null);
  const [formId, setFormId] = useState<number>(null);

  const totalPages: number = useMemo(() => {
    return form?.pages?.length;
  }, [form]);

  useEffect(() => {
    if (
      (imageForDimensions && !_currentPage) ||
      (imageForDimensions && _currentPage !== currentPage)
    ) {
      _setCurrentPage(currentPage);

      setOriginalImageDimensions({
        width: imageForDimensions.width,
        height: imageForDimensions.height,
      });

      const aspectRatio = imageForDimensions.width / imageForDimensions.height;

      const canvasWidth =
        imageForDimensions.width > 1200 ? 1200 : imageForDimensions.width;

      const canvasHeight = canvasWidth / aspectRatio;

      setImageDimensions({
        width: canvasWidth,
        height: canvasHeight,
      });
    }
  }, [imageForDimensions]);

  useEffect(() => {
    form && setFormId(form.formId);
    if ((form && form.formId !== formId) || currentPage !== _currentPage) {
      setAllFields(gatherAllFields(form.pages[currentPage - 1]));
      getImageUrl();
    }
  }, [form, currentPage]);

  useEffect(() => {
    if (imageDimensions && originalImageDimensions) {
      setImageOffsetPercentage(
        imageDimensions.width / originalImageDimensions.width,
      );
    }
  }, [imageDimensions, originalImageDimensions]);

  const getImageUrl = () => {
    const page = form.pages.find((page) => page.pageNumber === currentPage);
    const url = page.image;

    setPdfUrl(url);
  };

  const gatherAllFields = (page: Page): Field[] => {
    const newFields = [];

    for (const section of page.sections) {
      newFields.push(...section.fields);

      if (section.sections && section.sections.length > 0) {
        for (const subsection of section.sections) {
          newFields.push(...subsection.fields);
        }
      }
    }

    return newFields;
  };

  return (
    <div className="packet-form-view">
      {pdfUrl && (
        <>
          <PacketFormViewCanvas
            allFields={allFields}
            handleCanvasClick={handleCanvasClick}
            imageDimensions={imageDimensions}
            offsetPercentage={
              imageDimensions?.width / originalImageDimensions?.width
            }
            imageRef={imgEl}
            handleChange={handleChange}
            state={state}
            savePacketForm={savePacketForm}
            packetFormSections={packetFormSections}
            requiredFieldsError={requiredFieldsError}
          />
          <img
            style={{ display: 'none' }}
            src={pdfUrl}
            ref={imgEl}
            onLoad={() => {
              setImageForDimensions({
                width: imgEl.current.naturalWidth,
                height: imgEl.current.naturalHeight,
              });
            }}
          />
        </>
      )}

      <div className="packet-form-view__paginator-container">
        {totalPages > 1 && (
          <Paginator
            currentPage={currentPage - 1}
            onChangePage={() => {}}
            totalPages={totalPages}
            previousPage={() => setCurrentPage(currentPage - 1)}
            nextPage={() => setCurrentPage(currentPage + 1)}
            canPreviousPage={currentPage > 1}
            canNextPage={currentPage < totalPages}
            gotoPage={(page: number) => {
              setCurrentPage(page + 1);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PacketFormView;
