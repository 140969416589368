import React, { Component, ReactNode } from 'react';
import * as Sentry from '@sentry/react';

import { Nullable } from '@/types/generic';
import ErrorScreen from './ErrorScreen';

interface Error {
  message: string;
}

interface Props {
  children: ReactNode;
  fallbackComponent?: ReactNode;
}

interface State {
  error: Nullable<Error>;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return {
      error,
    };
  }

  // NOTE: Re-implement if sentry is added to this project
  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope((scope: any) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  renderFallbackComponent = () => {
    const { fallbackComponent } = this.props;
    const { error } = this.state;

    return fallbackComponent ? (
      fallbackComponent
    ) : (
      <ErrorScreen message={error.message} />
    );
  };

  render() {
    const { children } = this.props;
    const { error } = this.state;

    return error ? this.renderFallbackComponent() : children;
  }
}

export default ErrorBoundary;
