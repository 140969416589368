import { PacketFormActions } from '@/actions/actions/packet_form';
import { PacketFormState } from '@/types/packet_form';
import { FileActions } from '@/actions/actions/file';

const initialState: PacketFormState = {
  packetForm: null,
};

export default function reducer(
  state: PacketFormState = initialState,
  action: any,
): PacketFormState {
  const { type, payload } = action;

  switch (type) {
    case PacketFormActions.UPDATE_PACKET_FORM_SUCCESS:
      return {
        ...state,
        packetForm: payload.packetForm
      }
    case PacketFormActions.GET_PACKET_FORM_SUCCESS:
      return {
        ...state,
        packetForm: payload.packetForm
      }
    case FileActions.DELETE_FILE_SUCCESS:
      const newPacketFormFiles = state.packetForm.files.filter((file) => {
        return file.fileId !== payload.data
      });
      return {
        ...state,
        packetForm: {
          ...state.packetForm,
          files: newPacketFormFiles,
        }
      };
    case PacketFormActions.RESET_PACKET_FORM:
      const _state = JSON.parse(JSON.stringify(state));
      delete _state.packetForm;
      return {
        ..._state,
      }
    default:
      return state;
  }
}
