import React from 'react';

export interface ProfileActionProps {
  header?: string;
  children: React.ReactNode;
  className?: string;
}

export const ProfileAction: React.FC<ProfileActionProps> = ({
  header,
  children,
  className,
}) => {
  return (
    <div
      className={`profile__actions columns container md-flex-col ${
        className ? className : ''
      }`}>
      {header && <h2 className="profile__actions--header">{header}</h2>}
      <div className="profile__actions--buttons">{children}</div>
    </div>
  );
};

export default ProfileAction;
