import classNames from 'classnames';
import React from 'react';

import { Icon } from '../icon/index';
import { SideEffect } from '@/types/generic';

type Increment = 'next' | 'prev';

interface Props {
  currentPage: number;
  onChangePage: SideEffect;
  totalPages: number;
  previousPage: SideEffect;
  nextPage: SideEffect;
  canPreviousPage: boolean;
  canNextPage: boolean;
  gotoPage: SideEffect;
}

const incrementIcon = (to: Increment) => {
  return to === 'next' ? 'caretRight' : 'caretLeft';
};

export const Paginator = (props: Props) => {
  const {
    currentPage,
    onChangePage,
    totalPages,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
  } = props;

  const renderPageIncrement = (to: Increment) => {
    const prevOrNext = to === 'prev';
    const iconClass = prevOrNext ? 'chevron-left' : 'chevron-right';
    const goPrevOrNext = prevOrNext ? previousPage : nextPage;

    return (
      <a className="paginator__increment" onClick={() => goPrevOrNext()}>
        <Icon iconClass={iconClass} color="black" />
      </a>
    );
  };

  const renderPageLink = (page: number) => {
    return (
      <a
        className={classNames('paginator__page', {
          'is-active': currentPage === page,
        })}
        key={page}
        onClick={() => gotoPage(page)}>
        {page + 1}
      </a>
    );
  };

  const renderPageLinks = () => {
    const pageNumbers = Array.from(Array(totalPages).keys()).filter(
      (pageNumber) => pageNumber > 1 && pageNumber <= totalPages,
    );
    return (
      <>{pageNumbers.map((pageNumber) => renderPageLink(pageNumber - 1))}</>
    );
  };

  return (
    <div className="paginator">
      {canPreviousPage && renderPageIncrement('prev')}
      {renderPageLink(0)}
      {renderPageLinks()}
      {totalPages > 1 && renderPageLink(totalPages - 1)}
      {canNextPage && renderPageIncrement('next')}
    </div>
  );
};
