import React from 'react';

import { DropdownSelect } from '@/components/ui/dropdown-select';
import { Option } from '@/types/option';
import { ApprovalPath } from '@/types/approval_path';
import { Icon } from '@/components/ui';

interface Props {
  handleRemoveApprover: (userIndex: number) => void;
  handleSelectApprover: (value: string, userIndex: number) => void;
  selectedApprovalPath: ApprovalPath;
  stopIndex: number;
  userIndex: number;
  userOptions: Option[];
}

const StopUser = (props: Props): JSX.Element => {
  const {
    handleRemoveApprover,
    handleSelectApprover,
    selectedApprovalPath,
    stopIndex,
    userIndex,
    userOptions,
  } = props;

  return (
    <div className="send-packet__stop-user">
      <div style={{ marginRight: '3px', width: '100%' }}>
        <DropdownSelect
          label="Approver"
          options={userOptions}
          onClick={(value) => {
            handleSelectApprover(value, userIndex);
          }}
          selectedValue={
            selectedApprovalPath?.stops[stopIndex]?.approvalStopDetail?.users[
              userIndex
            ]?.userId
          }
          placeholder="Select approver...."
          typeAhead={false}
          renderSelectedOption={true}
        />
      </div>
      {userIndex !== 0 && (
        <Icon
          iconClass="x-enclosed"
          onClick={() => {
            handleRemoveApprover(userIndex);
          }}
          size="1.1rem"
        />
      )}
    </div>
  );
};

export default StopUser;
