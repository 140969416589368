import { call, put, takeLatest } from 'redux-saga/effects';
import { flashSuccess, flashError } from '@/actions/sagas/messageSaga';

import { PacketActions, ApprovePacket, approvePacketFailure, approvePacketSuccess } from '@/actions/actions/packet';
import Api from '@/api/Api';
import { buildPath, Routes } from '@/utils/routeUtils';

export function* approvePacketSaga(
  action: ApprovePacket
) {
  try {
    const { approvalAction, packetNumber } = action.payload;

    const url = buildPath(Routes.api.packets, `${packetNumber}/approvalpath/approvalaction`)

    const response = yield call(Api.utility.put, url, approvalAction);

    yield put(approvePacketSuccess(response.data));
    yield put(flashSuccess('Packet Approved!'));
  } catch (err) {
    yield put(approvePacketFailure(err));
    yield put(flashError('Error: Packet Not Approved!'));
  }
}

export function* watchApprovePacket() {
  yield takeLatest(PacketActions.APPROVE_PACKET, approvePacketSaga);
}
