import React, { useState, useEffect } from 'react';

import interact from 'interactjs';

import { Icon } from '@/components/ui';
import { DraggableModalCoordinates } from '@/types/draggable_modal';

export interface DraggableModalProps {
  children: JSX.Element;
  initialLeftPostion?: string;
  initialTopPostion?: string;
  handleClickX?: () => void;
  height?: string | number;
  width?: string | number;
  maxWidth?: string | number;
  lastCoordinates?: DraggableModalCoordinates;
  setLastCoordinates?: React.Dispatch<
    React.SetStateAction<DraggableModalCoordinates>
  >;
}

export const DraggableModal: React.FC<DraggableModalProps> = ({
  children,
  initialLeftPostion,
  initialTopPostion,
  handleClickX,
  height,
  width,
  lastCoordinates,
  setLastCoordinates,
  maxWidth,
}) => {
  const [isRendered, setIsRendered] = useState<boolean>(false);
  const [resizeWidth, setResizeWidth] = useState<number>(null);
  const position = { x: 0, y: 0 };

  useEffect(() => {
    setTimeout(() => {
      setIsRendered(true);
    }, 50);
  }, []);

  const handleClose = () => {
    setIsRendered(false);
    setTimeout(() => {
      handleClickX();
    }, 150);
  };

  const recordLastCoordinates = (x, y) => {
    setLastCoordinates({ x: x, y: y });
  };

  useEffect(() => {
    if (isRendered) {
      interact('.draggable-modal')
        .draggable({
          onmove(event) {
            position.x += event.dx;
            position.y += event.dy;

            event.target.style.transform = `translate(${position.x}px, ${position.y}px)`;

            event.target.setAttribute('data-x', position.x);
            event.target.setAttribute('data-y', position.y);
            recordLastCoordinates(position.x, position.y);
          },
        })
        .resizable({
          edges: { top: true, left: true, bottom: true, right: true },
          listeners: {
            move: function (event) {
              let { x, y } = event.target.dataset;

              x = (parseFloat(x) || 0) + event.deltaRect.left;
              y = (parseFloat(y) || 0) + event.deltaRect.top;

              Object.assign(event.target.style, {
                minHeight: '100px',
                minWidth: '280px',
                maxWidth: maxWidth,
                width: `${event.rect.width}px`,
                height: `${event.rect.height}px`,
                transform: `translate(${x}px, ${y}px)`,
              });
              Object.assign(event.target.dataset, { x, y });
              setResizeWidth(event.rect.width);
            },
          },
        });
    }
  }, [isRendered]);

  return (
    <div
      style={{
        position: 'fixed',
        height: '100vh',
        width: '100vw',
        pointerEvents: 'none',
        top: 0,
        left: 0,
        zIndex: 999999,
      }}>
      <div
        style={{
          height: height,
          width: width,
        }}
        className={`draggable-modal ${isRendered ? 'is-open' : ''} ${
          typeof resizeWidth === 'number' && resizeWidth <= 320
            ? 'md-width'
            : ''
        }`}>
        <div
          className="draggable-modal__x-enclosed"
          onMouseDown={(e) => {
            e.currentTarget && e.stopPropagation();
          }}>
          <Icon
            size="1.25rem"
            iconClass="x-enclosed"
            color="var(--primary)"
            onMouseDown={handleClose}
          />
        </div>

        <div className="draggable-modal__content">{children}</div>
      </div>{' '}
    </div>
  );
};
