import React, { useState, useEffect } from 'react';

import { Icon, Loading } from '@/components/ui';
import { throttle } from '@/utils/throttle';
export interface ButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Button contents
   */
  label?: string;
  labelColor?: string;
  /**
   * Icon class (optional)
   */
  iconClass?: string;
  /**
   * Additional classes to include
   */
  className?: string;
  /**
   * What color should the icon use
   */
  iconColor?: string;
  /**
   * Enable default text-tranform (uppercase) CSS property
   */
  transformText?: boolean;
  /**
   * Button has a border or not
   */
  border?: boolean;
  /**
   * Button has a border or not
   */
  borderColor?: string;
  /**
   * Button has a border or not
   */
  disabled?: boolean;
  /**
   * Optional click handler
   */
  onClick?: (e) => void;
  /**
   * Throttle clicking action
   */
  applyThrottle?: boolean;
  throttleTimeout?: number;
}

/**
 * Primary UI component for user interaction
 */
export const Button: React.FC<ButtonProps> = ({
  primary = false,
  size = 'medium',
  backgroundColor,
  label,
  labelColor = 'var(--primary)',
  iconClass,
  transformText = true,
  iconColor,
  disabled,
  border,
  className,
  borderColor,
  applyThrottle,
  onClick,
  throttleTimeout = 100,
  ...props
}) => {
  const [throttled, setThrottled] = useState<boolean>(false);
  const mode = primary ? 'button--primary' : 'button--secondary';

  const onClickHelper = (e) => {
    if (applyThrottle) {
      setThrottled(true);
      throttle(onClick(e), throttleTimeout);
      setTimeout(() => {
        setThrottled(false);
      }, throttleTimeout * 1.5);
    } else if (!applyThrottle) {
      onClick(e);
    }
  };

  useEffect(() => {
    if (throttled) {
      document.body.style.cursor = 'wait';
    } else if (!throttled) {
      document.body.style.cursor = 'default';
    }
    return () => {
      document.body.style.cursor = 'default';
    };
  }, [throttled]);

  return (
    <button
      disabled={disabled || throttled}
      type="button"
      className={[
        'button',
        `button--${size}`,
        `${border && 'button--border'}`,
        mode,
        'fw-700',
        transformText ? '' : 'button--transform-text-false',
        iconClass,
        className,
        disabled ? 'disabled' : '',
      ].join(' ')}
      style={{
        backgroundColor,
        borderColor,
        color: labelColor || 'var(--primary)',
      }}
      onClick={(e) => {onClick && onClickHelper(e)}}
      {...props}>
      {throttled && (
        <Loading
          positionRelative={true}
          ringColor={labelColor}
          height="100%"
          width="100%"
        />
      )}
      <div
        className="flex flex-center"
        style={{ opacity: throttled ? '0' : '1' }}>
        {iconClass && <Icon color={iconColor} iconClass={iconClass} />}
        {label}
      </div>
    </button>
  );
};
