import React from 'react';

import { Fields } from '@/contexts/packets-page/form-wizard/packet-form/sections/section/subsection/fields';
import { Icon } from '@/components/ui';

export interface SubSectionProps {
  section: any;
  pageIndex: number;
  section1Index: number;
  renderField: (a, b, c, d) => React.ReactNode;
  sectionImage: string;
  setModalCategory: (a) => void;
}

export const SubSection: React.FC<SubSectionProps> = ({
  section,
  renderField,
  pageIndex,
  section1Index,
  sectionImage,
  setModalCategory,
}) => {
  let keyCount = 0;

  const getKey = (prefix) => {
    keyCount = keyCount += 1;
    return `${prefix}-${keyCount}`;
  };

  return (
    <>
      {section.sections.map((subSection, index) => {
        let section2Index = index;
        return (
          <div className="packet-form__sub-section" key={getKey('sub-section')}>
            <h4 className="packet-form__sub-section--header">
              {subSection.label}
            </h4>
            <Fields
              key={getKey('fields')}
              subSection={subSection}
              pageIndex={pageIndex}
              section1Index={section1Index}
              section2Index={section2Index}
              renderField={renderField}
            />
          </div>
        );
      })}
      <div className="packet-form__field--row" key={getKey('form-field')}>
        {section.fields.map((field, index) => {
          const locationReference = `_pages[${pageIndex}].sections[${section1Index}].fields[${index}].value = value`;
          const locationInState = `state?.packetForm?.form?.pages[${pageIndex}]?.sections[${section1Index}]?.fields[${index}]?.value`;
          return (
            <div
              key={getKey('row-contents')}
              className="packet-form__field--row-contents">
              <div className="packet-form__field--row-container">
                {renderField(
                  field,
                  locationReference,
                  locationInState,
                  getKey('input-field'),
                )}
                {sectionImage && (
                  <Icon
                    size={'1.8rem'}
                    iconClass={'media'}
                    color="var(--neutral1)"
                    onClick={() =>
                      setModalCategory({
                        category: 'pdf-preview',
                        section: section,
                      })
                    }
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
