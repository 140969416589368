import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { PacketActions } from '@/contexts/packets-page/packet-actions';
import { Button, DropdownSelect } from '@/components/ui';

export interface PacketsHeaderProps {
  currentPage: string;
}

const PacketsHeader: React.FC<PacketsHeaderProps> = ({ currentPage }) => {
  const history = useHistory();
  const activeLinkStyles = classNames('packet-header-link', {
    'is-current-page': currentPage === 'unsent',
  });
  const unsentLinkStyles = classNames('packet-header-link', {
    'is-current-page': currentPage === 'submitted',
  });
  const pastLinkStyles = classNames('packet-header-link', {
    'is-current-page': currentPage === 'past',
  });
  const routeOptions = [
    { value: '/', label: 'UNSENT PACKETS' },
    { value: '/packets/submitted-packets', label: 'SUBMITTED PACKETS' },
    { value: '/packets/past-packets', label: 'PAST PACKETS' },
  ];

  return (
    <PacketActions className="packet-index flex">
      <DropdownSelect
        options={routeOptions}
        selectedValue={history.location.pathname}
        onClick={(path) => history.push(path)}
      />
      <div className="packet-index__sections md-disp-none">
        <Link className={activeLinkStyles} to="/">
          UNSENT PACKETS
        </Link>
        <Link className={unsentLinkStyles} to="/packets/submitted-packets">
          SUBMITTED PACKETS
        </Link>
        <Link className={pastLinkStyles} to="/packets/past-packets">
          PAST PACKETS
        </Link>
      </div>
      <Button
        className="packet-header-button"
        primary={true}
        size="large"
        label="create packet"
        onClick={() => history.push('/packets/create-packet')}
      />
      <h3 className="packet-index__header">{currentPage} packets</h3>
    </PacketActions>
  );
};

export default PacketsHeader;
