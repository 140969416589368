import classNames from 'classnames';
import React from 'react';

export interface IconProps {
  /**
   * Icon class/category
   */
  iconClass: string;
  /**
   * Icon size (default 1rem x 1rem)
   */
  size?: string;
  /**
   * Icon color (default #transparent)
   */
  color: string;
  /**
   * Optional onClick handler
   */
  onClick?: () => void;
  onMouseDown?: () => void;
  className?: string;
}

/**
 * Primary UI component for SVG Icons
 */
export const Icon: React.FC<IconProps> = ({
  iconClass,
  size = '1rem',
  color = 'transparent',
  onClick,
  className,
  onMouseDown,
}) => {
  return (
    <span
      onMouseDown={onMouseDown}
      onClick={onClick || onClick}
      className={classNames('icon', iconClass, className, {
        'is-clickable': !!onClick,
      })}
      style={{ height: size, width: size, backgroundColor: color }}></span>
  );
};
