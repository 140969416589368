import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updatePacketForm } from '@/actions/actions/packet_form';
import { getPacketForm } from '@/actions/actions/packet_form';
import { Packet } from '@/types/packet';
import { selectPacketForm } from '@/selectors/packet_form';
import { PacketFormPanel } from '@/contexts/packets-page/form-wizard/packet-form-panel';
import { PacketFormSummary } from '@/contexts/packets-page/form-wizard/packet-form-summary';
import { ProgressBar, Loading, DropdownSelect } from '@/components/ui';
import { PacketForm } from '@/contexts/packets-page/form-wizard/packet-form';
import PacketFormView, { Location } from './packet-form-view';
import {
  Field,
  Section,
  PacketForm as PacketFormType,
} from '@/types/packet_form';
import { selectDeviceType } from '@/selectors/device_type';
import { DropdownOptions } from '@/types/dropdown_options';
import { PacketStatus } from '../packet-actions/user-role-packet-actions/editor';
export interface FormWizardProps {
  formPacket: Packet;
  totalPercentageCompleted: number;
  updatePacketStatus: React.Dispatch<PacketStatus>;
  setTotalPercentageCompleted: React.Dispatch<React.SetStateAction<number>>;
}

type currentViewCategory = 'pdf' | 'wizard';

export const FormWizard: React.FC<FormWizardProps> = ({
  formPacket,
  totalPercentageCompleted,
  setTotalPercentageCompleted,
  updatePacketStatus,
}) => {
  const [currentView, setCurrentView] = useState<currentViewCategory>('wizard');
  const [currentForm, setCurrentForm] = useState<PacketFormType>(null);
  const [currentSection, setCurrentSection] = useState<number>(1);
  const [packetFormSections, setPacketFormSections] = useState<any>(null);
  const _formWizard = useRef<HTMLDivElement>();
  const _pdfWizard = useRef<HTMLDivElement>();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [allFields, setAllFields] = useState<Field[]>([]);
  const [imageOffsetPercentage, setImageOffsetPercentage] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [formPacketId, setFormPacketId] = useState<number>(null);
  const [scrollToId, setScrollToId] = useState<string>(null);
  const [filteredSections, setFilteredSections] = useState<boolean>(true);
  const [hasFilteredSections, setHasFilteredSections] = useState<boolean>(
    false,
  );
  const [noSections, setNoSections] = useState<boolean>(null);
  const [modalCategory, setModalCategory] = useState<any>(null);
  const [packetSummaryExtended, setPacketSummaryExtended] = useState<boolean>(
    null,
  );
  const [packetBundleOptions, setPacketBundleOptions] = useState<
    DropdownOptions[]
  >(null);
  const [currentPacket, setCurrentPacket] = useState<string>(null);
  const [renderAllSections, setRenderAllSections] = useState<boolean>(false);

  const packetForm = useSelector(selectPacketForm);
  const deviceType = useSelector(selectDeviceType);

  const hasUseCase: boolean = useMemo(() => {
    if (packetForm) {
      return !!packetForm.formUseCaseId;
    }
  }, [packetForm]);

  const needsUseCase: boolean = useMemo(() => {
    if (packetForm) {
      return !!packetForm.form.useCases.length;
    }
  }, [packetForm]);

  useEffect(() => {
    if (needsUseCase && hasUseCase) {
      setCurrentView('pdf');
    } else if (needsUseCase && !hasUseCase) {
      setCurrentView('wizard');
    } else {
      setCurrentView('pdf');
    }
  }, [hasUseCase]);

  const registerListeners = () => {
    window.addEventListener('scroll', resizeWizard);
    window.addEventListener('resize', resizeWizard);
  };

  const deRegisterListeners = () => {
    window.removeEventListener('scroll', resizeWizard);
    window.removeEventListener('resize', resizeWizard);
  };

  useEffect(() => {
    if (formPacket && deviceType === 'mobile') {
      buildPacketBundleOptions(formPacket);
    }
  }, [formPacket, deviceType]);

  useEffect(() => {
    registerListeners();

    return () => {
      deRegisterListeners();
      setLoading(true);
    };
  }, []);

  useEffect(() => {
    resizeWizard();
  }, [currentView]);

  useEffect(() => {
    if (currentPacket)
      //sets the current form from the dropdown menu option (for the mobile view)
      setCurrentFormHelper(
        null,
        currentPacket.split(',')[1],
        currentPacket.split(',')[0],
      );
  }, [currentPacket]);

  const buildSections = () => {
    const sections = packetForm?.form?.pages?.reduce((acc, currentVal) => {
      const _sections = currentVal?.sections
        .map((section, index) => {
          return {
            pageNumber: currentVal?.pageNumber - 1,
            sectionIndex: index,
            ...section,
          };
        })
        .filter((section) => {
          if (section.isDisabled) {
            setHasFilteredSections(true);
          }
          if (
            (filteredSections &&
              formPacket?.currentUser?.role === 'Approver') ||
            (filteredSections && formPacket?.currentUser?.role === 'Editor')
              ? !section.isDisabled
              : true
          ) {
            return section;
          }
        });

      if (Array.isArray(_sections) && _sections.length) {
        acc.push(_sections);
        return acc;
      } else {
        setNoSections(true);
        setFilteredSections(false);
      }
    }, []);

    Array.isArray(sections) &&
      sections.length &&
      //@ts-ignore
      setPacketFormSections(sections.flat(1));
  };

  useEffect(() => {
    buildSections();
    filteredSections === true && setCurrentSection(1);
  }, [filteredSections]);

  useEffect(() => {
    if (currentView === 'pdf') {
      setFilteredSections(false);
    } else if (
      currentView === 'wizard' &&
      formPacket?.currentUser?.role === 'Approver'
    ) {
      setFilteredSections(true);
    }
  }, [currentView]);

  useEffect(() => {
    if (packetForm && packetForm?.form?.pages) {
      buildSections();
    }
    if (packetForm) {
      packetForm?.packetFormId !== currentForm?.packetFormId &&
        setCurrentSection(1);
      setCurrentForm(packetForm);
      setRenderAllSections(true);
      setLoading(false);
    }
  }, [packetForm]);

  useEffect(() => {
    if (
      (formPacket && !formPacketId) ||
      (formPacket && formPacket?.packetId !== formPacketId)
    ) {
      dispatch(
        getPacketForm(
          formPacket?.packetId,
          formPacket?.packetFormBundles[0]?.packetFormBundleId,
          formPacket?.packetFormBundles[0]?.packetForms[0]?.packetFormId,
        ),
      );
      setFormPacketId(formPacket?.packetId);
    }
  }, [formPacket]);

  useEffect(() => {
    if (packetFormSections) {
      setAllFields(gatherAllFields(packetFormSections));
    }
  }, [packetFormSections]);

  const savePacketForm = (packetForm?) => {
    dispatch(
      updatePacketForm(
        formPacket?.packetId,
        currentForm?.packetFormBundleId,
        currentForm?.packetFormId,
        packetForm ? packetForm : currentForm,
      ),
    );
  };

  const updateUseCase = (value) => {
    let useCaseId = currentForm;

    useCaseId.formUseCaseId = value;
    savePacketForm(useCaseId);
  };

  const buildPacketBundleOptions = (packet) => {
    const options = packet.packetFormBundles.map((bundle) => {
      const headerOptions = {
        value: `${bundle.packetFormBundleName}`,
        label: `${bundle.packetFormBundleName}`,
        header: true,
      };
      const options = bundle.packetForms.map((packetForm) => {
        return {
          value: `${packetForm.packetFormId},${packetForm.packetFormBundleId}`,
          label: `${packetForm.formSummary.formName}`,
          header: false,
        };
      });
      return [headerOptions, options];
    });
    options
      .flat(2)
      .reverse()
      .find((option) => {
        if (option.header === false) {
          setCurrentPacket(option.value);
        }
      });
    setPacketBundleOptions(options.flat(2));
  };

  const setCurrentFormHelper = (packetId, packetFormBundleId, packetFormId) => {
    setLoading(true);
    dispatch(
      getPacketForm(
        packetId || formPacket.packetId,
        packetFormBundleId,
        packetFormId,
      ),
    );
  };

  const resizeWizard = () => {
    if (currentView === 'wizard') {
      document
        .querySelector<HTMLElement>(':root')
        .style.setProperty(
          '--wizardHeight',
          `${_formWizard.current?.clientHeight}px`,
        );
    } else {
      document
        .querySelector<HTMLElement>(':root')
        .style.setProperty(
          '--wizardHeight',
          `${_pdfWizard.current?.clientHeight}px`,
        );
    }

    // NOTE: We want to de-register and re-register this so
    // that we get the latest currentView value from state
    deRegisterListeners();
    registerListeners();
  };

  const attachSectionsToField = (
    section: Section,
    subsection?: Section,
  ): Field[] => {
    const newFields = [];

    for (const field of section.fields) {
      if (subsection) {
        newFields.push({
          ...field,
          sectionIndex: section.sectionIndex,
          subsectionIndex: subsection.sectionIndex,
        });
      } else {
        newFields.push({
          ...field,
          sectionIndex: section.sectionIndex,
        });
      }
    }

    return newFields;
  };

  const gatherAllFields = (sections: Section[]): Field[] => {
    const newFields = [];

    for (const section of sections) {
      const sectionFields = attachSectionsToField(section);
      newFields.push(...sectionFields);

      if (section.sections && section.sections.length > 0) {
        for (const subsection of section.sections) {
          const subsectionFields = attachSectionsToField(section, subsection);
          newFields.push(...subsectionFields);
        }
      }
    }

    return newFields;
  };

  const handleSizeOffset = (value: number): number => {
    return value * imageOffsetPercentage;
  };

  const checkForCollision = (field, location) => {
    const leftBoundary: number = handleSizeOffset(
      field.fieldValueRegion.xCoordinate,
    );
    const rightBoundary: number =
      handleSizeOffset(field.fieldValueRegion.xCoordinate) +
      handleSizeOffset(field.fieldValueRegion.width);
    const topBoundary: number = handleSizeOffset(
      field.fieldValueRegion.yCoordinate,
    );
    const bottomBoundary: number =
      handleSizeOffset(field.fieldValueRegion.yCoordinate) +
      handleSizeOffset(field.fieldValueRegion.height);

    if (
      Math.round(location.x) >= Math.round(leftBoundary) &&
      Math.round(location.x) <= Math.round(rightBoundary) &&
      Math.round(location.y) >= Math.round(topBoundary) &&
      Math.round(location.y) <= Math.round(bottomBoundary)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleCanvasClick = (location: Location) => {
    setFilteredSections(false);
    let clickedField;
    console.log(`location, ${JSON.stringify(location)}`);
    allFields.forEach((field) => {
      const isCollision = checkForCollision(field, location);

      if (isCollision) {
        clickedField = field;
      }
    });
    console.log(`clickedField, ${JSON.stringify(clickedField)}`);
    if (clickedField) {
      // Intentionally commented out in case AC revert
      // setCurrentSection(clickedField.sectionIndex + 1);
      // setCurrentView('wizard');
      // setScrollToId(clickedField.id);
    }
  };

  useEffect(() => {
    packetSummaryExtended &&
      document
        .querySelector<HTMLElement>(':root')
        .style.setProperty('--packetPageHeight', `347px`);
  }, [packetSummaryExtended]);

  // NOTE: We only want to show the percentage complete if the
  // current user's role is Editor - otherwise hide it
  const showPercentageComplete = formPacket?.currentUser?.role === 'Editor';

  const wizardStyles = currentView === 'wizard' ? '' : 'form-section-hidden';
  const pdfStyles = currentView === 'pdf' ? '' : 'form-section-hidden';

  return (
    <div
      className={`form-wizard ${
        packetSummaryExtended ? 'form-wizard--shift-down' : ''
      }`}>
      {formPacket && packetForm && currentForm && packetFormSections ? (
        <>
          <PacketFormPanel
            packet={formPacket}
            onClick={(packetFormId, packetFormBundleId, formId) =>
              setCurrentFormHelper(packetFormId, packetFormBundleId, formId)
            }
            currentForm={currentForm}
            totalPercentageCompleted={totalPercentageCompleted}
            setTotalPercentageCompleted={setTotalPercentageCompleted}
            showPercentageComplete={showPercentageComplete}
          />
          <PacketFormSummary
            renderAllSections={renderAllSections}
            setCurrentSection={setCurrentSection}
            formInfo={{
              name: currentForm?.form.formName || '',
              useCaseId: currentForm?.formUseCaseId,
              useCases: currentForm?.form?.useCases,
              formId: packetForm.packetFormId,
              currentUserRole: formPacket?.currentUser?.role,
            }}
            currentView={currentView}
            setCurrentView={setCurrentView}
            updateUseCase={updateUseCase}
            currentSection={currentSection}
            packetFormSections={packetFormSections}
            formPacket={formPacket}
            noSections={noSections}
            hasFilteredSections={hasFilteredSections}
            filteredSections={filteredSections}
            setFilteredSections={setFilteredSections}
            setModalCategory={setModalCategory}
            setPacketSummaryExtended={setPacketSummaryExtended}
            currentPacket={currentPacket}
            setCurrentPacket={setCurrentPacket}
            packetBundleOptions={packetBundleOptions}
            deviceType={deviceType}
          />

          {!loading && (
            <PacketForm
              currentSection={currentSection}
              currentView={currentView}
              formData={currentForm}
              isLastPage={currentSection === packetFormSections.length}
              setCurrentSection={setCurrentSection}
              packetId={formPacket?.packetId}
              packetFormSections={packetFormSections}
              currentUserRole={
                noSections === true ? 'Viewer' : formPacket?.currentUser?.role
              }
              setScrollToId={setScrollToId}
              scrollToId={scrollToId}
              setModalCategory={setModalCategory}
              modalCategory={modalCategory}
              renderAllSections={renderAllSections}
              form={packetForm?.form}
              currentPage={currentPage}
              handleCanvasClick={handleCanvasClick}
              setCurrentPage={setCurrentPage}
              setImageOffsetPercentage={setImageOffsetPercentage}
              formWizardRef={_formWizard}
              pdfWizardRef={_pdfWizard}
              updatePacketStatus={updatePacketStatus}
            />
          )}
        </>
      ) : (
        <div className="no-data" ref={_formWizard}>
          {setTimeout(() => {
            setLoading(false);
          }, 7000) && loading ? (
            <Loading
              ringColor="#fff"
              height="70vh"
              width="100%"
              positionRelative={true}
              message="Loading Form..."
            />
          ) : (
            'no data'
          )}
        </div>
      )}
    </div>
  );
};
