import { Packet, PacketDecisionAction } from "@/types/packet";

// Action Types
export enum PacketActions {
  GET_PACKET = 'PACKET/GET_PACKET',
  GET_PACKET_SUCCESS = 'PACKET/GET_PACKET_SUCCESS',
  GET_PACKET_FAILURE = 'PACKET/GET_PACKET_FAILURE',
  UPDATE_PACKET = 'PACKET/UPDATE_PACKET',
  UPDATE_PACKET_SUCCESS = 'PACKET/UPDATE_PACKET_SUCCESS',
  UPDATE_PACKET_FAILURE = 'PACKET/UPDATE_PACKET_FAILURE',
  CREATE_PACKET = 'PACKET/CREATE_PACKET',
  CREATE_PACKET_SUCCESS = 'PACKET/CREATE_PACKET_SUCCESS',
  CREATE_PACKET_FAILURE = 'PACKET/CREATE_PACKET_FAILURE',
  APPROVE_PACKET = 'PACKET/APPROVE_PACKET',
  APPROVE_PACKET_SUCCESS = 'PACKET/APPROVE_PACKET_SUCCESS',
  APPROVE_PACKET_FAILURE = 'PACKET/APPROVE_PACKET_FAILURE',
  DENY_PACKET = 'PACKET/DENY_PACKET',
  DENY_PACKET_SUCCESS = 'PACKET/DENY_PACKET_SUCCESS',
  DENY_PACKET_FAILURE = 'PACKET/DENY_PACKET_FAILURE',
  RESET_PACKET = 'PACKET/RESET_PACKET',
  UPDATE_LOCAL_PACKET = 'PACKET/UPDATE_LOCAL_PACKET'
}

// Action Interfaces
export interface ResetPacket {
  type: typeof PacketActions.RESET_PACKET;
}
export interface GetPacket {
  type: typeof PacketActions.GET_PACKET;
  payload: {
    packetNumber: number
  }
}

export interface GetPacketSuccess {
  type: typeof PacketActions.GET_PACKET_SUCCESS;
  payload: {
    packet: Packet;
  }
}

export interface GetPacketFailure {
  type: typeof PacketActions.GET_PACKET_FAILURE;
  payload: {
    error: string;
  }
}
export interface UpdatePacket {
  type: typeof PacketActions.UPDATE_PACKET;
  payload: {
    packetNumber: number,
    packet: Packet
  }
}

export interface UpdateLocalPacket {
  type: typeof PacketActions.UPDATE_LOCAL_PACKET;
  payload: {
    packet: Packet
  }
}

export interface UpdatePacketSuccess {
  type: typeof PacketActions.UPDATE_PACKET_SUCCESS;
  payload: {
    packet: Packet;
  }
}

export interface UpdatePacketFailure {
  type: typeof PacketActions.UPDATE_PACKET_FAILURE;
  payload: {
    error: string;
  }
}
export interface CreatePacket {
  type: typeof PacketActions.CREATE_PACKET;
  payload: {
    packet: Packet
  }
}

export interface CreatePacketSuccess {
  type: typeof PacketActions.CREATE_PACKET_SUCCESS;
  payload: {
    packet: Packet;
  }
}

export interface CreatePacketFailure {
  type: typeof PacketActions.CREATE_PACKET_FAILURE;
  payload: {
    error: string;
  }
}

export interface ApprovePacket {
  type: typeof PacketActions.APPROVE_PACKET;
  payload: {
    approvalAction: PacketDecisionAction,
    packetNumber: number
  }
}

export interface ApprovePacketSuccess {
  type: typeof PacketActions.APPROVE_PACKET_SUCCESS;
  payload: {
    packet: Packet;
  }
}

export interface ApprovePacketFailure {
  type: typeof PacketActions.APPROVE_PACKET_FAILURE;
  payload: {
    error: string;
  }
}

export interface DenyPacket {
  type: typeof PacketActions.DENY_PACKET;
  payload: {
    approvalAction: PacketDecisionAction,
    packetNumber: number
  }
}

export interface DenyPacketSuccess {
  type: typeof PacketActions.DENY_PACKET_SUCCESS;
  payload: {
    packet: Packet;
  }
}

export interface DenyPacketFailure {
  type: typeof PacketActions.DENY_PACKET_FAILURE;
  payload: {
    error: string;
  }
}

// Action Type List
export type PacketAction = GetPacket | GetPacketFailure | GetPacketSuccess | UpdatePacket | UpdatePacketFailure | UpdatePacketSuccess | CreatePacket | CreatePacketFailure | CreatePacketSuccess | ApprovePacket | ApprovePacketSuccess | ApprovePacketFailure | DenyPacket | DenyPacketSuccess | DenyPacketFailure | ResetPacket | UpdateLocalPacket;

// Action Creators
export const resetPacket = (): PacketAction => ({
  type: PacketActions.RESET_PACKET,
});

export const updateLocalPacket = (packet: Packet): PacketAction => ({
  type: PacketActions.UPDATE_LOCAL_PACKET,
  payload: {
    packet,
  }
});

export const getPacket = (packetNumber: number): PacketAction => ({
  type: PacketActions.GET_PACKET,
  payload: {
    packetNumber,
  },
});

export const getPacketSuccess = (packet: Packet): PacketAction => ({
  type: PacketActions.GET_PACKET_SUCCESS,
  payload: {
    packet,
  }
});

export const getPacketFailure = (error: string): PacketAction => ({
  type: PacketActions.GET_PACKET_FAILURE,
  payload: {
    error,
  }
});

export const updatePacket = (packetNumber: number, packet: Packet): PacketAction => ({
  type: PacketActions.UPDATE_PACKET,
  payload: {
    packetNumber,
    packet
  },
});

export const updatePacketSuccess = (packet: Packet): PacketAction => ({
  type: PacketActions.UPDATE_PACKET_SUCCESS,
  payload: {
    packet,
  }
});

export const updatePacketFailure = (error: string): PacketAction => ({
  type: PacketActions.UPDATE_PACKET_FAILURE,
  payload: {
    error,
  }
});

export const createPacket = (packet: Packet): PacketAction => ({
  type: PacketActions.CREATE_PACKET,
  payload: {
    packet,
  },
});

export const createPacketSuccess = (packet: Packet): PacketAction => ({
  type: PacketActions.CREATE_PACKET_SUCCESS,
  payload: {
    packet,
  }
});

export const createPacketFailure = (error: string): PacketAction => ({
  type: PacketActions.CREATE_PACKET_FAILURE,
  payload: {
    error,
  }
});

export const approvePacket = (approvalAction: PacketDecisionAction, packetNumber: number): PacketAction => ({
  type: PacketActions.APPROVE_PACKET,
  payload: {
    approvalAction,
    packetNumber
  },
});

export const approvePacketSuccess = (packet: Packet): PacketAction => ({
  type: PacketActions.APPROVE_PACKET_SUCCESS,
  payload: {
    packet,
  }
});

export const approvePacketFailure = (error: string): PacketAction => ({
  type: PacketActions.APPROVE_PACKET_FAILURE,
  payload: {
    error,
  }
});

export const denyPacket = (approvalAction: PacketDecisionAction, packetNumber: number): PacketAction => ({
  type: PacketActions.DENY_PACKET,
  payload: {
    approvalAction,
    packetNumber
  },
});

export const denyPacketSuccess = (packet: Packet): PacketAction => ({
  type: PacketActions.DENY_PACKET_SUCCESS,
  payload: {
    packet,
  }
});

export const denyPacketFailure = (error: string): PacketAction => ({
  type: PacketActions.DENY_PACKET_FAILURE,
  payload: {
    error,
  }
});
