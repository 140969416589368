import { call, put, takeLatest } from 'redux-saga/effects';
import jwt_decode from 'jwt-decode';

import { DiscoveredDataActions, UpdateDiscoveredData, updateDiscoveredDataSuccess, getDiscoveredDataFailure } from '@/actions/actions/discovered_data';
import Api from '@/api/Api';
import { flashError, flashSuccess } from '../messageSaga';

export function* updateDiscoveredDataSaga(
  _action: UpdateDiscoveredData
) {
  try {
    let decoded = jwt_decode(localStorage.getItem('bearer_token'));
    let userId = decoded['sub'];
    const url = `/api/v1/users/${userId}/discovereddata`;
    const discoveredDataInfo = _action.payload.discoveredData;
    const response = yield call(Api.utility.put, url, discoveredDataInfo);

    yield put(flashSuccess('Successfully updated Data'));
    yield put(updateDiscoveredDataSuccess(response.data))
  } catch(err) {
    yield put(flashError('Could not update Data'));
    yield put(getDiscoveredDataFailure(err))
  }
}

export function* watchUpdateDiscoveredData() {
  yield takeLatest(DiscoveredDataActions.UPDATE_USER_DISCOVERED_DATA, updateDiscoveredDataSaga);
}
