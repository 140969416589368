import { UsersActions } from '@/actions/actions/users';
import { UsersState } from '@/types/users';

const initialState: UsersState = {
  users: null,
};

export default function reducer(
  state: UsersState = initialState,
  action: any,
): UsersState {
  const { type, payload } = action;
  switch (type) {
    case UsersActions.GET_USERS_SUCCESS:
      return {
        ...state,
        users: payload.users
      }
    default:
      return state;
  }
}
