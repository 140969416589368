import React, { Component } from 'react';
import SignaturePad from 'react-signature-canvas';

import { Button, Modal } from '@/components/ui';

interface Props {
  onClickClose: () => void;
  onClickSubmit: (imageUrl: string) => void;
}

class SignaturePadModal extends Component<Props, {}> {
  constructor(props: Props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  sigPad = {};

  handleSubmit() {
    const dataUrl = this.sigPad
      // @ts-ignore
      .getTrimmedCanvas()
      .toDataURL('image/png');

    this.props.onClickSubmit(dataUrl);
    this.props.onClickClose();
  }

  handleClear() {
    // @ts-ignore
    this.sigPad.clear();
  }

  render() {
    return (
      <Modal handleClickX={() => this.props.onClickClose()}>
        <div className="signature-pad-modal">
          <div>
            <p className="signature-pad-modal__header">
              Draw with mouse to sign
            </p>
          </div>

          <div className="signature-pad-modal__pad-container">
            <SignaturePad
              ref={(ref) => {
                this.sigPad = ref;
              }}
              canvasProps={{ width: '490px', height: '60px' }}
            />
          </div>

          <div className="signature-pad-modal__action-container">
            <Button onClick={this.handleClear} label="Clear" primary />
            <div>
              <Button
                onClick={this.props.onClickClose}
                label="Cancel"
                primary
                border
              />
              <Button
                onClick={this.handleSubmit}
                label="Submit"
                primary
                border
                borderColor="#3a4886"
                backgroundColor="#3a4886"
                labelColor="#fff"
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default SignaturePadModal;
