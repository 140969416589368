import React, { useEffect } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { InfoCard, Loading } from '@/components/ui';
import { getUserProfile } from '@/actions/actions/user';

export const UserProfile = (): JSX.Element => {
  const user = useSelector((state: RootStateOrAny) => {
    return state.onBehalfOfUserProfile.onBehalfOfUserProfile;
  });

  const parseUserData = () => {
    return [
      {
        label: 'First Name',
        value: user.firstName,
        type: 'string',
        name: 'firstName',
      },
      {
        label: 'Last Name',
        value: user.lastName,
        type: 'string',
        name: 'lastName',
      },
      {
        label: 'Date of Birth',
        value: user.dateOfBirth || 'n/a',
        type: 'date',
        name: 'dateOfBirth',
      },
      {
        label: 'Social Security Number',
        value: user.socialSecurityNumber || 'n/a',
        type: 'string',
        name: 'socialSecurityNumber',
      },
      {
        label: 'Email Address',
        value: user.email || 'n/a',
        type: 'string',
        name: 'email',
      },
      {
        label: 'Address Line 1',
        value: user.address1 || 'n/a',
        type: 'address',
        name: 'address1',
      },
      {
        label: 'Address Line 2',
        value: user.address2 || 'n/a',
        type: 'address',
        name: 'address2',
      },
      {
        label: 'City',
        value: user.city || 'n/a',
        type: 'address',
        name: 'city',
      },
      {
        label: 'State',
        value: user.state || 'n/a',
        type: 'address',
        name: 'state',
      },
      {
        label: 'Zip Code',
        value: user.zip || 'n/a',
        type: 'address',
        name: 'zip',
      },
      {
        label: 'Country',
        value: user.country || 'n/a',
        type: 'address',
        name: 'country',
      },
      {
        label: 'Unit',
        value: user.unit || 'n/a',
        type: 'string',
        name: 'unit',
      },
      {
        label: 'Duty Phone',
        value: user.dutyPhone || 'n/a',
        type: 'string',
        name: 'dutyPhone',
      },
      {
        label: 'Supervisor',
        value: user.supervisor || 'n/a',
        type: 'string',
        name: 'supervisor',
      },
      {
        label: 'Organization',
        value: user.organization || 'n/a',
        type: 'string',
        name: 'organization',
      },
      {
        label: 'AFSC',
        value: user.afsc || 'n/a',
        type: 'string',
        name: 'afsc',
      },
      {
        label: 'Grade',
        value: user.grade || 'n/a',
        type: 'string',
        name: 'grade',
      },
      {
        label: 'Office',
        value: user.office || 'n/a',
        type: 'string',
        name: 'office',
      },
      {
        label: 'Email Opt Out',
        value: user.emailOptOut || "false",
        type: 'string',
        name: 'emailOptOut',
      },
    ];
  };

  const styles = {
    marginLeft: '45%',
    marginTop: '5%',
    width: '100%',
  };
  if (user === null) {
    return (
      <div style={styles}>
        <Loading
          height="50px"
          width="50px"
          positionRelative={true}
          ringColor="var(--secondary1)"
        />
      </div>
    );
  }

  return (
    <>
      <InfoCard
        header={`${user.firstName} ${user.lastName}`}
        data={parseUserData()}
        edit={false}
      />
    </>
  );
};

export default UserProfile;
