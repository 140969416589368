import classNames from 'classnames';
import React, { useEffect, useReducer, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReqFieldsContext } from './req-fields';
import { updatePacketForm } from '@/actions/actions/packet_form';
import { Sections } from '@/contexts/packets-page/form-wizard/packet-form/sections';
import { Button, Loading } from '@/components/ui';
import { UseCaseChooser } from './use-case-chooser';
import { Role } from '@/types/packet';
import { Form, PacketForm as PacketFormType } from '@/types/packet_form';
import { PacketFormDraggableModal } from '@/contexts/packets-page/form-wizard/packet-form/packet-form-draggable-modal';
import { DraggableModalCoordinates } from '@/types/draggable_modal';
import PacketFormView, { Location } from '../packet-form-view/index';
import { selectPacketForm } from '@/selectors/packet_form';
import { handleChangeConfig } from './sections/section';
import { FieldValuesSearchArgs } from '@/types/field_values';
import { getFieldValues } from '@/actions/actions/field_values';
import { usePrevious } from '@/hooks/usePrevious';
import { PacketStatus } from '../../packet-actions/user-role-packet-actions/editor';
import { selectPacket } from '@/selectors/packet';
import { useHistory } from 'react-router-dom';
import { Fields } from './sections/section/subsection/fields';

type ButtonAction = 'back' | 'next' | 'save' | 'autosave' | 'saveAndSend';

export interface PacketFormProps {
  className?: string;
  currentPage: number;
  currentSection: number;
  currentUserRole: Role;
  currentView: string;
  form: Form;
  formData: PacketFormType;
  handleCanvasClick: (location: Location) => void;
  isLastPage: boolean;
  modalCategory: any;
  packetFormSections: Array<any>;
  packetId: number;
  renderAllSections: boolean;
  scrollToId: string;
  setCurrentPage: (page: number) => void;
  setCurrentSection: (section) => void;
  setImageOffsetPercentage: (percentage: number) => void;
  setModalCategory: React.Dispatch<React.SetStateAction<string>>;
  setScrollToId: React.Dispatch<string>;
  formWizardRef: any;
  pdfWizardRef: any;
  updatePacketStatus: React.Dispatch<PacketStatus>;
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'RESET':
      return {
        packetForm: {},
      };
    case 'FORM':
      return {
        ...state,
        packetForm: {
          ...state.packetForm,
          [action.key]: action.value,
        },
      };
    case 'RESTORE':
      return {
        ...state,
        packetForm: action.value,
      };
    case 'REQUIRED_FIELD_IDS':
      return {
        ...state,
        requiredFieldIds: action.value,
      };
    case 'ACTIVE_FIELD_IDS':
      return {
        ...state,
        activeFieldIds: action.value,
      };
    case 'LOADED':
      return {
        ...state,
        loaded: action.value,
      };
    default:
      break;
  }
  return state;
};

export const PacketForm: React.FC<PacketFormProps> = ({
  className,
  currentPage,
  currentSection,
  currentUserRole,
  currentView,
  form,
  formData,
  handleCanvasClick,
  isLastPage,
  modalCategory,
  packetFormSections,
  packetId,
  renderAllSections,
  scrollToId,
  setCurrentPage,
  setCurrentSection,
  setImageOffsetPercentage,
  setModalCategory,
  setScrollToId,
  pdfWizardRef,
  formWizardRef,
  updatePacketStatus,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, localDispatch] = useReducer(reducer, { packetForm: {} });
  const [packetFormId, setPacketFormId] = useState<number>(null);
  const [requiredFieldsError, setRequiredFieldsError] = useState<boolean>(
    false,
  );
  const [requiredFieldIds, setRequiredFieldIds] = useState([]);
  const [invalidFieldMessages, setInvalidFieldMessages] = useState<string[]>( [],);
  const debounce = useRef(null);
  const [useCaseId, setUseCaseId] = useState<number>(null);
  const [section, setSection] = useState<any>(null);
  const [lastCoordinates, setLastCoordinates] = useState<
    DraggableModalCoordinates
  >(null);
  const [fieldId, setFieldId] = useState<string>('');
  const [suggestedValues, setSuggestedValues] = useState<string[]>(null);
  const packetForm = useSelector(selectPacketForm);
  const [stateChangeDetected, setStateChangeDetected] = useState<boolean>(null);

  const packet = useSelector(selectPacket);

  const previousPacketForm = usePrevious(packetForm);

  const canRenderNavigationButtons: boolean =
    currentUserRole !== 'Viewer' && packet && packet.statusId !== 3;

  const clearAllFieldIds = () => {
    localDispatch({
      type: 'ACTIVE_FIELD_IDS',
      value: [],
    });
    localDispatch({
      type: 'REQUIRED_FIELD_IDS',
      value: [],
    });
  };

  const restorePreviousSection = (cs) => {
    let _packetForm = JSON.parse(JSON.stringify(state.packetForm));
    const previousSection =
      formData.form.pages[packetFormSections[cs - 1]?.pageNumber].sections[
        packetFormSections[cs - 1].sectionIndex
      ];

    _packetForm.form.pages[packetFormSections[cs - 1]?.pageNumber].sections[
      cs - 1
    ] = previousSection;
    localDispatch({
      type: 'RESTORE',
      value: _packetForm,
    });
  };

  const navigateSections = (buttonAction: ButtonAction, e?) => {
    const isBack: boolean = buttonAction === 'back';
    const isNext: boolean = buttonAction === 'next';
    const isSave: boolean = buttonAction === 'save';
    const isSaveAndSend: boolean = buttonAction === 'saveAndSend';
    const shouldSavePacketForm: boolean = isNext || isSave || isSaveAndSend;
    const isEditorOrApprover: boolean =
      currentUserRole === 'Editor' || currentUserRole === 'Approver';

    if (isBack) {
      restorePreviousSection(currentSection);
      clearAllFieldIds();
      setCurrentSection(currentSection - 1);
    } else if (
      state?.requiredFieldIds?.length === 0 ||
      !state?.requiredFieldIds?.length
    ) {
      if (isNext) {
        clearAllFieldIds();
        setCurrentSection(currentSection + 1);
      }
      if (shouldSavePacketForm) {
        if (isEditorOrApprover) {
          isSaveAndSend ? saveAndSendPacketForm() : savePacketForm();
        }
      }
      setRequiredFieldsError(false);
    } else if (state?.requiredFieldIds?.length) {
      setRequiredFieldsError(true);
    }
    if (e) {
      // NOTE: Disabling for 6/17/2021 build
      // window.scrollTo({
      //   top: state?.requiredFieldIds?.length ? 400 : 0,
      //   behavior: state?.requiredFieldIds?.length ? 'smooth' : 'auto',
      // });
      e.currentTarget.blur();
    }
  };

  const savePacketForm = (packetForm?, autosave?) => {
    dispatch(
      updatePacketForm(
        packetId,
        state?.packetForm?.packetFormBundleId,
        state?.packetForm?.packetFormId,
        packetForm ? packetForm : state.packetForm,
        autosave,
      ),
    );
    console.log('triggered savePacketForm');
  };

  const saveAndSendPacketForm = () => {
    savePacketForm();
    if (!!packet.approvalPath) {
      updatePacketStatus({ statusCode: 3, message: 'Sending' });
    } else {
      history.push(`/packets/packet/${packetId}/send`);
    }
    console.log('triggered saveAndSendPacketForm')
  };

  const updateUseCase = (value) => {
    let _packet = JSON.parse(JSON.stringify(state.packetForm));
    _packet.formUseCaseId = value;
    savePacketForm(_packet);
  };

  const populateFormState = (data, type) => {
    if (!state?.loaded) {
      for (let [key, value] of Object.entries(data)) {
        localDispatch({ type: type, key: key, value: value });
      }
    }
    localDispatch({ type: 'LOADED', value: true });
  };

  const resetState = () => {
    window.scrollTo(0, 0);
    localDispatch({ type: 'RESET' });
    setRequiredFieldsError(false);
  };

  const resetStateHelper = () => {
    localDispatch({ type: 'LOADED', value: false });
    resetState();
  };

  const populateFormData = (formData) => {
    const _formData = JSON.parse(JSON.stringify(formData));
    populateFormState(_formData, 'FORM');
  };

  const validateData = (data) => {
    console.log("v == validating data", data)
    if (data === null || !data) {
      return false;
    } else if (data) {
      return data.toString().replace(/\s/g, '').length ? true : false;
    } else if (new Date(data)) {
      return true;
    } else {
      return false;
    }
  };

  const validateRegex = (value, field) => {
    const fieldRegex = new RegExp(field.validationRegex);
    console.log("v == value ", value);
    console.log("v == field ", field);
    if(!value.toString().match(fieldRegex)){
      if(!invalidFieldMessages.includes(field.validationMessage)){
        invalidFieldMessages.push(field.validationMessage || "Invalid")
      }
      console.log("v == field messages == ", invalidFieldMessages);
      return false;
    } else {
      if(invalidFieldMessages.includes(field.validationMessage)){
        invalidFieldMessages.splice(invalidFieldMessages.indexOf(field.validationMessage), 1);
      }
      return true;
    }
  }

  const getFieldValuesHelper = (args: FieldValuesSearchArgs) => {
    dispatch(getFieldValues(args));
  };

  const buildFieldValueArgs = (value, id) => {
    let fieldValueArg = {
      fieldId: id,
      userId: localStorage.getItem('user_id'),
      searchValue: value,
    };
    setFieldId(id);
    getFieldValuesHelper(fieldValueArg);
  };

  const handleChange = (
    { value, locations, field }: handleChangeConfig,
    isKeystroke?: boolean,
  ) => {
    console.log(invalidFieldMessages)
    let _pages = state?.packetForm?.form?.pages;
    setRequiredFieldIds(state?.requiredFieldIds);
    let _requiredFieldIds = state?.requiredFieldIds || [];
    let _activeFieldIds = state?.activeFieldIds || [];
    let _invalidFieldMessages = state?.invalidFieldMessages || [];


    if (
      field?.rules?.InputState === 'Disabled' ||
      eval(locations.ref.slice(0, -8).replace(/\./g, '?.')) === undefined
    ) {
      return null;
    }

    eval(locations.ref);

    if (field?.isRequired) {
      let isValid = validateData(eval(locations.ref));

      if(field?.validationRegex){
        isValid = validateRegex(eval(locations.ref), field);
      }
      if (isValid && _requiredFieldIds?.includes(field.id)) {
        _requiredFieldIds.splice(_requiredFieldIds.indexOf(field.id), 1);
        if (_activeFieldIds?.includes(field.id)) {
          _activeFieldIds.splice(_activeFieldIds.indexOf(field.id), 1);
        }
      } else {
        if (!isValid && !_requiredFieldIds?.includes(field.id)) {
          _requiredFieldIds.push(field.id);
        }
        if (!isValid && !_activeFieldIds.includes(field.id)) {
          _activeFieldIds.push(field.id);
        }
      }
    }

    !stateChangeDetected && state.loaded && setStateChangeDetected(true);

    localDispatch({
      type: 'FORM',
      key: 'form',
      value: { ...state.packetForm.form, pages: _pages },
    });
    localDispatch({
      type: 'REQUIRED_FIELD_IDS',
      value: _requiredFieldIds,
    });
    debounce.current && clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      if (
        field.type.toLowerCase() === 'textfield' &&
        eval(locations.state)?.length &&
        isKeystroke
      ) {
        buildFieldValueArgs(eval(locations.ref), field.id);
      }
    }, 200);
    localDispatch({
      type: 'ACTIVE_FIELD_IDS',
      value: _activeFieldIds,
    });
    if (
      field.type.toLowerCase() === 'textfield' &&
      !eval(locations.state)?.length
    ) {
      setSuggestedValues(null);
    }
  };

  useEffect(() => {
    formData && setPacketFormId(formData?.packetFormId);
    formData && setUseCaseId(formData?.formUseCaseId);
    formData && !packetFormId && populateFormData(formData);
  }, [formData]);

  useEffect(() => {
    console.log('reqFieldIds', requiredFieldIds?.length)
    setRequiredFieldsError(false);
    clearAllFieldIds();
  }, [packetFormSections]);

  useEffect(() => {
    if (formData && formData?.packetFormId === packetFormId) {
      localDispatch({ type: 'LOADED', value: false });
      setTimeout(() => {
        localDispatch({ type: 'LOADED', value: true });
      }, 650);
    }
  }, [currentSection]);

  useEffect(() => {
    scrollToId &&
      state.loaded &&
      document.getElementsByClassName(scrollToId)[0]?.scrollIntoView();
    setTimeout(() => {
      setScrollToId(null);
    }, 1000);
  }, [scrollToId, state]);

  useEffect(() => {
    if (packetForm && packetForm !== previousPacketForm) {
      localDispatch({ type: 'RESTORE', value: packetForm });
    }
  }, [packetForm]);

  const renderNavigationButtonLabel = (
    renderAllSections: boolean,
    buttonAction: ButtonAction,
  ): string => {
    const neitherEditorNorApprover: boolean =
      currentUserRole !== 'Editor' && currentUserRole !== 'Approver';
    const isFinalSection: boolean =
      packetFormSections.length === currentSection;

    if (neitherEditorNorApprover) {
      return 'next';
    }

    if (!neitherEditorNorApprover) {
      if (isFinalSection || renderAllSections) {
        return buttonAction === 'saveAndSend' ? 'save and send' : 'save';
      } else {
        return 'save and next';
      }
    }
  };

  const renderNavigationButtons = (shouldSaveAndSend: boolean = false) => {
    if (
      (formData.form.useCases.length === 0 || formData.formUseCaseId) &&
      canRenderNavigationButtons
    ) {
      const saveButtonAction: ButtonAction = shouldSaveAndSend
        ? 'saveAndSend'
        : 'save';

      return (
        <div
          className={classNames('packet-form__navigation-buttons', {
            'flex-end': renderAllSections,
          })}>
          {renderAllSections ? (
            <Button
              disabled={!state?.loaded}
              className="packet-form__navigation-buttons--button"
              size="large"
              label={renderNavigationButtonLabel(true, saveButtonAction)}
              onClick={(e) => navigateSections(saveButtonAction, e)}
              applyThrottle={true}
              throttleTimeout={500}
            />
          ) : (
            <>
              <Button
                className="packet-form__navigation-buttons--button"
                size="large"
                label={'previous section'}
                disabled={currentSection <= 1 ? true : false || !state?.loaded}
                onClick={(e) => navigateSections('back', e)}
                applyThrottle={true}
                throttleTimeout={250}
              />
              <Button
                disabled={
                  !state?.loaded || (currentUserRole === 'Viewer' && isLastPage)
                }
                className="packet-form__navigation-buttons--button"
                size="large"
                label={renderNavigationButtonLabel(false, 'save')}
                onClick={(e) =>
                  navigateSections(
                    packetFormSections.length === currentSection
                      ? 'save'
                      : 'next',
                    e,
                  )
                }
                applyThrottle={true}
                throttleTimeout={500}
              />
            </>
          )}
        </div>
      );
    }
  };
  const renderInvalidMessages = () => {
    console.log("v == rendering messages", state?.requiredFieldIds);
    if(invalidFieldMessages?.length > 0){
      console.log("v == rendering messages 2", state);
      return invalidFieldMessages.map((m) => (
        <div>{m}</div>
        )
      )
    }
  }

  const wizardStyles = currentView === 'wizard' ? '' : 'form-section-hidden';
  const pdfStyles = currentView === 'pdf' ? '' : 'form-section-hidden';

  return (
    //@ts-ignore
    <ReqFieldsContext.Provider value={[state?.requiredFieldIds, setRequiredFieldIds]}>
      <div className={wizardStyles}>
        <div className="form-wizard__form-section" ref={formWizardRef}>
          <div className={`packet-form ${className ? className : ''}`}>
            {renderNavigationButtons(true)}
            {requiredFieldsError && state?.requiredFieldIds?.length > 0 && (
              <p className="packet-form__required-field-warning">
                Please fill or sign <span>{state?.requiredFieldIds?.length}</span>{' '}
                remaining required or invalid field
                {`${state?.requiredFieldIds?.length === 1 ? '!' : 's!'}`}
                { renderInvalidMessages() }
              </p>
            )}
            {state.loaded ? (
              packetFormSections &&
              (formData.form.useCases.length === 0 || formData.formUseCaseId ? (
                <Sections
                  packetFormSections={packetFormSections}
                  currentSection={currentSection}
                  isLastPage={isLastPage}
                  localDispatch={localDispatch}
                  state={state}
                  requiredFieldsError={requiredFieldsError}
                  currentUserRole={currentUserRole}
                  setSection={setSection}
                  setModalCategory={setModalCategory}
                  savePacketForm={savePacketForm}
                  renderAllSections={renderAllSections}
                  setStateChangeDetected={setStateChangeDetected}
                  setSuggestedValues={setSuggestedValues}
                  handleChange={handleChange}
                  suggestedValues={suggestedValues}
                  fieldId={fieldId}
                  setFieldId={setFieldId}
                />
              ) : (
                <div style={{ backgroundColor: '#fff', padding: '10px' }}>
                  <UseCaseChooser
                    onLoad={setRequiredFieldsError}
                    label="Choose Use Case"
                    useCases={formData.form.useCases}
                    onClick={(e) => updateUseCase(e)}
                    value={
                      state?.packetForm
                        ? state?.packetForm?.formUseCaseId
                        : null
                    }
                  />
                </div>
              ))
            ) : (
              <Loading height="30vh" width="100%" positionRelative={true} />
            )}
            {renderNavigationButtons()}
            {modalCategory && (
              <PacketFormDraggableModal
                category={modalCategory}
                setCategory={setModalCategory}
                lastCoordinates={lastCoordinates}
                setLastCoordinates={setLastCoordinates}
              />
            )}
          </div>
        </div>
      </div>
      {currentView === 'pdf' && (
        <div className={pdfStyles}>
          <div className="form-wizard__form-section" ref={pdfWizardRef}>
            <div className="packet-form">
              {renderNavigationButtons(true)}
              {requiredFieldsError && state?.requiredFieldIds?.length > 0 && (
                <p className="packet-form__required-field-warning">
                  Please fill or sign <span>{state?.requiredFieldIds?.length}</span>{' '}
                  required or invalid field
                  {`${state?.requiredFieldIds?.length === 1 ? '!' : 's!'}`}
                  { renderInvalidMessages() }
                </p>
              )}
              <PacketFormView
                currentPage={currentPage}
                form={packetForm?.form}
                handleCanvasClick={handleCanvasClick}
                setCurrentPage={setCurrentPage}
                setImageOffsetPercentage={setImageOffsetPercentage}
                handleChange={handleChange}
                state={state}
                savePacketForm={savePacketForm}
                packetFormSections={packetFormSections}
                requiredFieldsError={requiredFieldsError}
              />
              {/* {renderNavigationButtons()} */}
            </div>
          </div>
        </div>
      )}
    </ReqFieldsContext.Provider>
  );
};
