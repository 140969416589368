import React, { useState, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { selectPacketBundles } from '@/selectors/packet_bundles';
import { selectForms } from '@/selectors/forms';
import { getFormBundles } from '@/actions/actions/form_bundles';
import { getForms } from '@/actions/actions/forms';
import { createPacket } from '@/actions/actions/packet';
import { ProgressBar, Button } from '@/components/ui/';
import { packetTemplate } from '@/contexts/create-packet-page/data';
import { SectionOne } from '@/contexts/create-packet-page/section-one';
import { SectionTwo } from '@/contexts/create-packet-page/section-two';
import { SectionThree } from '@/contexts/create-packet-page/section-three';
import { selectPacket } from '@/selectors/packet';
import { selectUser } from '@/selectors/user';
import { getUserProfile } from '@/actions/actions/user';
import { Loading } from '@/components/ui';
import { User } from '@/types/user';
import { getUsers } from '@/actions/actions/users';
import { selectUsers } from '@/selectors/users';
import { Option } from '@/types/option';
import { selectDeviceType } from '@/selectors/device_type';

const reducer = (state, action) => {
  switch (action.type) {
    case 'POPULATE':
      return {
        ...state,
        packet: {
          ...state.packet,
          [action.key]: action.value,
        },
      };
    case 'UPDATE_PACKET':
      return {
        ...state,
        packet: action.value,
      };
    case 'SELECTED_PACKETS':
      return {
        ...state,
        selectedPackets: action.value,
      };
    case 'SELECTED_APPROVAL_PATH':
      return {
        ...state,
        selectedApprovalPath: {
          ...state.selectedApprovalPath,
          [action.key]: action.value,
        },
      };
    case 'RESET_APPROVAL_PATH':
      return {
        ...action.value,
      };
    case 'SELECTED_APPROVAL_TIMELINE':
      return {
        ...state,
        approvalTimeline: action.value,
      };

    case 'UPDATE_APPROVAL_PATH_NAME':
      return {
        ...state,
        selectedApprovalPath: {
          ...state.selectedApprovalPath,
          name: action.value,
          save: true,
        },
      };
    default:
      break;
  }
  return state;
};

const CreatePacket: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, localDispatch] = useReducer(reducer, {});
  const [section, setSection] = useState<number>(1);
  const [forms, setForms] = useState<any>();
  const [packetPreviews, updatePacketPreviews] = useState([]);
  const [filteredPacketPreviews, setFilteredPacketPreviews] = useState([]);
  const [packetSubmitted, setPacketSubmitted] = useState<boolean>(false);
  const [user, setUser] = useState<User>(null);
  const [userOptions, setUserOptions] = useState<Option[]>(null);
  const [userIndex, setUserIndex] = useState(null);
  const [isPowerUser, setIsPowerUser] = useState<boolean>(false);
  const [onBehalfOf, setOnBehalfOf] = useState<number>(1);
  const [errorMessages, setErrorMessages] = useState<string[]>(null);

  const currentUser = useSelector(selectUser);
  const packetBundles = useSelector(selectPacketBundles);
  const formBundles = useSelector(selectForms);
  const newPacket = useSelector(selectPacket);
  const userData = useSelector(selectUsers);
  const deviceType = useSelector(selectDeviceType);
  let keyCount = 0;

  const getKey = (prefix) => {
    keyCount = keyCount += 1;
    return `${prefix}-${keyCount}`;
  };

  useEffect(() => {
    if (!currentUser) {
      document.querySelector('html').style.overflowY = 'hidden';
      dispatch(getUserProfile());
    } else if (currentUser) {
      document.querySelector('html').style.overflowY = 'auto';
      setUser(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    dispatch(getFormBundles());
    dispatch(getForms());
    setPacketSubmitted(false);
  }, []);

  useEffect(() => {
    section === 1 && dispatch(getUsers('1'));
    section === 3 && dispatch(getUsers());
  }, [section]);

  useEffect(() => {
    userData && buildUserOptions(userData);
  }, [userData]);

  const determineIfPowerUser = (_user) => {
    if (
      _user?.userRoles?.filter((role) => {
        return role.roleName === 'PowerUser';
      })?.length
    ) {
      setIsPowerUser(true);
    }
  };

  useEffect(() => {
    user && determineIfPowerUser(user);
  }, [user]);

  const buildUserOptions = (_users) => {
    const _options = _users.map((user) => {
      return {
        value: user.userId,
        label: `${user.firstName} ${user.lastName}`,
      };
    });
    setUserOptions(
      _options.sort((a, b) => {
        return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
      }),
    );
    setUserIndex(
      _users.reduce((obj, user) => {
        obj[user.userId] = user;
        return obj;
      }, {}),
    );
  };

  const handleInput = (value) => {
    localDispatch({ type: 'POPULATE', key: 'name', value: value });
  };

  useEffect(() => {
    for (let [key, value] of Object.entries(packetTemplate)) {
      localDispatch({ type: 'POPULATE', key: key, value: value });
    }
  }, [packetTemplate]);

  useEffect(() => {
    packetBundles &&
      formBundles &&
      setForms([...formBundles, ...packetBundles]);
  }, [packetBundles, formBundles]);

  const buildPreviews = () => {
    const previews = forms.map((packet) => {
      let _preview = {
        packet: packet,
        title: packet?.name || packet?.formName,
        details: packet?.formName
          ? packet?.formName
          : packet?.forms?.reduce((acc, currVal) => {
              let formName = currVal.formName;
              acc.push(formName);
              return acc;
            }, []) || null,
      };
      return _preview;
    });
    updatePacketPreviews(
      previews.sort((a, b) => {
        return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
      }),
    );
  };

  const buildPacket = () => {
    let _packet = state.packet;
    // let _selectedApprovalPath = state.selectedApprovalPath;
    // const _approvalTimelineId = state.approvalTimeline.approvalTimelineId;
    const _packetBundles = state?.selectedPackets?.reduce((acc, packet) => {
      const _selectedPacket = {
        packetFormBundleName: packet.title,
        packetForms: packet.packet.formId
          ? [{ formId: packet.packet.formId }]
          : packet?.packet?.forms?.map((form) => {
              return { formId: form.formId };
            }),
      };
      acc.push(_selectedPacket);
      return acc;
    }, []);

    // _selectedApprovalPath.stops.map((stop) => {
    //   stop.approvalTimelineId = _approvalTimelineId;
    // });
    // _packet.approvalPath = _selectedApprovalPath;

    delete _packet['approvalPath'];

    _packet.packetFormBundles = _packetBundles;
    if (!_packet.onBehalfOfUserId) {
      _packet.onBehalfOfUserId =
        localStorage.getItem('user_id') && localStorage.getItem('user_id');
    }

    _packet.creatorUserId =
      localStorage.getItem('user_id') && localStorage.getItem('user_id');

    _packet.onBehalfOfUserId &&
      _packet.creatorUserId &&
      dispatch(createPacket(_packet));
    setPacketSubmitted(true);
  };

  useEffect(() => {
    forms && buildPreviews();
  }, [forms]);

  useEffect(() => {
    newPacket &&
      packetSubmitted &&
      setTimeout(() => {
        history.push(`/packets/packet/${newPacket.packetId}`);
      }, 500);
  }, [packetSubmitted, newPacket]);

  const findNullApprovalUsers = () => {
    let nullUsers = false;

    state?.selectedApprovalPath?.stops?.map((stop) => {
      stop.approvalStopDetail.users.map((user) => {
        if (user.userId === null) nullUsers = true;
      });
    });

    return nullUsers;
  };

  const packetValidationCheck = () => {
    const _errorMessages = JSON.parse(JSON.stringify(errorMessages)) || [];
    const packetNameErrorMessage = 'Please add a packet name';
    const noSelectedFormsErrorMessage =
      'Select forms or form groups to include in packet';
    const selectedApprovalPathErrorMessage = 'Select Approval Path';
    const approvalTimelineErrorMessage = 'Select Approval Timeline';
    const undefinedApproversErrorMessage = 'Select New Approvers';

    let isValid: boolean = true;

    if (section === 1) {
      if (!state?.packet?.name.replace(/\s/g, '').length) {
        isValid = false;
        _errorMessages.push(packetNameErrorMessage);
      } else if (state?.packet?.name.replace(/\s/g, '').length) {
        _errorMessages.includes(packetNameErrorMessage) &&
          _errorMessages.splice(
            _errorMessages.indexOf(packetNameErrorMessage),
            1,
          );
      }
      if (!state?.selectedPackets?.length) {
        isValid = false;
        _errorMessages.push(noSelectedFormsErrorMessage);
      } else if (state?.selectedPackets?.length) {
        _errorMessages.includes(noSelectedFormsErrorMessage) &&
          _errorMessages.splice(
            _errorMessages.indexOf(noSelectedFormsErrorMessage),
            1,
          );
      }
    } else if (section === 2) {
      if (!state?.selectedApprovalPath) {
        _errorMessages.push(selectedApprovalPathErrorMessage);
      } else if (state?.selectedApprovalPath) {
        _errorMessages.includes(selectedApprovalPathErrorMessage) &&
          _errorMessages.splice(
            _errorMessages.indexOf(selectedApprovalPathErrorMessage),
            1,
          );
      }
      if (!state?.approvalTimeline) {
        _errorMessages.push(approvalTimelineErrorMessage);
      } else if (state?.approvalTimeline) {
        _errorMessages.includes(approvalTimelineErrorMessage) &&
          _errorMessages.splice(
            _errorMessages.indexOf(approvalTimelineErrorMessage),
            1,
          );
      }
    }
    //@ts-ignore
    setErrorMessages([...new Set(_errorMessages)]);

    return isValid;
  };

  const handleCreatePacket = () => {
    const isValid = packetValidationCheck();

    if (isValid) {
      buildPacket();
    }
  };

  return (
    <div className="create-packet page__container bg-blue-gradient ">
      <div className="create-packet__container container">
        <div className="create-packet__header-section">
          <div className="packet__actions">
            <Button
              backgroundColor="transparent"
              iconClass="back"
              label="Cancel"
              primary={false}
              size="small"
              transformText={false}
              onClick={() => history.goBack()}
              labelColor="#fff"
            />
          </div>
          <h1 className="create-packet__header-section--h1">
            create new packet
          </h1>
          <div style={{ width: '100px' }} />

          {/* NOTE: Hidden for June 2021 updates
           <h2 className="create-packet__header-section--h2">
            {section !== 3 && `select ${section === 1 ? 'forms' : 'approvers'}`}
            {section === 3 && `Preview Packet`}
          </h2>
          {section !== 3 && (
            <ProgressBar
              currentPageNumber={section}
              totalPages={2}
              completedColor="var(--tertiary2)"
              totalColor="var(--tertiary3)"
              width={deviceType === 'mobile' ? window.screen.width * 0.8 : 500}
            />
          )} */}
        </div>
        <div className="create-packet__container section">
          {errorMessages?.length > 0 && (
            <div className="create-packet__required-step-warning card">
              <p className="create-packet__required-step-warning-message">
                Please correct the following{' '}
                <span>{errorMessages?.length}</span> errors
              </p>
              <ul className="create-packet__required-step-warning-ul">
                {errorMessages?.length &&
                  errorMessages.map((message) => {
                    return (
                      <li
                        key={getKey('warning')}
                        className="create-packet__required-step-warning-ul-li">
                        {message}
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}
          {section === 1 && (
            <SectionOne
              state={state || {}}
              handleInput={(e) => handleInput(e)}
              packetPreviews={packetPreviews}
              filteredPacketPreviews={filteredPacketPreviews}
              setFilteredPacketPreviews={setFilteredPacketPreviews}
              localDispatch={localDispatch}
              user={user}
              userIndex={userIndex}
              userOptions={userOptions}
              isPowerUser={isPowerUser}
              setOnBehalfOf={setOnBehalfOf}
              onBehalfOf={onBehalfOf}
            />
          )}
          {section === 2 && (
            <SectionTwo state={state || {}} localDispatch={localDispatch} />
          )}
          {section === 3 && (
            <SectionThree
              localDispatch={localDispatch}
              state={state || {}}
              userIndex={userIndex}
              userOptions={userOptions}
              isPowerUser={isPowerUser}
            />
          )}
          <div className="create-packet__navigation-buttons flex flex-end">
            <Button
              primary={true}
              size={'large'}
              label="Create Packet"
              onClick={(e) => {
                handleCreatePacket();
                window.scrollTo({ top: 0, behavior: 'smooth' });
                e.currentTarget.blur();
              }}
              applyThrottle={true}
              throttleTimeout={1000}
            />
          </div>
        </div>
      </div>
      <>
        {!user && <Loading message="Loading..." height="100vh" width="100vw" />}
      </>
    </div>
  );
};

export default CreatePacket;
