import React from 'react';

export interface FieldsProps {
  subSection: any;
  pageIndex: number;
  section1Index: number;
  section2Index: number;
  renderField: (a, b, c, d) => any;
}

export const Fields: React.FC<FieldsProps> = ({
  subSection,
  pageIndex,
  section1Index,
  section2Index,
  renderField,
}) => {
  let keyCount = 0;

  const getKey = (prefix) => {
    keyCount = keyCount += 1;
    return `${prefix}-${keyCount}`;
  };

  return (
    <>
      {subSection &&
        subSection.fields.map((field, index) => {
          const locationReference = `_pages[${pageIndex}].sections[${section1Index}].sections[${section2Index}].fields[${index}].value = value`;
          const locationInState = `state?.packetForm?.form?.pages[${pageIndex}]?.sections[${section1Index}]?.sections[${section2Index}]?.fields[${index}]?.value`;
          return (
            <div className="packet-form__field" key={getKey('field-input')}>
              {renderField(
                field,
                locationReference,
                locationInState,
                getKey('input'),
              )}
            </div>
          );
        })}
    </>
  );
};
