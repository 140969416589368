import React, { useEffect, useState } from 'react';

import { DraggableModal } from '@/components/ui';
import { PDFPreview } from '@/contexts/packets-page/form-wizard/packet-form/packet-form-draggable-modal/pdf-preview';
import { UserProfile } from '@/contexts/packets-page/form-wizard/packet-form/packet-form-draggable-modal/user-profile';
import { DraggableModalCoordinates } from '@/types/draggable_modal';

export interface PacketFormDraggableModalProps {
  category: any;
  setCategory: (a) => void;
  lastCoordinates: DraggableModalCoordinates;
  setLastCoordinates: React.Dispatch<
    React.SetStateAction<DraggableModalCoordinates>
  >;
}

export const PacketFormDraggableModal: React.FC<PacketFormDraggableModalProps> = ({
  category,
  setCategory,
  lastCoordinates,
  setLastCoordinates,
}) => {
  const renderContent = ({ category, section }) => {
    switch (category) {
      case 'pdf-preview':
        return <PDFPreview section={section} />;
      default:
        break;
    }
    switch (category) {
      case 'profile':
        return <UserProfile />;
      default:
        break;
    }
  };

  return (
    <>
      {category && (
        <DraggableModal
          initialLeftPostion={window.innerWidth * 0.25}
          initialTopPostion={390}
          handleClickX={() => setCategory(null)}
          height={category === 'profile' ? 600 : 'max-content'}
          width={category === 'profile' ? 500 : window.innerWidth * 0.65}
          maxWidth={category === 'profile' ? 500 : window.innerWidth * 0.65}
          lastCoordinates={lastCoordinates}
          setLastCoordinates={setLastCoordinates}>
          {renderContent(category)}
        </DraggableModal>
      )}
    </>
  );
};
