import React, { useState } from 'react';
import moment from 'moment';
import { ApprovalPathLine } from '@/components/ui/approval-path-line';
import { PacketStatusStop } from '@/types/packet';

export interface ApproverEventCardProps {
  offset: string;
  idx: number;
  start?: boolean;
  end?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  approvalData: PacketStatusStop;
}

export const ApproverEventCard: React.FC<ApproverEventCardProps> = ({
  offset,
  idx,
  start=false,
  isLast=false,
  isFirst=false,
  end=false,
  approvalData
}) => {
  const [readMore, setReadMore] = useState(false)

  const updateReadMore = () => {
    setReadMore(!readMore)
  }

  const packetStatus = status => {
    switch (status) {
      case 1:
      case 5:
        return 'Pending'
      case 2:
        return 'Approved'
      case 3:
        return 'Rejected'
      case 4:
        return 'Denied'
      case 6:
        return 'Created'
      default:
        return 'Awaiting Review'
    }
  }

  const toDateString = (date: Date) => {
    return moment(date).format('MM/DD/YYYY');
  };

  const readMoreLess = (comments) => {
    if (comments.length > 280) {
      return (
        <div>
          {readMore ? <div className='event__comments'>{comments}</div> : <div className='event__comments'>{comments.substring(0, 280)}...</div>}
          {
            readMore
              ?
              <div className='read-more' onClick={updateReadMore}>Read Less</div>
              :
              <div className='read-more' onClick={updateReadMore}>Read More</div>
          }
        </div>
      )
    } else {
      return (
        <div>{approvalData['comments']}</div>
      )
    }
  }

  return (
    <div className={`packet-approval__event ${offset}`}>
      <ApprovalPathLine
        offset={offset}
        start={start}
        end={end}
        isFirst={isFirst}
        isLast={isLast}
        approvalData={approvalData}
      />
      <div className='packet-approval__info'>
        {approvalData['userName'] && <div className='packet-approval__owner'>{approvalData['userName']}</div>}
        {approvalData['title'] && <div className='packet-approval__title'>{approvalData['title']}</div>}
        {approvalData['office'] && <div className='packet-approval__title'>{approvalData['office']}Office</div>}
        {approvalData['department'] && <div className='packet-approval__department'>{approvalData['department']}</div>}
      </div>
      <div className='packet-approval__status-container'>
        {approvalData['approvalStatusId'] && <div className='packet-approval__status'>Status: <span className='packet-approval__status packet-approval__status-type'>{packetStatus(approvalData['approvalStatusId'])}</span></div>}
        {approvalData['lastStatusChangeDateTimeUtc'] && <div className='packet-approval__date'>{toDateString(approvalData['lastStatusChangeDateTimeUtc'])}</div>}
        <div className='packet-approval__status'>{approvalData['comments'] && 'Comments:' }</div>
        <div>{readMoreLess(approvalData['comments'])}</div>
      </div>
    </div>
  );
};

export default ApproverEventCard;
