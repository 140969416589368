import { createSelector } from 'reselect';

import { AppState } from '@/types/app';
import { ApprovalPathState } from '@/types/approval_path';

export const approvalPathState = (state: AppState): ApprovalPathState => state.approvalPaths;

export const selectApprovalPaths = createSelector(approvalPathState, (state) => state.approvalPaths);

export const selectUserApprovalPath = createSelector(approvalPathState, (state) => state.userApprovalPath);
