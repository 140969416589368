import { call, put, takeLatest } from 'redux-saga/effects';

import { PacketActions, CreatePacket, createPacketFailure, createPacketSuccess } from '@/actions/actions/packet';
import Api from '@/api/Api';
import { buildPath, Routes } from '@/utils/routeUtils';

export function* createPacketSaga(
  action: CreatePacket
) {
  try {
    const { packet } = action.payload;

    const url = buildPath(Routes.api.packets, null)

    const response = yield call(Api.utility.post, url, packet);

    yield put(createPacketSuccess(response.data));
  } catch (err) {
    yield put(createPacketFailure(err));
  }
}

export function* watchCreatePacket() {
  yield takeLatest(PacketActions.CREATE_PACKET, createPacketSaga);
}
