import { call, put, takeLatest } from 'redux-saga/effects';

import { PacketFormActions, updatePacketForm, updatePacketFormFailure, updatePacketFormSuccess } from '@/actions/actions/packet_form';
import Api from '@/api/Api';
import { flashError, flashSuccess } from '../messageSaga';

export function* updatePacketFormSaga(
  action: updatePacketForm
) {
  try {
    const { packetId, packetFormBundleId, packetFormId, packetForm, autosave } = action.payload;

    const url = `/api/v1/packets/${packetId}/packetformbundles/${packetFormBundleId}/packetform/${packetFormId}`;

    const response = yield call(Api.utility.put, url, packetForm);

    yield put(flashSuccess(autosave? 'Form auto-saved' : 'Successfully saved form'));
    if(autosave){
      console.log("auto saved so not changing the state of the packet");
    } else {
      yield put(updatePacketFormSuccess(response.data));
    }
  } catch (err) {
    yield put(flashError('Could not save form'));
    yield put(updatePacketFormFailure(err));
  }
}

export function* watchupdatePacketForm() {
  yield takeLatest(PacketFormActions.UPDATE_PACKET_FORM, updatePacketFormSaga);
}
