import React from 'react';

export interface ApprovalPathLineProps {
  offset: string;
  start?: boolean;
  end?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  approvalData: any;
}

export const ApprovalPathLine: React.FC<ApprovalPathLineProps> = ({
  offset,
  start = false,
  isLast = false,
  isFirst = false,
  end = false,
  approvalData,
}) => {
  const eventDotType = (eventStatus) => {
    switch (eventStatus) {
      case 2:
      case 6:
        return <div className="dot dot__approved-approval"></div>;
      case 3:
        return <div className="dot dot__rejected-approval"></div>;
      case 4:
        return <div className="dot dot__denied-approval"></div>;
      default:
        return <div className="dot dot-approval"></div>;
    }
  };

  return (
    <div className={`progress-line__container`}>
      <div className="progress-line">
        {/* ApprovalPath Line Top Scenarios */}
        {start && (
          <div className={`line-approval line__top-approval is-first`}></div>
        )}
        {isFirst && !start && (
          <div className={`line-approval line__top-approval ${offset}`}></div>
        )}
        {!isFirst && (!isLast || isLast) && (
          <div className={`line-approval line__top-approval`}></div>
        )}

        {/* Dot  */}
        {eventDotType(approvalData['approvalStatusId'])}

        {/* ApprovalPath Line Bottom Scenarios */}
        {isFirst && isLast === false && !end && (
          <div className="line-approval line__bottom-approval"></div>
        )}
        {isFirst && !isLast && end && (
          <div className="line-approval line__bottom-approval"></div>
        )}
        {isFirst && isLast && !end && (
          <div
            className={`line-approval line__bottom-approval ${offset}`}></div>
        )}
        {!isFirst && isLast && !end && (
          <div
            className={`line-approval line__bottom-approval ${offset}`}></div>
        )}
        {!isFirst && !isLast && (
          <div className="line-approval line__bottom-approval "></div>
        )}
        {isFirst && isLast && end && <div className="progress-line__end"></div>}
      </div>
    </div>
  );
};

export default ApprovalPathLine;
