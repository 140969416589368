import axios, { AxiosInstance } from 'axios';

import { logoutUser, refreshAuthToken } from '@/utils/oauth';

interface Config {
  timeout: number;
  headers: Headers;
  validateStatus: (status: number) => boolean;
  baseURL: string;
  withCredentials?: boolean;
}

interface Headers {
  Authorization: string;
  Accept: string;
  'Content-Type': string;
  'Access-Control-Allow-Origin': string;
  withCredentials?: boolean;
  mode?: string;
}

export class Api {
  baseConfig: Config;

  baseHeaders: Headers;

  baseUrl: string;

  utility: AxiosInstance;

  bearerToken: string;

  refreshToken: string;

  withCredentials: boolean;

  constructor() {
    const bearerToken = localStorage.getItem("bearer_token");
    this.baseHeaders = {
      Authorization: `Bearer ${bearerToken}`,
      'Access-Control-Allow-Origin': "*",
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    this.baseUrl = this.setBaseUrl();

    this.baseConfig = {
      baseURL: this.baseUrl,
      timeout: 60000,
      headers: this.baseHeaders,
      validateStatus: (status: number) => {
        return status >= 200 && status < 300;
      },
    };

    this.utility = axios.create(this.baseConfig);

    this.bearerToken = bearerToken;

    this.setRefreshTokenInterceptor();
  }

  setAuthUtility(authToken: string) {
    this.utility.defaults.headers.common.Authorization = authToken;
  }

  setBaseUtility() {
    this.utility.defaults.headers.common.Authorization = undefined;
  }

  setBearerToken(bearerToken: string) {
    this.bearerToken = bearerToken;
  }

  setRefreshToken(refreshToken: string) {
    this.refreshToken = refreshToken;
  }

  setBaseUrl() {
    if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
      return process.env.APPLICATION_URL;
    } else {
      return process.env.APPLICATION_URL;
    }
  }

  setRefreshTokenInterceptor() {
    this.utility.interceptors.response.use(response => response, async (error) => {
      if (error?.response?.status === 401) {
        const originalRequest = error.config
        if (!originalRequest._retry) {
          // So that on subsequent 401s this exits
          const newToken = refreshAuthToken();

          originalRequest._retry = true;
          originalRequest.headers.Authorization = `Bearer ${newToken}`;

          return this.utility(originalRequest);
        } else {
          logoutUser();
        }
      }
    });
  }
}

const api = new Api();


export default api;
