import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import { DropdownSelect } from '@/components/ui/dropdown-select';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectApprovalPaths,
  selectUserApprovalPath,
} from '@/selectors/approval_paths';
import { getApprovalPaths } from '@/actions/actions/approval_paths';
import { PacketApprovers } from '@/contexts/create-packet-page/packet-approvers';
import { getUsers } from '@/actions/actions/users';
import { selectUsers, usersState } from '@/selectors/users';
import { Icon } from '@/components/ui';
import Header from './header';
import Stop from './stop';
import { Modal } from '@/components/ui/modal';
import { TextField } from '@/components/ui/text-field';
import { selectPacket } from '@/selectors/packet';
import { getPacket, updatePacket } from '@/actions/actions/packet';

interface ParamTypes {
  packetId: string;
}

const SendPacket = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { packetId } = useParams<ParamTypes>();

  const [approvalPaths, setApprovalPaths] = useState([]);
  const [selectedApprovalPath, setSelectedApprovalPath] = useState(null);
  const [users, setUsers] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [userIndex, setUserIndex] = useState(null);
  const [isShowingCreatePathModal, setIsShowingCreatePathModal] = useState<
    boolean
  >(false);
  const [newPathName, setNewPathName] = useState<string>('');
  const [canSendPacket, setCanSendPacket] = useState<boolean>(false);

  const userData = useSelector(selectUsers);
  const packet = useSelector(selectPacket);
  const approvalPathsData = useSelector(selectApprovalPaths);
  const userApprovalPath = useSelector(selectUserApprovalPath);
  // const approvalTimelinesData = useSelector(selectApprovalTimelines);

  // const canCreateNewApprovalPath: boolean = useMemo(
  //   () => state && state.selectedApprovalPath,
  //   [state],
  // );

  useEffect(() => {
    dispatch(getUsers('3'));
    dispatch(getApprovalPaths());
  }, []);

  useEffect(() => {
    // approvalPathsData &&
    //   setApprovalPaths([
    //     userApprovalPath,
    //     ...JSON.parse(JSON.stringify(approvalPathsData)),
    //   ]);

    if (approvalPathsData) {
      if (userApprovalPath) {
        setApprovalPaths([
          userApprovalPath,
          ...JSON.parse(JSON.stringify(approvalPathsData)),
        ]);
        setSelectedApprovalPath(userApprovalPath);
      } else {
        setApprovalPaths(JSON.parse(JSON.stringify(approvalPathsData)));
      }
    }
  }, [approvalPathsData, userApprovalPath]);

  useEffect(() => {
    // if (packetId && packet?.packetId !== parseInt(packetId)) {
    //   dispatch(resetPacket());
    // }
    packetId && dispatch(getPacket(parseInt(packetId)));
  }, [packetId]);

  useEffect(() => {
    userData && setUsers(userData);
  }, [userData]);

  useEffect(() => {
    users && buildUserOptions(users);
  }, [users]);

  useEffect(() => {
    getCanSendPacket();
  })

  const buildUserOptions = (_users) => {
    const _options = _users.map((user) => {
      return {
        value: user.userId,
        label: `${user.office ? user.office + " " : "" }${user.firstName} ${user.lastName} `,
      };
    });
    setUserOptions(
      _options.sort((a, b) => {
        return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
      }),
    );
    setUserIndex(
      _users.reduce((obj, user) => {
        obj[user.userId] = user;
        return obj;
      }, {}),
    );
  };

  const buildApprovalPathOptions = (data) => {
    const sortedPaths = data.reduce(
      (paths, currentPath) => {
        let option = {
          value: currentPath.name,
          label: currentPath.name,
        };

        if (currentPath.approvalPathType === 1) {
          paths.defaultPaths = [...paths.defaultPaths, option];
        } else if (currentPath.approvalPathType === 2) {
          if (!paths?.userDefinedPaths) {
            paths['userDefinedPaths'] = [option];
          } else {
            paths.userDefinedPaths = [...paths?.userDefinedPaths, option];
          }
        }
        return paths;
      },
      { defaultPaths: [] },
    );

    const options = Object.entries(sortedPaths).map((entry) => {
      return entry.map((item: any) => {
        if (typeof item === 'string' && item === 'userDefinedPaths') {
          return { header: true, value: item, label: 'Your Approval Paths' };
        } else if (typeof item === 'string' && item === 'defaultPaths') {
          return {
            header: true,
            value: null,
            label: 'Predefined Approval Paths',
          };
        } else {
          return item;
        }
      });
    });
    //@ts-ignore
    return options.flat(2);
  };

  const setApprovalPathChoiceHelper = (choice) => {
    let matchedChoice = null;

    if (choice) {
      matchedChoice = approvalPaths.filter((path) => {
        if (path.name === choice) return path;
      });
    }

    if (matchedChoice) {
      let _selectedApprovalPath = matchedChoice[0];
      let mod = _selectedApprovalPath?.stops?.map((stop)=> {
        stop.approvalStopDetail.users.map((user) => {
          user.userId = null
        })
      })

      setSelectedApprovalPath(_selectedApprovalPath);
    }
  };

  const getCanSendPacket = () => {
    const _users = []

    if (selectedApprovalPath?.stops !== null && selectedApprovalPath?.stops !== undefined) {
      const _stops = selectedApprovalPath?.stops
      _stops.map((stop) => {
        stop?.approvalStopDetail.users?.map((user) => {
          if (user.userId === null) {
            _users.push(user);
          }
        })
      })
      if (_users.length === 0) setCanSendPacket(true);
      else setCanSendPacket(false);
    }
  }

  const handleCloseNewPathNameModal = () => {
    setIsShowingCreatePathModal(false);
    setNewPathName('');
  };

  const handleSaveNewPathName = () => {
    const _selectedApprovalPath = { ...selectedApprovalPath };

    _selectedApprovalPath.name = newPathName;

    _selectedApprovalPath.save = true;

    setSelectedApprovalPath(_selectedApprovalPath);

    setApprovalPaths([...approvalPaths, _selectedApprovalPath]);

    handleCloseNewPathNameModal();
  };

  const handleSendPacket = () => {
    if (selectedApprovalPath) {
      let _packetData = packet;

      _packetData.statusId = 3;
      _packetData.approvalPath = selectedApprovalPath;

      dispatch(updatePacket(_packetData.packetId, _packetData));

      history.push('/');
    }
  };

  return (
    <>
      <div className="send-packet">
        <Header backText="Cancel" header="send packet" />

        <div style={{ backgroundColor: '#fff', padding: '10px' }}>
          <h3 className="create-packet__section--header">
            Assign approvers for packet
          </h3>

          <div className="send-packet__top-actions">
            <div style={{ width: '350px' }}>
              <DropdownSelect
                label="Saved Paths"
                options={
                  approvalPaths && buildApprovalPathOptions(approvalPaths)
                }
                placeholder="Please select an approval path..."
                selectedValue={selectedApprovalPath?.name || null}
                onClick={(choice) => {
                  setApprovalPathChoiceHelper(choice);
                }}
                typeAhead={false}
                renderSelectedOption={true}
              />
            </div>

            <div
              style={{
                paddingTop: '30px',
                display: 'flex',
                alignItems: 'center',
              }}>
              <span style={{ margin: '0 10px' }}>Or</span>

              <Button
                label="create new path"
                onClick={() =>
                  history.push(`/packets/packet/${packetId}/send/create`)
                }
                borderColor="black"
              />
            </div>
          </div>

          {selectedApprovalPath && (
            <>
              <div className="send-packet__stops">
                {selectedApprovalPath.stops.map((stop, stopIndex) => {
                  return (
                    <Stop
                      key={stopIndex}
                      selectedApprovalPath={selectedApprovalPath}
                      setSelectedApprovalPath={setSelectedApprovalPath}
                      stop={stop}
                      stopIndex={stopIndex}
                      userOptions={userOptions}
                    />
                  );
                })}
              </div>

              <div style={{ marginTop: '20px' }}>
                <Button
                  label="save as new path"
                  onClick={() => setIsShowingCreatePathModal(true)}
                  labelColor="black"
                  borderColor="black"
                  size="small"
                />
              </div>
            </>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '10px',
          }}>
          <Button
            disabled={!canSendPacket}
            label="send packet"
            backgroundColor="white"
            labelColor="black"
            onClick={handleSendPacket}
            size="small"
          />
        </div>
      </div>

      {isShowingCreatePathModal && (
        <Modal handleClickX={handleCloseNewPathNameModal}>
          <div className="create-packet__new-path-modal">
            <h1>Name New Approver Path</h1>

            <TextField
              label="Path Name"
              onChange={(value) => setNewPathName(value)}
              placeholder="Enter path name"
              defaultValue={newPathName}
            />

            <div className="create-packet__new-path-modal--action-container md-flex-dir-col-rev">
              <Button
                className="md-width-100"
                label="cancel"
                size="small"
                border
                borderColor="#000"
                primary
                onClick={handleCloseNewPathNameModal}
              />
              <Button
                className="solid md-margin-btm-15 md-width-100"
                label="okay"
                primary={false}
                size="small"
                onClick={handleSaveNewPathName}
                labelColor="#fff"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SendPacket;
