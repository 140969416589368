import React from 'react';

import { ApprovalPath, Stop } from '@/types/approval_path';
import { Option } from '@/types/option';
import { Button } from '@/components/ui';
import StopUser from './stop-user';

interface Props {
  selectedApprovalPath: ApprovalPath;
  setSelectedApprovalPath: (approvalPath: ApprovalPath) => void;
  stop: Stop;
  stopIndex: number;
  userOptions: Option[];
}

const Stop = (props: Props): JSX.Element => {
  const {
    selectedApprovalPath,
    setSelectedApprovalPath,
    stop,
    stopIndex,
    userOptions,
  } = props;

  const totalApproverFields = () => {
    let total = 0;
    stop.approvalStopDetail.requiredForms.forEach((requiredForm) => {
      requiredForm.requiredFieldIds.forEach((fieldIds) => {
        total++;
      });
    });
    return total;
  };

  const handleAddApprover = () => {
    const _selectedApprovalPath = {
      ...selectedApprovalPath,
    };

    _selectedApprovalPath.stops[stopIndex].approvalStopDetail.users.push({
      userId: null,
      approvalStatusId: null,
    });

    setSelectedApprovalPath(_selectedApprovalPath);
  };

  const handleSelectApprover = (value: string, userIndex: number) => {
    const _selectedApprovalPath = { ...selectedApprovalPath };

    _selectedApprovalPath.stops[stopIndex].approvalStopDetail.users[
      userIndex
    ].userId = value;

    setSelectedApprovalPath(_selectedApprovalPath);
    console.log('triggered from stop comp', _selectedApprovalPath)
  };

  const handleRemoveApprover = (userIndex: number) => {
    const _selectedApprovalPath = {
      ...selectedApprovalPath,
    };

    _selectedApprovalPath.stops[stopIndex].approvalStopDetail.users.splice(
      userIndex,
      1,
    );

    setSelectedApprovalPath(_selectedApprovalPath);
  };

  return (
    <div className="send-packet__stop">
      <span key={stopIndex} className="send-packet__stop-title">
        {`${stopIndex + 1}. `}
        <span>{stop.displayName}</span>
      </span>

      <span
        style={{
          display: 'block',
        }}>{`(${totalApproverFields()} Approval Fields)`}</span>

      {stop.approvalStopDetail.users.map((user, userIndex) => {
        return (
          <StopUser
            key={userIndex}
            handleRemoveApprover={handleRemoveApprover}
            handleSelectApprover={handleSelectApprover}
            selectedApprovalPath={selectedApprovalPath}
            stopIndex={stopIndex}
            userIndex={userIndex}
            userOptions={userOptions}
          />
        );
      })}
      <Button
        className="solid"
        label="add approver"
        primary={false}
        size="small"
        onClick={() => {
          handleAddApprover();
        }}
        labelColor="#fff"
      />
    </div>
  );
};

export default Stop;
