import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { PacketActions } from '@/contexts/packets-page/packet-actions';
import { DropdownSelect } from '@/components/ui';
import { selectDeviceType } from '@/selectors/device_type';
import { selectTeamApprovalNeededPackets } from '@/selectors/packets';

export interface PacketsHeaderProps {
  currentPage: string;
}

const TeamPacketsHeader: React.FC<PacketsHeaderProps> = ({ currentPage }) => {
  const history = useHistory();
  const teamPackets = useSelector(selectTeamApprovalNeededPackets);
  const approvalNeededLinkStyles = classNames('packet-header-link', {
    'is-current-page': currentPage === 'approval-needed',
  });
  const pastLinkStyles = classNames('packet-header-link', {
    'is-current-page': currentPage === 'past',
  });
  const routeOptions = [
    {
      value: '/packets/team-packets/approval-needed',
      label: 'APPROVAL NEEDED',
    },
    { value: '/packets/team-packets/past', label: 'PAST PACKETS' },
  ];

  const deviceType = useSelector(selectDeviceType);

  return (
    <PacketActions className="packet-index">
      {deviceType === 'mobile' ? (
        <DropdownSelect
          options={routeOptions}
          selectedValue={history.location.pathname}
          onClick={(path) => history.push(path)}
        />
      ) : (
        <div className="packet-index__sections">
          <>
            <Link
              className={approvalNeededLinkStyles}
              to="/packets/team-packets/approval-needed">
              APPROVAL NEEDED
              {teamPackets && teamPackets.length > 0 && (
                <span className="navbar__link--highlight">
                  {teamPackets.length}
                </span>
              )}
            </Link>
          </>
          <Link className={pastLinkStyles} to="/packets/team-packets/past">
            PAST PACKETS
          </Link>
        </div>
      )}
      <h3 className="packet-index__header team-packets-md-header">
        {currentPage.replace(/-/g, ' ')}
        {currentPage === 'past' ? ' packets' : ''}
      </h3>
    </PacketActions>
  );
};

export default TeamPacketsHeader;
