import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Icon, DropdownSelect, ProgressBar } from '@/components/ui';
import { PacketFormBundleDrawer } from '@/contexts/packets-page/form-wizard/packet-form-panel/packet-form-bundle-drawer';
import { Packet } from '@/types/packet';
import { PacketForm } from '@/types/packet_form';
import { updateLocalPacket } from '@/actions/actions/packet';
export interface PacketFormPanelProps {
  packet: Packet;
  onClick: (a, b, c) => void;
  currentForm: PacketForm;
  totalPercentageCompleted: number;
  showPercentageComplete: boolean;
  setTotalPercentageCompleted: React.Dispatch<React.SetStateAction<number>>;
}

export const PacketFormPanel: React.FC<PacketFormPanelProps> = React.memo(
  ({
    packet,
    onClick,
    currentForm,
    totalPercentageCompleted,
    showPercentageComplete,
    setTotalPercentageCompleted,
  }) => {
    const dispatch = useDispatch();
    const [currentPacketFormId, setCurrentPacketFormId] = useState<number>(
      null,
    );

    useEffect(() => {
      packet && calculateTotalPercentCompleted();
    }, [packet]);

    useEffect(() => {
      if (currentForm?.packetFormId === currentPacketFormId) {
        calculateTotalPercentCompleted(currentForm);
      } else {
        setCurrentPacketFormId(currentForm?.packetFormId);
      }
    }, [currentForm]);

    const calculateTotalPercentCompleted = (updatedForm?: PacketForm) => {
      let percentages = [];
      let _packet = JSON.parse(JSON.stringify(packet));

      if (updatedForm) {
        _packet.packetFormBundles.map((bundle) => {
          bundle.packetForms.map((form) => {
            if (form.packetFormId === updatedForm.packetFormId) {
              form.formSummary = updatedForm.formSummary;
            }
          });
        });
        dispatch(updateLocalPacket(_packet));
      }

      _packet?.packetFormBundles?.map((packet) => {
        packet.packetForms.map((form) => {
          percentages.push(form.formSummary.percentCompleted);
        });
      });

      const percentageComplete =
        percentages.reduce((acc, cVal) => acc + cVal) / percentages.length;

      setTotalPercentageCompleted(
        percentageComplete > 100 ? 100 : percentageComplete,
      );
    };

    return (
      <>
        <div className="packet-form-panel colmuns section hide-scrollbar">
          <div className="packet-form-panel__packet bundle hide-scrollbar">
            <Icon
              iconClass="form--multi"
              className="packet-form-panel__packet--icon"
              size="3.3rem"
            />
            <h5 className="packet-form-panel__title">
              {packet.name.length > 16
                ? `${packet.name.slice(0, 16)}...`
                : packet.name}
            </h5>
            {showPercentageComplete && (
              <ProgressBar
                percentCompleted={totalPercentageCompleted.toString()}
                size="small"
                width={136}
              />
            )}
          </div>
          <PacketFormBundleDrawer
            packetFormBundles={packet.packetFormBundles}
            packetId={packet.packetId}
            onClick={(packetId, packetFormBundleId, packetFormId) =>
              onClick(packetId, packetFormBundleId, packetFormId)
            }
          />
        </div>
      </>
    );
  },
);
