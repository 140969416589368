import { FormBundlesActions } from '@/actions/actions/form_bundles';
import { FormBundlesState } from '@/types/form_bundle';

const initialState: FormBundlesState = {
  formBundles: null,
};

export default function reducer(
  state: FormBundlesState = initialState,
  action: any,
): FormBundlesState {
  const { type, payload } = action;

  switch (type) {
    case FormBundlesActions.GET_FORM_BUNDLES_SUCCESS:
      return {
        ...state,
        formBundles: payload.packet
      }
    default:
      return state;
  }
}
