import React, { useEffect, useState } from 'react';

import { Section } from '@/contexts/packets-page/form-wizard/packet-form/sections/section';
import { Role } from '@/types/packet';
import { PacketForm as PacketFormType } from '@/types/packet_form';
import { Button } from '@/components/ui/button';

export interface SectionsProps {
  packetFormSections: any;
  currentSection: number;
  isLastPage: boolean;
  state: any;
  localDispatch: React.Dispatch<any>;
  requiredFieldsError: boolean;
  currentUserRole: Role;
  setSection: React.Dispatch<React.SetStateAction<any>>;
  setModalCategory: React.Dispatch<React.SetStateAction<string>>;
  savePacketForm: (packetForm?: PacketFormType, autosave?: boolean) => void;
  renderAllSections: boolean;
  setStateChangeDetected: React.Dispatch<React.SetStateAction<boolean>>;
  setSuggestedValues: React.Dispatch<React.SetStateAction<string[]>>;
  handleChange: any;
  fieldId: string;
  setFieldId: React.Dispatch<React.SetStateAction<string>>;
  suggestedValues: string[];
}

export const Sections: React.FC<SectionsProps> = ({
  currentSection,
  isLastPage,
  packetFormSections,
  state,
  localDispatch,
  requiredFieldsError,
  currentUserRole,
  setSection,
  setModalCategory,
  savePacketForm,
  renderAllSections,
  setStateChangeDetected,
  setSuggestedValues,
  handleChange,
  fieldId,
  setFieldId,
  suggestedValues,
}) => {
  let keyCount = 0;
  let requiredFieldIds = [];

  const allOpen = {};
  const allCollapsed = {};

  packetFormSections.forEach((section, index) => {
    allOpen[index] = true;
    allCollapsed[index] = false;
  });

  const [isOpen, setIsOpen] = useState(allOpen);

  const getKey = (prefix) => {
    keyCount = keyCount += 1;
    return `${prefix}-${keyCount}`;
  };

  useEffect(() => {
    (!requiredFieldIds.length && state?.requiredFieldIds?.length) ||
      (requiredFieldIds.length && addRequiredFields());
  }, [requiredFieldIds]);

  const addRequiredFields = () => {
    if (!state?.requiredFieldIds?.length) {
      localDispatch({
        type: 'REQUIRED_FIELD_IDS',
        value: requiredFieldIds,
      });
    }
  };

  const handleCollapseAllClick = () => {
    setIsOpen(allCollapsed);
  };

  const handleExpandAllClick = () => {
    setIsOpen(allOpen);
  };

  const handleToggleOpen = (index: number) => {
    setIsOpen({
      ...isOpen,
      [index]: !isOpen[index],
    });
  };

  const renderSection = (section, index) => {
    return (
      <Section
        packetFormSectionsLength={packetFormSections?.length}
        sectionIndex={index}
        requiredFieldIds={requiredFieldIds}
        isLastPage={isLastPage}
        key={getKey('section')}
        section={section}
        state={state}
        localDispatch={localDispatch}
        requiredFieldsError={requiredFieldsError}
        currentUserRole={currentUserRole}
        setModalCategory={setModalCategory}
        setSection={setSection}
        savePacketForm={savePacketForm}
        renderAllSections={renderAllSections}
        isOpen={isOpen[index]}
        handleToggleOpen={handleToggleOpen}
        index={index}
        setStateChangeDetected={setStateChangeDetected}
        setSuggestedValues={setSuggestedValues}
        handleChange={handleChange}
        fieldId={fieldId}
        setFieldId={setFieldId}
        suggestedValues={suggestedValues}
      />
    );
  };

  return (
    <>
      <div className="packet-form__collapse-container">
        <p>
          <span onClick={handleCollapseAllClick}>Collapse All</span>
          <span> | </span>
          <span onClick={handleExpandAllClick}>Expand All</span>
        </p>
      </div>
      {renderAllSections
        ? packetFormSections.map(renderSection)
        : [packetFormSections[currentSection - 1]].map(renderSection)}
    </>
  );
};
