import React from 'react';

export interface ProgressBarProps {
  /**
   * Current page number
   */
  currentPageNumber?: number;
  /**
   * Total number of pages
   */
  totalPages?: number;
  /**
   * Percentage completed
   */
  percentCompleted?: string;
  /**
   * width in numbers only
   */
  width?: number;
  /**
   * isWidthPercentage in boolean only
   */
   isWidthPercentage?: boolean;
  /**
   * Completed bar color
   */
  completedColor?: string;
  /**
   * Total bar color
   */
  totalColor?: string;
  /**
   * Size small or large
   */
  size?: string;

  row?: boolean;

  section?: string;
}

/**
 * UI progress bar component
 */
export const ProgressBar: React.FC<ProgressBarProps> = ({
  percentCompleted,
  currentPageNumber,
  totalPages,
  width,
  isWidthPercentage,
  size,
  completedColor,
  totalColor,
  row,
  section
}) => {
  const calculatePercentage = () => {

    return isWidthPercentage ? `${(currentPageNumber / totalPages) * width}%` : `${(currentPageNumber / totalPages) * (width || 325)}px`;
  };

  const addLeadingZero = (number: number) => {
    return number < 10 ? `0${number}` : number;
  };

  const calculatePixels = () => {
    return `${
      (parseInt(percentCompleted) / 100) *
      (parseInt(percentCompleted) <= 0 ? 0 : width)
    }px`;
  };

  return (
    <div className={`progress-bar${row ? '__row' : ''} ${size === 'small' ? 'small' : 'large'}`}>
      {currentPageNumber >= 0 && (
        <p className="progress-bar__page-progress">
          {section && <span className="progress-bar__page-section">{section}</span>}
          <span className="progress-bar__page-progress--current-page">
            {addLeadingZero(currentPageNumber)}
          </span>
          /{addLeadingZero(totalPages)}
        </p>
      )}
      <div
        className="progress-bar__progress--total"
        style={{
          width: isWidthPercentage ? `${width}%` : width,
          borderColor: totalColor,
        }}>
        <div
          className="progress-bar__progress--completed"
          style={{
            width: percentCompleted ? calculatePixels() : calculatePercentage(),
            background: completedColor,
          }}></div>
      </div>
      {parseInt(percentCompleted) >= 0 && (
        <p className="progress-bar__progress--percentage">
          {parseInt(percentCompleted).toFixed()}
        </p>
      )}
    </div>
  );
};
