import React from 'react';

import { CustomToastProps } from '@/components/ui/toast';

export const SuccessToast: React.FC<CustomToastProps> = ({
  message
}) => {
  return(
    <div className="toast-card">
      <div className="toast-sidebar__success" />
      <div className="toast-icon toast-icon__success" />
      <div className="toast-content">
        <div className="toast-content__header toast-content__header__success">Success</div>
        <div className="toast-content__message">{message}</div>
      </div>
    </div>
  );
};
