import { call, put, takeLatest } from 'redux-saga/effects';
import jwt_decode from 'jwt-decode';


import { DiscoveredDataActions, GetDiscoveredData, getDiscoveredDataSuccess, getDiscoveredDataFailure } from '@/actions/actions/discovered_data';
import Api from '@/api/Api';

export function* getDiscoveredDataSaga(
  _actions: GetDiscoveredData
) {
  try {
    let decoded = jwt_decode(localStorage.getItem('bearer_token'));
    let userId = decoded['sub'];
    const url = `/api/v1/users/${userId}/discovereddata`;

    const response = yield call(Api.utility.get, url);
    yield put(getDiscoveredDataSuccess(response.data));
  } catch (err) {
    yield put(getDiscoveredDataFailure(err));
  }
}

export function* watchGetDiscoveredData() {
  yield takeLatest(DiscoveredDataActions.GET_USER_DISCOVERED_DATA, getDiscoveredDataSaga);
}
