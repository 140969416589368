import React, { useState, useEffect } from 'react';

import { Drawer } from '@/components/ui';
import { PacketFormSummary } from '@/contexts/packets-page/form-wizard/packet-form-panel/packet-form-bundle-summary';
import { throttle } from '@/utils/throttle';

export interface PacketFormBundleDrawerProps {
  packetFormBundles: any;
  packetId: number;
  onClick: (a, b, c) => void;
}

export const PacketFormBundleDrawer: React.FC<PacketFormBundleDrawerProps> = ({
  packetFormBundles,
  onClick,
  packetId,
}) => {
  const [throttled, setThrottled] = useState<boolean>(false);
  let keyCount = 0;

  const getKey = (prefix) => {
    keyCount = keyCount += 1;
    return `${prefix}-${keyCount}`;
  };

  useEffect(() => {
    if (throttled) {
      document.body.style.cursor = 'wait';
    } else if (!throttled) {
      document.body.style.cursor = 'default';
    }
    return () => {
      document.body.style.cursor = 'default';
    };
  }, [throttled]);

  return (
    <div className="packet-form-panel__bundle-drawer">
      {packetFormBundles.map((bundle, index) => {
        return (
          <div key={getKey('packet')}>
            <Drawer
              open={index === 0 ? true : false}
              label={
                bundle.packetFormBundleName.length > 16
                  ? `${bundle.packetFormBundleName.slice(0, 16)}...`
                  : bundle.packetFormBundleName
              }
              id={index.toString()}>
              {bundle.packetForms.map((form) => {
                return (
                  <div key={getKey('packet')}>
                    <PacketFormSummary
                      throttled={throttled}
                      formName={form.formSummary.formName}
                      percentCompleted={form.formSummary.percentCompleted}
                      pageCount={form.formSummary.pageCount}
                      onClick={() => {
                        throttle(
                          onClick(
                            packetId,
                            bundle.packetFormBundleId,
                            form.packetFormId,
                          ),
                          1250,
                        );
                        setThrottled(true);
                        setTimeout(() => {
                          setThrottled(false);
                        }, 1250);
                      }}
                    />
                  </div>
                );
              })}
            </Drawer>
          </div>
        );
      })}
    </div>
  );
};
