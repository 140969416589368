import React from 'react';

import { DropdownSelect } from '@/components/ui/dropdown-select';
import { Icon } from '@/components/ui';
import { TextField } from '@/components/ui/text-field';
import { ApprovalPath, Stop as StopType } from '@/types/approval_path';
import { Button } from '@/components/ui/button';
import { Field, PacketForm } from '@/types/packet_form';
import classNames from 'classnames';
import StopUser from './stop-user';

interface Props {
  allFields: Field[];
  approvalPath: ApprovalPath;
  approvalTimelineOptions: any[];
  selectedStopIndex: number;
  setApprovalPath: (approvalPath: ApprovalPath) => void;
  setSelectedStopIndex: (index: number) => void;
  stop: StopType;
  stopIndex: number;
  userOptions: any[];
  packetForms: PacketForm[];
  hasStopError: boolean;
}

const Stop = (props: Props) => {
  const {
    allFields,
    approvalPath,
    approvalTimelineOptions,
    selectedStopIndex,
    setApprovalPath,
    setSelectedStopIndex,
    stop,
    stopIndex,
    userOptions,
    packetForms,
    hasStopError,
  } = props;

  return (
    <div
      className="create-new__stop"
      onClick={(e) => {
        setSelectedStopIndex(stopIndex);
      }}>
      <div>
        <span className="create-new__stop-number">{stopIndex + 1}</span>
      </div>
      <div
        className={classNames('create-new__stop-main', {
          'is-selected': stopIndex === selectedStopIndex,
        })}>
        {hasStopError && !stop.approvalStopDetail.users[0].userId && (
          <p style={{ color: 'red' }}>Stop must have at least one approver.</p>
        )}
        {stopIndex > 0 && (
          <div className="create-new__stop-x">
            <Icon
              iconClass="x"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedStopIndex(0);

                let _approvalPath = { ...approvalPath };

                _approvalPath.stops.splice(stopIndex, 1);
                _approvalPath.stops.forEach((stop, stopIndex) => {
                  stop.sequenceNumber = stopIndex + 1;
                });

                setApprovalPath(_approvalPath);
              }}
              size="15px"
              color="#000"
            />
          </div>
        )}
        <div className="input-container">
          <label
            className="input-container__label"
            htmlFor={`stop__${stopIndex}`}>
            Stop Name
          </label>
          <input
            // label="Stop Name"
            name={`stop__${stopIndex}`}
            onChange={(e) => {
              let _approvalPath = { ...approvalPath };
              _approvalPath.stops[stopIndex].displayName = e.target.value;
              setApprovalPath(_approvalPath);
            }}
            value={approvalPath.stops[stopIndex].displayName}
            className="input-container__input--text-field"
          />
        </div>

        {stop.approvalStopDetail.users.map((user, userIndex) => {
          return (
            <StopUser
              userOptions={userOptions}
              approvalPath={approvalPath}
              stopIndex={stopIndex}
              setApprovalPath={setApprovalPath}
              userIndex={userIndex}
            />
          );
        })}

        <div>
          <Button
            className="solid"
            label="add approver"
            primary={false}
            size="small"
            onClick={() => {
              let _approvalPath = { ...approvalPath };
              _approvalPath.stops[stopIndex].approvalStopDetail.users.push({
                userId: null,
                approvalStatusId: null,
              });
              setApprovalPath(_approvalPath);
            }}
            labelColor="#fff"
          />
        </div>

        <div>
          <p>Approval Fields</p>

          {stop.approvalStopDetail.requiredForms.map(
            (requiredForm, requiredFormIndex) => {
              const form = packetForms.find(
                (packetForm) => packetForm.formId === requiredForm.formId,
              );

              return (
                <div>
                  {!!requiredForm.requiredFieldIds.length && (
                    <p>{form.formSummary.formName}</p>
                  )}

                  {requiredForm.requiredFieldIds.map(
                    (requiredFieldId, requiredFieldIdIndex) => {
                      const field = allFields.find(
                        (f) => f.id === requiredFieldId.fieldId,
                      );

                      return (
                        <div className="create-new__stop-field">
                          <span className="stop-field-label">
                            {field.label}
                          </span>
                          <Icon
                            iconClass="x-enclosed"
                            onClick={() => {
                              const _approvalPath = {
                                ...approvalPath,
                              };
                              _approvalPath.stops[
                                stopIndex
                              ].approvalStopDetail.requiredForms[
                                requiredFormIndex
                              ].requiredFieldIds.splice(
                                requiredFieldIdIndex,
                                1,
                              );
                              setApprovalPath(_approvalPath);
                            }}
                            size="1.1rem"
                          />
                        </div>
                      );
                    },
                  )}
                </div>
              );
            },
          )}
        </div>

        <div>
          <DropdownSelect
            label="Select Approval Timing"
            options={approvalTimelineOptions}
            nullOptionLabel="Select approval timing..."
            selectedValue={approvalPath.stops[stopIndex].approvalTimelineId}
            onClick={(e) => {
              const _approvalPath = { ...approvalPath };
              _approvalPath.stops[stopIndex].approvalTimelineId = e;
              setApprovalPath(_approvalPath);
            }}
            disabled={false}
          />
        </div>
      </div>
    </div>
  );
};

export default Stop;
