import { PacketActions } from '@/actions/actions/packet';
import { PacketState } from '@/types/packet';

const initialState: PacketState = {
  packet: null,
};

export default function reducer(
  state: PacketState = initialState,
  action: any,
): PacketState {
  const { type, payload } = action;

  switch (type) {
    case PacketActions.UPDATE_LOCAL_PACKET:
      return {
        ...state,
        packet: payload.packet
      }
    case PacketActions.RESET_PACKET:
      const _state = JSON.parse(JSON.stringify(state));
      delete _state.packet; 
      return {
        ..._state,
      }
    case PacketActions.GET_PACKET_SUCCESS:
      return {
        ...state,
        packet: payload.packet
      }
    case PacketActions.UPDATE_PACKET_SUCCESS:
      return {
        ...state,
        packet: payload.packet
      }
    case PacketActions.CREATE_PACKET_SUCCESS:
      return {
        ...state,
        packet: payload.packet
      }
    default:
      return state;
  }
}
