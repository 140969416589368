import React, { useState, useEffect } from 'react';

import { PacketContents } from '@/contexts/create-packet-page/packet-contents';
import { PacketApprovers } from '@/contexts/create-packet-page/packet-approvers';
import { PacketDeadline } from '@/contexts/create-packet-page/packet-deadline';
import { User } from '@/types/user';
import { Option } from '@/types/option';

interface SectionThreeProps {
  state: any;
  localDispatch: React.Dispatch<any>;
  userIndex: any;
  userOptions: Option[];
  isPowerUser: boolean;
}

export const SectionThree: React.FC<SectionThreeProps> = ({
  state,
  localDispatch,
  userIndex,
  isPowerUser,
}) => {
  return (
    <div className="create-packet__section section-3 flex md-flex-col">
      <div className="create-packet__section--inner--left card">
        <PacketContents
          selectedPackets={state.selectedPackets}
          localDispatch={localDispatch}
          drawerOpen={true}
          state={state}
          isPowerUser={isPowerUser}
          userIndex={userIndex}
        />
      </div>
      <div className="create-packet__section--inner--right">
        <div className="create-packet__section--inner--right--top card">
          <h4 className="create-packet__section--sub-header approvers">
            packet approvers
          </h4>
          <PacketApprovers
            selectedApprovalPath={state.selectedApprovalPath}
            localDispatch={localDispatch}
            mode="view"
          />
        </div>
        <div className="card">
          <PacketDeadline
            selectedApprovalPath={state.selectedApprovalPath}
            approvalTimeline={state.approvalTimeline}
          />
        </div>
      </div>
    </div>
  );
};
