import { renderToast } from '@/components/ui/toast';
import { toast } from 'react-toastify';
import { takeLatest } from 'redux-saga/effects';

type MessageType = 'error' | 'notice' | 'success';
enum MessageActions {
  FLASH_MESSAGE = 'MESSAGES/FLASH_MESSAGE',
}

interface FlashMessage {
  type: MessageActions.FLASH_MESSAGE;
  payload: {
    message: string;
    type: MessageType;
  };
};

export const flashError = (message: string): FlashMessage => ({
  type: MessageActions.FLASH_MESSAGE,
  payload: {
    message,
    type: 'error',
  },
});

export const flashNotice = (message: string): FlashMessage => ({
  type: MessageActions.FLASH_MESSAGE,
  payload: {
    message,
    type: 'notice',
  },
});

export const flashSuccess = (message: string): FlashMessage => ({
  type: MessageActions.FLASH_MESSAGE,
  payload: {
    message,
    type: 'success',
  },
});

export function* flashMessageSaga(action: FlashMessage) {
  const { payload } = action;
  const { message, type } = payload;

  const toastOptions = {
    hideProgressBar: true,
  };

  toast(renderToast(message, type), toastOptions);
};

export default function* messageSaga() {
  yield takeLatest(MessageActions.FLASH_MESSAGE, flashMessageSaga);
};
