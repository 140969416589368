import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectPacketForm } from '@/selectors/packet_form';
import { FileUploadButton } from '@/components/ui/file-upload-button';
import { deleteFile } from '@/actions/actions/file';
import { Loading } from '@/components/ui/loading';
import api from '@/api/Api';
import { Role } from '@/types/packet';

export interface FileUploadSectionProps {
  packetFormBundleId: string;
  packetFormId: string;
  currentUserRole: Role;
}

export const FileUploadSection: React.FC<FileUploadSectionProps> = ({
  currentUserRole,
  packetFormBundleId,
  packetFormId,
}) => {
  const dispatch = useDispatch();

  const [isDownloading, setIsDownloading] = useState<boolean>();

  const packetForm = useSelector(selectPacketForm);

  const handleClickRemove = (fileId: string) => {
    dispatch(deleteFile(fileId));
  };

  const handleFileClicked = async (file: any) => {
    const { fileId } = file;

    setIsDownloading(true);

    const FILE_HEADERS = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('bearer_token')}`,
        'Access-Control-Allow-Origin': '*',
      },
      responseType: 'blob',
    };

    const url = `${process.env.APPLICATION_URL}/api/v1/packets/files/${fileId}`;

    // @ts-ignore
    const response = await api.utility.get(url, FILE_HEADERS);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(response.data);
    link.download = file.fileName;
    link.click();
    link.remove();

    setIsDownloading(false);
  };

  return (
    <>
      {currentUserRole === 'Editor' && (
        <FileUploadButton
          packetFormBundleId={packetFormBundleId}
          packetFormId={packetFormId}
        />
      )}

      {isDownloading && (
        <Loading ringColor="#201f35" message="Downloading File..." />
      )}

      {packetForm.files.map((file) => {
        return (
          <div key={file.fileId}>
            <div
              className="file-upload__file-name"
              onClick={() => handleFileClicked(file)}>
              {file.fileName}
            </div>
            {currentUserRole === 'Editor' && (
              <div
                className="file-upload__remove-button"
                onClick={() => handleClickRemove(file.fileId)}>
                Remove
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};
