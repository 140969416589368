import { call, put, takeLatest } from 'redux-saga/effects';

import { FormBundlesActions, GetFormBundles, getFormBundlesFailure, getFormBundlesSuccess } from '@/actions/actions/form_bundles';
import Api from '@/api/Api';
import { buildPath, Routes } from '@/utils/routeUtils';

export function* getFormBundlesSaga(
  action: GetFormBundles
) {
  try {

    const url = buildPath(Routes.api.formbundles, null)

    const response = yield call(Api.utility.get, url);

    yield put(getFormBundlesSuccess(response.data));
  } catch (err) {
    yield put(getFormBundlesFailure(err));
  }
}

export function* watchGetFormBundles() {
  yield takeLatest(FormBundlesActions.GET_FORM_BUNDLES, getFormBundlesSaga);
}
