import React, { useState, useEffect, useContext } from 'react';
import { ReqFieldsContext } from '@/contexts/packets-page/form-wizard/packet-form/req-fields';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Packet } from '@/types/packet';
import { Button, ConfirmationModal, Loading } from '@/components/ui';
import { approvePacket } from '@/actions/actions/packet';
import { updatePacketForm } from '@/actions/actions/packet_form';
import { selectPacketForm } from '@/selectors/packet_form';

interface ParamTypes {
  packetId: string;
}

export interface ApproverPacketActionsProps {
  packetData: Packet;
}

export const ApproverPacketActions: React.FC<ApproverPacketActionsProps> = ({
  packetData,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [confirmationResponse, setConfirmationResponse] = useState<boolean>(
    null,
  );
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const packetForm = useSelector(selectPacketForm);
  //@ts-ignore
  const [ReqFields, setReqFields] = useContext(ReqFieldsContext);
  const [canApprove, setCanApprove] = useState<boolean>(false);
  const { packetId } = useParams<ParamTypes>();

  useEffect(() => {
    confirmationResponse && handleFormSubmit();
  }, [confirmationResponse]);

  const handleFormSubmit = () => {
    const approvalAction = {
      action: 'Approve',
      comment: comment,
    };

    dispatch(updatePacketForm(
      Number(packetId),
      packetForm?.packetFormBundleId,
      packetForm?.formId,
      packetForm,
      false
    ));

    dispatch(approvePacket(approvalAction, parseInt(packetId)));
    setSubmitted(true);
    setTimeout(() => {
      history.push('/packets/team-packets/approval-needed');
    }, 500);
  };

  const checkFields = () => {
    let _requiredFieldIds = localStorage.getItem('reqItems');
    console.log(_requiredFieldIds);
    if (parseInt(_requiredFieldIds) === 0) setConfirmationModal(true);
    else setErrorModal(true);
  }

  return (
    <>
      {packetData && (
        <>
          {/* for later use when the ability to print is added */}
          {/* <Button primary={true} size="medium" label="print forms" /> */}
          <Button
            className="md-width-100 md-margin-btm-20"
            primary={true}
            size="medium"
            label="reject packet"
            onClick={() =>
              history.push(`/packets/${packetData.packetId}/reject-or-deny`)
            }
          />
          <Button
            className="md-width-100"
            primary={true}
            size="medium"
            label="approve packet"
            onClick={() => checkFields()}
          />
        </>
      )}
      {submitted && <Loading message="Approving Packet" />}
      {confirmationModal && (
        <ConfirmationModal
          yesButtonLabel="Approve Packet"
          header="Approve Packet"
          prompt="Are you sure?"
          noButtonLabel="no"
          hasCommentSection
          handleTextInput={(value) => setComment(value)}
          textValue={comment}
          handleResponse={(res) => {
            setConfirmationResponse(res);
            setConfirmationModal(false);
          }}
          closeModal={() => {
            setConfirmationModal(false);
          }}
        />
      )}
      {errorModal && (
        <ConfirmationModal
          header="ERROR"
          prompt="Please fill out all required fields before approving."
          yesButtonLabel="Ok"
          handleResponse={() => setErrorModal(false)}
          closeModal={() => {
            setErrorModal(false);
          }}
          />
      )}
    </>
  );
};
