import React, { useState, useEffect } from 'react';

import { Icon, ProgressBar } from '@/components/ui';

export interface PacketFormSummaryProps {
  formName: string;
  percentCompleted: number;
  pageCount: number;
  onClick: () => void;
  throttled: boolean;
}

/**
 * Packet Form summary for Packet Form Panel
 */
export const PacketFormSummary: React.FC<PacketFormSummaryProps> = ({
  formName,
  percentCompleted,
  pageCount,
  onClick,
  throttled,
}) => {
  return (
    <div
      className={`packet-form-panel__packet hide-scrollbar ${
        throttled ? 'throttled' : ''
      }`}
      onClick={onClick}>
      <Icon
        iconClass={pageCount > 1 ? 'form--multi' : 'form'}
        className="packet-form-panel__packet--icon"
        size="3.3rem"
      />
      <h5 className="packet-form-panel__title form">{formName}</h5>
      <ProgressBar
        percentCompleted={percentCompleted.toString()}
        size="small"
        width={136}
      />
    </div>
  );
};
