import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DateTime } from 'luxon';

import { DropdownSelect } from '@/components/ui';
import { PacketApprovers } from '@/contexts/create-packet-page/packet-approvers';
import { selectApprovalPaths } from '@/selectors/approval_paths';
import { getApprovalPaths } from '@/actions/actions/approval_paths';
import { selectApprovalTimelines } from '@/selectors/approval_timelines';
import { getApprovalTimelines } from '@/actions/actions/approval_timelines';
import { Button } from '@/components/ui/button';
import { Modal } from '@/components/ui/modal';
import { TextField } from '@/components/ui/text-field';

export interface SectionTwoProps {
  state: any;
  localDispatch: React.Dispatch<any>;
}

export const SectionTwo: React.FC<SectionTwoProps> = ({
  state,
  localDispatch,
}) => {
  const dispatch = useDispatch();
  const [approvalPaths, setApprovalPaths] = useState([]);
  const [approvalTimelines, setApprovalTimelines] = useState(null);
  const [approvalTimelineOptions, setApprovalTimelineOptions] = useState(null);
  const [isShowingCreatePathModal, setIsShowingCreatePathModal] = useState<
    boolean
  >(false);
  const [newPathName, setNewPathName] = useState<string>('');

  const approvalPathsData = useSelector(selectApprovalPaths);
  const approvalTimelinesData = useSelector(selectApprovalTimelines);

  const canCreateNewApprovalPath: boolean = useMemo(
    () => state && state.selectedApprovalPath,
    [state],
  );

  useEffect(() => {
    dispatch(getApprovalPaths());
    dispatch(getApprovalTimelines());
  }, []);

  useEffect(() => {
    approvalPathsData &&
      setApprovalPaths(JSON.parse(JSON.stringify(approvalPathsData)));
  }, [approvalPathsData]);

  const buildApprovalPathOptions = (data) => {
    const sortedPaths = data.reduce(
      (paths, currentPath) => {
        let option = {
          value: currentPath.name,
          label: currentPath.name,
        };

        if (currentPath.approvalPathType === 1) {
          paths.defaultPaths = [...paths.defaultPaths, option];
        } else if (currentPath.approvalPathType === 2) {
          if (!paths?.userDefinedPaths) {
            paths['userDefinedPaths'] = [option];
          } else {
            paths.userDefinedPaths = [...paths?.userDefinedPaths, option];
          }
        }
        return paths;
      },
      { defaultPaths: [] },
    );

    const options = Object.entries(sortedPaths).map((entry) => {
      return entry.map((item: any) => {
        if (typeof item === 'string' && item === 'userDefinedPaths') {
          return { header: true, value: item, label: 'Your Approval Paths' };
        } else if (typeof item === 'string' && item === 'defaultPaths') {
          return {
            header: true,
            value: null,
            label: 'Predefined Approval Paths',
          };
        } else {
          return item;
        }
      });
    });
    //@ts-ignore
    return options.flat(2);
  };

  useEffect(() => {
    approvalTimelinesData && setApprovalTimelines(approvalTimelinesData);
  }, [approvalTimelinesData]);

  useEffect(() => {
    approvalTimelines && buildApprovalTimelinesOptions();
  }, [approvalTimelines]);

  const buildApprovalTimelinesOptions = () => {
    const options = approvalTimelines.map((timeline) => {
      return {
        value: JSON.stringify({
          approvalTimelineId: timeline.approvalTimelineId,
          hourOffset: timeline.hourOffset,
        }),
        label: timeline.name,
        header: false,
      };
    });
    setApprovalTimelineOptions(options);
  };

  const setApprovalPathChoiceHelper = (choice) => {
    let matchedChoice = null;

    if (choice) {
      matchedChoice = JSON.parse(JSON.stringify(approvalPaths)).filter(
        (path) => {
          if (path.name === choice) return path;
        },
      );
    }

    if (matchedChoice) {
      for (let [key, value] of Object.entries(matchedChoice[0])) {
        localDispatch({
          type: 'SELECTED_APPROVAL_PATH',
          key: key,
          value: value,
        });
      }
    } else {
      let _state = state;

      delete _state?.selectedApprovalPath;
      localDispatch({
        type: 'RESET_APPROVAL_PATH',
        value: _state,
      });
    }
  };

  const updateApprovalTimelines = (value) => {
    localDispatch({
      type: 'SELECTED_APPROVAL_TIMELINE',
      value: JSON.parse(value),
    });
  };

  const calculateDeadline = (hours, stops) => {
    const formattedDate = DateTime.fromJSDate(new Date())
      .plus({
        hours: hours * stops,
      })
      .toLocaleString(DateTime.DATE_FULL);
    return (
      <p className="create-packet__date-estimate--date">{formattedDate}</p>
    );
  };

  const handleCloseNewPathNameModal = () => {
    setIsShowingCreatePathModal(false);
    setNewPathName('');
  };

  const handleSaveNewPathName = () => {
    localDispatch({
      type: 'UPDATE_APPROVAL_PATH_NAME',
      value: newPathName,
    });
    handleCloseNewPathNameModal();
  };

  return (
    <>
      <div
        className="create-packet__section section-2 flex"
        style={{ marginTop: '0' }}>
        <div className="create-packet__section--left section-2__section card">
          <h3 className="create-packet__section--header">
            Assign approvers for packet
          </h3>
          <div className="create-packet__step">3</div>
          <p>Select Approval Path Template</p>
          <DropdownSelect
            label="Select Path"
            options={approvalPaths && buildApprovalPathOptions(approvalPaths)}
            placeholder="Please select an approval path..."
            selectedValue={state?.selectedApprovalPath?.name || null}
            onClick={(choice) => {
              setApprovalPathChoiceHelper(choice);
            }}
            typeAhead={false}
            renderSelectedOption={true}
          />
          <h4 className="create-packet__section--sub-header approvers">
            packet approvers
          </h4>
          <div className="create-packet__section--appover-sequences">
            <PacketApprovers
              selectedApprovalPath={state.selectedApprovalPath || {}}
              localDispatch={localDispatch}
              mode="edit"
            />
          </div>

          {canCreateNewApprovalPath && (
            <div className="create-packet__new-path-container">
              <Button
                label="create as new path"
                size="large"
                border
                borderColor="#000"
                primary
                onClick={() => setIsShowingCreatePathModal(true)}
              />
            </div>
          )}
        </div>
        <div className="create-packet__section--right section-2__section card">
          <h3 className="create-packet__section--header">
            Set timeline for approval
          </h3>
          <div className="create-packet__step">4</div>
          <DropdownSelect
            label="Select Approval Timing"
            options={approvalTimelineOptions}
            nullOptionLabel="Select approval timing..."
            selectedValue={JSON.stringify(state?.approvalTimeline) || null}
            onClick={(e) => updateApprovalTimelines(e)}
            message="All approvers will receive the same amount of time to approve form(s) from the day they receive the packet."
            disabled={!state?.selectedApprovalPath}
          />
          {state?.approvalTimeline && state?.selectedApprovalPath && (
            <div className="create-packet__date-estimate">
              <h4 className="create-packet__section--sub-header">
                packet deadline
              </h4>
              <p className="create-packet__date-estimate--blurb">
                If sent today
              </p>
              {calculateDeadline(
                state.approvalTimeline.hourOffset,
                state.selectedApprovalPath.stops.length,
              )}
            </div>
          )}
        </div>
      </div>
      {isShowingCreatePathModal && (
        <Modal handleClickX={handleCloseNewPathNameModal}>
          <div className="create-packet__new-path-modal">
            <h1>Name New Approver Path</h1>

            <TextField
              label="Path Name"
              onChange={(value) => setNewPathName(value)}
              placeholder="Enter path name"
              defaultValue={newPathName}
            />

            <div className="create-packet__new-path-modal--action-container md-flex-dir-col-rev">
              <Button
                className="md-width-100"
                label="cancel"
                size="large"
                border
                borderColor="#000"
                primary
                onClick={handleCloseNewPathNameModal}
              />
              <Button
                className="solid md-margin-btm-15 md-width-100"
                label="okay"
                primary={false}
                size="large"
                onClick={handleSaveNewPathName}
                labelColor="#fff"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
