
import { call, put, takeLatest } from 'redux-saga/effects';

import { ApprovalTimelinesActions, GetApprovalTimelines, getApprovalTimelinesFailure, getApprovalTimelinesSuccess } from '@/actions/actions/approval_timelines';
import Api from '@/api/Api';
import { buildPath, Routes } from '@/utils/routeUtils';

export function* getApprovalTimelinesSaga(
  action: GetApprovalTimelines
) {
  try {

    const url = buildPath(Routes.api.approvalTimelines, null)

    const response = yield call(Api.utility.get, url);

    yield put(getApprovalTimelinesSuccess(response.data));
  } catch (err) {
    yield put(getApprovalTimelinesFailure(err));
  }
}

export function* watchGetApprovalTimelines() {
  yield takeLatest(ApprovalTimelinesActions.GET_APPROVAL_TIMELINES, getApprovalTimelinesSaga);
}