import React from 'react';

import { Icon } from '@/components/ui';

export interface IconProps {
  /**
   * Tooltip text
   */
  text: string;
  /**
   * Tooltip icon size
   */
  size?: string;
}

/**
 * Primary UI component for SVG Icons
 */
export const Tooltip: React.FC<IconProps> = ({ text, size = '1rem' }) => {
  return (
    <div className="tooltip">
      <Icon iconClass="tooltip" size={size} />
      <p className="tooltip__text">{text}</p>
    </div>
  );
};
