import React from 'react';
import { DateTime } from 'luxon';
import moment from 'moment';

export interface ValueLabelTableProps {
  /**
   * Array of objects containg data to be displayed
   */
  data?: Array<{ label: string; value: any; type: string }>;
  /**
   * Optional className value
   */
  className?: string;
}

/**
 * Component displays names and values of passed obeject
 */

export const ValueLabelTable: React.FC<ValueLabelTableProps> = ({
  data,
  className,
}) => {
  const parseData = (data) => {
    switch (data.type) {
      case 'string':
        return data.value;
      case 'checkbox':
        if(data.value && data.value != 'false') {
          return "Yes";
        } else {
          return "No";
        }
      case 'address':
        return data.value;
      case 'date':
        return moment(data.value).format('MM/DD/YYYY');
      default:
        break;
    }
  };

  return (
    <>
      <table className={`${className}`}>
        <tbody>
          {data &&
            data.map((data, key) => {
              return (
                <tr
                  className="info-card-table-row md-flex md-flex-col"
                  key={data.type + key}>
                  <td className="info-card-table-data info-card-label">
                    {data.label}
                  </td>
                  <td className="info-card-table-data fw-700">
                    {parseData(data)}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
};
