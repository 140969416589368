const states = [
  { "label": "Alabama","value": "Alabama"},
  { "label": "Alaska","value": "Alaska"},
  { "label": "Alberta","value": "Alberta"},
  { "label": "American Samoa","value": "American Samoa"},
  { "label": "Arizona","value": "Arizona"},
  { "label": "Arkansas","value": "Arkansas"},
  { "label": "Armed Forces (AE)","value": "Armed Forces (AE)"},
  { "label": "Armed Forces Americas","value": "Armed Forces Americas"},
  { "label": "Armed Forces Pacific","value": "Armed Forces Pacific"},
  { "label": "British Columbia","value": "British Columbia"},
  { "label": "California","value": "California"},
  { "label": "Colorado","value": "Colorado"},
  { "label": "Connecticut","value": "Connecticut"},
  { "label": "Delaware","value": "Delaware"},
  { "label": "District Of Columbia","value": "District Of Columbia"},
  { "label": "Florida","value": "Florida"},
  { "label": "Georgia","value": "Georgia"},
  { "label": "Guam","value": "Guam"},
  { "label": "Hawaii","value": "Hawaii"},
  { "label": "Idaho","value": "Idaho"},
  { "label": "Illinois","value": "Illinois"},
  { "label": "Indiana","value": "Indiana"},
  { "label": "Iowa","value": "Iowa"},
  { "label": "Kansas","value": "Kansas"},
  { "label": "Kentucky","value": "Kentucky"},
  { "label": "Louisiana","value": "Louisiana"},
  { "label": "Maine","value": "Maine"},
  { "label": "Manitoba","value": "Manitoba"},
  { "label": "Maryland","value": "Maryland"},
  { "label": "Massachusetts","value": "Massachusetts"},
  { "label": "Michigan","value": "Michigan"},
  { "label": "Minnesota","value": "Minnesota"},
  { "label": "Mississippi","value": "Mississippi"},
  { "label": "Missouri","value": "Missouri"},
  { "label": "Montana","value": "Montana"},
  { "label": "Nebraska","value": "Nebraska"},
  { "label": "Nevada","value": "Nevada"},
  { "label": "New Brunswick","value": "New Brunswick"},
  { "label": "New Hampshire","value": "New Hampshire"},
  { "label": "New Jersey","value": "New Jersey"},
  { "label": "New Mexico","value": "New Mexico"},
  { "label": "New York","value": "New York"},
  { "label": "Newfoundland","value": "Newfoundland"},
  { "label": "North Carolina","value": "North Carolina"},
  { "label": "North Dakota","value": "North Dakota"},
  { "label": "Northwest Territories","value": "Northwest Territories"},
  { "label": "Nova Scotia","value": "Nova Scotia"},
  { "label": "Nunavut","value": "Nunavut"},
  { "label": "Ohio","value": "Ohio"},
  { "label": "Oklahoma","value": "Oklahoma"},
  { "label": "Ontario","value": "Ontario"},
  { "label": "Oregon","value": "Oregon"},
  { "label": "Pennsylvania","value": "Pennsylvania"},
  { "label": "Prince Edward Island","value": "Prince Edward Island"},
  { "label": "Puerto Rico","value": "Puerto Rico"},
  { "label": "Quebec","value": "Quebec"},
  { "label": "Rhode Island","value": "Rhode Island"},
  { "label": "Saskatchewan","value": "Saskatchewan"},
  { "label": "South Carolina","value": "South Carolina"},
  { "label": "South Dakota","value": "South Dakota"},
  { "label": "Tennessee","value": "Tennessee"},
  { "label": "Texas","value": "Texas"},
  { "label": "Utah","value": "Utah"},
  { "label": "Vermont","value": "Vermont"},
  { "label": "Virgin Islands","value": "Virgin Islands"},
  { "label": "Virginia","value": "Virginia"},
  { "label": "Washington","value": "Washington"},
  { "label": "West Virginia","value": "West Virginia"},
  { "label": "Wisconsin","value": "Wisconsin"},
  { "label": "Wyoming","value": "Wyoming"},
  { "label": "Yukon Territory","value": "Yukon Territory"}
]

module.exports = states
