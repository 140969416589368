import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

import { Button, InfoCard, Modal, Loading } from '@/components/ui';
import { getUserProfile, updateUserProfile } from '@/actions/actions/user';
import { ProfileEditForm } from '@/contexts/profile-page/profile-edit-form';
import { ProfileHeader } from '@/contexts/profile-page/profile-header';
import { AdditionalDataForm } from '@/contexts/profile-page/additional-data-form';
import { logoutUser } from '@/utils/oauth';
import { selectDeviceType } from '@/selectors/device_type';
import { getDiscoveredData, updateDiscoveredData } from '@/actions/actions/discovered_data';

export const ProfilePage = (): JSX.Element => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootStateOrAny) => {
    return state.user.user;
  });
  const discoveredUserData = useSelector((state: RootStateOrAny) => {
    return state.discoveredData.discoveredData;
  });

  const [resetPassword, setResetPassword] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [toggleAdditionalDetails, setToggleAdditionalDetails] = useState(true);

  const deviceType = useSelector(selectDeviceType);

  const showGeneral = () => {
    return !toggleAdditionalDetails && setToggleAdditionalDetails(!toggleAdditionalDetails);
  }

  const showAdditional = () => {
    return toggleAdditionalDetails && setToggleAdditionalDetails(!toggleAdditionalDetails);
  }

  useEffect(() => {
    dispatch(getUserProfile());

    if (discoveredUserData === null) {
      dispatch(getDiscoveredData());
    }

  }, []);

  const toggleResetPassword = () => {
    setResetPassword(!resetPassword);
  };

  const toggleShowEdit = () => {
    setShowEdit(!showEdit);
  };

  const parseUserData = () => {
    return [
      {
        label: 'First Name',
        value: user.firstName,
        type: 'string',
        name: 'firstName',
      },
      {
        label: 'Last Name',
        value: user.lastName,
        type: 'string',
        name: 'lastName',
      },
      {
        label: 'Date of Birth',
        value: user.dateOfBirth || 'n/a',
        type: 'date',
        name: 'dateOfBirth',
      },
      {
        label: 'Social Security Number',
        value: user.socialSecurityNumber || 'n/a',
        type: 'string',
        name: 'socialSecurityNumber',
      },
      {
        label: 'Email Address',
        value: user.email || 'n/a',
        type: 'string',
        name: 'email',
      },
      {
        label: 'Address Line 1',
        value: user.address1 || 'n/a',
        type: 'address',
        name: 'address1',
      },
      {
        label: 'Address Line 2',
        value: user.address2 || 'n/a',
        type: 'address',
        name: 'address2',
      },
      {
        label: 'City',
        value: user.city || 'n/a',
        type: 'address',
        name: 'city',
      },
      {
        label: 'State',
        value: user.state || 'n/a',
        type: 'address',
        name: 'state',
      },
      {
        label: 'Zip Code',
        value: user.zip || 'n/a',
        type: 'address',
        name: 'zip',
      },
      {
        label: 'Country',
        value: user.country || 'n/a',
        type: 'address',
        name: 'country',
      },
      {
        label: 'Unit',
        value: user.unit || 'n/a',
        type: 'string',
        name: 'unit',
      },
      {
        label: 'Duty Phone',
        value: user.dutyPhone || 'n/a',
        type: 'string',
        name: 'dutyPhone',
      },
      {
        label: 'Organization',
        value: user.organization || 'n/a',
        type: 'string',
        name: 'organization',
      },
      {
        label: 'AFSC',
        value: user.afsc || 'n/a',
        type: 'string',
        name: 'afsc',
      },
      {
        label: 'Grade',
        value: user.grade || 'n/a',
        type: 'string',
        name: 'grade',
      },
      {
        label: 'Supervisor',
        value: user.supervisor|| 'n/a',
        type: 'string',
        name: 'supervisor',
      },
      {
        label: 'Office',
        value: user.office|| 'n/a',
        type: 'string',
        name: 'office',
      },
      {
        label: 'Email Opt Out',
        value: user.emailOptOut || "false",
        type: 'checkbox',
        name: 'emailOptOut',
      },
    ];
  };

  const parseApprovedDiscoveredData = () => {
    let approvedData = [];
    discoveredUserData.map((data, idx) => {
      if (data.status === 'A') {
        approvedData.push({
          id: idx,
          label: data.key,
          value: data.value || 'n/a',
          type: 'string',
          name: data.key,
        })
      }
    })
    return approvedData.length > 0 ? approvedData : null;
  }

  const styles = {
    marginLeft: '45%',
    marginTop: '5%',
    width: '100%',
  };
  if (user === null) {
    return (
      <div style={styles}>
        <Loading
          height="50px"
          width="50px"
          positionRelative={true}
          ringColor="var(--secondary1)"
        />
      </div>
    );
  }

  return (
    <div className="profile-page bg-blue-gradient page__container">
      <div className="container is-widescreen columns is-vcentered">
        <ProfileHeader currentPage="profile" />
      </div>
      <div className="page__header container">
        <h1>Profile</h1>
      </div>
      <div className="page__sub--header container">
        <p>
          Profile information will be saved back to your employee personnel
          file and used to populate form responses.
        </p>
      </div>
      <InfoCard
        headerLinks={["General Details", "Additional Details"]}
        data={parseUserData()}
        discoveredData={discoveredUserData && parseApprovedDiscoveredData()}
        edit={true}
        onClick={toggleShowEdit}
        toggleData={toggleAdditionalDetails}
        showGeneral={showGeneral}
        showAdditional={showAdditional}
      />
      <div className="button-container button-container--bottom container column is-12">
        <Button
          primary={true}
          label="reset password"
          labelColor="var(--primary)"
          size="large"
          onClick={toggleResetPassword}
        />
        {deviceType === 'mobile' && (
          <Button
            className="profile-header-button"
            primary={false}
            size="large"
            label="log out"
            labelColor="#fff"
            onClick={() => logoutUser()}
          />
        )}
        {
          showEdit && (
            <>
              {
                toggleAdditionalDetails
                  ?
                  <Modal header="edit profile">
                    <ProfileEditForm
                      data={parseUserData()}
                      toggleModal={toggleShowEdit}
                      handleFormSubmit={updateUserProfile}
                      formType="edit-user-profile"
                    />
                  </Modal>
                  :
                  <Modal header="additional details">
                    <AdditionalDataForm
                      data={parseApprovedDiscoveredData()}
                      toggleModal={toggleShowEdit}
                      handleFormSubmit={updateDiscoveredData}
                      formType="edit-additional-data"
                    />
                  </Modal>
              }
            </>
          )}
        {resetPassword && (
          <Modal header="reset password">
            <ProfileEditForm
              data={user}
              toggleModal={toggleResetPassword}
              // --- commented out for future reset password action
              // handleFormSubmit={updateUserPassword}
              handleFormSubmit={() => {
                return null;
              }}
              formType="reset-password"
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default ProfilePage;
