
import { call, put, takeLatest } from 'redux-saga/effects';

import { ApprovalPathsActions, GetApprovalPaths, getApprovalPathsFailure, getApprovalPathsSuccess } from '@/actions/actions/approval_paths';
import Api from '@/api/Api';
import { buildPath, Routes } from '@/utils/routeUtils';

export function* getApprovalPathsSaga(
  action: GetApprovalPaths
) {
  try {

    const url = buildPath(Routes.api.approvalPaths, null)

    const response = yield call(Api.utility.get, url);

    yield put(getApprovalPathsSuccess(response.data));
  } catch (err) {
    yield put(getApprovalPathsFailure(err));
  }
}

export function* watchGetApprovalPaths() {
  yield takeLatest(ApprovalPathsActions.GET_APPROVAL_PATHS, getApprovalPathsSaga);
}