import { call, put, takeLatest } from 'redux-saga/effects';
import jwt_decode from 'jwt-decode';

import { UserActions, getUserFailure, getUserSuccess, UpdateUserProfile } from '@/actions/actions/user';
import Api from '@/api/Api';
import { flashError, flashSuccess } from '../messageSaga';


export function* updateUserProfileSaga(
  _action: UpdateUserProfile,
) {
  try {
    let decoded = jwt_decode(localStorage.getItem('bearer_token'));
    let userId = decoded['sub'];
    const url = `/api/v1/users/${userId}`;
    const userInfo = _action.payload.user;

    const response = yield call(Api.utility.put, url, userInfo);

    yield put(flashSuccess('Profile successfully saved'));
    yield put(getUserSuccess(response.data));
  } catch (err) {
    yield put(flashError('Could not save changes to Profile'));
    yield put(getUserFailure(err));
  }
}

export function* watchUpdateUserProfile() {
  yield takeLatest(UserActions.UPDATE_USER_PROFILE, updateUserProfileSaga);
}
