import moment from 'moment';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';

import { Field, Form } from '@/types/packet_form';
import { ImageDimensions, Location } from '..';
import { PacketForm as PacketFormType } from '@/types/packet_form';
import { SignatureField } from '@/components/ui/signature-field';
import { Checkbox } from '@/components/ui/checkbox';

interface Props {
  allFields: Field[];
  handleCanvasClick: (location: Location) => void;
  imageDimensions: ImageDimensions;
  offsetPercentage: number;
  imageRef: React.MutableRefObject<HTMLImageElement>;
  handleChange: any;
  state: any;
  savePacketForm: (packetForm?: PacketFormType, autosave?: boolean) => void;
  packetFormSections: any;
  requiredFieldsError: boolean;
}

interface CanvasDimensions {
  width: number;
  height: number;
}

const CustomInput = forwardRef(
  (
    {
      value,
      onClick,
      isInvalid,
      isDisabled,
      inputDimensions,
      scaleSizePercentage,
    }: any,
    ref,
  ) => {
    const backgroundColor = isDisabled ? 'transparent' : '#ddd';

    let borderStyles = isInvalid ? '3px solid red' : '1px solid black';

    if (isDisabled) {
      borderStyles = 'none';
    }

    return (
      <input
        onClick={onClick}
        value={value}
        onChange={() => {}}
        style={{
          backgroundColor: backgroundColor,
          border: borderStyles,
          width: inputDimensions.width,
          height: inputDimensions.height,
          fontSize: `${16 * scaleSizePercentage}px`,
        }}
      />
    );
  },
);

const PacketFormViewCanvas = (props: Props): JSX.Element => {
  const {
    allFields,
    handleCanvasClick,
    imageDimensions,
    offsetPercentage,
    imageRef,
    handleChange,
    state,
    savePacketForm,
    packetFormSections,
    requiredFieldsError,
  } = props;
  const canvasRef = useRef(null);

  const [canvasDimensions, setCanvasDimensions] = useState<CanvasDimensions>({
    width: imageDimensions?.width || 0,
    height: imageDimensions?.height || 0,
  });

  const [shouldUseFreeText, setShouldUseFreeText] = useState<boolean>(false);

  useEffect(() => {
    if (imageDimensions) {
      handleCanvasResize();
    }
  }, [imageDimensions]);

  const handleSizeOffset = (value: number): number => {
    return value * offsetPercentage;
  };

  const handleCanvasResize = () => {
    const canvas = document.getElementById('canvas');

    setCanvasDimensions({
      width: canvas.getBoundingClientRect().width,
      height: canvas.getBoundingClientRect().height,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleCanvasResize);
    return () => {
      window.removeEventListener('resize', handleCanvasResize);
    };
  }, []);

  useEffect(() => {
    //@ts-ignore
    CanvasRenderingContext2D.prototype.renderText = function (
      text,
      x,
      y,
      letterSpacing,
      fontSize,
    ) {
      if (!text || typeof text !== 'string' || text.length === 0) {
        return;
      }

      if (typeof letterSpacing === 'undefined') {
        letterSpacing = 0;
      }

      let characters = String.prototype.split.call(text, ''),
        index = 0,
        current,
        currentPosition = x,
        align = 1;

      if (this.textAlign === 'right') {
        characters = characters.reverse();
        align = -1;
      } else if (this.textAlign === 'center') {
        let totalWidth = 0;
        for (let i = 0; i < characters.length; i++) {
          totalWidth += this.measureText(characters[i]).width + letterSpacing;
        }
        currentPosition = x - totalWidth / 2;
      }

      while (index < text.length) {
        current = characters[index++];
        if (fontSize) {
          this.font = fontSize;
        }
        this.fillText(current, currentPosition, y);
        currentPosition +=
          align * (this.measureText(current).width + letterSpacing);
      }
    };
  }, []);

  const hasValidationError = (field): boolean => {
    if (
      state?.requiredFieldIds?.includes(field.id) &&
      state?.activeFieldIds?.includes(field.id)
    ) {
      return true;
    } else if (
      state?.requiredFieldIds?.includes(field.id) &&
      requiredFieldsError
    ) {
      return true;
    } else {
      return false;
    }
  };

  const renderInputs = () => {
    return packetFormSections.map((section, index) => {
      const pageIndex = section.pageNumber;
      const sectionIndex = section.sectionIndex;

      return section.fields.map((field, index) => {
        const locationReference = `_pages[${pageIndex}].sections[${sectionIndex}].fields[${index}].value = value`;
        const locationInState = `state.packetForm.form.pages[${pageIndex}].sections[${sectionIndex}].fields[${index}].value`;
        const value = eval(locationInState);

        const region = field.fieldValueRegion;

        const xPercentage =
          (handleSizeOffset(region.xCoordinate) / imageDimensions.width) * 100;
        const yPercentage =
          (handleSizeOffset(region.yCoordinate) / imageDimensions.height) * 100;

        const isInvalid: boolean = hasValidationError(field);
        let borderStyles: string = isInvalid
          ? '3px solid red'
          : '1px solid black';

        const baseStyles = {
          position: 'absolute',
          left: `${xPercentage}%`,
          top: `${yPercentage}%`,
        };

        const isFieldDisabled: boolean =
          field.rules && field.rules['InputState'] === 'Disabled';

        const backgroundColor = isFieldDisabled ? 'transparent' : '#ddd';

        if (isFieldDisabled) {
          borderStyles = 'none';
        }

        const calculateValue = () => {
          const inState = eval(locationInState);

          if (inState || inState === '') {
            return inState;
          } else if (inState === null) {
            if (field.defaultValue) {
              return field.defaultValue;
            } else if (field.defaultValue === null) {
              return '';
            }
          }
        };

        const scaleSizePercentage = () => {
          return canvasDimensions.width / imageDimensions.width;
        };

        switch (field.type.toLowerCase()) {
          case 'textfield':
            return (
              <input
                disabled={isFieldDisabled}
                title={field.helpText}
                value={calculateValue()}
                onChange={(e) =>
                  handleChange(
                    {
                      value: e.target.value,
                      locations: {
                        ref: locationReference,
                        state: locationInState,
                      },
                      field,
                    },
                    true,
                  )
                }
                //@ts-ignore
                style={{
                  ...baseStyles,
                  width: handleSizeOffset(region.width) * scaleSizePercentage(),
                  height:
                    handleSizeOffset(region.height) * scaleSizePercentage(),
                  fontSize: `${16 * scaleSizePercentage()}px`,
                  backgroundColor: backgroundColor,
                  border: borderStyles,
                }}
                onBlur={() => {
                  // savePacketForm(null, true);
                }}
              />
            );

          case 'signaturefield':
            return (
              <div
                //@ts-ignore
                style={{
                  ...baseStyles,
                }}>
                <SignatureField
                  disabled={isFieldDisabled}
                  className={field.id}
                  label=""
                  handleSubmit={(value) => {
                    handleChange({
                      value: value,
                      locations: {
                        ref: locationReference,
                        state: locationInState,
                      },
                      field,
                    });
                    // savePacketForm(null, true);
                  }}
                  inputDimensions={{
                    width:
                      handleSizeOffset(region.width) * scaleSizePercentage(),

                    height:
                      handleSizeOffset(region.height) * scaleSizePercentage(),
                  }}
                  isPdf
                  value={value}
                  isInvalid={isInvalid}
                />
              </div>
            );

          case 'datefield':
            const calculatedValue = calculateValue();
            if(
              value == null 
              && value != calculatedValue
              && 
                (calculatedValue != null 
                  && calculatedValue != '')
              ) 
            {
              handleChange({
                value: calculatedValue,
                locations: {
                  ref: locationReference,
                  state: locationInState,
                },
                field,
              });
            }
            
            return (
              <div
                // @ts-ignore
                style={{
                  ...baseStyles,
                  width: handleSizeOffset(region.width) * scaleSizePercentage(),
                  height:
                    handleSizeOffset(region.height) * scaleSizePercentage(),
                }}>
                <DatePicker
                  customInput={
                    <CustomInput
                      isDisabled={isFieldDisabled}
                      isInvalid={isInvalid}
                      inputDimensions={{
                        width:
                          handleSizeOffset(region.width) *
                          scaleSizePercentage(),
                        height:
                          handleSizeOffset(region.height) *
                          scaleSizePercentage(),
                      }}
                      scaleSizePercentage={scaleSizePercentage()}
                    />
                  }
                  onChange={(date: Date) => {
                    handleChange({
                      value: date,
                      locations: {
                        ref: locationReference,
                        state: locationInState,
                      },
                      field,
                    });

                    if (date === null) {
                      // savePacketForm(null, true);
                    }
                  }}
                  selected={value ? new Date(value) : null}
                  // onCalendarClose={() => savePacketForm(null, true)}
                  isClearable={!isFieldDisabled}
                  showYearDropdown
                  onBlur={() => console.log('on blur date picker fired')}
                />
              </div>
            );

          case 'dropdown':
            if (shouldUseFreeText || 
              (isFieldDisabled && 
              (
              field.value !== "Select" ||
              field.value !== "Member met all requirements for AFSC upgrade." ||
              field.value !== "Member has not met all requirements for AFSC upgrade."
              )
              )) {
              console.log(field.id)
              return <input
              disabled={isFieldDisabled}
              title={field.helpText}
              value={value}
              onChange={(e) =>
                handleChange(
                  {
                    value: e.target.value,
                    locations: {
                      ref: locationReference,
                      state: locationInState,
                    },
                    field,
                  },
                  true,
                )
              }
              //@ts-ignore
              style={{
                ...baseStyles,
                width: handleSizeOffset(region.width) * scaleSizePercentage(),
                height:
                  handleSizeOffset(region.height) * scaleSizePercentage(),
                fontSize: `${16 * scaleSizePercentage()}px`,
                backgroundColor: backgroundColor,
                border: borderStyles,
              }}
              onBlur={() => {
                // savePacketForm(null, true);
              }}
            />
            }
            else {
              console.log(field.id);
              return <select
                disabled={isFieldDisabled}
                value={value || field.selectOptions[0]}
                // @ts-ignore
                style={{
                  ...baseStyles,
                  width: handleSizeOffset(region.width) * scaleSizePercentage(),
                  height:
                    handleSizeOffset(region.height) * scaleSizePercentage(),
                  backgroundColor: backgroundColor,
                  border: borderStyles,
                }}
                onChange={(e) => {
                  handleChange({
                    value: e.target.value,
                    locations: {
                      ref: locationReference,
                      state: locationInState,
                    },
                    field,
                  });
                  if (e.target.value == 'SetShouldUseFreeText') {
                    setShouldUseFreeText(true);
                    handleChange({
                      value: '',
                      locations: {
                        ref: locationReference,
                        state: locationInState,
                      },
                      field,
                    });
                  }
                }}>
                <option value="Select" disabled>
                  Please select
                </option>
                {field.selectOptions.map((option, index) => {
                  return <option value={option}>{option}</option>;
                })}
                <option value="SetShouldUseFreeText">Enter alternative remarks...</option>

              </select>
            }

          

          case 'radiobuttonfield':
            const selectedValue = field.valueOptions.find(
              (option) => option.label === field.value,
            );

            return (
              <>
                {field.valueOptions.map((option, index) => {
                  const leftPercentage =
                    (handleSizeOffset(option.coordinates.xCoordinate) /
                      imageDimensions.width) *
                    100;

                  const topPercentage =
                    (handleSizeOffset(option.coordinates.yCoordinate) /
                      imageDimensions.height) *
                    100;

                  const isSelected: boolean = selectedValue === option;

                  let radioBackgroundColor = isSelected ? '#201f35' : '#ddd';

                  if (isFieldDisabled && !isSelected) {
                    radioBackgroundColor = 'transparent';
                  }

                  const baseRadioStyles = {
                    border: '1px solid black',
                    position: 'absolute',
                    left: `${leftPercentage}%`,
                    top: `${topPercentage}%`,
                    width:
                      handleSizeOffset(option.coordinates.width) *
                      scaleSizePercentage(),
                    height:
                      handleSizeOffset(option.coordinates.height) *
                      scaleSizePercentage(),
                  };

                  return (
                    <div
                      onClick={() => {
                        if (!isFieldDisabled) {
                          handleChange({
                            value: option.label,
                            locations: {
                              ref: locationReference,
                              state: locationInState,
                            },
                            field,
                          });
                        }
                      }}
                      //@ts-ignore
                      style={{
                        ...baseRadioStyles,
                        backgroundColor: radioBackgroundColor,
                        border: borderStyles,
                      }}
                    />
                  );
                })}
              </>
            );
          case 'checkboxfield':
            return (
              <div
                // @ts-ignore
                style={{
                  ...baseStyles,
                  width: `${
                    handleSizeOffset(region.width) * scaleSizePercentage()
                  }px`,
                  height: `${
                    handleSizeOffset(region.width) * scaleSizePercentage()
                  }px`,
                }}>
                <Checkbox
                  disabled={isFieldDisabled}
                  isPdf
                  size={`${
                    handleSizeOffset(region.width) * scaleSizePercentage()
                  }px`}
                  className={field.id}
                  label=""
                  value={value || 'false'}
                  message={field.helpText}
                  required={field.isRequired}
                  onClick={(value) =>
                    handleChange({
                      value: value,
                      locations: {
                        ref: locationReference,
                        state: locationInState,
                      },
                      field,
                    })
                  }
                />
              </div>
            );
        }
      });
    });
  };

  return (
    <div style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
      {imageDimensions && (
        <div
          style={{
            position: 'relative',
            margin: '0 auto 0 auto',
            width: 'calc(100% - 205px)',
            maxWidth: '1200px',
          }}>
          <canvas
            id="canvas"
            style={{
              backgroundImage: `url(${imageRef?.current?.src})`,
              backgroundSize: 'contain',
              width: '100%',
            }}
            ref={canvasRef}
            width={imageDimensions.width}
            height={imageDimensions.height}
            onClick={(e) => {
              const rect = canvasRef.current.getBoundingClientRect();

              handleCanvasClick({
                x: e.clientX - rect.left,
                y: e.clientY - rect.top,
              });
            }}
          />
          {renderInputs()}
        </div>
      )}
    </div>
  );
};

export default PacketFormViewCanvas;
