// Action Types
export enum PacketFormActions {
  GET_PACKET_FORM = 'PACKET_FORM/GET_PACKET_FORM',
  GET_PACKET_FORM_SUCCESS = 'PACKET_FORM/GET_PACKET_FORM_SUCCESS',
  GET_PACKET_FORM_FAILURE = 'PACKET_FORM/GET_PACKET_FORM_FAILURE',
  UPDATE_PACKET_FORM = 'PACKET_FORM/UPDATE_PACKET_FORM',
  UPDATE_PACKET_FORM_SUCCESS = 'PACKET_FORM/UPDATE_PACKET_FORM_SUCCESS',
  UPDATE_PACKET_FORM_FAILURE = 'PACKET_FORM/UPDATE_PACKET_FORM_FAILURE',
  RESET_PACKET_FORM = 'PACKET_FORM/RESET',
}

// Action Interfaces
export interface GetPacketForm {
  type: typeof PacketFormActions.GET_PACKET_FORM;
  payload: {
    packetId: number;
    packetFormBundleId: number;
    packetFormId: number;
  }
}

export interface GetPacketFormSuccess {
  type: typeof PacketFormActions.GET_PACKET_FORM_SUCCESS;
  payload: {
    packetForm: any;
  }
}

export interface GetPacketFormFailure {
  type: typeof PacketFormActions.GET_PACKET_FORM_FAILURE;
  payload: {
    error: string;
  }
}

export interface updatePacketForm {
  type: typeof PacketFormActions.UPDATE_PACKET_FORM;
  payload: {
    packetId: number;
    packetFormBundleId: number;
    packetFormId: number;
    packetForm: any;
    autosave?: boolean;
  }
}

export interface updatePacketFormSuccess {
  type: typeof PacketFormActions.UPDATE_PACKET_FORM_SUCCESS;
  payload: {
    packetForm: any;
  }
}

export interface updatePacketFormFailure {
  type: typeof PacketFormActions.UPDATE_PACKET_FORM_FAILURE;
  payload: {
    error: string;
  }
}

export interface ResetPacketForm {
  type: typeof PacketFormActions.RESET_PACKET_FORM;
  payload: null;
}

// Action Type List
export type PacketFormAction = GetPacketForm | GetPacketFormFailure | GetPacketFormSuccess | updatePacketForm | updatePacketFormFailure | updatePacketFormSuccess | ResetPacketForm;

// Action Creators
export const getPacketForm = ( 
  packetId: number,
  packetFormBundleId: number,
  packetFormId: number
): PacketFormAction => ({
  type: PacketFormActions.GET_PACKET_FORM,
  payload: {
    packetId,
    packetFormBundleId,
    packetFormId
  },
});

export const getPacketFormSuccess = (packetForm: any): PacketFormAction => ({
  type: PacketFormActions.GET_PACKET_FORM_SUCCESS,
  payload: {
    packetForm,
  }
});

export const getPacketFormFailure = (error: string): PacketFormAction => ({
  type: PacketFormActions.GET_PACKET_FORM_FAILURE,
  payload: {
    error,
  }
});

export const updatePacketForm = (
  packetId: number,
  packetFormBundleId: number,
  packetFormId: number,
  packetForm: any,
  autosave?: boolean,
): PacketFormAction => ({
  type: PacketFormActions.UPDATE_PACKET_FORM,
  payload: {
    packetId,
    packetFormBundleId,
    packetFormId,
    packetForm,
    autosave
  },
});

export const updatePacketFormSuccess = (packetForm: any): PacketFormAction => ({
  type: PacketFormActions.UPDATE_PACKET_FORM_SUCCESS,
  payload: {
    packetForm,
  }
});

export const updatePacketFormFailure = (error: string): PacketFormAction => ({
  type: PacketFormActions.UPDATE_PACKET_FORM_FAILURE,
  payload: {
    error,
  }
});

export const resetPacketForm = (): PacketFormAction => ({
  type: PacketFormActions.RESET_PACKET_FORM,
  payload: null,
});
