import { FieldValues, FieldValuesSearchArgs } from "@/types/field_values";

// Action Types
export enum FieldValuesActions {
  GET_FIELD_VALUES = 'FIELD_VALUE/GET_FIELD_VALUES',
  GET_FIELD_VALUES_SUCCESS = 'FIELD_VALUE/GET_FIELD_VALUES_SUCCESS',
  GET_FIELD_VALUES_FAILURE = 'FIELD_VALUE/GET_FIELD_VALUES_FAILURE',
}

// Action Interfaces
export interface GetFieldValues {
  type: typeof FieldValuesActions.GET_FIELD_VALUES;
  payload: {
    searchArgs: FieldValuesSearchArgs;
  }
}

export interface GetFieldValuesSuccess {
  type: typeof FieldValuesActions.GET_FIELD_VALUES_SUCCESS;
  payload: {
    fieldValues: FieldValues;
  }
}

export interface GetFieldValuesFailure {
  type: typeof FieldValuesActions.GET_FIELD_VALUES_FAILURE;
  payload: {
    error: string;
  }
}

// Action Type List
export type FieldValuesAction = GetFieldValues | GetFieldValuesFailure | GetFieldValuesSuccess;

// Action Creators
export const getFieldValues = (searchArgs: FieldValuesSearchArgs): FieldValuesAction => ({
  type: FieldValuesActions.GET_FIELD_VALUES,
  payload: {
    searchArgs,
  }
});

export const getFieldValuesSuccess = (fieldValues: FieldValues): FieldValuesAction => ({
  type: FieldValuesActions.GET_FIELD_VALUES_SUCCESS,
  payload: {
    fieldValues,
  }
});

export const getFieldValuesFailure = (error: string): FieldValuesAction => ({
  type: FieldValuesActions.GET_FIELD_VALUES_FAILURE,
  payload: {
    error,
  }
});