import React, { useEffect, useState, useRef } from 'react';

import { Button } from '../button';
export interface ModalProps {
  header?: string;
  handleClickX?: () => void;
  clickOutsideToClose?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  header,
  handleClickX,
  clickOutsideToClose,
  ...props
}) => {
  const [isRendered, setIsRendered] = useState<boolean>(false);
  const _modal = useRef(null);

  useEffect(() => {
    document.querySelector('html').style.overflow = 'hidden';
    setTimeout(() => {
      setIsRendered(true);
    }, 50);

    return () => {
      document.querySelector('html').style.overflow = 'auto';
    };
  }, []);

  const handleClose = () => {
    setIsRendered(false);
    setTimeout(() => {
      handleClickX();
    }, 150);
  };

  const handleOutsideClick = (e) => {
    clickOutsideToClose &&
      _modal.current &&
      !_modal.current.contains(e.target) &&
      handleClose();
  };

  return (
    <div
      className="modal is-active is-clipped"
      onClick={(e) => handleOutsideClick(e)}>
      <div
        className={`modal-background ${
          clickOutsideToClose ? 'point-on-hover' : ''
        }`}></div>
      <div
        className={`modal-content ${isRendered ? 'is-open' : ''}`}
        ref={_modal}>
        {handleClickX && (
          <div className="modal__x-container">
            <Button iconClass="x" iconColor="#000" onClick={handleClose} />
          </div>
        )}
        <div className="modal__header">
          <h1 className="modal__header">{header}</h1>
        </div>
        <div className="modal__container-form">{props.children}</div>
      </div>
    </div>
  );
};
