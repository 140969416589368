import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUsers } from '@/actions/actions/users';
import { selectUsers } from '@/selectors/users';

import { ApproverEventCard } from '@/contexts/packets-page/packet-approvers/packet-approver-event';
import { Loading } from '@/components/ui/loading';

export interface PacketApproversProps {
  approvalPath: any;
  className?: string;
}

export const PacketApprovers: React.FC<PacketApproversProps> = ({
  approvalPath,
  className,
}) => {
  const dispatch = useDispatch();
  const _approvers = useRef<HTMLDivElement>();
  const allUsers = useSelector(selectUsers);

  useEffect(() => {
    window.addEventListener('scroll', resizeWizard);
    window.addEventListener('resize', resizeWizard);
    window.scrollTo(0, 0);

    return () => {
      window.removeEventListener('scroll', resizeWizard);
      window.removeEventListener('resize', resizeWizard);
    };
  }, []);

  useEffect(() => {
    !allUsers && dispatch(getUsers());
  }, [allUsers]);

  const resizeWizard = () => {
    document
      .querySelector<HTMLElement>(':root')
      .style.setProperty(
        '--wizardHeight',
        `${_approvers?.current?.clientHeight}px`,
      );
  };

  const parseApprovalData = () => {
    const stops = approvalPath['stops'];
    let data = [
      [
        {
          userName: findUser(approvalPath['creatorUserId']),
          name: approvalPath['name'],
          approvalStatusId: 6,
          date: approvalPath['date'],
          comments: '',
        },
      ],
    ];
    let count = 0;
    stops.forEach((stop) => {
      const { approvalComments, approvalStopDetail } = stop;
      if (approvalStopDetail) {
        approvalStopDetail.users.forEach((user) => {
          const userId = user.userId;
          user.userName = findUser(userId);
          user.comments = findComments(userId, approvalComments);
        });
        data.push(approvalStopDetail.users);
        count++;
      }
    });
    const rectified = [];
    rectified.push(data[0]);
    let _approvers = data.slice(1, count+1).reverse();
    _approvers.forEach((approver) => {
      rectified.push(approver);
    });
    return rectified;
  };

  const findComments = (userId, approvalComments) => {
    for (
      let commentIdx = 0;
      commentIdx < approvalComments.length;
      commentIdx++
    ) {
      if (approvalComments[commentIdx]['userId'] === userId) {
        return approvalComments[commentIdx]['comment'];
      }
    }
    return '';
  };

  const findUser = (userId) => {
    if (allUsers) {
      for (let i = 0; i < allUsers.length; i++) {
        if (userId === allUsers[i]['userId']) {
          return `${allUsers[i]['firstName']} ${allUsers[i]['lastName']}`;
        }
      }
    }
  };

  const displayApprovalCards = (data) => {
    let approvalCards = [];
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].length; j++) {
        if (i === 0) {
          approvalCards.push([
            <ApproverEventCard
              key={`approval-${i}${j}`}
              idx={i}
              offset={'offset-left'}
              approvalData={data[i][j]}
              isFirst={true}
              isLast={false}
              start={true}
              end={i == data.length - 1 ? true : false}
            />,
          ]);
        } else if (data[i].length == 1) {
          approvalCards.push([
            <ApproverEventCard
              key={`approval-${i}${j}`}
              idx={i}
              offset="offset-left"
              approvalData={data[i][j]}
              isFirst={true}
              isLast={true}
              start={false}
              end={i == data.length - 1 ? true : false}
            />,
          ]);
        } else if (i % 2 !== 0) {
          if (j === 0) {
            approvalCards.push([
              <ApproverEventCard
                key={`approval-${i}${j}`}
                idx={i}
                offset={'offset-left'}
                approvalData={data[i][j]}
                isFirst={true}
                isLast={false}
                start={false}
                end={i == data.length - 1 ? true : false}
              />,
            ]);
          } else if (j === data[i].length - 1) {
            approvalCards[i].push(
              <ApproverEventCard
                key={`approval-${i}${j}`}
                idx={i}
                offset={'offset-left'}
                approvalData={data[i][j]}
                isFirst={false}
                isLast={true}
                start={false}
                end={i == data.length - 1 ? true : false}
              />,
            );
          } else {
            approvalCards[i].push(
              <ApproverEventCard
                key={`approval-${i}${j}`}
                idx={i}
                offset={'offset-left'}
                approvalData={data[i][j]}
                isFirst={false}
                isLast={false}
                start={false}
                end={i == data.length - 1 ? true : false}
              />,
            );
          }
        } else {
          if (j === 0) {
            approvalCards.push([
              <ApproverEventCard
                key={`approval-${i}${j}`}
                idx={i}
                offset={'offset-left'}
                approvalData={data[i][j]}
                isFirst={true}
                isLast={false}
                start={false}
                end={i == data.length - 1 ? true : false}
              />,
            ]);
          } else if (j === data[i].length - 1) {
            approvalCards[i].push(
              <ApproverEventCard
                key={`approval-${i}${j}`}
                idx={i}
                offset={'offset-left'}
                approvalData={data[i][j]}
                isFirst={false}
                isLast={true}
                start={false}
                end={i == data.length - 1 ? true : false}
              />,
            );
          } else {
            approvalCards[i].push(
              <ApproverEventCard
                key={`approval-${i}${j}`}
                idx={i}
                offset={'offset-left'}
                approvalData={data[i][j]}
                isFirst={false}
                isLast={false}
                start={false}
                end={i == data.length - 1 ? true : false}
              />,
            );
          }
          if (approvalCards.length != 0) {
            const reversed = approvalCards.reverse();
            console.log(reversed)   
          }
        }
      }
    }
    return approvalCards;
  };
  return (
    <div className="packet-approval" ref={_approvers}>
      {approvalPath ? (
        displayApprovalCards(parseApprovalData())
      ) : (
        <Loading
          ringColor="#fff"
          height="70vh"
          width="100%"
          positionRelative={true}
          message="Loading Packet Approvers..."
        />
      )}
    </div>
  );
};

export default PacketApprovers;
