import React, { useEffect, useRef, useState } from 'react';

import { TextField } from '@/components/ui/text-field';
import Header from '../header';
import { ApprovalPath, Stop as StopType } from '@/types/approval_path';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '@/selectors/user';
import { selectPacket } from '@/selectors/packet';
import { DropdownSelect } from '@/components/ui/dropdown-select';
import { useHistory, useParams } from 'react-router-dom';
import { getPacket } from '@/actions/actions/packet';
import { useMemo } from 'react';
import { PacketFormBundle } from '@/types/packet';
import { getPacketForm } from '@/actions/actions/packet_form';
import { Loading } from '@/components/ui/loading';
import { getUsers } from '@/actions/actions/users';
import { selectUsers } from '@/selectors/users';
import { Button } from '@/components/ui/button';
import { Icon } from '@/components/ui';
import { selectPacketForm } from '@/selectors/packet_form';
import { Field, PacketForm, Section } from '@/types/packet_form';
import Api from '@/api/Api';
import { selectApprovalTimelines } from '@/selectors/approval_timelines';
import { getApprovalTimelines } from '@/actions/actions/approval_timelines';
import { setUserApprovalPath } from '@/actions/actions/approval_paths';
import Stop from './stop';

// approval path
// {
//   approvalPathType,
//   creatorUserId,
//   name,
//   save,
//   stops[]
// }

// stop
// {
//   approvalComments[],
//   approvalStatus,
//   approvalStatusId,
//   approverStopDetail{},
//   approvalStopTypeId,
//   approvalTimelineId,
//   displayName,
//   lastStatusChangeDateTimeUtc,
//   sequenceNumber,
// }

// approvalStopDetail
// {
//   requiredForms[],
//   users[]
// }

// requiredForm
// {
//   formId,
//   requiredFieldIds[{fieldId}],
// }

//user
// {
//   approvalStatus,
//   approvalStatusId,
//   lastStatusChangeDateTimeUtc,
//   userId
// }

interface ParamTypes {
  packetId: string;
}

const CreateNew = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { packetId } = useParams<ParamTypes>();

  const packet = useSelector(selectPacket);
  const user = useSelector(selectUser);
  const userData = useSelector(selectUsers);
  const approvalTimelines = useSelector(selectApprovalTimelines);

  const canvasRefs = useRef([]);
  const imageRefs = useRef([]);

  const emptyStop: StopType = {
    approvalComments: [],
    approvalStatus: null,
    approvalStatusId: 1,
    approvalStopDetail: {
      requiredForms: [],
      users: [{ userId: null, approvalStatusId: 1 }],
    },
    approvalStopTypeId: 1,
    approvalTimelineId: 1,
    displayName: '',
    lastStatusChangeDateTimeUtc: null,
    sequenceNumber: 1,
  };

  const initializeApprovalPath = (): ApprovalPath => {
    return {
      approvalPathType: 2,
      creatorUserId: user?.userId,
      name: '',
      save: true,
      stops: [emptyStop],
    };
  };

  const [approvalPath, setApprovalPath] = useState<ApprovalPath>(
    initializeApprovalPath(),
  );
  const [userOptions, setUserOptions] = useState([]);
  const [currentForm, setCurrentForm] = useState<PacketFormBundle>();
  const [imageDimensions, setImageDimensions] = useState<{
    width: number;
    height: number;
  }>();
  const [canvasDimensions, setCanvasDimensions] = useState<{
    width: number;
    height: number;
  }>();
  const [approvalTimelineOptions, setApprovalTimelineOptions] = useState(null);
  const [packetForms, setPacketForms] = useState([]);
  const [imageOffsetPercentage, setImageOffsetPercentage] = useState<number>();
  const [allFields, setAllFields] = useState([]);
  const [selectedPacketFormIndex, setSelectedPacketFormIndex] = useState<
    number
  >(0);
  const [selectedStopIndex, setSelectedStopIndex] = useState<number>(0);
  const [hasPathNameError, setHasPathNameError] = useState<boolean>(false);
  const [hasStopError, setHasStopError] = useState<boolean>(false);

  useEffect(() => {
    packetId && dispatch(getPacket(parseInt(packetId)));
  }, [packetId]);

  useEffect(() => {
    dispatch(getUsers('3'));
    dispatch(getApprovalTimelines());
  }, []);

  useEffect(() => {
    approvalTimelines && buildApprovalTimelinesOptions();
  }, [approvalTimelines]);

  const buildApprovalTimelinesOptions = () => {
    const options = approvalTimelines.map((timeline) => {
      return {
        value: timeline.approvalTimelineId,
        label: timeline.name,
        header: false,
      };
    });
    setApprovalTimelineOptions(options);
  };

  useEffect(() => {
    if (currentForm) {
      setPacketForms([]);
      try {
        for (const packetForm of currentForm.packetForms) {
          fetchPacketForm(packetForm);
        }
      } catch (err) {
        console.error('err', err);
      }
    }
  }, [currentForm]);

  useEffect(() => {
    if (imageDimensions) {
      const aspectRatio = imageDimensions.width / imageDimensions.height;

      const canvasWidth =
        imageDimensions.width > 1200 ? 1200 : imageDimensions.width;

      const canvasHeight = canvasWidth / aspectRatio;

      setImageOffsetPercentage(canvasWidth / imageDimensions.width);

      setCanvasDimensions({ width: canvasWidth, height: canvasHeight });
    }
  }, [imageDimensions]);

  // console.log('APPROVAL PATH:  ', approvalPath);
  // console.log('PACKET:   ', packet);
  // console.log('CURRENT FORM:   ', currentForm);
  // console.log('PACKET FORMS:   ', packetForms);
  // console.log('IMAGE OFFSET PERCENTAGE:   ', imageOffsetPercentage);
  // console.log('ALLLLL FIELDS:   ', allFields);
  // console.log('selected stop index', selectedStopIndex);
  // console.log('has stop error', hasStopError);

  const buildFormBundleOptions = useMemo(() => {
    if (packet) {
      return packet.packetFormBundles.map((packetForm) => {
        return {
          label: packetForm.packetFormBundleName,
          value: packetForm,
        };
      });
    } else {
      return [];
    }
  }, [packet]);

  const buildUserOptions = (_users) => {
    const _options = _users.map((user) => {
      return {
        value: user.userId,
        label: `${user.office ? user.office + " " : "" }${user.firstName} ${user.lastName} `,
      };
    });
    setUserOptions(
      _options.sort((a, b) => {
        return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
      }),
    );
  };

  useEffect(() => {
    if (buildFormBundleOptions && buildFormBundleOptions[0]) {
      setCurrentForm(buildFormBundleOptions[0].value);
    }
  }, [buildFormBundleOptions]);

  useEffect(() => {
    userData && buildUserOptions(userData);
  }, [userData]);

  const fetchPacketForm = async (packetForm) => {
    try {
      const url = `/api/v1/packets/${packetId}/packetformbundles/${currentForm.packetFormBundleId}/packetform/${packetForm.packetFormId}`;
      const response = await Api.utility.get(url);
      setPacketForms((prev) => [...prev, response.data]);
    } catch (err) {
      console.error('err', err);
    }
  };

  const attachSectionsToField = (
    section: Section,
    subsection?: Section,
  ): Field[] => {
    const newFields = [];

    for (const field of section.fields) {
      if (subsection) {
        newFields.push({
          ...field,
          sectionIndex: section.sectionIndex,
          subsectionIndex: subsection.sectionIndex,
        });
      } else {
        newFields.push({
          ...field,
          sectionIndex: section.sectionIndex,
        });
      }
    }

    return newFields;
  };

  const gatherAllFields = (sections: Section[]): Field[] => {
    const newFields = [];

    for (const section of sections) {
      const sectionFields = attachSectionsToField(section);
      newFields.push(...sectionFields);

      if (section.sections && section.sections.length > 0) {
        for (const subsection of section.sections) {
          const subsectionFields = attachSectionsToField(section, subsection);
          newFields.push(...subsectionFields);
        }
      }
    }

    return newFields;
  };

  const handleSizeOffset = (value) => {
    return value * imageOffsetPercentage;
  };

  const checkForCollision = (field, location) => {
    const leftBoundary: number = handleSizeOffset(
      field.fieldValueRegion.xCoordinate,
    );
    const rightBoundary: number =
      handleSizeOffset(field.fieldValueRegion.xCoordinate) +
      handleSizeOffset(field.fieldValueRegion.width);
    const topBoundary: number = handleSizeOffset(
      field.fieldValueRegion.yCoordinate,
    );
    const bottomBoundary: number =
      handleSizeOffset(field.fieldValueRegion.yCoordinate) +
      handleSizeOffset(field.fieldValueRegion.height);

    if (
      Math.round(location.x) >= Math.round(leftBoundary) &&
      Math.round(location.x) <= Math.round(rightBoundary) &&
      Math.round(location.y) >= Math.round(topBoundary) &&
      Math.round(location.y) <= Math.round(bottomBoundary)
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (packet && !!packetForms.length) {
      // TODO: Update for multiple packetForms
      const packetForm = packetForms[0];
      const sections = packetForm?.form?.pages?.reduce((acc, currentVal) => {
        const _sections = currentVal?.sections.map((section, index) => {
          return {
            pageNumber: currentVal?.pageNumber - 1,
            sectionIndex: index,
            ...section,
          };
        });

        if (Array.isArray(_sections) && _sections.length) {
          acc.push(_sections);
          return acc;
        }
      }, []);

      const allFields = gatherAllFields(sections.flat(1));

      const canvas = canvasRefs.current[0];

      const ctx = canvas.getContext('2d');

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      drawAllFields(allFields);

      ctx.fillStyle = '#58a4b030';
      ctx.strokeStyle = '#3a4886';
      ctx.lineWidth = 2;

      const allRequiredFields = [];

      const requiredForms =
        approvalPath.stops[selectedStopIndex].approvalStopDetail.requiredForms;

      for (const requiredForm of requiredForms) {
        for (const requiredFieldId of requiredForm.requiredFieldIds) {
          allRequiredFields.push(requiredFieldId);
        }
      }

      for (const requiredField of allRequiredFields) {
        const foundField = allFields.find(
          (field) => field.id === requiredField.fieldId,
        );

        ctx.fillRect(
          handleSizeOffset(foundField.fieldValueRegion.xCoordinate),
          handleSizeOffset(foundField.fieldValueRegion.yCoordinate),
          handleSizeOffset(foundField.fieldValueRegion.width),
          handleSizeOffset(foundField.fieldValueRegion.height),
        );
        ctx.strokeRect(
          handleSizeOffset(foundField.fieldValueRegion.xCoordinate),
          handleSizeOffset(foundField.fieldValueRegion.yCoordinate),
          handleSizeOffset(foundField.fieldValueRegion.width),
          handleSizeOffset(foundField.fieldValueRegion.height),
        );
      }
    }
  }, [approvalPath, selectedStopIndex]);

  useEffect(() => {
    if (!!packetForms.length) {
      const all = [];
      for (const packetForm of packetForms) {
        const sections = packetForm?.form?.pages?.reduce((acc, currentVal) => {
          const _sections = currentVal?.sections.map((section, index) => {
            return {
              pageNumber: currentVal?.pageNumber - 1,
              sectionIndex: index,
              ...section,
            };
          });

          if (Array.isArray(_sections) && _sections.length) {
            acc.push(_sections);
            return acc;
          }
        }, []);
        const allFields = gatherAllFields(sections.flat(1));
        all.push(...allFields);
      }
      setAllFields([...all]);
    }
  }, [packetForms]);

  useEffect(() => {
    if (!!allFields.length && imageOffsetPercentage) {
      setTimeout(() => {
        drawAllFields(allFields);
      }, 100);
    }
  }, [allFields, canvasRefs.current, imageOffsetPercentage]);

  const drawAllFields = (all) => {
    // TODO: Update for multiple canvases
    const canvas = canvasRefs.current[0];

    if (canvas) {
      const ctx = canvas.getContext('2d');

      // ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = '#e8e9ee';

      for (const field of all) {
        ctx.fillRect(
          handleSizeOffset(field.fieldValueRegion.xCoordinate),
          handleSizeOffset(field.fieldValueRegion.yCoordinate),
          handleSizeOffset(field.fieldValueRegion.width),
          handleSizeOffset(field.fieldValueRegion.height),
        );
      }
    }
  };

  const handleCanvasClick = ({ x, y, packetFormIndex }) => {
    let clickedField;

    allFields.forEach((field) => {
      const isCollision = checkForCollision(field, { x, y });

      if (isCollision) {
        clickedField = field;
      }
    });

    if (clickedField) {
      handleClickedField(clickedField, packetFormIndex);
    }
  };

  // TODO: Update method to work with multiple stops
  const handleClickedField = (field, packetFormIndex) => {
    // find packet form
    const packetForm: PacketForm = packetForms[packetFormIndex];

    // extract packet form formId
    const formId = packetForm.formId;

    // extract required forms
    const requiredForms =
      approvalPath.stops[selectedStopIndex].approvalStopDetail.requiredForms;

    // check if current stop's approvalStopDetails.requiredForms includes formId
    const foundRequiredForm = requiredForms.find(
      (requiredForm) => requiredForm.formId === formId,
    );

    if (foundRequiredForm) {
      const foundRequiredFormIndex = requiredForms.indexOf(foundRequiredForm);
      // if yes, check requiredFieldIds already has field
      const foundRequiredFieldId = foundRequiredForm.requiredFieldIds.find(
        (requiredFieldId) => requiredFieldId.fieldId === field.id,
      );

      if (foundRequiredFieldId) {
        const requiredFieldIdIndex = foundRequiredForm.requiredFieldIds.indexOf(
          foundRequiredFieldId,
        );

        // if yes, remove from requiredFieldIds
        let _approvalPath = { ...approvalPath };
        _approvalPath.stops[selectedStopIndex].approvalStopDetail.requiredForms[
          foundRequiredFormIndex
        ].requiredFieldIds.splice(requiredFieldIdIndex, 1);

        setApprovalPath(_approvalPath);
      } else {
        // if no, add to requiredFieldIds
        let _approvalPath = { ...approvalPath };

        _approvalPath.stops[selectedStopIndex].approvalStopDetail.requiredForms[
          foundRequiredFormIndex
        ].requiredFieldIds.push({ fieldId: field.id });

        setApprovalPath(_approvalPath);
      }
    } else {
      // if no, add formId to requiredForms with field as first requiredFieldId
      let _approvalPath = { ...approvalPath };

      _approvalPath.stops[
        selectedStopIndex
      ].approvalStopDetail.requiredForms.push({
        formId: formId,
        requiredFieldIds: [{ fieldId: field.id }],
      });

      setApprovalPath(_approvalPath);
    }
  };

  const renderCanvases = () => {
    if (canvasDimensions) {
      return (
        <div
          className="canvases-container"
          style={{ maxHeight: '700px', overflowY: 'scroll' }}>
          {packetForms.map((packetForm, packetFormIndex) => {
            return (
              <div
                style={{
                  position: 'relative',
                  maxWidth: '1200px',
                }}>
                <canvas
                  onLoadedData={() => console.log('canvas loaded data')}
                  onLoad={() => console.log('canvas loaded')}
                  id="canvas"
                  style={{
                    backgroundImage: `url(${imageRefs.current[packetFormIndex]?.src})`,
                    backgroundSize: 'contain',
                    width: '100%',
                  }}
                  ref={(el) => (canvasRefs.current[packetFormIndex] = el)}
                  width={canvasDimensions.width}
                  height={canvasDimensions.height}
                  onClick={(e) => {
                    const rect = canvasRefs.current[
                      packetFormIndex
                    ].getBoundingClientRect();
                    const x = e.clientX - rect.left;
                    const y = e.clientY - rect.top;

                    handleCanvasClick({
                      x: x,
                      y: y,
                      packetFormIndex: packetFormIndex,
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div>
          <Loading positionRelative />
        </div>
      );
    }
  };

  const renderImages = () => {
    if (!!packetForms.length) {
      return packetForms.map((packetForm, packetFormIndex) => {
        return (
          <img
            style={{ display: 'none' }}
            src={packetForm?.form.pages[0].image}
            ref={(el) => (imageRefs.current[packetFormIndex] = el)}
            onLoad={() => {
              setImageDimensions({
                width: imageRefs.current[packetFormIndex].naturalWidth,
                height: imageRefs.current[packetFormIndex].naturalHeight,
              });
            }}
          />
        );
      });
    }
  };

  const isPathValid = () => {
    let isValid: boolean = true;
    const hasName: boolean = !!approvalPath.name;

    if (!hasName) {
      setHasPathNameError(true);
      isValid = false;
    }

    approvalPath.stops.forEach((stop, stopIndex) => {
      if (!stop.approvalStopDetail.users[0].userId) {
        console.log('do i see this');
        setHasStopError(true);
        isValid = false;
      }
    });

    return isValid;
  };

  if (!packet) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <div className="create-new">
      <Header backText="Back" header="create approval path" />

      <div className="columns">
        <div className="column is-3">
          <div
            style={{
              maxHeight: '750px',
              overflowY: 'scroll',
              backgroundColor: '#fff',
              padding: '10px',
            }}>
            {hasPathNameError && !approvalPath?.name && (
              <p style={{ color: 'red' }} className="error">
                Approval path name is required
              </p>
            )}

            <TextField
              label="Path Name"
              onChange={(value: string) =>
                setApprovalPath({ ...approvalPath, name: value })
              }
              defaultValue={approvalPath.name}
            />

            <p>Assign stops, approvers, and approver fields.</p>
            {/* stop */}

            {approvalPath.stops.map((stop, stopIndex) => {
              return (
                <Stop
                  allFields={allFields}
                  approvalPath={approvalPath}
                  approvalTimelineOptions={approvalTimelineOptions}
                  selectedStopIndex={selectedStopIndex}
                  setApprovalPath={setApprovalPath}
                  setSelectedStopIndex={setSelectedStopIndex}
                  stop={stop}
                  stopIndex={stopIndex}
                  userOptions={userOptions}
                  packetForms={packetForms}
                  hasStopError={hasStopError}
                />
              );
            })}
            <div style={{ marginTop: '10px' }}>
              <Button
                className="solid"
                label="add stop"
                primary={false}
                size="small"
                onClick={() => {
                  const _approvalPath = { ...approvalPath };
                  const _emptyStop = emptyStop;
                  _emptyStop.sequenceNumber = approvalPath.stops.length + 1;
                  _approvalPath.stops.push(_emptyStop);
                  setApprovalPath(_approvalPath);
                }}
                labelColor="white"
              />
            </div>
          </div>
          <div style={{ marginTop: '10px' }}>
            <Button
              backgroundColor="white"
              className="solid"
              label="save path"
              primary={false}
              size="small"
              onClick={() => {
                if (isPathValid()) {
                  dispatch(setUserApprovalPath(approvalPath));
                  history.push(`/packets/packet/${packetId}/send`);
                }
              }}
              labelColor="black"
            />
          </div>
        </div>
        <div className="column is-9">
          <div className="create-new__form-select-container">
            <DropdownSelect
              label="Form Name"
              onClick={(choice) => {
                if (choice) {
                  const matchedChoice = packet.packetFormBundles.find(
                    (packetForm) =>
                      packetForm.packetFormBundleName ===
                      choice.packetFormBundleName,
                  );

                  if (matchedChoice) {
                    setCurrentForm(matchedChoice);
                  }
                }
              }}
              options={buildFormBundleOptions}
              selectedValue={currentForm || null}
            />
          </div>
          {renderCanvases()}
          {renderImages()}
        </div>
      </div>
    </div>
  );
};

export default CreateNew;
