import React from 'react';
import { Icon, Drawer } from '@/components/ui';

export interface ParentValueListProps {
  data: any;
  drawerOpen: boolean;
  shouldShowX?: boolean;
}

export const ParentValueList: React.FC<ParentValueListProps> = ({
  data,
  drawerOpen = false,
  shouldShowX = false,
}) => {
  let keyCount = 0;
  const getKey = (prefix) => {
    keyCount = keyCount += 1;
    return `${prefix}-${keyCount}`;
  };

  const renderMain = (preview) => {
    if (preview.data.length === 0) {
      return (
        <p className="title-value-list__section-form">No data available</p>
      );
    } else if (preview.data.length === 1) {
      if (preview.data[0] !== preview.title) {
        return (
          <p className="title-value-list__section-form">
            {preview.data[0]} - {preview.title}
          </p>
        );
      } else {
        return <p className="title-value-list__section-form">{preview.data}</p>;
      }
    } else {
      return (
        <>
          {shouldShowX && (
            <Icon
              iconClass="x-enclosed"
              color="var(--primary)"
              onClick={null}
            />
          )}
          <Drawer label={preview.title} open={drawerOpen}>
            <ul className="title-value-list__section--ul">
              {preview?.data.map((name) => {
                return <li key={getKey('preview-drawer-li')}>{name}</li>;
              })}
            </ul>
          </Drawer>
        </>
      );
    }
  };

  return (
    <div className="title-value-list__section">
      <ul className="title-value-list__section--ul">
        {data &&
          data.map((preview) => {
            {
              return (
                preview.data && (
                  <div
                    key={getKey('preview-drawer')}
                    className="preview-drawer-parent flex align-items-center">
                    {renderMain(preview)}
                  </div>
                )
              );
            }
          })}
      </ul>
    </div>
  );
};
