import { call, put, takeLatest } from 'redux-saga/effects';

import {
  PacketsActions,
  getPacketsFailure,
  GetPackets,
  getTeamPacketsSuccess,
} from '@/actions/actions/packets';
import Api from '@/api/Api';
import { buildPath, Routes } from '@/utils/routeUtils';

export function* getTeamPacketsSaga(action: GetPackets) {
  try {
    const { userId } = action.payload;

    const url = buildPath(Routes.api.packets, 'user/approvals')

    const response = yield call(Api.utility.get, url);

    yield put(getTeamPacketsSuccess(response));
  } catch (err) {
    yield put(getPacketsFailure(err));
  }
}

export function* watchGetTeamPackets() {
  yield takeLatest(PacketsActions.GET_TEAM_PACKETS, getTeamPacketsSaga);
}
