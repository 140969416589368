import { Forms } from "@/types/forms";

// Action Types
export enum FormsActions {
  GET_FORMS = 'FORM/GET_FORMS',
  GET_FORMS_SUCCESS = 'FORM/GET_FORMS_SUCCESS',
  GET_FORMS_FAILURE = 'FORM/GET_FORMS_FAILURE',
}

// Action Interfaces
export interface GetForms {
  type: typeof FormsActions.GET_FORMS;
}

export interface GetFormsSuccess {
  type: typeof FormsActions.GET_FORMS_SUCCESS;
  payload: {
    forms: Forms[];
  }
}

export interface GetFormsFailure {
  type: typeof FormsActions.GET_FORMS_FAILURE;
  payload: {
    error: string;
  }
}

// Action Type List
export type FormsAction = GetForms | GetFormsFailure | GetFormsSuccess;

// Action Creators
export const getForms = (): FormsAction => ({
  type: FormsActions.GET_FORMS,
});

export const getFormsSuccess = (forms: Forms[]): FormsAction => ({
  type: FormsActions.GET_FORMS_SUCCESS,
  payload: {
    forms,
  }
});

export const getFormsFailure = (error: string): FormsAction => ({
  type: FormsActions.GET_FORMS_FAILURE,
  payload: {
    error,
  }
});
