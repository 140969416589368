import { call, put, takeLatest } from 'redux-saga/effects';

import { FieldValuesActions, GetFieldValues, getFieldValuesFailure, getFieldValuesSuccess } from '@/actions/actions/field_values';
import Api from '@/api/Api';
import { buildPath, Routes } from '@/utils/routeUtils';

export function* getFieldValuesSaga(
  action: GetFieldValues
) {
  try {
    const { searchArgs } = action.payload;

    const url = buildPath(Routes.api.fieldValues, null)

    const response = yield call(Api.utility.post, url, searchArgs);

    yield put(getFieldValuesSuccess(response.data));
  } catch (err) {
    yield put(getFieldValuesFailure(err));
  }
}

export function* watchGetFieldValues() {
  yield takeLatest(FieldValuesActions.GET_FIELD_VALUES, getFieldValuesSaga);
}
