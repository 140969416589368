import React from 'react';

import { Icon, Drawer } from '@/components/ui';
import { User } from '@/types/user';
import { Option } from '@/types/option';
export interface PacketContentsProps {
  selectedPackets: any;
  localDispatch: React.Dispatch<any>;
  drawerOpen: boolean;
  state?: any;
  user?: User;
  userIndex?: any;
  isPowerUser?: boolean;
}

export const PacketContents: React.FC<PacketContentsProps> = ({
  selectedPackets,
  localDispatch,
  drawerOpen = false,
  state,
  user,
  userIndex,
  isPowerUser,
}) => {
  let keyCount = 0;

  const getKey = (prefix) => {
    keyCount = keyCount += 1;
    return `${prefix}-${keyCount}`;
  };

  const addPreview = (preview) => {
    let _selectedPackets = selectedPackets || [];
    const selectedPacketNames = _selectedPackets.reduce((acc, currVal) => {
      acc.push(currVal.title);
      return acc;
    }, []);

    if (selectedPacketNames.includes(preview.title)) {
      _selectedPackets.splice(selectedPacketNames.indexOf(preview.title), 1);
    } else if (!selectedPacketNames.includes(preview.title)) {
      _selectedPackets.push(preview);
    }
    localDispatch({
      type: 'SELECTED_PACKETS',
      value: _selectedPackets,
    });
  };

  return (
    <div className="create-packet__packet-selected-section">
      {isPowerUser && (
        <p className="create-packet__packet-selected-section--requester">
          <b>Requester:</b>{' '}
          {`${userIndex[state?.packet?.onBehalfOfUserId]?.firstName}
          ${userIndex[state?.packet?.onBehalfOfUserId]?.lastName}`}
        </p>
      )}
      <p className="create-packet__packet-selected-section--header">
        Packet Contents
      </p>
      <ul className="create-packet__packet-selected-section--ul">
        {selectedPackets &&
          selectedPackets.map((preview) => {
            {
              return preview.details ? (
                <div
                  key={getKey('preview-drawer')}
                  className="preview-drawer-parent flex align-items-center">
                  {!drawerOpen && (
                    <Icon
                      iconClass="x-enclosed"
                      color="var(--primary)"
                      onClick={() => addPreview(preview)}
                    />
                  )}
                  {Array.isArray(preview.details) ? (
                    <Drawer label={preview.title} open={drawerOpen}>
                      <ul className="create-packet__packet-selected-section--ul packet-contents">
                        {preview?.details.map((formName) => {
                          return (
                            <li key={getKey('preview-drawer-li')}>
                              {formName}
                            </li>
                          );
                        })}
                      </ul>
                    </Drawer>
                  ) : (
                    <p className="create-packet__packet-selected-section--form">
                      {preview.details}
                    </p>
                  )}
                </div>
              ) : (
                <li
                  className="flex align-items-center create-packet__packet-selected-section--ul--li"
                  key={getKey('preview')}>
                  {!drawerOpen && (
                    <Icon
                      iconClass="x-enclosed"
                      color="var(--primary)"
                      onClick={() => addPreview(preview)}
                    />
                  )}
                  <span className="preview-title">{preview.title}</span>
                </li>
              );
            }
          })}
      </ul>
    </div>
  );
};
