import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { DropdownSelect, RadioButtons } from '@/components/ui';
import { User } from '@/types/user';
import { Option } from '@/types/option';

export interface RequesterSectionProps {
  user: User;
  state: any;
  localDispatch: React.Dispatch<any>;
  userOptions: Option[];
  onBehalfOf: number;
  setOnBehalfOf: React.Dispatch<number>;
}

export const RequesterSection: React.FC<RequesterSectionProps> = ({
  user,
  state,
  localDispatch,
  userOptions,
  onBehalfOf,
  setOnBehalfOf,
}) => {
  const options = [
    { value: 1, label: 'Myself' },
    {
      value: 2,
      label: 'Other(s)',
    },
  ];

  useEffect(() => {
    onBehalfOf && onBehalfOf === 1 && setOnBehalfOfUser(user.userId);
  }, [onBehalfOf]);

  const setOnBehalfOfUser = (value) => {
    let _packet = state.packet;

    _packet.onBehalfOfUserId = value;
    localDispatch({
      type: 'UPDATE_PACKET',
      value: _packet,
    });
  };

  return (
    <div
      className="create-packet__section requester-section"
      style={{ marginTop: '0' }}>
      <div className="create-packet__step">1</div>
      <div className="create-packet__section--main">
        <h3 className="create-packet__section--header">Requester</h3>
        <RadioButtons
          options={options}
          onClick={(value) => setOnBehalfOf(value)}
          value={onBehalfOf}
        />
        {onBehalfOf === 2 && state?.packet && (
          <>
            <DropdownSelect
              className="margin-0"
              label="Packet Requester"
              options={userOptions}
              selectedValue={state?.packet?.onBehalfOfUserId || user.userId}
              onClick={(value) => setOnBehalfOfUser(value)}
            />
          </>
        )}
      </div>
    </div>
  );
};
