import React from 'react';
import { DateTime } from 'luxon';

import { ApprovalTimeline } from '@/types/approval_timeline';

export interface PacketDeadlineProps {
  approvalTimeline: ApprovalTimeline;
  selectedApprovalPath: any;
}

export const PacketDeadline: React.FC<PacketDeadlineProps> = ({
  selectedApprovalPath,
  approvalTimeline,
}) => {
  const calculateDeadline = (hours, stops) => {
    const formattedDate = DateTime.fromJSDate(new Date())
      .plus({
        hours: hours * stops,
      })
      .toLocaleString(DateTime.DATE_FULL);
    return (
      <p className="create-packet__date-estimate--date">{formattedDate}</p>
    );
  };

  return (
    <>
      {approvalTimeline && selectedApprovalPath && (
        <div className="create-packet__date-estimate">
          <h4 className="create-packet__section--sub-header">
            packet deadline
          </h4>
          <p className="create-packet__date-estimate--blurb">If sent today</p>
          {calculateDeadline(
            approvalTimeline.hourOffset,
            selectedApprovalPath.stops.length,
          )}
        </div>
      )}
    </>
  );
};
