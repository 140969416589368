import React, { useState } from 'react';

import { Button } from '@/components/ui';
import { FileUploadModal } from '../file-upload-modal';

export interface FileUploadButtonProps {
  packetFormBundleId: string;
  packetFormId: string;
};

export const FileUploadButton: React.FC<FileUploadButtonProps> = ({
  packetFormBundleId,
  packetFormId,
}) => {
  const [isFileUploadModalShowing, setIsShowingFileUploadModal] = useState<boolean>(false);

  const handleClose = () => {
    setIsShowingFileUploadModal(false);
  };

  return (
    <>
      <div className="file-upload__label">File Upload (optional)</div>

      <Button
        border={true}
        className="file-upload__button"
        label="upload"
        primary={true}
        onClick={() => setIsShowingFileUploadModal(true)}
      />

      {isFileUploadModalShowing && (
        <FileUploadModal
          onClickClose={handleClose}
          packetFormBundleId={packetFormBundleId}
          packetFormId={packetFormId}
        />
      )}
    </>
  );
};
