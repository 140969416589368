import { createSelector } from 'reselect';

import { AppState } from '@/types/app';
import { PacketsState } from '@/types/packet';

export const packetsState = (state: AppState): PacketsState => state.packets;

export const selectPackets = createSelector(
  packetsState,
  (state) => state.packets,
);

const activeStatuses = [1, 2, 3, 4];
const submittedStatuses = [3, 4];
const pastStatuses = [5, 6, 7, 8];
const teamApprovalNeededStatuses = [3];
const teamPastStatuses = [5, 6, 7, 8];

export const selectActivePackets = createSelector(packetsState, (state) => {
  if (state.packets) {
    return state.packets.filter((packet) =>
      activeStatuses.includes(packet.statusId),
    );
  }
});

export const selectSubmittedPackets = createSelector(packetsState, (state) => {
  if (state.packets) {
    return state.packets.filter((packet) =>
    submittedStatuses.includes(packet.statusId),
    );
  }
});

export const selectPastPackets = createSelector(packetsState, (state) => {
  if (state.teamPackets) {
    return state.teamPackets.filter((packet) =>
      pastStatuses.includes(packet.statusId),
    );
  }
});

export const selectTeamApprovalNeededPackets = createSelector(
  packetsState,
  (state) => {
    if (state.teamPackets) {
      return state.teamPackets.filter((packet) =>
        teamApprovalNeededStatuses.includes(packet.statusId),
      );
    }
  },
);

export const selectTeamPastPackets = createSelector(packetsState, (state) => {
  if (state.teamPackets) {
    return state.teamPackets.filter((packet) =>
      teamPastStatuses.includes(packet.statusId),
    );
  }
});
