import { call, put, takeLatest } from 'redux-saga/effects';

import { FileActions, DeleteFile, deleteFileFailure, deleteFileSuccess } from '@/actions/actions/file';
import Api from '@/api/Api';
import { buildPath, Routes } from '@/utils/routeUtils';

export function* deleteFileSaga(
  action: DeleteFile
) {
  try {
    const { fileId } = action.payload;

    const url = buildPath(Routes.api.file, fileId)

    const response = yield call(Api.utility.delete, url);

    if (response?.status === 200) {
      yield put(deleteFileSuccess(fileId));
    } else {
      yield put(deleteFileFailure('Unable to remove file'))
    }
  } catch (err) {
    yield put(deleteFileFailure(err));
  }
}

export function* watchDeleteFile() {
  yield takeLatest(FileActions.DELETE_FILE, deleteFileSaga);
}
