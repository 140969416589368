import { call, put, takeLatest } from 'redux-saga/effects';
import { flashSuccess, flashError } from '@/actions/sagas/messageSaga';

import { PacketActions, DenyPacket, denyPacketFailure, denyPacketSuccess } from '@/actions/actions/packet';
import Api from '@/api/Api';
import { buildPath, Routes } from '@/utils/routeUtils';

export function* denyPacketSaga(
  action: DenyPacket
) {
  try {
    const { approvalAction, packetNumber } = action.payload;

    const url = buildPath(Routes.api.packets, `${packetNumber}/approvalpath/approvalaction`)

    const response = yield call(Api.utility.put, url, approvalAction);

    yield put(denyPacketSuccess(response.data));
    yield put(flashSuccess('Packet Denied!'));
  } catch (err) {
    yield put(denyPacketFailure(err));
    yield put(flashSuccess('Error: Packet Not Denied!'));
  }
}

export function* watchDenyPacket() {
  yield takeLatest(PacketActions.DENY_PACKET, denyPacketSaga);
}
