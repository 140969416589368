import React from 'react';

export interface ToggleProps {
  /**
   * Label
   */
  label: string;
  /**
   * Label for off value
   */
  offLabel?: string;
  /**
   * Label for on value
   */
  onLabel?: string;
  /**
   * ClassName value, either 'on' or ''
   */
  value?: string;
  /**
   * setvalue function
   */
  setValue: (e) => void;
}

/**
 * UI component for toggle switches
 */
export const Toggle: React.FC<ToggleProps> = ({
  label,
  value,
  setValue,
  onLabel,
  offLabel,
}) => {
  return (
    <div className="toggle__container">
      <p className="toggle__label">{`${label}`}</p>
      <div
        className={`toggle__outer ${value ? 'on' : ''}`}
        onClick={() => setValue(!value)}>
        <div className="toggle__switch"></div>
        {offLabel && <div className={`toggle__off-label`}>{offLabel}</div>}
        {onLabel && <div className={`toggle__on-label`}>{onLabel}</div>}
      </div>
    </div>
  );
};
