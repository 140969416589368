import classNames from 'classnames';
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReqFieldsContext } from '../../req-fields';
import { getFieldValues } from '@/actions/actions/field_values';
import { selectFieldValues } from '@/selectors/field_values';
import { FieldValuesSearchArgs } from '@/types/field_values';
import { PacketForm as PacketFormType } from '@/types/packet_form';

import {
  TextFieldForWizard,
  RadioButtons,
  Checkbox,
  DatePickerPlus,
  SignatureField,
  DropdownSelect,
} from '@/components/ui';
import { SubSection } from '@/contexts/packets-page/form-wizard/packet-form/sections/section/subsection';
import { FileUploadSection } from './file-upload-section';
import { Role } from '@/types/packet';
import { Icon } from '@/components/ui/icon';
import { useContext } from 'react';

export interface SectionProps {
  isLastPage: boolean;
  section: any;
  state: any;
  localDispatch: React.Dispatch<any>;
  requiredFieldsError: boolean;
  currentUserRole: Role;
  setModalCategory: React.Dispatch<React.SetStateAction<string>>;
  setSection: React.Dispatch<React.SetStateAction<any>>;
  savePacketForm: (packetForm?: PacketFormType, autosave?: boolean) => void;
  renderAllSections: boolean;
  requiredFieldIds: string[];
  sectionIndex: number;
  packetFormSectionsLength: number;
  isOpen?: boolean;
  handleToggleOpen: (index: number) => void;
  index: number;
  setStateChangeDetected: React.Dispatch<React.SetStateAction<boolean>>;
  setSuggestedValues: React.Dispatch<React.SetStateAction<string[]>>;
  handleChange: any;
  fieldId: string;
  setFieldId: React.Dispatch<React.SetStateAction<string>>;
  suggestedValues: string[];
}

type Locations = {
  ref: string;
  state: string;
};

export type handleChangeConfig = {
  value: string | number | boolean | null;
  locations: Locations;
  field: any;
};

export const Section: React.FC<SectionProps> = ({
  isLastPage,
  section,
  state,
  localDispatch,
  requiredFieldsError,
  currentUserRole,
  setModalCategory,
  setSection,
  savePacketForm,
  renderAllSections,
  requiredFieldIds,
  sectionIndex,
  packetFormSectionsLength,
  isOpen,
  handleToggleOpen,
  index,
  setStateChangeDetected,
  setSuggestedValues,
  handleChange,
  fieldId,
  setFieldId,
  suggestedValues,
}) => {
  let keyCount = 0;
  const errorMessage = 'This field is required';
  const [sectionLabel, setSectionLabel] = useState(null);

  const fieldValues = useSelector(selectFieldValues);
  //@ts-ignore
  const [ReqFields, setReqFields] = useState([]);

  useEffect(() => {
    if (section) {
      setSection(section);
      setSectionLabel(section.label);
      section.label !== sectionLabel && setStateChangeDetected(false);
    }
  }, [section]);

  useEffect(() => {
    fieldValues && setSuggestedValues(fieldValues?.suggestedValues);
  }, [fieldValues]);

  const handleAutoSaveForm = () => {
    setReqFields(requiredFieldIds);
    
    console.log('Reqfields', requiredFieldIds);
  };
  
  useEffect(() => {
    setReqFields(requiredFieldIds);
    localStorage.setItem('reqItems', ReqFields.length.toString())
    // console.log('reqItems', localStorage.getItem('reqItems'))
    // console.log('reqFields', ReqFields)
    // console.log('ReqFields length', requiredFieldIds.length)
  })

  const getKey = (prefix) => {
    keyCount = keyCount += 1;
    return `${prefix}-${keyCount}`;
  };

  const renderField = (field, locationReference, locationInState, key) => {
    {
      field.isRequired &&
        !field.value &&
        !field.defaultValue &&
        !eval(locationInState) &&
        !requiredFieldIds.includes(field.id) &&
        requiredFieldIds.push(field.id);
    }

    const isFieldDisabled: boolean =
      field.rules && field.rules['InputState'] === 'Disabled';
    const value = eval(locationInState) || field.value || field.defaultValue;

    const calculateValue = () => {
      const inState = eval(locationInState);

      if (inState || inState === '') {
        return inState;
      } else if (inState === null) {
        if (field.defaultValue) {
          return field.defaultValue;
        } else if (field.defaultValue === null) {
          return '';
        }
      }
    };

    switch (field.type.toLowerCase()) {
      case 'textfield':
      case 'textareafield':
        return (
          <TextFieldForWizard
            className={field.id}
            key={key}
            textArea={field.type.toLowerCase() === 'textarea'}
            label={field.label}
            defaultValue={value}
            value={calculateValue()}
            required={field.isRequired}
            onChange={(value, isKeystroke = false) =>
              handleChange(
                {
                  value: value,
                  locations: { ref: locationReference, state: locationInState },
                  field,
                },
                isKeystroke,
              )
            }
            message={field.helpText}
            errorMessage={renderErrorMessage(field)}
            suggestedValues={fieldId === field.id && suggestedValues}
            disabled={isFieldDisabled}
            handleOnBlur={handleAutoSaveForm}
          />
        );
      case 'radiobuttonfield':
        const radioOptions = field.valueOptions.map((option) => {
          return { value: option.label, label: option.label };
        });
        return (
          <RadioButtons
            className={field.id}
            key={key}
            label={field.label}
            value={value}
            options={radioOptions}
            message={field.helpText}
            onClick={(value) =>
              handleChange({
                value: value,
                locations: { ref: locationReference, state: locationInState },
                field,
              })
            }
            required={field.isRequired}
            errorMessage={renderErrorMessage(field)}
            disabled={isFieldDisabled}
          />
        );
      case 'datefield':
        return (
          <DatePickerPlus
            className={`${field.id} date-picker-plus-for-wizard input-container`}
            key={key}
            noFutureDates={false}
            label={field.label}
            onChange={(date) =>
              handleChange({
                value: date,
                locations: { ref: locationReference, state: locationInState },
                field,
              })
            }
            value={value}
            required={field.isRequired}
            message={field.helpText}
            errorMessage={renderErrorMessage(field)}
            disabled={isFieldDisabled}
            handleOnBlur={handleAutoSaveForm}
          />
        );
      case 'checkboxfield':
        return (
          <Checkbox
            className={field.id}
            key={key}
            label={field.label}
            value={value || 'false'}
            message={field.helpText}
            required={field.isRequired}
            defaultValue={value}
            errorMessage={renderErrorMessage(field)}
            disabled={isFieldDisabled}
            onClick={(value) =>
              handleChange({
                value: value,
                locations: { ref: locationReference, state: locationInState },
                field,
              })
            }
          />
        );
      case 'dropdownfield':
        const options = field.valueOptions.map((option) => {
          return { value: option.value, label: option.label, header: false };
        });
        return (
          <DropdownSelect
            className={field.id}
            key={key}
            options={options}
            label={field.label}
            selectedValue={value}
            onClick={(value) =>
              handleChange({
                value: value,
                locations: { ref: locationReference, state: locationInState },
                field,
              })
            }
            message={field.helpText}
            required={field.isRequired}
            errorMessage={renderErrorMessage(field)}
            disabled={isFieldDisabled}
          />
        );
      case 'dropdown':
        const dropdownOptions = field.selectOptions.map((option) => {
          return { value: option, label: option, header: false };
        });

        return (
          <DropdownSelect
            className={field.id}
            key={key}
            options={dropdownOptions}
            label={field.label}
            selectedValue={value}
            onClick={(value) =>
              handleChange({
                value: value,
                locations: { ref: locationReference, state: locationInState },
                field,
              })
            }
            required={field.isRequired}
          />
        );
      case 'signaturefield':
        return (
          <SignatureField
            className={field.id}
            key={key}
            label={field.label}
            handleSubmit={(value) =>
              handleChange({
                value: value,
                locations: { ref: locationReference, state: locationInState },
                field,
              })
            }
            required={field.isRequired}
            errorMessage={renderErrorMessage(field)}
            message={field.helpText}
            value={
              eval(locationInState) === null
                ? null
                : eval(locationInState) || field.defaultValue || field.value
            }
            disabled={isFieldDisabled}
          />
        );
    }
  };

  const renderErrorMessage = (field) => {
    if (
      state?.requiredFieldIds?.includes(field.id) &&
      state?.activeFieldIds?.includes(field.id)
    ) {
      return errorMessage;
    }
    if (state?.requiredFieldIds?.includes(field.id) && requiredFieldsError) {
      return errorMessage;
    }
  };

  const smallThumbnailStyle = {
    maxWidth: '100%',
    maxHeight: 125,
    cursor: 'pointer',
  };

  return (
    <>
      {state &&
        [section].map((_section) => {
          let pageIndex = _section?.pageNumber;
          let section1Index = _section?.sectionIndex;
          if (_section?.sections) {
            return (
              <div
                className={`packet-form__section ${
                  sectionIndex === packetFormSectionsLength - 1
                    ? 'last-section'
                    : ''
                }`}
                key={getKey('page')}>
                {_section?.image && !renderAllSections && (
                  <img
                    src={section.image}
                    style={smallThumbnailStyle}
                    onClick={() => setModalCategory('pdf-preview')}
                  />
                )}
                <div
                  className="packet-form__header-container"
                  onClick={() => handleToggleOpen(index)}>
                  <h3 className="packet-form__header">{section.label}</h3>
                  <Icon
                    iconClass={classNames('chevron-down', {
                      open: isOpen,
                    })}
                    color="var(--neutral1)"
                  />
                </div>
                {isOpen && (
                  <SubSection
                    setModalCategory={setModalCategory}
                    sectionImage={_section.image}
                    pageIndex={pageIndex}
                    section1Index={section1Index}
                    renderField={renderField}
                    section={_section}
                  />
                )}
              </div>
            );
          }
          if (_section?.fields?.length > 0) {
            return (
              <div className="packet-form__field--row" key={getKey('__form')}>
                {_section.fields.map((field, index) => {
                  const locationReference = `_pages[${pageIndex}].sections[${section1Index}].fields[${index}].value = value`;
                  const locationInState = `state.packetForm.form.pages[${pageIndex}].sections[${section1Index}].fields[${index}].value`;
                  return (
                    <>
                      {renderField(
                        field,
                        locationReference,
                        locationInState,
                        getKey('_field_'),
                      )}
                    </>
                  );
                })}
              </div>
            );
          }
        })}
      {isLastPage && (
        <div className="packet-form__field" key={getKey('__file_upload_form')}>
          <FileUploadSection
            packetFormBundleId={state.packetForm.packetFormBundleId}
            packetFormId={state.packetForm.packetFormId}
            currentUserRole={currentUserRole}
          />
        </div>
      )}
    </>
  );
};
