import React, { useState } from 'react';
import {
  Button,
  ValueLabelTable,
  ParentValueList,
  AttachmentList,
  LabelValueInput,
} from '@/components/ui';
import { Link } from 'react-router-dom';
import { updateUserProfile } from '@/actions/actions/user';

export interface InfoCardProps {
  /**
   * Does this card need a header?
   */
  header?: string;
  headerLinks?: Array<string>;
  /**
   * Does this card need a header?
   */
  edit?: boolean;
  /**
   * data to be displayed
   */
  data?: Array<{ label: string; value: any; type: string }>;
  discoveredData?: Array<{ label: string; value: any; type: string }>;
  toggleData?: boolean;
  showGeneral?: () => void;
  showAdditional?: () => void;
  onClick: any;
  component?: string;
  hasLink?: boolean;
  linkLabel?: string;
  linkAction?: any;
  linkActionPage?;
  string;
}

export const InfoCard: React.FC<InfoCardProps> = ({
  header,
  headerLinks,
  edit = true,
  data,
  discoveredData,
  toggleData,
  showGeneral,
  showAdditional,
  onClick,
  component = 'value-label-table',
  hasLink,
  linkLabel,
  linkAction,
  linkActionPage,
  ...props
}) => {
  const displayComponent = (data) => {
    switch (component) {
      case 'value-label-table':
        return <ValueLabelTable data={data} className="info-card-table" />;
      case 'parent-value-list':
        return <ParentValueList data={data} drawerOpen={false} />;
      case 'attachment-list':
        return <AttachmentList data={data} />;
      case 'label-value-input':
        return <LabelValueInput data={data} />;
      default:
        return <ValueLabelTable data={data} className="info-card-table" />;
    }
  };

  return (
    <div className="info-card container">
      <div className="info-card-header-parent container">
        {header && <h3 className="info-card-header">{header}</h3>}
        {headerLinks && (
          <>
            <h3
              className={`info-card-header-link ${
                toggleData ? 'selected' : ''
              }`}
              onClick={showGeneral}>
              {headerLinks[0]}
            </h3>
            <h3
              className={`info-card-header-link ${
                !toggleData ? 'selected' : ''
              }`}
              onClick={showAdditional}>
              {headerLinks[1]}
            </h3>
          </>
        )}
        {hasLink && (
          <span
            onClick={() => linkAction(linkActionPage)}
            className="info-card-link">
            {linkLabel}
            <span className="icon arrow-right link-icon"></span>
          </span>
        )}
        {edit && (
          <Button
            primary={true}
            label="Edit"
            size="small"
            iconClass="edit"
            iconColor="var(--neutral1)"
            onClick={(e) => {
              onClick();
              e.target.blur();
            }}
            borderColor="transparent"
          />
        )}
        {toggleData ? displayComponent(data) : displayComponent(discoveredData)}
      </div>
    </div>
  );
};
