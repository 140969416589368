import React, { useState } from 'react';

import { Packet } from '@/types/packet';
import { PacketPreview } from '@/contexts/create-packet-page/section-one/packet-previews/packet-preview';

type PacketInfo = {
  packet: Array<Packet | any>;
  title: string;
  details: string[];
};

export interface PacketPreviewsProps {
  packetInfo: PacketInfo[];
  selectedPackets: string[];
  onClick: (e) => void;
}

export const PacketPreviews: React.FC<PacketPreviewsProps> = ({
  packetInfo,
  onClick,
  selectedPackets,
}) => {
  let keyCount = 0;

  const getKey = (prefix) => {
    keyCount = keyCount += 1;
    return `${prefix}-${keyCount}`;
  };

  return (
    <div className="packet-preview__container">
      {packetInfo?.length ? (
        packetInfo.map((preview) => {
          return (
            <PacketPreview
              key={getKey('packet-preview')}
              preview={preview}
              selectedPackets={selectedPackets}
              onClick={onClick}
            />
          );
        })
      ) : (
        <p className="packet-preview__container--no-match">No Results.</p>
      )}
    </div>
  );
};
