import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { PacketApprovers } from '@/contexts/packets-page/packet-approvers/index';
import { PacketDetails } from '@/contexts/packets-page/packet-details';
import { getPacket, updatePacket, resetPacket } from '@/actions/actions/packet';
import { DropdownSelect, Loading } from '@/components/ui';
import { SubNavbar } from '@/contexts/packets-page/sub-navbar';
import { PacketActions } from '@/contexts/packets-page/packet-actions';
import { selectPacket } from '@/selectors/packet';
import { FormWizard } from '@/contexts/packets-page/form-wizard';
import { Packet } from '@/types/packet';
import { ApproverPacketActions } from '@/contexts/packets-page/packet-actions/user-role-packet-actions/approver';
import { EditorPacketActions } from '@/contexts/packets-page/packet-actions/user-role-packet-actions/editor';
import { resetPacketForm } from '@/actions/actions/packet_form';

type LoadingState = {
  message: string;
};

interface ParamTypes {
  packetId: string;
}

const PacketsPage = (): JSX.Element => {
  const [isActive, setIsActive] = useState<string>(null);
  const [packetData, setPacketData] = useState<Packet>(null);
  const dispatch = useDispatch();
  const { packetId } = useParams<ParamTypes>();
  const history = useHistory();
  const [loadingConfig, setLoadingConfig] = useState<LoadingState>(null);
  const [totalPercentageCompleted, setTotalPercentageCompleted] = useState<
    number
  >(0);

  const packet = useSelector(selectPacket);

  useEffect(() => {
    history.listen((location) => {
      if (location) {
        updateActiveTab();
      }
    });

    updateActiveTab();
    return () => {
      dispatch(resetPacket());
      dispatch(resetPacketForm());
      setPacketData(null);
    };
  }, []);

  const updateActiveTab = (tab?: string) => {
    if (tab) setIsActive(tab);
    else if (window?.location?.href?.split('#')[1])
      setIsActive(window?.location?.href?.split('#')[1]);
    else setIsActive('forms');
  };

  useEffect(() => {
    if (packetId && packet?.packetId !== parseInt(packetId)) {
      dispatch(resetPacket());
    }
    packetId && dispatch(getPacket(parseInt(packetId)));
  }, [packetId]);

  useEffect(() => {
    packet && setPacketData(packet);
  }, [packet]);

  const setActiveSidePanelButton = (category) => {
    setIsActive(category);
  };

  const updatePacketStatus = ({ statusCode, message }) => {
    let _packetData = JSON.parse(JSON.stringify(packetData));

    if (statusCode) {
      _packetData.statusId = statusCode;
    }
    setLoadingConfig({ message: message });
    dispatch(updatePacket(_packetData.packetId, _packetData));

    // NOTE: If we have a statusCode of undefined, we know that we
    // are just saving the packet and do not want to redirect the
    // user back to the packets page
    if (statusCode) {
      setTimeout(() => {
        history.push('/packets');
      }, 2000);
    } else {
      setTimeout(() => {
        setLoadingConfig(null);
      }, 1000);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      setLoadingConfig(null);
    };
  }, []);

  return (
    <div className="packets-page page__container bg-blue-gradient">
      <div className="page__container--content">
        <div
          className="container page__container--wizard"
          style={{ backgroundColor: 'transparent' }}>
          <PacketActions
            backButton={true}
            header={packetData ? packetData?.name : ''}
            className="packet__actions--packets-page">
            {packetData?.currentUser?.role?.toLowerCase() === 'editor' && (
              false && <EditorPacketActions
                packetData={packetData}
                updatePacketStatus={updatePacketStatus}
                totalPercentageCompleted={totalPercentageCompleted}
              />
            )}
            {packetData?.currentUser?.role?.toLowerCase() === 'approver' && (
              <ApproverPacketActions packetData={packetData} />
            )}
          </PacketActions>
          <DropdownSelect
            label={'Section'}
            options={[
              { value: 'details', label: 'Packet Details' },
              { value: 'forms', label: 'Forms' },
              { value: 'approvers', label: 'Approvers' },
            ]}
            selectedValue={isActive}
            name="section"
            onClick={(value) => setIsActive(value)}
            className="packets-page__dropdown-nav"
          />
          <SubNavbar
            isActive={isActive}
            onClick={setActiveSidePanelButton}
            shouldShowApprovers={!!packet?.approvalPath}
          />
          {isActive === 'packet-details' && (
            <PacketDetails packet={packetData} setIsActive={setIsActive} />
          )}
          {isActive === 'forms' && (
            <FormWizard
              formPacket={packetData}
              totalPercentageCompleted={totalPercentageCompleted}
              setTotalPercentageCompleted={setTotalPercentageCompleted}
              updatePacketStatus={updatePacketStatus}
            />
          )}
          {isActive === 'approvers' && (
            <PacketApprovers approvalPath={packetData?.approvalPath} />
          )}
          {/* {isActive === 'status' && <PacketStatus statusData={packetData} />} */}
        </div>
      </div>
      {loadingConfig && (
        <Loading
          message={`${loadingConfig.message} Packet...`}
          backgroundColor="rgba(0, 0, 0, 0.3)"
        />
      )}
    </div>
  );
};

export default PacketsPage;
