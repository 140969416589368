import { ApprovalTimeline } from '@/types/approval_timeline';

// Action Types
export enum ApprovalTimelinesActions {
  GET_APPROVAL_TIMELINES = 'PACKET/GET_APPROVAL_TIMELINES',
  GET_APPROVAL_TIMELINES_SUCCESS = 'PACKET/GET_APPROVAL_TIMELINES_SUCCESS',
  GET_APPROVAL_TIMELINES_FAILURE = 'PACKET/GET_APPROVAL_TIMELINES_FAILURE',
}

// Action Interfaces
export interface GetApprovalTimelines {
  type: typeof ApprovalTimelinesActions.GET_APPROVAL_TIMELINES;
}

export interface GetApprovalTimelinesSuccess {
  type: typeof ApprovalTimelinesActions.GET_APPROVAL_TIMELINES_SUCCESS;
  payload: {
    approvalTimelines: ApprovalTimeline[];
  }
}

export interface GetApprovalTimelinesFailure {
  type: typeof ApprovalTimelinesActions.GET_APPROVAL_TIMELINES_FAILURE;
  payload: {
    error: string;
  }
}


// Action Type List
export type ApprovalTimelineAction = GetApprovalTimelines | GetApprovalTimelinesFailure | GetApprovalTimelinesSuccess

// Action Creators
export const getApprovalTimelines = (): ApprovalTimelineAction => ({
  type: ApprovalTimelinesActions.GET_APPROVAL_TIMELINES,
});

export const getApprovalTimelinesSuccess = (approvalTimelines: ApprovalTimeline[]): ApprovalTimelineAction => ({
  type: ApprovalTimelinesActions.GET_APPROVAL_TIMELINES_SUCCESS,
  payload: {
    approvalTimelines,
  }
});

export const getApprovalTimelinesFailure = (error: string): ApprovalTimelineAction => ({
  type: ApprovalTimelinesActions.GET_APPROVAL_TIMELINES_FAILURE,
  payload: {
    error,
  }
});
