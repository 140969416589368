import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Button } from '@/components/ui';
import { selectDeviceType } from '@/selectors/device_type';
export interface PacketActionProps {
  header?: string;
  children: React.ReactNode;
  className?: string;
  backButton?: boolean;
}

export const PacketActions: React.FC<PacketActionProps> = ({
  header,
  children,
  className,
  backButton,
}) => {
  const history = useHistory();

  const deviceType = useSelector(selectDeviceType);

  return (
    <div
      className={`packet__actions md-flex-col ${className ? className : ''
        }`}>
      {deviceType === 'mobile' && backButton && (
        <Button
          className="packet__actions--back-button"
          backgroundColor="transparent"
          iconClass="back"
          label="Back"
          labelColor="var(--neutral4)"
          borderColor="transparent"
          primary={true}
          size="small"
          transformText={false}
          onClick={() => {
            history.goBack();
            window.scrollTo({ top: 0, behavior: 'auto' });
          }}
        />
      )}
      {
        header &&
        <div className='packet__actions--header-container'>
          <Button
            borderColor="transparent"
            backgroundColor="transparent"
            iconColor="#fff"
            iconClass="back"
            label="Back"
            primary={false}
            transformText={false}
            onClick={() => history.goBack()}
            labelColor="#fff"
          />
          <h2 className="packet__actions--header">{header}</h2>
        </div>
      }
      <div className="packet__actions--buttons">{children}</div>
    </div>
  );
};
