import api from '@/api/Api';
import jwt_decode from 'jwt-decode';

var baseUrl = process.env.AUTH_URL;
export async function getAuth() {
  var state = generateRandomString();
  localStorage.setItem('pkce_state', state);

  var code_verifier = generateRandomString();
  localStorage.setItem('pkce_code_verifier', code_verifier);

  var code_challenge = await pkceChallengeFromVerifier(code_verifier);

  var redirectUrl = process.env.REDIRECT_URL;

  var endpoint = baseUrl + "/auth";
    //'https://login.tackleai.net/auth/realms/Dev-TackleAI/protocol/openid-connect/auth';
  var url =
    endpoint +
    '?response_type=code' +
    '&client_id=' +
    encodeURIComponent('hrforms') +
    '&redirect_uri=' +
    encodeURIComponent(redirectUrl) +
    '&state=' +
    '&scope=' +
    encodeURIComponent('openid roles') +
    '&code_challenge=' +
    encodeURIComponent(code_challenge) +
    '&code_challenge_method=S256';

  window.location = url;
}

export function checkParse() {
  var tokenEndpoint = baseUrl + "/token";
    // 'https://login.tackleai.net/auth/realms/Dev-TackleAI/protocol/openid-connect/token';
  var q = parseQueryString(window.location.search.substring(1));
  console.log('parsed == ', api);
  let accessToken;

  var redirectUrl = process.env.REDIRECT_URL;
  if (q.code) {
    console.log('q code ==', q.code);

    console.log('pkce_state', localStorage.getItem('pkce_state'));
    console.log('q state', q.state);
    // Verify state matches what we set at the beginning
    if (false) {
      //|| localStorage.getItem("pkce_state") != q.state) {
      alert('Invalid state');
    } else {
      // Exchange the authorization code for an access token

      sendPostRequest(
        tokenEndpoint,
        {
          grant_type: 'authorization_code',
          code: q.code,
          client_id: 'hrforms', //config.client_id,
          redirect_uri: redirectUrl,
          code_verifier: localStorage.getItem('pkce_code_verifier'),
        },
        function (request, body) {
          // Initialize your application now that you have an access token.
          // Here we just display it in the browser.
          // document.getElementById("access_token").innerText = body.access_token;
          // document.getElementById("start").classList = "hidden";
          // document.getElementById("token").classList = "";
          console.log('success!', body);
          accessToken = body.access_token;
          console.log('access token ==', accessToken);

          api.setBearerToken(body.access_token);
          api.setRefreshToken(body.refresh_token);

          console.log('updated api', api);
          localStorage.setItem('bearer_token', body.access_token);
          localStorage.setItem('refresh_token', body.refresh_token);
          const decoded = jwt_decode(body.access_token);
          localStorage.setItem('user_id', decoded.sub);

          // Replace the history entry to remove the auth code from the browser address bar
          window.history.replaceState({}, null, '/');
          window.location = '/';
        },
        function (request, error) {
          // This could be an error response from the OAuth server, or an error because the
          // request failed such as if the OAuth server doesn't allow CORS requests
          // document.getElementById("error_details").innerText = error.error+"\n\n"+error.error_description;
          // document.getElementById("error").classList = "";
          console.log('error!', error);
        },
      );
    }

    // Clean these up since we don't need them anymore
    localStorage.removeItem('pkce_state');
    localStorage.removeItem('pkce_code_verifier');
  }
}

function parseQueryString(string) {
  if (string == '') {
    return {};
  }
  var segments = string.split('&').map((s) => s.split('='));
  var queryString = {};
  segments.forEach((s) => (queryString[s[0]] = s[1]));
  return queryString;
}

// + "&redirect_uri=https%3A%2F%2Foauth.pstmn.io%2Fv1%2Fcallback"

function generateRandomString() {
  var array = new Uint32Array(28);
  // window.crypto.getRandomValues(array);
  return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join(
    '',
  );
}

async function pkceChallengeFromVerifier(v) {
  // var hashed = await sha256(v);
  // console.log('here is my hashed', hashed);
  // const s =  base64urlencode(hashed);
  // console.log('ss == ', s);
  // return s;
  return "vQOsFCjw6ob0uDpzH_x5Z7uChm2FRTIviI0vboV__Bg";
}

// Calculate the SHA256 hash of the input text.
// Returns a promise that resolves to an ArrayBuffer
function sha256(plain) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  const sha =  window.crypto.subtle.digest('SHA-256', data);
  console.log('here is my sha', sha);
  return window.crypto.subtle.digest('SHA-256', data);
}

// Base64-urlencodes the input string
function base64urlencode(str) {
  // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
  // btoa accepts chars only within ascii 0-255 and base64 encodes them.
  // Then convert the base64 encoded to base64url encoded
  //   (replace + with -, replace / with _, trim trailing =)
  return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
}

// Make a POST request and parse the response as JSON
function sendPostRequest(url, params, success, error) {
  var request = new XMLHttpRequest();
  request.open('POST', url, true);
  request.withCredentials = true;
  request.setRequestHeader(
    'Content-Type',
    'application/x-www-form-urlencoded; charset=UTF-8',
  );
  request.onload = function () {
    var body = {};
    try {
      body = JSON.parse(request.response);
    } catch (e) {}

    if (request?.status == 200) {
      success(request, body);
    } else {
      error(request, body);
    }
  };
  request.onerror = function () {
    error(request, {});
  };
  var body = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  request.send(body);
}

export function refreshAuthToken() {
  const tokenEndpoint = baseUrl + "/token";
    // 'https://login.tackleai.net/auth/realms/Dev-TackleAI/protocol/openid-connect/token';

  let request = new XMLHttpRequest();
  request.open('POST', tokenEndpoint, false);
  request.withCredentials = true;
  request.setRequestHeader(
    'Content-Type',
    'application/x-www-form-urlencoded; charset=UTF-8',
  );

  let requestBody = {
    client_id: 'hrforms',
    grant_type: 'refresh_token',
    refresh_token: localStorage.getItem('refresh_token'),
  };

  var body = Object.keys(requestBody)
    .map((key) => key + '=' + requestBody[key])
    .join('&');

  try {
    request.send(body);

    if (request?.status === 200) {
      const body = JSON.parse(request.response);

      const accessToken = body.access_token;
      const refreshToken = body.refresh_token;

      api.setBearerToken(accessToken);
      api.setRefreshToken(refreshToken);

      localStorage.setItem('bearer_token', accessToken);
      localStorage.setItem('refresh_token', refreshToken);
      const decoded = jwt_decode(accessToken);
      localStorage.setItem('user_id', decoded.sub);

      return accessToken;
    } else {
      console.log('error!', request.status, request.statusText);
    }
  } catch (error) {
    console.log('error!', error);
  }
}

export function logoutUser() {
  localStorage.clear();
  window.location = baseUrl + `/logout?redirect_uri=${process.env.REDIRECT_URL}`;
}
