import React from 'react';
import { Button } from '@/components/ui';

export interface ConfirmationCardProps {
  text: string;
  arrayIdx: number;
  status?: string;
  data?: number;
  onSubmit: any;
  toggleModal: any;
}

export const ConfirmationCard: React.FC<ConfirmationCardProps> = ({
  text,
  data,
  onSubmit,
  toggleModal,
  arrayIdx,
  status
}) => {


  return (
    <div>
      <div>
        {text}
      </div>
      <div>
        <div className='confirmation-card__data'>{data}</div>
      </div>
      <div className="button-group">
        <Button
          primary={true}
          label="no"
          size="large"
          border={true}
          onClick={toggleModal}
        />
        <Button
          primary={false}
          label="yes"
          size="large"
          border={true}
          backgroundColor="var(--secondary1)"
          type="submit"
          labelColor="#fff"
          onClick={() => {
            onSubmit(arrayIdx, status)
            toggleModal()
          }}
        />
      </div>

    </div>
  );
};
