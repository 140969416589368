import React, { useEffect, useRef } from 'react';

import { Modal } from '@/components/ui';
export interface PacketPreviewModalProps {
  preview: any;
  renderModal: boolean;
  setRenderModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PacketPreviewModal: React.FC<PacketPreviewModalProps> = ({
  preview,
  setRenderModal,
}) => {
  let keyCount = 0;

  const getKey = (prefix) => {
    keyCount = keyCount += 1;
    return `${prefix}-${keyCount}`;
  };

  const getFiles = () => {
    const _fileCount = preview.packet.forms.map((form) => {
      return (
        <div key={getKey('packet-preview-packet')}>
          <p className="packet-preview-modal__packet-preview-packet-name">
            {form.formName}
          </p>
          {form.pages && (
            <p className="packet-preview-modal__packet-preview-packet-pages">
              {form.pages.length} page{form.pages?.length === 1 ? '' : 's'}
            </p>
          )}
        </div>
      );
    });
    return _fileCount.flat(1);
  };

  useEffect(() => {
    document.querySelector('html').style.overflow = 'hidden';

    return () => {
      document.querySelector('html').style.overflow = 'auto';
    };
  }, []);

  return (
    <Modal
      header={preview.title}
      handleClickX={() => setRenderModal(false)}
      clickOutsideToClose={true}>
      <p className="packet-preview-modal__file-count">{getFiles().length}</p>
      {getFiles()}
    </Modal>
  );
};
