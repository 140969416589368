import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Role } from '@/types/packet';
import { Packet } from '@/types/packet';
import { getUsers } from '@/actions/actions/users';
import { selectUsers } from '@/selectors/users';
import { selectUser } from '@/selectors/user';
import api from '@/api/Api';
import {
  getUserProfile,
  getOnBehalfOfUserProfile,
} from '@/actions/actions/user';
import { User } from '@/types/user';
import {
  Toggle,
  ProgressBar,
  RadioButtons,
  DropdownSelect,
} from '@/components/ui';
import { DropdownOptions } from '@/types/dropdown_options';

type UseCases = {
  formUseCaseId: number;
  name: string;
};

type FormInfo = {
  name: string;
  formId: number | null;
  useCaseId: number | null;
  useCases: UseCases[];
  currentUserRole: Role;
};
export interface PacketFormSummaryProps {
  formInfo: FormInfo;
  currentView: string;
  setCurrentView: (arg) => void;
  updateUseCase: (useCaseId) => void;
  setCurrentSection: (section) => void;
  currentSection: number;
  packetFormSections: any;
  formPacket: Packet;
  noSections: boolean;
  filteredSections: any;
  hasFilteredSections: boolean;
  setFilteredSections: React.Dispatch<React.SetStateAction<boolean>>;
  setModalCategory: React.Dispatch<React.SetStateAction<any>>;
  setPacketSummaryExtended: React.Dispatch<React.SetStateAction<boolean>>;
  currentPacket: string;
  setCurrentPacket: React.Dispatch<React.SetStateAction<string>>;
  packetBundleOptions: DropdownOptions[];
  deviceType: string;
  renderAllSections: boolean;
}

export const PacketFormSummary: React.FC<PacketFormSummaryProps> = ({
  formInfo,
  currentView,
  setCurrentView,
  updateUseCase,
  setCurrentSection,
  packetFormSections,
  currentSection,
  formPacket,
  noSections,
  filteredSections,
  hasFilteredSections,
  setFilteredSections,
  setModalCategory,
  setPacketSummaryExtended,
  currentPacket,
  setCurrentPacket,
  packetBundleOptions,
  deviceType,
  renderAllSections,
}) => {
  const dispatch = useDispatch();
  const [useCaseName, setUseCaseName] = useState<string>('');
  const [user, setUser] = useState<User>(null);
  const [userIndex, setUserIndex] = useState(null);

  const userData = useSelector(selectUsers);
  const currentUser = useSelector(selectUser);

  useEffect(() => {
    if (!currentUser) {
      document.querySelector('html').style.overflowY = 'hidden';
      dispatch(getUserProfile());
    } else if (currentUser) {
      document.querySelector('html').style.overflowY = 'auto';
      setUser(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    dispatch(getUsers('1'));
  }, []);

  useEffect(() => {
    if (user?.userId !== formPacket?.onBehalfOfUserId && formPacket) {
      dispatch(getOnBehalfOfUserProfile(formPacket?.onBehalfOfUserId));
    }
  }, [user, formPacket]);

  useEffect(() => {
    if (userData) {
      setUserIndex(
        userData.reduce((obj, user) => {
          obj[user.userId] = user;
          return obj;
        }, {}),
      );
    }
  }, [userData]);

  useEffect(() => {
    hasFilteredSections && setPacketSummaryExtended(true);
  }, [hasFilteredSections]);

  const toggleView = () => {
    currentView === 'wizard' ? setCurrentView('pdf') : setCurrentView('wizard');
  };

  useEffect(() => {
    formInfo && findUseCase();
  }, [formInfo]);

  const findUseCase = () => {
    const useCase = formInfo.useCases.filter((useCase) => {
      if (useCase.formUseCaseId === formInfo.useCaseId) {
        return useCase;
      }
    });
    useCase[0] && setUseCaseName(useCase[0].name);
  };

  const printForms = async (event) => {
    event.preventDefault();
    const FILE_HEADERS = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('bearer_token')}`,
        'Access-Control-Allow-Origin': '*',
      },
      responseType: 'blob',
    };

    // api/v1/packets/{packetId}/packetformbundle/{packetFormBundleId}/packetform/{packetFormId}/pdf
    // const url = `${process.env.APPLICATION_URL}/api/v1/packets/${formPacket.packetId}/packetformbundle/${formPacket.packetFormBundles[0].packetFormBundleId}/packetform/${formInfo.formId}/pdf`;
    const url = `${process.env.APPLICATION_URL}/api/v1/packets/${formPacket.packetId}/packetform/${formInfo.formId}/pdf`;

    // @ts-ignore
    const response = await api.utility.get(url, FILE_HEADERS);
    console.log(response);

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(response.data);
    link.download = `${formInfo.name}.pdf`;
    link.click();
    link.remove();
  };
  //console.log("current packet", formPacket)

  return (
    <div className="packet-form-summary ">
      <div className="packet-form-summary__container flex flex-wrap">
        <div className="flex flex-wrap align-items-center">
          <h2 className="packet-form-summary__header">{formInfo.name}</h2>

          <a href="return false;" onClick={(e) => printForms(e)}>
            Download Form
          </a>

          {formInfo.useCaseId && formInfo?.currentUserRole !== 'Viewer' && (
            <p className="packet-form-summary__use-case">
              Use Case: {useCaseName} -{' '}
              {formInfo?.currentUserRole !== 'Approver' && (
                <span
                  onClick={() => {
                    setCurrentSection(1);
                    updateUseCase(null);
                  }}>
                  RESET
                </span>
              )}
            </p>
          )}
        </div>
        <div className="packet-form-summary__buttons">
          <Toggle
            label="View"
            value={currentView === 'wizard' && true}
            setValue={toggleView}
            offLabel="PDF"
            onLabel="Wizard"
          />
        </div>
      </div>
      {!renderAllSections && (
        <div>
          <ProgressBar
            currentPageNumber={currentSection}
            totalPages={packetFormSections ? packetFormSections.length : 0}
            width={100}
            isWidthPercentage
            row
            section="Section"
          />
        </div>
      )}

      <div className="packet-form-summary__on-behalf-of flex flex-space-between align-items-center">
        {(formPacket?.currentUser?.role === 'Approver' ||
          formPacket?.currentUser?.role === 'Editor') &&
          !noSections &&
          currentView !== 'pdf' &&
          hasFilteredSections && (
            <RadioButtons
              className="form-wizard__approver-section-filter-toggle"
              label="View Sections"
              options={[
                { value: true, label: 'My Required Sections' },
                { value: false, label: 'All Sections' },
              ]}
              onClick={(value) => setFilteredSections(value)}
              value={filteredSections}
            />
          )}
        {userIndex && (
          <div className="packet-form-summary__on-behalf-of--user">
            Packet on behalf of{' '}
            <span className="packet-form-summary__on-behalf-of--user-name">
              {`${userIndex[formPacket?.onBehalfOfUserId]?.firstName}
                      ${userIndex[formPacket?.onBehalfOfUserId]?.lastName}
                      ${userIndex[formPacket?.onBehalfOfUserId]?.office || ""}`}
            </span>
          </div>
        )}
      </div>
      {deviceType === 'mobile' && packetBundleOptions && currentPacket && (
        <DropdownSelect
          label={'File'}
          options={packetBundleOptions}
          selectedValue={currentPacket}
          name="section"
          onClick={(value) => setCurrentPacket(value)}
          nullOption={false}
          renderSelectedOption={true}
          className="packet-form-dropdown"
        />
      )}
    </div>
  );
};
