import classNames from 'classnames';
import React, { useState } from 'react';

import { Button } from '../button';
import SignaturePadModal from './signature-modal';

export interface SignatureFieldProps {
  className: string;
  disabled?: boolean;
  errorMessage?: string;
  handleSubmit: (imageUrl: string) => void;
  inputDimensions?: { width: number; height: number };
  isPdf?: boolean;
  label: string;
  message?: string;
  required?: boolean;
  value?: string;
  isInvalid?: boolean;
}

/**
 * Primary UI component for signature input
 */
export const SignatureField: React.FC<SignatureFieldProps> = ({
  className,
  disabled,
  errorMessage,
  handleSubmit,
  inputDimensions,
  isPdf,
  label,
  message,
  required,
  value,
  isInvalid,
}) => {
  const [isSignatureModalShowing, setIsShowingSignatureModal] = useState<
    boolean
  >(false);

  const hasAddedSignature: boolean = !!value;

  const handleClose = () => {
    setIsShowingSignatureModal(false);
  };

  const renderClearSignature = () => {
    if (isPdf) {
      return (
        <div className="signature-field__x">
          <Button
            iconClass="x"
            iconColor="#000"
            onClick={() => handleSubmit(null)}
          />
        </div>
      );
    } else {
      if (!disabled) {
        return (
          <span
            className={classNames('signature-field__remove', {
              'is-pdf': isPdf,
            })}
            onClick={() => handleSubmit(null)}>
            Remove
          </span>
        );
      }
    }
  };

  const renderMainContent = () => {
    if (hasAddedSignature) {
      return (
        <>
          <div
            className="signature-field__image-container"
            style={{
              width: inputDimensions ? inputDimensions.width : '100%',
              height: inputDimensions ? inputDimensions.height : '100%',
            }}>
            <img src={value} />
          </div>

          {renderClearSignature()}
        </>
      );
    } else if (!disabled) {
      return (
        <div
          className={classNames('signature-field__button', {
            error: errorMessage || isInvalid,
            'is-pdf': isPdf,
          })}
          onClick={() => !disabled && setIsShowingSignatureModal(true)}
          style={{
            width: inputDimensions?.width,
            height: inputDimensions?.height,
          }}></div>
      );
    }
  };

  if (isPdf) {
    return (
      <div
        className={classNames('signature-field is-pdf', {
          [className]: className,
        })}>
        <label
          className={classNames('signature-field__label', {
            'field-required': required,
          })}>
          {label}
        </label>

        <div className="signature-field__main is-pdf">
          {renderMainContent()}
        </div>

        {message && <p className={`signature-field__message`}>{message}</p>}

        {errorMessage && (
          <p className={`signature-field__message--error`}>{errorMessage}</p>
        )}

        {isSignatureModalShowing && (
          <SignaturePadModal
            onClickClose={handleClose}
            onClickSubmit={handleSubmit}
          />
        )}
      </div>
    );
  }

  return (
    <div className={classNames('signature-field', { [className]: className })}>
      <label
        className={classNames('signature-field__label', {
          'field-required': required,
        })}>
        {label}
      </label>

      <div className="signature-field__main">{renderMainContent()}</div>

      {message && <p className={`signature-field__message`}>{message}</p>}

      {errorMessage && (
        <p className={`signature-field__message--error`}>{errorMessage}</p>
      )}

      {isSignatureModalShowing && (
        <SignaturePadModal
          onClickClose={handleClose}
          onClickSubmit={handleSubmit}
        />
      )}
    </div>
  );
};
