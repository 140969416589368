import React, { useState } from 'react';

import { Packet } from '@/types/packet';
import { PacketPreviewModal } from '@/contexts/create-packet-page/section-one/packet-previews/packet-preview-modal';

export interface PacketPreviewProps {
  preview: any;
  selectedPackets: string[];
  onClick: (e) => void;
}

export const PacketPreview: React.FC<PacketPreviewProps> = ({
  preview,
  selectedPackets,
  onClick,
}) => {
  const [renderModal, setRenderModal] = useState<boolean>(false);

  return (
    <>
      <div
        className={`packet-preview__parent ${
          preview?.packet?.forms && preview?.packet?.forms.length > 1
            ? 'packet-bundle'
            : ''
        }`}>
        <div
          className={`packet-preview 
            ${selectedPackets.includes(preview.title) ? 'selected' : ''}
            
          `}
          onClick={() => onClick(preview)}>
          <div className="packet-preview__select-box"></div>
          <div className="packet-preview__title-container">
            <p className="packet-preview__title">{preview.title}</p>
            {preview.details && Array.isArray(preview.details) && (
              <div
                className="packet-preview__view-details"
                onClick={(e) => {
                  e.stopPropagation();
                  setRenderModal(!renderModal);
                }}>
                View Details
              </div>
            )}
          </div>
        </div>
      </div>
      {renderModal && (
        <PacketPreviewModal
          preview={preview}
          renderModal={renderModal}
          setRenderModal={setRenderModal}
        />
      )}
    </>
  );
};
