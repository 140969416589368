import { UserActions } from '@/actions/actions/user';
import { OnBehalfOfuserState } from '@/types/user';

const initialState: OnBehalfOfuserState = {
  onBehalfOfUserProfile: null,
};

export default function reducer(
  state: OnBehalfOfuserState = initialState,
  action: any,
): OnBehalfOfuserState {
  const { type, payload } = action;
  switch (type) {
    case UserActions.GET_ON_BEHALF_OF_USER_PROFILE_SUCCESS:
      return {
        ...state,
        onBehalfOfUserProfile: payload.user
      }
    default:
      return state;
  }
}
