import { ApprovalPath } from '@/types/approval_path';

// Action Types
export enum ApprovalPathsActions {
  GET_APPROVAL_PATHS = 'PACKET/GET_APPROVAL_PATHS',
  GET_APPROVAL_PATHS_SUCCESS = 'PACKET/GET_APPROVAL_PATHS_SUCCESS',
  GET_APPROVAL_PATHS_FAILURE = 'PACKET/GET_APPROVAL_PATHS_FAILURE',
  SET_USER_APPROVAL_PATH = 'PACKET/SET_USER_APPROVAL_PATH',
}

// Action Interfaces
export interface GetApprovalPaths {
  type: typeof ApprovalPathsActions.GET_APPROVAL_PATHS;
}

export interface GetApprovalPathsSuccess {
  type: typeof ApprovalPathsActions.GET_APPROVAL_PATHS_SUCCESS;
  payload: {
    approvalPaths: ApprovalPath[];
  }
}

export interface GetApprovalPathsFailure {
  type: typeof ApprovalPathsActions.GET_APPROVAL_PATHS_FAILURE;
  payload: {
    error: string;
  }
}

export interface SetUserApprovalPath {
  type: typeof ApprovalPathsActions.SET_USER_APPROVAL_PATH;
  payload: {
    approvalPath: ApprovalPath;
  }
}


// Action Type List
export type ApprovalPathAction = GetApprovalPaths | GetApprovalPathsFailure | GetApprovalPathsSuccess | SetUserApprovalPath

// Action Creators
export const getApprovalPaths = (): ApprovalPathAction => ({
  type: ApprovalPathsActions.GET_APPROVAL_PATHS,
});

export const getApprovalPathsSuccess = (approvalPaths: ApprovalPath[]): ApprovalPathAction => ({
  type: ApprovalPathsActions.GET_APPROVAL_PATHS_SUCCESS,
  payload: {
    approvalPaths,
  }
});

export const getApprovalPathsFailure = (error: string): ApprovalPathAction => ({
  type: ApprovalPathsActions.GET_APPROVAL_PATHS_FAILURE,
  payload: {
    error,
  }
});

export const setUserApprovalPath = (approvalPath: ApprovalPath): ApprovalPathAction => ({
  type: ApprovalPathsActions.SET_USER_APPROVAL_PATH,
  payload: {
    approvalPath
  }
})
