import React from 'react';

import { ApprovalPath, Stop } from '@/types/approval_path';
import { DropdownSelect, Icon } from '@/components/ui';

type Mode = 'edit' | 'view';

type UserOptions = {
  label: string;
  value: string;
};

export interface UsersProps {
  selectedApprovalPath: ApprovalPath;
  mode: Mode;
  stop: Stop;
  stopIndex: number;
  userOptions: UserOptions[];
  userIndex: number;
  localDispatch: React.Dispatch<any>;
}

export const Users: React.FC<UsersProps> = ({
  selectedApprovalPath,
  mode,
  stop,
  stopIndex,
  userOptions,
  userIndex,
  localDispatch,
}) => {
  let keyCount = 0;

  const getKey = (prefix) => {
    keyCount = keyCount += 1;
    return `${prefix}-${keyCount}`;
  };

  const updateApprover = (value, path) => {
    const _selectedApprovalPath = selectedApprovalPath;
    const newUser = {
      userId: null,
      approvalStatusId: null,
    };

    eval(path);
    localDispatch({
      type: 'SELECTED_APPROVAL_PATH',
      key: 'selectedApprovalPath',
      value: _selectedApprovalPath,
    });
  };

  return (
    <>
      {stop.approvalStopDetail.users.map((user, index) => {
        const path = `_selectedApprovalPath.stops[${stopIndex}].approvalStopDetail.users[${index}].userId = value`;
        const removeUser = `_selectedApprovalPath.stops[${stopIndex}].approvalStopDetail.users.splice(${index}, 1)`;
        return (
          <div
            className="create-packet__section--approver--squence-group--approver"
            key={getKey('approver')}>
            {mode === 'edit' ? (
              <DropdownSelect
                label="Approver"
                options={userOptions}
                onClick={(value) => {
                  updateApprover(value, path);
                }}
                selectedValue={
                  selectedApprovalPath?.stops[stopIndex]?.approvalStopDetail
                    ?.users[index]?.userId
                }
                placeholder="Choose approver...."
                typeAhead={false}
                renderSelectedOption={true}
              />
            ) : (
              <p className="create-packet__section--approver--squence-group--approver--name">{`${
                userIndex[
                  selectedApprovalPath?.stops[stopIndex]?.approvalStopDetail
                    ?.users[index]?.userId
                ]?.firstName
              } ${
                userIndex[
                  selectedApprovalPath?.stops[stopIndex]?.approvalStopDetail
                    ?.users[index]?.userId
                ]?.lastName
              }`}</p>
            )}
            {index !== 0 && mode === 'edit' && (
              <Icon
                iconClass="x-enclosed"
                onClick={() => updateApprover(null, removeUser)}
                size="1.1rem"
              />
            )}
          </div>
        );
      })}
    </>
  );
};
