export function throttle(fn, ms) {
  let timeout;
  function exec() {
      fn.apply()
  }
  function clear() {
      timeout == undefined ? null : clearTimeout(timeout)
  }
  if(fn !== undefined && ms !== undefined) {
      timeout = setTimeout(exec, ms)
  }
  // @ts-ignore
  throttle.clearTimeout = function() {
      clear();
  }
}