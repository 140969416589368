import React, { useEffect } from 'react';

import { DropdownSelect } from '@/components/ui/dropdown-select';

type UseCase = {
  formUseCaseId: number;
  name: string;
};

interface UseCaseChooserProps {
  useCases: UseCase[];
  label: string;
  onClick: (e) => void;
  value: string;
  onLoad: (e) => void;
}

export const UseCaseChooser: React.FC<UseCaseChooserProps> = ({
  useCases,
  label,
  onClick,
  value,
  onLoad,
}) => {
  useEffect(() => {
    onLoad(false);
  }, []);

  return (
    <>
      <DropdownSelect
        className="use-case-chooser"
        options={useCases.map((useCase) => {
          return {
            value: useCase.formUseCaseId,
            label: useCase.name,
            header: false,
          };
        })}
        label={label}
        onClick={onClick}
        nullOptionLabel="Please choose use case to proceed..."
        selectedValue={value}
      />
    </>
  );
};
