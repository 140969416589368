import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

import { getUserProfile } from '@/actions/actions/user';
import { Button, LabelValueInput, Loading } from '@/components/ui';
import { ProfileHeader } from '@/contexts/profile-page/profile-header';
import { getDiscoveredData, updateDiscoveredData } from '@/actions/actions/discovered_data';

export interface DiscoveredDataPageProps {
  discoveredData: any;
}

export const DiscoveredDataPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootStateOrAny) => {
    return state.user.user;
  });
  const discoveredUserData = useSelector((state: RootStateOrAny) => {
    return state.discoveredData.discoveredData;
  });
  const [discoveredData, setDiscoveredData] = useState([]);
  const [togglePendingRejected, setTogglePendingRejected] = useState(true);

  const handleInputChange = (id, key, value) => {
    discoveredData[id] = {
      key: key,
      value: value
    }
    setDiscoveredData(discoveredData);
  };

  const showPending = () => {
    return !togglePendingRejected && setTogglePendingRejected(true);
  };

  const showRejected = () => {
    return togglePendingRejected && setTogglePendingRejected(false);
  };

  useEffect(() => {

    if (discoveredUserData === null) {
      dispatch(getUserProfile());
      dispatch(getDiscoveredData());
    }

    setDiscoveredData(discoveredUserData)
  }, [discoveredUserData]);

  const rejectApproveFromDiscovered = (idx, status) => {
    discoveredData[idx].status = status;
    setDiscoveredData(discoveredData);
    handleSaveSubmit();
  }

  const handleSaveSubmit = () => {
    dispatch(updateDiscoveredData(discoveredData))
  }

  const styles = {
    marginLeft: '45%',
    marginTop: '5%',
    width: '100%',
  }

  if (user === null) {
    return (
      <div style={styles} >
        <Loading
          height='50px'
          width='50px'
          positionRelative={true}
          ringColor='var(--secondary1)'
        />
      </div>
    );
  }

  return (
    <div className="profile-page bg-blue-gradient page__container">
      <div className="container is-widescreen columns is-vcentered">
        <ProfileHeader currentPage='discovered' />
      </div>
      <div className="info-card container">
        <div className="info-card-header-parent container">
          <h3 className={`info-card-header-link ${togglePendingRejected ? "selected" : ""}`} onClick={showPending}>Discovered Data</h3>
          <h3 className={`info-card-header-link ${!togglePendingRejected ? "selected" : ""}`} onClick={showRejected}>Rejected Data</h3>
        </div>
        {discoveredData && togglePendingRejected ?
          discoveredData.map((data, idx) => {
            if (data.status === 'P' || data.status === undefined || data.status === null) {
              return (
                <LabelValueInput
                  key={data.key+idx}
                  data={data}
                  id={idx}
                  handleChange={handleInputChange}
                  handleConfirm={rejectApproveFromDiscovered}
                  showApproveDeny
                />
              )
            };
          })
          :
          discoveredData && discoveredData.map((data, idx) => {
            if (data.status === 'R' || data.status === undefined || data.status === null) {
              return (
                <LabelValueInput
                  key={data.key+idx}
                  data={data}
                  id={idx}
                  handleChange={handleInputChange}
                  handleConfirm={rejectApproveFromDiscovered}
                />
              )
            };
          })
        }
        {discoveredData && discoveredData.length === 0 && <div>There is no Discovered Data</div>}
      </div>
      <div className="button-container-discover container">
        <Button
          primary={true}
          label="save"
          size="large"
          onClick={handleSaveSubmit}
        />
      </div>
    </div>
  );
};

export default DiscoveredDataPage;
