import moment from 'moment';

export const formatDate = (date: string, format: string): string => {

  if (!date) {
    return '';
  }

  if (moment(date).isValid()) {
    return moment(date).format(format);
  } else {
    return 'Invalid Date';
  }
};
