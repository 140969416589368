import { call, put, takeLatest } from 'redux-saga/effects';

import { PacketFormActions, GetPacketForm, getPacketFormFailure, getPacketFormSuccess } from '@/actions/actions/packet_form';
import Api from '@/api/Api';

export function* getPacketFormSaga(
  action: GetPacketForm
) {
  try {
    const { packetId, packetFormBundleId, packetFormId } = action.payload;

    const url = `/api/v1/packets/${packetId}/packetformbundles/${packetFormBundleId}/packetform/${packetFormId}`;

    const response = yield call(Api.utility.get, url);

    yield put(getPacketFormSuccess(response.data));
  } catch (err) {
    yield put(getPacketFormFailure(err));
  }
}

export function* watchGetPacketForm() {
  yield takeLatest(PacketFormActions.GET_PACKET_FORM, getPacketFormSaga);
}
