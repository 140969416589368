import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PacketsHeader from '@/contexts/packets-page/my-packets/packets-header';
import { PastPacketsTable } from '@/contexts/packets-page/my-packets/past-packets/past-packets-table';
import { PacketCard, PacketCardPaginator, Loading } from '@/components/ui';
import { clearPackets, getPackets } from '@/actions/actions/packets';
import { selectPastPackets } from '@/selectors/packets';
import { selectDeviceType } from '@/selectors/device_type';

const PastPackets: React.FC = () => {
  const dispatch = useDispatch();
  const [cards, setCards] = useState(null);
  const [cardsPerPage, setCardsPerPage] = useState<number>(6);
  const [startIndex, setStartIndex] = useState<number>(0);
  const [endIndex, setEndIndex] = useState<number>(startIndex + cardsPerPage);
  let keyCount = 0;

  let packets = useSelector(selectPastPackets);
  const deviceType = useSelector(selectDeviceType);

  let memoizedPackets = useMemo(() => packets, [packets]);

  const getKey = (prefix) => {
    keyCount = keyCount += 1;
    return `${prefix}-${keyCount}`;
  };

  useEffect(() => {
    dispatch(getPackets(localStorage.getItem('user_id')));

    return () => {
      dispatch(clearPackets());
    };
  }, []);

  useEffect(() => {
    setEndIndex(startIndex + cardsPerPage);
  }, [startIndex]);

  useEffect(() => {
    if (packets) {
      setCards(packets.slice(startIndex, endIndex));
    }
  }, [packets, endIndex]);

  const navigate = (directionOrPageNumber) => {
    if (typeof directionOrPageNumber === 'number') {
      setStartIndex(cardsPerPage * directionOrPageNumber);
    }
  };

  return (
    <div className="create-packet page__container bg-blue-gradient ">
      <div className="create-packet__container container">
        <div className="create-packet__actions section is-12 is-widescreen">
          <PacketsHeader currentPage="past" />
          {deviceType === 'mobile' ? (
            <>
              <div className="packet-card-table">
                {cards &&
                  cards?.map((packet) => {
                    return (
                      <PacketCard
                        key={getKey('packet-card')}
                        packet={packet}
                        type="active"
                      />
                    );
                  })}
              </div>
              <PacketCardPaginator
                navigate={navigate}
                filteredItems={packets}
                itemsPerPage={cardsPerPage}
              />
            </>
          ) : (
            memoizedPackets && <PastPacketsTable packets={memoizedPackets} />
          )}
          {!memoizedPackets && (
            <Loading
              ringColor="#fff"
              height="70vh"
              width="100%"
              positionRelative={true}
              message="Loading Past Packets..."
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PastPackets;
