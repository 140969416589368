import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUsers } from '@/actions/actions/users';
import { selectUsers } from '@/selectors/users';
import moment from 'moment';

import { InfoCard, Loading } from '@/components/ui';

import { Packet } from '@/types/packet';

export interface PacketDetailsProps {
  packet: Packet;
  setIsActive: any;
}

export const PacketDetails: React.FC<PacketDetailsProps> = ({
  packet,
  setIsActive,
}) => {
  const allUsers = useSelector(selectUsers);
  const dispatch = useDispatch();
  const _details = useRef<HTMLDivElement>();

  useEffect(() => {
    window.addEventListener('scroll', resizeWizard);
    window.addEventListener('resize', resizeWizard);
    window.scrollTo(0, 0);

    return () => {
      window.removeEventListener('scroll', resizeWizard);
      window.removeEventListener('resize', resizeWizard);
    };
  }, []);

  useEffect(() => {
    !allUsers && dispatch(getUsers());
  }, [allUsers]);

  const resizeWizard = () => {
    document
      .querySelector<HTMLElement>(':root')
      .style.setProperty(
        '--wizardHeight',
        `${_details?.current?.clientHeight}px`,
      );
  };

  const parseGeneralDetails = () => {
    let cardInfoData = [
      {
        label: 'Requested by:',
        value: findUser(packet['creatorUserId']),
        type: 'string',
      },
      {
        label: 'Requested:',
        value: `${moment(packet['createdDateTimeUtc']).format('L')} at ${moment(
          packet['createdDateTimeUtc'],
        ).format('h:mm a')}`,
        type: 'string',
      },
      {
        label: 'Due:',
        value: moment(packet['createdDateTimeUtc']).add(35, 'days').calendar(),
        type: 'string',
      },
    ];
    return cardInfoData;
  };

  const includedForms = () => {
    let packetForms = [];
    packet['packetFormBundles'].map((bundle) => {
      let formBundle = {
        title: bundle['packetFormBundleName'],
        data: [],
      };
      bundle['packetForms'].forEach((packetForm) => {
        formBundle['data'].push(packetForm['formSummary']['formName']);
      });
      packetForms.push(formBundle);
    });
    return packetForms;
  };

  const findUser = (userId) => {
    let packetCreator = undefined;
    if (allUsers) {
      allUsers.forEach((user) => {
        if (userId === user['userId']) {
          packetCreator = `${user['firstName']} ${user['lastName']}`;
        }
      });
    }
    return packetCreator;
  };

  const includedApprovers = () => {
    let approvers = [];
    if (packet['approvalPath']) {
      let stops = packet['approvalPath']['stops'];
      stops.map((stop) => {
        let approver = {
          title: stop['displayName'],
          data: [],
        };
        if (stop['approvalStopDetail']) {
          stop['approvalStopDetail']['users'].forEach((user) => {
            approver['data'].push(findUser(user['userId']));
          });
        }
        approvers.push(approver);
      });
    }
    return approvers;
  };

  const includedFiles = () => {
    let packetFiles = [];
    packet['packetFormBundles'].map((bundle) => {
      bundle['packetForms'].forEach((packetForm) => {
        if (packetForm['files'].length > 0) {
          packetForm['files'].forEach((file) => {
            packetFiles.push(file);
          });
        }
      });
    });
    if (packetFiles.length > 0) {
      return packetFiles;
    } else {
      return ['No files attached'];
    }
  };

  if (!packet) {
    return <Loading ringColor="#201f35" message="Loading..." />;
  }

  return (
    <div className="packet-details" ref={_details}>
      <div>
        <div className="columns">
          <div className="column is-6">
            <InfoCard
              edit={false}
              onClick={null}
              header="General Details"
              data={parseGeneralDetails()}
              toggleData
            />
          </div>
          <div className="column is-6">
            <InfoCard
              edit={false}
              onClick={null}
              header="Included Forms"
              hasLink={true}
              linkLabel="View Form Details"
              linkAction={setIsActive}
              linkActionPage="forms"
              component="parent-value-list"
              data={includedForms()}
              toggleData
            />
          </div>
        </div>

        <div className="columns">
          <div className="column is-6">
            <InfoCard
              edit={false}
              onClick={null}
              header="Attached Files"
              component="attachment-list"
              toggleData
              data={includedFiles()}
            />
          </div>
          <div className="column is-6">
            {includedApprovers().length ? (
              <InfoCard
                edit={false}
                onClick={null}
                header="Approvers"
                hasLink={true}
                linkLabel="View Approver Details"
                linkAction={setIsActive}
                linkActionPage="approvers"
                component="parent-value-list"
                data={includedApprovers()}
                toggleData
              />
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PacketDetails;
