import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearPackets, getTeamPackets } from '@/actions/actions/packets';
import { selectTeamApprovalNeededPackets } from '@/selectors/packets';
import TeamPacketsHeader from '../team-packets-header';
import { ApprovalNeedeTable } from '@/contexts/packets-page/team-packets/approval-needed-packets/approval-needed-table';
import { PacketCard, PacketCardPaginator, Loading } from '@/components/ui';
import { getUsers } from '@/actions/actions/users';
import { selectUsers } from '@/selectors/users';
import { selectDeviceType } from '@/selectors/device_type';

const TeamApprovalNeededPackets: React.FC = () => {
  const dispatch = useDispatch();
  let keyCount = 0;
  const [cards, setCards] = useState(null);
  const [cardsPerPage, setCardsPerPage] = useState<number>(6);
  const [startIndex, setStartIndex] = useState<number>(0);
  const [lastPageReached, setLastPageReached] = useState(false);
  const [endIndex, setEndIndex] = useState<number>(startIndex + cardsPerPage);

  const getKey = (prefix) => {
    keyCount = keyCount += 1;
    return `${prefix}-${keyCount}`;
  };

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getTeamPackets(localStorage.getItem('user_id')));
    return () => {
      dispatch(clearPackets());
    };
  }, []);

  const packets = useSelector(selectTeamApprovalNeededPackets);
  const memoizedPackets = useMemo(() => packets, [packets]);
  const users = useSelector(selectUsers);
  const deviceType = useSelector(selectDeviceType);

  useEffect(() => {
    setEndIndex(startIndex + cardsPerPage);
  }, [startIndex]);

  useEffect(() => {
    if (packets) {
      setCards(packets.slice(startIndex, endIndex));
    }
  }, [packets, endIndex]);

  const navigate = (directionOrPageNumber) => {
    if (typeof directionOrPageNumber === 'number') {
      setStartIndex(cardsPerPage * directionOrPageNumber);
    }
  };

  return (
    <div className="create-packet page__container bg-blue-gradient ">
      <div className="create-packet__container container">
        <div className="create-packet__actions section is-12 is-widescreen">
          <TeamPacketsHeader currentPage="approval-needed" />
          {deviceType === 'mobile' ? (
            <>
              <div className="packet-card-table">
                {cards &&
                  cards?.map((packet) => {
                    return (
                      <PacketCard
                        key={getKey('packet-card')}
                        packet={packet}
                        type="active"
                      />
                    );
                  })}
              </div>
              <PacketCardPaginator
                navigate={navigate}
                filteredItems={packets}
                itemsPerPage={cardsPerPage}
              />
            </>
          ) : (
            memoizedPackets &&
            users && (
              <ApprovalNeedeTable packets={memoizedPackets} users={users} />
            )
          )}
          {!memoizedPackets && (
            <Loading
              ringColor="#fff"
              height="70vh"
              width="100%"
              positionRelative={true}
              message="Loading Packets..."
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamApprovalNeededPackets;
