import { User } from "@/types/user";

// Action Types
export enum UserActions {
  GET_USER = 'USER/GET_USER',
  GET_USER_SUCCESS = 'USER/GET_USER_SUCCESS',
  GET_USER_FAILURE = 'USER/GET_USER_FAILURE',
  GET_USER_PROFILE = 'USER/GET_USER_PROFILE',
  GET_ON_BEHALF_OF_USER_PROFILE = 'USER/GET_ON_BEHALF_OF_USER_PROFILE',
  GET_ON_BEHALF_OF_USER_PROFILE_SUCCESS = 'USER/GET_ON_BEHALF_OF_USER_PROFILE_SUCCESS',
  GET_ON_BEHALF_OF_USER_PROFILE_FAILURE = 'USER/GET_ON_BEHALF_OF_USER_PROFILE_FAILURE',
  UPDATE_USER_PROFILE = 'USER/UPDATE_USER_PROFILE',
  UPDATE_USER_SUCCESS = 'USER/UPDATE_USER_SUCCESS',
}

// Action Interfaces
export interface GetUser {
  type: typeof UserActions.GET_USER;
  payload: null;
}

export interface GetUserSuccess {
  type: typeof UserActions.GET_USER_SUCCESS;
  payload: {
    user: User;
  }
}

export interface GetUserFailure {
  type: typeof UserActions.GET_USER_FAILURE;
  payload: {
    error: string;
  }
}

export interface GetUserProfile {
  type: typeof UserActions.GET_USER_PROFILE;
  payload: null
}

export interface GetOnBehalfOfUserProfile {
  type: typeof UserActions.GET_ON_BEHALF_OF_USER_PROFILE;
  payload: {
    userId: string;
  }
}

export interface GetOnBehalfOfUserProfileSuccess {
  type: typeof UserActions.GET_ON_BEHALF_OF_USER_PROFILE_SUCCESS;
  payload: {
    user: User;
  }
}

export interface GetOnBehalfOfUserProfileFailure {
  type: typeof UserActions.GET_ON_BEHALF_OF_USER_PROFILE_FAILURE;
  payload: {
    error: string;
  }
}

export interface UpdateUserProfile {
  type: typeof UserActions.UPDATE_USER_PROFILE;
  payload: {
    user: User;
  }
}

export interface UpdateUserSuccess {
  type: typeof UserActions.UPDATE_USER_SUCCESS;
  payload: {
    user: User;
  }
}

// Action Type List
export type UserAction = GetUser | GetUserProfile | GetUserFailure | GetUserSuccess | UpdateUserProfile | UpdateUserSuccess | GetOnBehalfOfUserProfile | GetOnBehalfOfUserProfileSuccess | GetOnBehalfOfUserProfileFailure;

// Action Creators
export const getUser = (): UserAction => ({
  type: UserActions.GET_USER,
  payload: null,
});

export const getUserSuccess = (user: User): UserAction => ({
  type: UserActions.GET_USER_SUCCESS,
  payload: {
    user,
  }
});

export const getUserFailure = (error: string): UserAction => ({
  type: UserActions.GET_USER_FAILURE,
  payload: {
    error,
  }
});

export const getOnBehalfOfUserProfile = (userId: string): UserAction => ({
  type: UserActions.GET_ON_BEHALF_OF_USER_PROFILE,
  payload: {
    userId,
  }
});

export const getOnBehalfOfUserProfileSuccess = (user: User): UserAction => ({
  type: UserActions.GET_ON_BEHALF_OF_USER_PROFILE_SUCCESS,
  payload: {
    user,
  }
});

export const getOnBehalfOfUserProfileFailure = (error: string): UserAction => ({
  type: UserActions.GET_ON_BEHALF_OF_USER_PROFILE_FAILURE,
  payload: {
    error,
  }
});

export const getUserProfile = (): UserAction => ({
  type: UserActions.GET_USER_PROFILE,
  payload: null
});

export const updateUserProfile = (user: User): UserAction => ({
  type: UserActions.UPDATE_USER_PROFILE,
  payload: {
    user,
  }
});

export const updateUserSuccess = (user: User): UserAction => ({
  type: UserActions.UPDATE_USER_SUCCESS,
  payload: {
    user,
  }
});
