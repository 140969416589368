import { call, put, takeLatest } from 'redux-saga/effects';

import { FormsActions, getFormsFailure, getFormsSuccess } from '@/actions/actions/forms';
import Api from '@/api/Api';
import { buildPath, Routes } from '@/utils/routeUtils';

export function* getFormsSaga() {
  try {
    const url = buildPath(Routes.api.forms, null)

    const response = yield call(Api.utility.get, url);

    yield put(getFormsSuccess(response));
  } catch (err) {
    yield put(getFormsFailure(err));
  }
}

export function* watchGetForms() {
  yield takeLatest(FormsActions.GET_FORMS, getFormsSaga);
}
