import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

import { InfoCard, Button, Loading } from '@/components/ui';
import { PacketSignature } from '@/contexts/packets-page/approve-deny-packet/packet-signature';
import { denyPacket, getPacket } from '@/actions/actions/packet';
import { getUsers } from '@/actions/actions/users';
import { selectUsers } from '@/selectors/users';
import { selectPacket } from '@/selectors/packet';
import { PacketActions } from '@/contexts/packets-page/packet-actions';

interface ParamTypes {
  packetId: string;
}

export interface ApproveDenyPacketProps {
  type: string;
}

const ApproveDenyPacket: React.FC<ApproveDenyPacketProps> = ({ type }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const packet = useSelector(selectPacket);
  const allUsers = useSelector(selectUsers);
  const { packetId } = useParams<ParamTypes>();

  useEffect(() => {
    packetId && dispatch(getPacket(parseInt(packetId)));
    window.scrollTo(0, 0);
  }, [packetId]);

  useEffect(() => {
    packet &&
      packet.statusId !== 3 &&
      history.push(`/packets/packet/${packetId}`);
  }, [packet]);

  useEffect(() => {
    !allUsers && dispatch(getUsers());
  }, [allUsers]);

  const parseGeneralDetails = () => {
    let cardInfoData = [
      {
        label: 'Requested by:',
        value: findUser(packet['creatorUserId']),
        type: 'string',
      },
      {
        label: 'Requested:',
        value: `${moment(packet['createdDateTimeUtc']).format('L')} at ${moment(
          packet['createdDateTimeUtc'],
        ).format('h:mm a')}`,
        type: 'string',
      },
      {
        label: 'Due:',
        value: moment(packet['createdDateTimeUtc']).add(35, 'days').calendar(),
        type: 'string',
      },
    ];
    return cardInfoData;
  };

  const includedForms = () => {
    let packetForms = [];
    packet['packetFormBundles'].map((bundle) => {
      let formBundle = {
        title: bundle['packetFormBundleName'],
        data: [],
      };
      bundle['packetForms'].forEach((packetForm) => {
        formBundle['data'].push(packetForm['formSummary']['formName']);
      });
      packetForms.push(formBundle);
    });
    return packetForms;
  };

  const findUser = (userId) => {
    let packetCreator = undefined;
    if (allUsers) {
      allUsers.forEach((user) => {
        if (userId === user['userId']) {
          packetCreator = `${user['firstName']} ${user['lastName']}`;
        }
      });
    }
    return packetCreator;
  };

  const includedApprovers = (packet) => {
    let approvers = [];
    let stops = packet['approvalPath']['stops'];
    stops.map((stop) => {
      let approver = {
        title: stop['displayName'],
        data: [],
      };
      if (stop['approvalStopDetail']) {
        stop['approvalStopDetail']['users'].forEach((user) => {
          approver['data'].push(findUser(user['userId']));
        });
      }
      approvers.push(approver);
    });
    return approvers;
  };

  const includedFiles = () => {
    let packetFiles = [];
    packet['packetFormBundles'].map((bundle) => {
      bundle['packetForms'].forEach((packetForm) => {
        if (packetForm['files'].length > 0) {
          packetForm['files'].forEach((file) => {
            packetFiles.push(file);
          });
        }
      });
    });
    if (packetFiles.length > 0) {
      return packetFiles;
    } else {
      return ['No files attached'];
    }
  };

  if (!allUsers || !packet) {
    return (
      <Loading
        ringColor="#fff"
        height="70vh"
        width="100%"
        positionRelative={true}
        message="Loading Packet..."
      />
    );
  }

  return (
    <div className="bg-blue-gradient page__container approve-deny">
      {packet ? (
        <div className="approve-deny__container--inner">
          <PacketActions>
            <Button
              backgroundColor="transparent"
              iconClass="back"
              label="Back"
              iconColor="white"
              labelColor="white"
              onClick={() => history.push(`/packets/packet/${packetId}`)}
              primary={true}
              size="small"
              transformText={false}
              border={false}
            />
          </PacketActions>
          <div className="approve-deny__container">
            <div className="page__header container">
                {(type === 'approve' && <h1>
                  approve packet
                </h1>)}
                {(type === 'reject-or-deny' && <h1>
                  reject packet
                </h1>)}
            </div>
            <InfoCard
              edit={false}
              onClick={null}
              header="General Details"
              data={parseGeneralDetails()}
              toggleData
            />
            <InfoCard
              edit={false}
              onClick={null}
              header="Included Forms"
              component="parent-value-list"
              data={includedForms()}
              toggleData
            />
            <InfoCard
              edit={false}
              onClick={null}
              header="Approvers"
              component="parent-value-list"
              data={includedApprovers(packet)}
              toggleData
            />
            <InfoCard
              edit={false}
              onClick={null}
              header="Attached Files"
              component="attachment-list"
              data={includedFiles()}
              toggleData
            />
            <PacketSignature
              packetId={packet['packetId']}
              header={
                (type === 'approve' && 'approve packet') ||
                (type === 'reject-or-deny' && 'reject or deny packet')
              }
              action={
                (type === 'approve' && 'Approving') ||
                (type === 'reject-or-deny' && 'Rejecting')
              }
              handleSubmit={type === 'reject-or-deny' && denyPacket}
              decisionType={type}
            />
          </div>
        </div>
      ) : (
        <Loading
          ringColor="#fff"
          height="70vh"
          width="100%"
          positionRelative={true}
          message="Loading Packet..."
        />
      )}
    </div>
  );
};

export default ApproveDenyPacket;
