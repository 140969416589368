import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import store from '@/actions/configureStore';
import history from '@/contexts/app/history';
import { getUserProfile } from '@/actions/actions/user';
import Layout from '@/components/layout';
import ProfilePage from '@/contexts/profile-page';
import DiscoveredDataPage from '@/contexts/profile-page/discovered-data-page';
import PacketsPage from '@/contexts/packets-page';
import CreatePacket from '@/contexts/create-packet-page';
import UnsentPackets from '@/contexts/packets-page/my-packets/unsent-packets';
import PastPackets from '@/contexts/packets-page/my-packets/past-packets';
import SubmittedPackets from '@/contexts/packets-page/my-packets/submitted-packets';
import Login from '@/contexts/login';
import ApproveDenyPacket from '@/contexts/packets-page/approve-deny-packet';
import TeamApprovalNeededPackets from '@/contexts/packets-page/team-packets/approval-needed-packets';
import TeamPastPackets from '@/contexts/packets-page/team-packets/past-packets';
import PageNotFound from '@/contexts/404-page';
import { PrivateRoute } from '@/components/private-route';
import { selectUser } from '@/selectors/user';
import { Button, Modal } from '@/components/ui';
import SendPacket from '@/contexts/packets-page/send-packet';
import CreateNew from '@/contexts/packets-page/send-packet/create-new';

const appendMetaTag = () => {
  let link = document.createElement('meta');
  link.setAttribute('name', 'viewport');
  link.content = 'width=device-width, initial-scale=1';
  document.getElementsByTagName('head')[0].appendChild(link);
};

const GetUser = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [didShowModal, setDidShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (!user) {
      dispatch(getUserProfile());
    }
  });

  if (user && !user.isProfileComplete && !didShowModal) {
    setDidShowModal(true);
    setShowModal(true);
  }

  const onClickClose = () => {
    setShowModal(false);
  };

  const onButtonClick = () => {
    history.push('/profile');
    setShowModal(false);
  };

  const showComplete = () => {
    if (showModal) {
      return (
        <Modal handleClickX={() => onClickClose()}>
          <div className="file-upload__modal" style={{ textAlign: 'center' }}>
            <p style={{ fontSize: 20, fontWeight: 'bold' }}>
              Please complete your profile to ensure forms are populating
              correctly.
            </p>
            <Button
              className="md-width-100 md-margin-btm-20"
              backgroundColor="#3a4886"
              labelColor="#fff"
              onClick={onButtonClick}
              size={'large'}
              primary={true}
              label="Complete Profile"
            />
          </div>
        </Modal>
      );
    }
  };

  return (
    <>
      {showComplete()}
      {children}
    </>
  );
};
document.addEventListener('DOMContentLoaded', () => {
  require('@rails/ujs').start();
  require('@rails/activestorage').start();
  require('channels');

  // SENTRY INIT
  Sentry.init({
    dsn:
      'https://e82c1f5c4d8c4481afaf461b85647ba7@o401182.ingest.sentry.io/5666877',
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
  // SENTRY INIT

  const unauthRoutes = (
    <Switch>
      <Route path="*" exact component={Login} />
    </Switch>
  );

  const authRoutes = (
    //@ts-ignore
    <Switch>
      <Route path="/" exact component={UnsentPackets} />
      <Redirect from="/packets" to="/" exact />
      <Route path="/packets/create-packet" exact component={CreatePacket} />
      <Route
        path="/packets/submitted-packets"
        exact
        component={SubmittedPackets}
      />
      <Route path="/packets/past-packets" exact component={PastPackets} />
      <Route path="/packets/packet/:packetId" exact component={PacketsPage} />
      <Route
        path="/packets/packet/:packetId/send"
        exact
        component={SendPacket}
      />
      <Route
        path="/packets/packet/:packetId/send/create"
        exact
        component={CreateNew}
      />
      <PrivateRoute
        path="/packets/team-packets/approval-needed"
        component={TeamApprovalNeededPackets}
        roles={['PowerUser', 'Approver']}
      />
      <PrivateRoute
        path="/packets/team-packets/past"
        component={TeamPastPackets}
        roles={['PowerUser', 'Approver']}
      />
      <Route path="/profile" exact component={ProfilePage} />
      <Route
        path="/profile/discovered-data"
        exact
        component={DiscoveredDataPage}
      />
      <PrivateRoute
        path="/packets/:packetId/reject-or-deny"
        roles={['PowerUser', 'Approver']}
        render={<ApproveDenyPacket type="reject-or-deny" />}
      />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );

appendMetaTag();

ReactDOM.render(
  <Provider store={store}>

    <ToastContainer
      autoClose={3000}
      transition={Slide}
      pauseOnFocusLoss={false}
    />
    <Router history={history}>
      <GetUser>
        <Layout>
          {localStorage.getItem('user_id') ? authRoutes : unauthRoutes}
        </Layout>
      </GetUser>
    </Router>

  </Provider>,
  document.getElementById('app'),
);
});
