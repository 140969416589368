import { combineReducers } from 'redux';

import AppState from '@/types/app';
import user from './user';
import packet from './packet';
import packets from './packets';
import packetForm from './packet_form';
import formBundles from './form_bundles';
import approvalPaths from './approval_path';
import approvalTimelines from './approval_timeline';
import users from './users';
import forms from './forms';
import fieldValues from './field_values';
import discoveredData from './discovered_data';
import onBehalfOfUserProfile from './on_behalf_of_user_profile';
import deviceType from './device_type';

const allReducers = combineReducers({
  user,
  packet,
  packets,
  packetForm,
  formBundles,
  approvalPaths,
  approvalTimelines,
  users,
  forms,
  fieldValues,
  discoveredData,
  onBehalfOfUserProfile,
  deviceType,
});

const rootReducer = (state: AppState, action: any) => {
  return allReducers(state, action);
};

export default rootReducer;
